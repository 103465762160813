import { onlyNum } from "./regax";

let monthOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
let dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
let dayOfWeekEN = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

let year, month, date, day;

let today = new Date();

export function getDefaultDay() {
  let defaultDate = new Date();
  let defaultDateTime = defaultDate.getTime() + 7 * 24 * 60 * 60 * 1000;

  defaultDate.setTime(defaultDateTime);

  year = defaultDate.getFullYear();
  month = defaultDate.getMonth() + 1;
  date = defaultDate.getDate();
  day = dayOfWeek[defaultDate.getDay()];

  if (month.toString().length === 1) {
    month = "0" + month;
  }

  if (date.toString().length === 1) {
    date = "0" + date;
  }

  return `${year}. ${month}. ${date} (${day})`;
}

export function getTodayDate() {
  year = today.getFullYear();
  month = today.getMonth() + 1;

  if (month.toString().length === 1) {
    month = "0" + month;
  }

  date = today.getDate();

  if (date.toString().length === 1) {
    date = "0" + date;
  }

  return `${year}-${month}-${date}`;
}

export function parseDate(arr) {
  // console.log(date.split(" ", 4));
  arr = arr.split(" ", 4);

  year = arr[3];
  month = monthOfYear.indexOf(arr[1]) + 1;
  date = arr[2];
  day = dayOfWeek[dayOfWeekEN.indexOf(arr[0])];

  if (month.toString().length === 1) {
    month = "0" + month;
  }

  if (date.toString().length === 1) {
    date = "0" + date;
  }

  //   return arr.split(" ", 4).join(" ");
  return `${year}. ${month}. ${date} (${day})`;
}

export function parseDatewithDash(arr) {
  arr = arr.replace(onlyNum, "");
  arr = [...arr];
  arr.splice(4, 0, "-");
  arr.splice(7, 0, "-");

  arr.join("");

  return arr.join("");
}

export function parseDateOnlyNum(arr) {
  arr = arr.replace(onlyNum, "");
  return arr;
}
