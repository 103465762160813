import React from "react";

import abledPlus from "../../images/icons/abledPlus.png";
import abledMinus from "../../images/icons/abledMinus.png";
import disabledMinus from "../../images/icons/disabledMinus.png";

function CountButton(props) {
  const [minpax] = React.useState(
    props.minpax === undefined ? 0 : props.minpax
  );
  return (
    <div style={{ height: "17px", width: "60px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <img
          src={
            props.num <= props.minpax || props.num === 0
              ? disabledMinus
              : abledMinus
          }
          alt=""
          style={{ height: "17px" }}
          onClick={() => {
            if (props.num !== 0) {
              if (props.num > minpax) props.setNum(props.num - 1);
            }
          }}
        />
        <div>{props.num}</div>
        <img
          src={abledPlus}
          alt=""
          style={{ height: "17px" }}
          onClick={() => {
            props.setNum(props.num + 1);
          }}
        />
      </div>
    </div>
  );
}

export default CountButton;
