import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import PageHeader from "../../components/views/Layout/Header/PageHeader";

import { CircularButton } from "../../styles/GlobalStyle";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function MyPage() {
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  const [isLogined, setIsLogined] = useState(
    window.sessionStorage.getItem("email") && true
  );
  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));
  const [companyName,setCompanyName] = useState(window.sessionStorage.getItem("companyName"));

  const navigate = useNavigate();

  return (
  
    <div className="container">
      <PageHeader />
      <div className="m-5 w-8">
        {!isLogined ? (
          <div>
            <CircularButton
              backgroundcolor="#5c80ff"
              onClick={() => {
                navigate("/login");
              }}
            >
              {t("MYPAGE.mypage01")}
            </CircularButton>
            <CircularButton
              className="m-1"
              color="#5c80ff"
              onClick={() => {
                navigate("/register");
              }}
            >
              {t("MYPAGE.mypage02")}
            </CircularButton>
          </div>
        ) : (
          <div>
            <div className="text-sm text-bold" style={{}}>내 계정</div>
            <div style={{ padding: "10px 0", borderBottom: "1px solid #252525" ,display:"flex",alignItems:"center"}}><span>{email}</span>{companyName ? (<div style={{marginLeft:'auto',background:"#FFC73C",color:"#FFF", border:"2px solid #6C4F00",padding:"3px 7px",borderRadius:"5px"}}>플랜 크리에이터</div>):null} </div>
            {companyName ? (
              <CircularButton
              className="m-2"
              backgroundcolor="#4472C4"
              style={{border:"2px solid #172C51"}}
              onClick={() => {
                 navigate("/mypage/myoption");
              }}
            >
              내 상품 관리
            </CircularButton>
            ):null}
            <CircularButton
              className="m-2"
              backgroundcolor="#5c80ff"
              onClick={() => {
                alert(t("MYPAGE.mypagePOP2"));
                window.sessionStorage.removeItem("email");
                window.sessionStorage.removeItem("token");
                window.sessionStorage.removeItem("companyName");
                setIsLogined(!isLogined);
              }}
            >
              로그아웃
            </CircularButton>
          </div>
        )}
      </div>
      <div className="m-3 w-9">
        <MyPageBlock
          style={{ borderTop: "1px solid #CCCCCC" }}
          onClick={() => {
            if (!isLogined) {
              alert(t("MYPAGE.mypagePOP1"));
            } else {
              navigate("/mypage/myplan");
            }
          }}
        >
          {t("MYPAGE.mypage03")}
        </MyPageBlock>
        <MyPageBlock
          onClick={() => {
            if (!isLogined) {
              alert(t("MYPAGE.mypagePOP1"));
            } else {
              navigate("/mypage/makingPlan");
            }
          }}
        >
          {t("MYPAGE.mypage04")}
        </MyPageBlock>
        <MyPageBlock>{t("MYPAGE.mypage05")}</MyPageBlock>
        <MyPageBlock
          onClick={() => {
            if (!isLogined) {
              alert(t("MYPAGE.mypagePOP1"));
            } else {
              navigate("/mypage/wishlist");
            }
          }}
        >
          {t("MYPAGE.mypage06")}
        </MyPageBlock>
        <MyPageBlock
          onClick={() => {
            navigate("/mypage/cs");
          }}
        >
          {t("MYPAGE.mypage07")}
        </MyPageBlock>
        
        {isLogined && (
          <MyPageBlock onClick={() => navigate("/mypage/changeInfo")}>
            회원정보 변경
          </MyPageBlock>
        )}
        {isLogined && (
          <MyPageBlock onClick={() => navigate("/mypage/changePassword")}>
            비밀번호 변경
          </MyPageBlock>
        )}
      </div>
    </div>
  );
}

const MyPageBlock = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid #cccccc;
  cursor : pointer;
`;

export default MyPage;
