import React from "react";
import Sheet from "react-modal-sheet";

import closeIcon from "../../images/icons/closeIcon.png";

function MySheet(props) {
  React.useEffect(() => {
    props.isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [props.isOpen]);

  return (
    <Sheet
      isOpen={props.isOpen}
      onClose={() => props.setOpen(false)}
      snapPoints={props.snapPoints ? [props.snapPoints] : [550]}
      disableDrag={true}
    >
      <Sheet.Container
        style={{
          maxWidth: "600px",
          left: "50%",
          marginLeft: "-300px",
        }}
      >
        <Sheet.Header>
          <div
            style={{
              margin: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src={closeIcon}
              alt=""
              width="10px"
              onClick={() => {
                props.setOpen(false);
              }}
            />
            <div>{props.header}</div>
            <div
              style={{ fontSize: "14px", fontWeight: 600, color: "#5c80ff" }}
              onClick={props.handleClick}
            >
              {props.headerButton}
            </div>
          </div>
        </Sheet.Header>
        <Sheet.Content>{props.children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
}

export default MySheet;
