import React from "react";
import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import { CircularButton } from "../../styles/GlobalStyle";

import checkIcon from "../../images/icons/check.png";

function RegisterEndPage() {
  const navigate = useNavigate();
  return (
    <div className="container" style={{ height: "100vh" }}>
      <PageHeader title="회원가입" />
      <div className="w-9" style={{ textAlign: "center" }}>
        <div
          style={{
            margin: "25vh auto 0",
            height: "100px",
            width: "100px",
            border: "7px solid #5c80ff",
            borderRadius: "100px",

            position: "relative",
          }}
        >
          <img
            src={checkIcon}
            alt=""
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
        <div className="m-4 text-xl text-bold">축하합니다!</div>
        <div className="m-1 text-sm">회원가입이 완료되었습니다.</div>
      </div>
      <div className="w-8">
        <CircularButton
          style={{ marginTop: "25vh" }}
          backgroundcolor="#5C80ff"
          onClick={() => {
            window.sessionStorage.setItem("isFirstLogin", "yes");
            navigate("/login");
          }}
        >
          로그인
        </CircularButton>
      </div>
    </div>
  );
}

export default RegisterEndPage;
