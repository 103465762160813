import { selector } from "recoil";
import { request } from "../utils";

export const getOrdList = selector({
  key: "getOrdList",
  get: async () => {
    const res = await request("GET", `/ord/countries/ko`);

    console.log(res);

    return res;
  },
});
