/* AWS */
import AWS from "aws-sdk";

/* 젤 앞 대문자 설정 */
export function capitalizeFirstLetter(str) {
  if (str.length === 0) {
    return str;
  }

  const firstLetter = str.charAt(0).toUpperCase();
  const remainingLetters = str.slice(1);

  return firstLetter + remainingLetters;
};

/* Image Upload */
export const uploadS3 = async (files, email, city) => {
  const resultList = [];
  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_S3,
    secretAccessKey: process.env.REACT_APP_Secret_KEY_S3,
  });

  for (const file of files) {
    let title = city + "_" + generateRandomNumber(10);
    console.log(file);

    const upload = new AWS.S3.ManagedUpload({
      params: {
        ACL: "public-read",
        Bucket: "mygmp",
        Key: `master/UserUpld/${email}/${title}.jpg`,
        Body: file,
      },
    });

    try {
      const data = await upload.promise();
      console.log(data);
      resultList.push(data);
    } catch (err) {
      console.error(err);
    }
  }

  return resultList; // 완료된 업로드 결과를 반환합니다.
};

/* Random */
export const generateRandomNumber = (digits) => {
  let randomNumber = '';

  for (let i = 0; i < digits; i++) {
    randomNumber += Math.floor(Math.random() * 10); // 0부터 9까지의 랜덤한 수
  }

  return randomNumber;
};


/* 12글자까지 자르기 설정 */
export const truncateString = (str) => {
  const index = str.indexOf("@");
  if (index !== -1 && index <= 12) {
    return str.slice(0, index);
  } else if (str.length > 12) {
    return str.slice(0, 12);
  } else {
    return str;
  }
}