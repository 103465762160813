import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationKo from "./translation_ko.json";
import translationEn from "./translation_en.json";
import translationZhchs from "./translation_zhchs.json";
import translationZhcht from "./translation_zhcht.json";

const resource = {
//국가 코드와 JSON 파일 match
    ko: {
        translation: translationKo,
    },
    en: {
        translation: translationEn,
    },
    zhchs: {
        translation: translationZhchs,
    },
    zhcht: {
        translation: translationZhcht,
    },
};

i18n.use(initReactI18next).init({
    resources: resource,
    lng: "ko", //default 국가 코드
    debug: true,
    keySeparator: false,
    interpolation: { escapeValue: false },
});

//브라우저 DB에 기록된 언어가 있으면 해당 언어를 불러온다.
// let language = localStorage.getItem("language");
let language = (localStorage.getItem("language") !== 'undefined' && localStorage.getItem("language") !== null) ? localStorage.getItem("language") : localStorage.setItem("language", "ko");
if(language !== null)
    i18n.changeLanguage(language);

//국가 코드를 변경하는 함수
export function changeLanguage() {
    // console.log("changeLanguage language : " + language);
    // console.log("changeLanguage i18n.language 11 : " + i18n.language);
    // i18n.changeLanguage(i18n.language === "ko" ? "en" : "ko");
    // console.log("changeLanguage i18n.language 22 : " + i18n.language);
    // i18n.changeLanguage(i18n.language);
    // i18n.changeLanguage(language);
    //브라우저 DB에 기록
    // localStorage.setItem("language", i18n.language);
}

export default i18n;