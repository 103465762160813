import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import Slider from "react-slick";

import { useTranslation } from "react-i18next";

import { img_url, server_url } from "../../server";

import homeMenu from "../../images/icons/homeMenu.png";
import backArrow from "../../images/icons/backArrow.png";

import PageHeader from "../../components/views/Layout/Header/PageHeader";

/* Img */
import img_addPhoto from "../../images/icons/file_add.svg"
import img_default from "../../images/img/img_default.png"
import img_modify from "../../images/icons/pencil.svg"
import img_camera from "../../images/img/img_camera.svg"
import img_add from "../../images/icons/file_add.svg"

/* AWS */
import AWS from "aws-sdk";

import Switch from '@mui/material/Switch';

/* Google Map */
import GoogleMapPolylineComponent from "../../components/GoogleMap/GoogleMapPolylineComponent";


function PlanSlotUpdateOption(props) {
  const location = useLocation();
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const [email] = useState(window.sessionStorage.getItem("email"));
  const [data, setData] = useState();

  //Switch
  const [switchBool, setSwitchBool] = useState(false);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [timehhNm, setTimehhNm] = useState("");
  const [timemmNm, setTimemmNm] = useState("00");

  //Time
  const hours = [];
  const minutes = [];

  for (let i = 0; i <= 24; i++) {
    let no = i;
    if(i<10){
      no = '0'+i;
    }
    hours.push(no);
  }
  for (let i = 0; i < 60; i++) {
    let no = i;
    if (i < 10) {
      no = '0' + i;
    }
    minutes.push(no);
  }

  //SwitchList
  const [spotActivation, setSpotActivation] = useState([]);

  //플랜 ID
  const [planSq,setPlanSq] = useState("");
  //플랜 코드네임
  const [planCd,setPlanCd] = useState("");
  //옵션 ID
  const [optionSq, setOptionSq] = useState(0);
  const [optionCd,setOptionCd] = useState("");
  //OrderNo
  const [ordNo,setOrdNo] = useState(0);
  //Day
  const [dayNo ,setDayNo] = useState(0);
  //옵션 이름
  const [optionName, setOptionName] = useState('');
  //Spot List
  const [spotList, setSpotList] = useState([]);
  //언어 세팅
  const [languageComment, setLanguageComment] = useState(language);
  //한국어 코멘트
  const [koComment, setKoComment] = useState("");
  //영어 코멘트
  const [enComment, setEnComment] = useState("");
  //간자체 코멘트
  const [zhchsComment, setzhchsComment] = useState("");
  //번자체 코멘크
  const [zhchtComment, setzhchtComment] = useState("");
  //코멘트
  const [comment, setComment] = useState("");
  //이미지들
  const [images, setImages] = useState([]);
  //이미지 size
  const [imageSize, setIamgeSize] = useState(0);

  const [active, setActive] = useState(false);

    //구글 좌표
    const [coordinate, setCoordinate] = useState([]);


  const setting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };



  /* 젤 앞 대문자 설정 */
  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) {
      return str;
    }

    const firstLetter = str.charAt(0).toUpperCase();
    const remainingLetters = str.slice(1);

    return firstLetter + remainingLetters;
  };

  const handleButtonClick = (lang) => {

  
    setLanguageComment(lang);
    switch (lang) {
      case "ko": case "한국어":
        setComment(koComment);
        break;
      case "English":
        setComment(enComment);
        break;
      case "中文繁體":
        setComment(zhchsComment);
        break;
      case "中文簡體":
        setComment(zhchtComment);
        break;
    }
  }

  const handleChange = (event) => {

    let value = event.target.value;
    console.log(languageComment);
    setComment(value);
    switch (languageComment) {
      case "ko": case "한국어":
        setKoComment(value);
        break;
      case "English":
        setEnComment(value);
        break;
      case "中文繁體":
        setzhchsComment(value);
        break;
      case "中文簡體":
        setzhchtComment(value);
        break;
    }
  }

  useEffect(() => {
    console.log("언어:" + language);
    console.log(location);

    if (location.state != null) {
      //값 세팅
      let getOption = location.state.optionSq;
      setPlanSq(location.state.planSq);
      setDayNo(location.state.dayNo);
      setOrdNo(location.state.orderNo);
      setOptionSq(location.state.optionSq);
      const getOptionDetail = async () => {
        let body = {
          planSq : location.state.planSq,
          optionSq: getOption,
          ordNo : location.state.orderNo,
          dayNo : location.state.dayNo
        };
        try {
          const res = await axios.post(`${server_url}/ordplan/getSlot`, body);
          console.log("0919-1");
          console.log(res);
          let item = res.data;
          
          if(item.startTimeNm != ""){
            setTimehhNm(item.startTimeNm);
          }
          if(item.startEndNm != ""){
            setTimemmNm(item.startEndNm);
          }

          if(item.startTimeNm != "" && item.startEndNm != ""){
            setSwitchBool(true);
          }

          

          //임시 설정
          //setActive(true);
          
        } catch (error) {
          console.error(error);
        }
      };
      getOptionDetail();
    }


  }, []);

  useEffect(() => {
    const getAllOption = () => {
      let body = {
        optionSq: optionSq
      }
      const res = axios.post(`${server_url}/myplan/option/getAllOption`, body);
      res.then((response) => {
        console.log(response);
        if (response.data != null) {
          let item = response.data.data;
          let masterOption = item.masterOption;
          console.log(item);
          let title = "title" + capitalizeFirstLetter(language) + "Nm";
          let desc = "desc" + capitalizeFirstLetter(language) + "Nm";
          
          setOptionName(masterOption[title]);
          setData(item);
          setOptionSq(masterOption.optionSq);
          if (masterOption.descEnNm == null) {
            setEnComment("");
          } else {
            setEnComment(masterOption.descEnNm);
          }
          if (masterOption.descKoNm == null) {
            setKoComment("");
          } else {
            setKoComment(masterOption.descKoNm);
            setComment(masterOption.descKoNm);
          }
          if (masterOption.descZhchsNm == null) {
            setzhchsComment("");
          } else {
            setzhchsComment(masterOption.descZhchsNm);
          }
          if (masterOption.descZhchtNm == null) {
            setzhchtComment("");
          } else {
            setzhchtComment(masterOption.descZhchtNm);
          }
          setImages(item.masterOptionImages);
          setIamgeSize(item.masterOptionImages.length);
          setSpotList(item.masterOptionSpots);
          if (item.comSpots != null) {
            const updatedCoordinate = item.comSpots.map((el) => {
              return {
                lat: Number(`${el.comSpot.latNm}`),
                lng: Number(`${el.comSpot.longNm}`),
                orderNo: el.orderNo,
              };
            });
            // setCoordinate 함수를 사용하여 상태를 업데이트
            setCoordinate(updatedCoordinate);
          }
        }
      });
    }
    console.log("0919-2");
    console.log(optionSq);
    if (optionSq != undefined && optionSq != 0) {
      getAllOption();
    }
  }, [optionSq]);

  useEffect((() => {
    // 스팟 리스트의 각 아이템에 대한 활성화 상태 초기화
    if (spotList != undefined) {
      setSpotActivation(Array(spotList.length).fill(false));
    }

  }), []);

  const handleSpotSwitchChange = () => {
    setSwitchBool(!switchBool);
  }


  const handleMoveUp = (index) => {
    if (index > 0) {
      setSpotList((prevItem) => {
        const updatedItems = [...prevItem];
        // Swap the elements except for ordno
        [updatedItems[index - 1], updatedItems[index]] = [
          { ...updatedItems[index], ordNo: updatedItems[index - 1].ordNo },
          { ...updatedItems[index - 1], ordNo: updatedItems[index].ordNo },
        ];
        return updatedItems;
      });
    }
  };

  const handleMoveDown = (index) => {
    if (index < spotList.length - 1) {
      setSpotList((prevItem) => {
        const updatedItems = [...prevItem];
        // Swap the elements except for ordno
        [updatedItems[index], updatedItems[index + 1]] = [
          { ...updatedItems[index + 1], ordNo: updatedItems[index].ordNo },
          { ...updatedItems[index], ordNo: updatedItems[index + 1].ordNo },
        ];
        return updatedItems;
      });
    }
  };

  const handleRemove = (index) => {
    if (spotList.length > 1) {
      setSpotList((prevItem) => {
        const updatedItems = prevItem.filter((_, i) => i !== index).map((item, i) => ({ ...item, ordNo: i + 1 }));
        return updatedItems;
      });
    } else {
      alert("스팟은 최소 1개 등록되어야 합니다.")
    }

  };

  const handleSelectChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "sl_hour":
        setTimehhNm(value);
        break;
      case "sl_min":
        setTimemmNm(value);
        break;
    }
  }

  useEffect(()=>{
    if(koComment != "" || enComment != "" || zhchsComment != "" || zhchtComment != ""){
      setActive(true);
    } else{
      setActive(false);
    }
  },[koComment,enComment,zhchsComment,zhchtComment])


  /* 옵션 저장 */
  const onSave = () => {
    let body = {
      option : {
        optionSq: optionSq,
        descKoNm: koComment,
        descEnNm: enComment,
        descZhchsNm: zhchsComment,
        descZhchtNm: zhchtComment
      },
      slot : {
        planSq : planSq,
        optionSq: optionSq,
        ordNo : ordNo,
        dayNo : dayNo,
        startTimeNm : timehhNm,
        startEndNm : timemmNm
      }
    }
    console.log(body);
    const res = axios.post(`${server_url}/ord/slot/option/save`, body);
    res.then((response) => {
      console.log(response);
      if(response.result = "success"){
        alert("슬롯 설정이 완료되었습니다");
        navigate(-2);
        //navigate(`/mypage/makingPlanSlot`,{state:response.data.plan});
      }
      
    });
  }

  return (
    <div className="container">
      <PageHeader title="일정 정보 수정"/>
      
      <div
        className="w-9 m-8"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <div style={{ marginTop: "20px", display: "flex", textAlign: "left", width: "100%", height: "35px", alignItems: "center" }}>
          <span style={{ marginLeft: "10px" }}>{optionName}</span>
        </div>
        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <div>
            {images.length > 0 ? (
              <Slider {...setting}>
                {images.map((imgList) => {
                  let imageUrl = "";
                  if (imgList.savenameNm.includes("https://")) {
                    imageUrl = imgList.savenameNm;
                  } else {
                    imageUrl = img_url + "/" + imgList.typeCd + "/" + imgList.savenameNm;
                  }
                  return (
                    <div key={imgList} style={{ width: "100%", height: "130%" }}>
                      <div
                        style={{
                          margin: "0px auto",
                          borderRadius: "10px",
                          height: "45vh",
                          maxHeight: "45vh",
                          objectFit: "cover",
                          backgroundImage: `url(${imageUrl})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          display:"flex",
                          justifyContent:"center"
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", marginTop: "auto",backgroundColor:"#FFDB97", borderRadius:"10px",padding:"5px 10px",marginBottom:"10px" }} onClick={() => { navigate(`/mypage/makingPlan/addOptionPhoto`, { state: data }) }}>
                  <img src={img_add} style={{ width: "25px", height: "25px" }}></img>
                  <span>사진 추가({imageSize})</span>
                </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              // 이미지가 없는 경우에 표시할 내용
              <div
                style={{
                  margin: "0px auto",
                  borderRadius: "10px",
                  height: "45vh",
                  maxHeight: "45vh",
                  objectFit: "cover",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  display:"flex",
                  justifyContent:"center"
                }}
              ><div style={{ display: "flex", alignItems: "center", marginTop: "auto", backgroundColor:"#FFDB97" }} onClick={() => { navigate(`/mypage/makingPlan/addOptionPhoto`, { state: data }) }}>
                  <img src={img_add} style={{ width: "25px", height: "25px" }}></img>
                  <span>사진 추가({imageSize})</span>
                </div>
              </div>
            )}
          </div>

        </div>
        <div style={{marginTop:"20px"}}>
            <Switch {...label} checked={switchBool} onChange={() => handleSpotSwitchChange()} />
            <span>시간 설정</span>
          </div>
          <div style={{ display: "flex" }}>
            <select id="sl_hour" style={{ marginRight: "14px", height: "30px", width: "47%", textAlign: "center" }} value={timehhNm} disabled={!switchBool} onChange={(e) => handleSelectChange(e)}>
              {hours.map(el => (
                <option key={el} value={el}>{el}</option>
              ))}
            </select>
            <select id="sl_min" style={{ height: "30px", width: "47%", textAlign: "center" }} value={timemmNm} disabled={!switchBool} onChange={(e) => handleSelectChange(e)}>
              {minutes.map(el => (
                <option key={el} value={el}>{el}</option>
              ))}
            </select>
          </div>

        <div style={{ width: "100%", marginTop: "20px" }}>
          <LanguageButton
            type="button"
            value="한국어"
            active={languageComment === "한국어" || languageComment === "ko"}
            onClick={() => handleButtonClick("한국어")}
          />
          <LanguageButton
            type="button"
            value="English"
            style={{ marginLeft: "10px" }}
            active={languageComment === "English"}
            onClick={() => handleButtonClick("English")}
          />
          <LanguageButton
            type="button"
            value="中文繁體"
            style={{ marginLeft: "10px" }}
            active={languageComment === "中文繁體"}
            onClick={() => handleButtonClick("中文繁體")}
          />
          <LanguageButton
            type="button"
            value="中文簡體"
            style={{ marginLeft: "10px" }}
            active={languageComment === "中文簡體"}
            onClick={() => handleButtonClick("中文簡體")}
          />
        </div>

        <div style={{ width: "100%", marginTop: "20px", display: "flex" }}>
          <textarea style={{ width: "100%", height: "100px", padding: "10px" }} value={comment} placeholder="상품 코멘트 하기" onChange={handleChange}></textarea>
        </div>



        <div style={{ display: "flex", alignItems: "center", marginTop: "30px", width: "100%" }} onClick={() => { navigate(`/mypage/makingOption/price`, { state: data }) }}>
        </div>
        <div style={{width:"100%"}}>
        {coordinate != [] && <GoogleMapPolylineComponent              coordinate={coordinate}        />}
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: "30px", width: "100%", paddingBottom: "110px" }}>
        <input type="button" onClick={onSave} className={active ? 'save_active' : 'save_notActive'} disabled={!active} value="수정 완료" />
        </div>

      </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 10px;

  width: 40%;
`;

const LanguageButton = styled.input`
  padding: 3px 10px;
  color: ${props => (props.active ? "#FFF" : "#000")};
  background: ${props => (props.active ? "#5B9BD5" : "#FFF")};
`

styled.active = `background : #000`;
export default PlanSlotUpdateOption;
