import { useCallback, useEffect, useRef } from "react";

import { googlemap_api } from "../../server";

function GoogleMapPolylineComponent(props) {
  const mapElement = useRef(null);

  // 컴포넌트가 마운트될 때, 수동으로 스크립트를 넣어줍니다.
  // ﻿이는 script가 실행되기 이전에 window.initMap이 먼저 선언되어야 하기 때문입니다.
  const loadScript = useCallback((url) => {
    const firstScript = window.document.getElementsByTagName("script")[0];
    const newScript = window.document.createElement("script");
    newScript.src = url;
    newScript.async = true;
    newScript.defer = true;
    firstScript?.parentNode?.insertBefore(newScript, firstScript);
  }, []);

  const initMap = useCallback(() => {
    const { google } = window;
    if (!mapElement.current || !google) return;

    const map = new google.maps.Map(mapElement.current,
        {
          zoom: 12,
          center: props.coordinate[0],
          mapTypeId: "terrain",
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }
  );

    const flightPath = new google.maps.Polyline({
      // path: flightPlanCoordinates,
      path: props.coordinate,
      geodesic: true,
      strokeColor: "#000000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    props.coordinate.forEach((value, index) => {
      const marker = new google.maps.Marker({
        position: value,
        map,
        label: `${value.orderNo}`,
        title: `${value.descNm}`,
        optimized: false,
      });
      
      google.maps.event.addListener(
          marker,
          "click",
          function (e) {
            document.getElementById('input_' + value.orderNo).focus();
            document.getElementById('input_' + value.orderNo).blur();
          }
      )
    });

    flightPath.setMap(map);
  });

  useEffect(() => {
    const script = window.document.getElementsByTagName("script")[0];
    const includeCheck = script.src.startsWith(
      "https://maps.googleapis.com/maps/api"
    );

    // script 중복 호출 방지
    if (includeCheck) return initMap();

    window.initMap = initMap;
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${googlemap_api}&callback=initMap&language=ko`
    );
  }, [initMap, loadScript]);

  return <div ref={mapElement} style={{ minHeight: "400px" }} />;
}

export default GoogleMapPolylineComponent;
