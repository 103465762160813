import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import MyModal from "../../components/Modal/MyModal";
import PageHeader from "../../components/views/Layout/Header/PageHeader";

import { CircularButton } from "../../styles/GlobalStyle";
import { regEmail } from "../../js/regax";
import { server_url } from "../../server";

import clearButton from "../../images/icons/clearButton.png";
import eyes from "../../images/icons/eyes.png";
import caution from "../../images/icons/caution.png";
import axios from "axios";

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [emailChk, setEmailChk] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="container">
      {/* Header */}
      <PageHeader title="회원가입" />
      {/* email 입력 */}
      <div className="m-8 w-9">
        <label className="text-sm">이메일</label>
        <InputBox className="m-1">
          <UnderlineInput
            type="email"
            className="m-1 text-xsm"
            onChange={(e) => setEmail(e.currentTarget.value)}
            value={email}
          />
          {email && (
            <img
              src={clearButton}
              alt=""
              onClick={() => {
                setEmail("");
              }}
              style={{ position: "absolute", bottom: "5px", right: "2%" }}
            />
          )}
        </InputBox>
        <CircularButton
          className="m-2 text-l"
          color="#5c80ff"
          backgroundcolor="white"
          onClick={() => {
            if (regEmail.test(email) === true) {
              // setIsOpen(true); // 추후에 이메일 중복여부 확인 후 Modal open
              const checkEmail = async (email) => {
                try {
                  const res = await axios.get(
                    `${server_url}/auth/duplicate-email?email=${email}`
                  );

                  return res.data.data;
                } catch (e) {
                  console.log(e);
                }
              };

              checkEmail(email).then((res) => {
                setEmailChk(res);
                // console.log(emailChk);
                if (!res) {
                  setIsOpen(true);
                } else {
                  alert("해당 이메일을 사용할 수 있습니다!");
                }
              });

              // console.log(tmp);
            } else {
              alert("이메일이 올바르지 않습니다.");
            }
          }}
        >
          이메일 확인
        </CircularButton>
        <MyModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          element={
            <div>
              <div className="m-3" style={{ textAlign: "center" }}>
                <img src={caution} alt="" />
              </div>
              <div className="m-2">
                입력하신 이메일 {email}(으)로 이미 회원가입이 되어 있습니다.
              </div>

              <div
                className="m-4"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "20px",
                }}
              >
                <CircularButton
                  onClick={() => setIsOpen(false)}
                  color="#5c80ff"
                  style={{ width: "45%" }}
                >
                  다른 메일 입력
                </CircularButton>
                <CircularButton
                  onClick={() => navigate("/login")}
                  backgroundcolor="#5c80ff"
                  style={{ width: "45%" }}
                >
                  로그인
                </CircularButton>
              </div>
            </div>
          }
        />
      </div>
      {/* 비밀번호 입력 */}
      <div className="m-4 w-9">
        <label className="text-sm">비밀번호</label>
        <InputBox className="m-1">
          <UnderlineInput
            type={showPassword ? "text" : "password"}
            className="m-1 text-xsm"
            onChange={(e) => setPassword(e.currentTarget.value)}
            value={password}
          />
          {password && (
            <img
              src={eyes}
              alt=""
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              style={{
                position: "absolute",
                bottom: "25%",
                right: "2%",
                width: "16px",
                height: "8px",
              }}
            />
          )}
        </InputBox>
      </div>
      {/* 비밀번호 확인 입력 */}
      <div className="m-4 w-9">
        <label className="text-sm">비밀번호 확인</label>
        <InputBox className="m-1">
          <UnderlineInput
            type={showConfirmPassword ? "text" : "password"}
            className="m-1 text-xsm"
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            value={confirmPassword}
          />
          {confirmPassword && (
            <img
              src={eyes}
              alt=""
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
              style={{
                position: "absolute",
                bottom: "25%",
                right: "2%",
                width: "16px",
                height: "8px",
              }}
            />
          )}
        </InputBox>
        <CircularButton
          className="m-4 text-l"
          backgroundcolor="#5c80ff"
          color="white"
          onClick={() => {
            if (!emailChk) {
              alert("이메일 확인 버튼을 눌러주세요.");
            } else {
              if (password !== confirmPassword) {
                alert("비밀번호를 다시 확인해주세요.");
              } else {
                navigate("/register/tos", {
                  state: { email: email, password: password },
                });
              }
            }
          }}
        >
          가입
        </CircularButton>
      </div>
    </div>
  );
}

const InputBox = styled.div`
  position: relative;

  border-bottom: 1px solid #252525;
`;

const UnderlineInput = styled.input`
  border: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }

  height: 15px;
  width: 95%;
`;

export default RegisterPage;
