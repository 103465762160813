import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import Datebar from "../../components/views/Calender/Datebar";
import MySheet from "../../components/Sheet/MySheet";
import GmpCalender from "../../components/views/Calender/GmpCalender";
import PlanList from "../../components/Plan/PlanList";

import { Title, CircularButton, ImgTitle } from "../../styles/GlobalStyle";
import {img_url, server_url} from "../../server";
import favicon from "../../images/favicon.png";
import {getTodayDate, parseDatewithDash} from "../../js/getDate";
import axios from "axios";

function CompanyPage() {

  const { state } = useLocation();
  console.log(state);
  const params = useParams();
  const [data, setData] = useState(state.data);
  const navigate = useNavigate();
  const [departure, setDeparture] = useState(state.body.startDate);
  const [isOpen, setIsOpen] = useState(false);
  const [pcList, setPcList] = useState([]);
    const [planList, setPlanList] = useState([]);
    const [planCount, setPlanCount] = useState([]);

    useEffect(() => {
        let getCityCdBody = {
            userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
        };

        let body = {
            userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
            fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
            today: getTodayDate(),
            startDate: parseDatewithDash(departure),
        };

        console.log(body);
        console.log(departure);

        const getPlanList = async () => {
            try {
                const res = await axios.post(
                    `${server_url}/plans/${data.pcList[0].cityCd}`,
                    // `${server_url}/plans/HKG`,
                    body
                );

                console.log(res.data);

                // setData(res.data);

                setPlanList(res.data.planList.filter((el) => el.toppickYn === "Y"));
                setPlanCount(res.data.countPlan);
            } catch (e) {
                console.log(e);
            }
        };

        getPlanList();
    }, [departure]);

  return (
    <div className="container">
      <PageHeader logo="true" />
      <div className="m-8" style={{ width: "100%" }}>
        <div className="container">
            <div>
                <img
                    style={{
                        borderRadius: "50%",
                        maxHeight: "22vh",
                    }}
                    src={img_url + `/${data.pcList[0].typeCd}/${data.pcList[0].savenameNm}`} />
            </div>
            <div
                style={{
                    position: "relative",
                    margin: "auto",
                    marginTop: "5%",
                    marginBottom: "1%",
                    fontWeight: "bold",
                }}
            >
                {data.pcList[0].localNm}
            </div>
            <div
                style={{
                    position: "relative",
                    margin: "auto",
                    marginBottom: "7%",
                    fontWeight: "bold",
                }}
            >
                {data.pcList[0].enNm}
            </div>
            <div
                style={{
                    position: "relative",
                    width: "70%",
                    margin: "auto",
                    marginBottom: "5%",
                }}
            >
                {langTitle(data)}
            </div>
        </div>

      </div>

      <div className="m-3" style={{ width: "100%" }}>
          <div
              style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
              }}
          >
              <Title>추천 플랜</Title>
          </div>

          <div
              onClick={() => {
                  setIsOpen(true);
              }}
          >
              <Datebar departure={departure} />
          </div>
          <MySheet
              isOpen={isOpen}
              setOpen={setIsOpen}
              children={
                  <GmpCalender
                      isOpen={isOpen}
                      setOpen={setIsOpen}
                      setDeparture={setDeparture}
                      departure={departure}
                  />
              }
          />

          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              {planList.map((item) => {
                  return (
                      <PlanList key={item.planSq} item={item} pclist={pcList} departure={departure} />
                  );
              })}
          </div>
      </div>

        <div style={{width: "100%"}}>
            {/*<div*/}
            {/*    style={{*/}
            {/*        display: "flex",*/}
            {/*        justifyContent: "center",*/}
            {/*        alignItems: "center",*/}
            {/*        border: "1px solid",*/}
            {/*        borderRadius: "30px",*/}
            {/*        width: "40%",*/}
            {/*        height: "5vh",*/}
            {/*        margin: "4% auto auto",*/}
            {/*    }}*/}
            {/*    onClick={() => {*/}
            {/*        navigate(`/plans/${data.pcList[0].cityCd}`, {*/}
            {/*            state: {*/}
            {/*                departure: departure,*/}
            {/*                title: data.pcList[0].localNm,*/}
            {/*            },*/}
            {/*        });*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        <img src={favicon} height="40px" />*/}
            {/*    </div>*/}
            {/*    &nbsp;&nbsp;*/}
            {/*    <div style={{fontWeight: "600"}}>*/}
            {/*        {data.countPlan}개 모든 플랜 보기*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div
                style={{
                    margin: "auto",
                    width: "45%",
                }}
            >
                <CircularButton
                    className="text-xsm"
                    onClick={() => {
                        navigate(`/plans/${data.pcList[0].cityCd}`, {
                            state: {
                                departure: departure,
                                title: data.pcList[0].localNm,
                            },
                        });
                    }}
                    backgroundcolor="#5c80ff"
                    style={{ height: "26px", marginRight: "25px", padding: "0 5px" }}
                >
                    {planCount}개 모든 플랜 보기
                </CircularButton>
            </div>
        </div>

    </div>
  );
}

// 공급자 설명 언어별추가 (2023-03-23)
function langTitle(data) {
    let langTitle;
    switch (localStorage.getItem("language")) {
        case "ko" :
            langTitle = data.pcList[0].title_ko
            break;
        case "en" :
            langTitle = data.pcList[0].title_en
            break;
        case "zhchs" :
            langTitle = data.pcList[0].title_zhchs
            break;
        case "zhcht" :
            langTitle = data.pcList[0].title_zhcht
            break;
        default : langTitle = data.pcList[0].title_ko
    }
    return langTitle;
}

export default CompanyPage;
