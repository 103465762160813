import React from "react";

function MyList(props) {
  return (
    <ul style={{ padding: "0 25px" }}>
      {props.list &&
        props.list.map((el) => {
          return (
            <li
              className="text-xsm m-2"
              key={el.code}
              style={{
                marginBottom: "5px",
                marginRight: "5px",
                listStyle: "none",

                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>현지도착 {el.day_ko}일 전 취소 시</div>
              <div>{el.type_ko}</div>
            </li>
          );
        })}
    </ul>
  );
}

export default MyList;
