import React, { useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import messageBox from "../../images/icons/messageBox.png";
import heart from "../../images/icons/heart.png";

import { useNavigate } from "react-router-dom";

import { parseDatewithDash, getTodayDate } from "../../js/getDate";
import { img_url, server_url } from "../../server";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function PlanList(props) {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [item] = React.useState(props.item);
  const [pcList] = React.useState(props.pclist);
  const [imgSaveName,setImgSaveName] = React.useState(item.savenameNm);

  useEffect(()=>{
    if(item.savenameNm.includes("https://")){
    } else{
      setImgSaveName(img_url +"/"+item.typeCd+"/"+item.savenameNm);
    }
  },[])

  
  // const [departure, setDeparture] = React.useState(props.departure);

  // console.log(props.departure);

    const setting = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    
  function setCurrencySymbol() {
    let currencySymbol;
    switch (localStorage.getItem("fxCodeCd")) {
      case "KRW":
        currencySymbol = "₩ "
        break;
      case "USD":
        currencySymbol = "US$ "
        break;
      case "HKD":
        currencySymbol = "HK$ "
        break;
      case "RMB":
        currencySymbol = "¥"
        break;
      default: currencySymbol = "₩ "
    }
    return currencySymbol;
  }

  return (
    <div
      style={{ margin: "10px 10px 40px" }}
      onClick={async () => {
        let body = {
          userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
          fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
          today: getTodayDate(),
          startDate: parseDatewithDash(props.departure),
        };

        console.log(body);

        const getPlanDetail = async () => {
          try {
            const res = await axios.post(
              `${server_url}/plans/${item.planSq}/${item.planCd}`,
              body
            );

            return res;
          } catch (e) {
            console.log(e);
          }
        };

        getPlanDetail().then((res) => {
          navigate(`/plans/${item.planSq}/${item.planCd}`, {
            state: { data: res.data, body: body },
          });
        });
      }}
    >
        {pcList.map((pclist) => {
            if (window.location.pathname.split("/")[1] === 'plans') {
            if (item.companySq !== null && pclist.pcSq === item.companySq) {
              let imageUrl = "";
              if(pclist.savenameNm.includes("https://")){
                  imageUrl = pclist.savenameNm;
              } else{
                imageUrl = img_url +"/PLNCreator"+"/"+pclist.savenameNm;
              }
                return (
                    <div>
                    <div
                        style={{
                            width: "70px",
                            height: "70px",
                            backgroundImage: `url(${imageUrl})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",

                            borderRadius: "100px",
                            display: "inline-block",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                // display: "inline-block",
                                // marginLeft: "30px",
                                width: "350%",
                                marginLeft: "140%",
                                marginTop: "30%",
                            }}
                        >
                        {langNm(pclist)}
                        </div>
                    </div>
                    </div>
                );
            }
            }
        })}

      <div style={{ position: "relative" }}>
        {/* 스팟페이지랑 페이지를 같이 쓰는 이슈로 인해 Url이 Plans일 때만 슬라이드 사용 */}
        {window.location.pathname.split("/")[1] !== 'plans' ?
            <img
              // src="https://source.unsplash.com/random"
              src={imgSaveName}
              alt=""
              style={{
                margin: "0 auto",
                borderRadius: "10px",
                width: "100%",
                maxHeight: "230px",
                objectFit: "cover",
              }}
            />
        :
          <div>
              <Slider {...setting}>
                  {item.planImageSaveNmList.map((imgList) => {
                      return(
                          <div key={imgList} style={{ width: "100%", height: "130%"}} >
                              <div
                                  style={{
                                      margin: "0px auto",
                                      borderRadius: "10px",
                                      height: "230px",
                                      maxHeight: "230px",
                                      objectFit: "cover",
                                      backgroundImage: `url(${img_url}/${imgList.typeCd}/${imgList.savenameNm})`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center center",
                                      backgroundRepeat: "no-repeat",

                                  }}
                              >
                              </div>
                          </div>
                      );
                  })}
              </Slider>
          </div>
        }
        <div>

        </div>
        {props.planfit === true && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              position: "absolute",
              top: "5%",
            }}
          >
            {item.planfitDesc.map((el) => {
              return (
                <div
                  style={{
                    fontSize: "12px",
                    background: "white",
                    borderRadius: "20px",
                    margin: "3px",
                    padding: "2px 5px",
                    textAlign: "center",
                  }}
                >
                  {el}
                </div>
              );
            })}
          </div>
        )}
          <div
              style={{
                  display: "grid",
                  position: "absolute",
                  top: "5%",
                  right: "1px",
              }}
          >
          <div
              style={{
                  fontSize: "12px",
                  background: "white",
                  borderRadius: "20px",
                  margin: "3px",
                  padding: "2px 5px",
                  textAlign: "center",
              }}
          >
              {item.cityNm}
          </div>
          </div>
      </div>
      <div
        className="m-1"
        style={{ display: "grid", gridTemplateColumns: "1.2fr 1fr", marginTop: "7%" }}
      >
        <div
          className="text-m"
          style={{
            fontWeight: 600,
            overflowX: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {item.planTitleNm}
        </div>
        {item.planPriceLocal !== "-1" ? (
          <div
            className="text-xl"
            style={{
              fontSize:"16px",
              fontWeight: 600,
              textAlign: "right",
              gridColumn: "2 / 3",
              gridRow: "1 / 3",
            }}
          >
             {setCurrencySymbol()}{item.planPriceLocal}~
            <div style={{ fontSize: "12px" }}>{t('PLAN.LIST05')}</div>
          </div>
        ) : (
          <div
            className="text-sm"
            style={{
              fontWeight: 600,
              textAlign: "right",
              color: "#c62828",
            }}
          >
            예약 마감
          </div>
        )}

        <div
          style={{
            fontSize: "12px",
            color: "#6b6b6b",
            maxHeight: "30px",
            overflowY: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {item.hotspotDesc.join(", ")}
        </div>
        <div></div>
      </div>
        <div></div>
        <div style={{ display: "flex" }} >
            {/* 플랜별 좋아요 영역 */}
            {
                window.location.pathname.split("/")[1] === 'plans' ?
                    <div
                        style={{
                            display:"flex",
                            alignItems:"center",
                            width: "13%",
                            height: "30px",
                            border: "1px solid",
                            borderRadius: "14px",
                            margin: "auto",
                            marginTop: "2%",
                            marginLeft: "35%",
                            backgroundColor: "#adadad",
                            minWidth:"75px"
                        }}
                    >
                        <img
                            style={{
                                float:"left",
                                marginRight:"5%",
                                marginLeft: "16%",
                                marginTop: "3%",
                                width:"18px",
                                height:"16px"
                            }}
                             src={heart}
                             alt="" />
                        <div style={{
                                marginLeft:"auto",
                                marginRight:"20px",
                                color: "white",
                            }}
                        >
                            {item.likeCount}
                        </div>
                    </div>
                :
                    null
            }

            {/* 댓글 개수 영역 */}
            {
                window.location.pathname.split("/")[1] === 'plans' ?
                    <div
                        style={{
                            display:"flex",
                            alignItems:"center",
                            width: "13%",
                            height: "30px",
                            border: "1px solid",
                            borderRadius: "14px",
                            margin: "auto",
                            marginRight: "35%",
                            marginTop: "2%",
                            backgroundColor: "#adadad",
                            minWidth:"75px"
                        }}
                    >
                        <img
                            style={{
                                float:"left",
                                marginRight:"5%",
                                marginLeft: "16%",
                                marginTop: "3%",
                                width:"18px",
                                height:"16px"
                            }}
                             src={messageBox}
                             alt="" />
                        <div style={{
                             marginLeft:"auto",
                             marginRight:"20px",
                            color: "white",
                        }}
                        >
                            {item.commentCount}
                        </div>
                    </div>
                    :
                    null
            }
        </div>
        {
            window.location.pathname.split("/")[1] === 'plans' ?
                <div
                    style={{
                        borderTop: "1px solid #d2d2d2",
                        marginTop: "5%",
                    }}
                ></div>
                :
                null
        }
    </div>
  );
}

// 공급자명 언어별추가 (2023-03-23)
function langNm(pclist) {
    let langNm;
    switch (localStorage.getItem("language")) {
        case "ko" :
            langNm = pclist.localNm
            break;
        case "en" :
            langNm = pclist.enNm
            break;
        case "zhchs" :
            langNm = pclist.enNm
            break;
        case "zhcht" :
            langNm = pclist.enNm
            break;
        default : langNm = pclist.localNm
    }
    return langNm;
}

export default PlanList;
