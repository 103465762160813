import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import styled from "styled-components";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import { server_url } from "../../server";
import { CircularButton } from "../../styles/GlobalStyle";
import InformationPolicy from "./InformationPolicy";
import Terms from "./Terms";

function TosPage() {
  const [isTos, setIsTos] = useState(true);

  const [checkTos, setCheckTos] = useState(false);
  const [checkPrivacy, setCheckPrivacy] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  console.log(state);

  return (
    <div className="container">
      <PageHeader title="이용약관" />
      <div
        className="m-8 w-8"
        style={{
          boxShadow:
            "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px 2px 7px rgba(0, 0, 0, 0.1)",
          borderRadius: "100px",
        }}
      >
        <ul
          className="text-m"
          style={{
            textAlign: "center",
            padding: "0",
            marginBottom: "0",
          }}
        >
          <LoginTool
            className={isTos ? "active" : "inactive"}
            onClick={() => {
              setIsTos(true);
            }}
          >
            이용약관
          </LoginTool>
          <LoginTool
            className={!isTos ? "active" : "inactive"}
            onClick={() => {
              setIsTos(!true);
            }}
          >
            개인정보 처리방침
          </LoginTool>
        </ul>
      </div>
      <div className="m-2 w-9">
        {isTos && (
          <div>
            {/* <div>국외여행표준약관</div> */}
            <Terms />
            <div>
              <input
                type="checkbox"
                onChange={() => {
                  setCheckTos(!checkTos);
                }}
              />
              이용약관에 동의하시겠습니까 ?
            </div>
          </div>
        )}
        {!isTos && (
          <div>
            <InformationPolicy />
            <input
              type="checkbox"
              onChange={() => {
                setCheckPrivacy(!checkPrivacy);
              }}
            ></input>
            개인정보 처리방침에 동의하시겠습니까?
          </div>
        )}
        <CircularButton
          className="m-2"
          backgroundcolor="#5c80ff"
          onClick={() => {
            //회원가입 api 적용
            if (!checkTos) {
              alert("이용약관에 동의해주세요.");
            } else {
              if (!checkPrivacy) {
                alert("개인정보 처리방침에 동의해주세요.");
              } else {
                const signup = async () => {
                  try {
                    const res = await axios.post(
                      `${server_url}/auth/signup`,
                      state
                    );

                    console.log(res.data);

                    return res.data.code;
                  } catch (e) {
                    console.log(e);

                    return e.response.data.code;
                  }
                };

                signup().then((res) => {
                  if (res === 1) {
                    navigate("/register/end");
                  }
                });
              }
            }
          }}
        >
          회원가입하기
        </CircularButton>
      </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 16px;

  width: 40%;
`;

export default TosPage;
