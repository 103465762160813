import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import PageHeader from "../../components/views/Layout/Header/PageHeader";

import i18n from "../../i18n/i18n";
import { useTranslation } from "react-i18next";

import { server_url } from "../../server";

/* Img */
import img_addPhoto from "../../images/icons/file_add.svg"
import img_default from "../../images/img/img_default.png"

/* CSS */
import styles from "../../styles/css/MakePlan.css"

/* AWS */
import AWS from "aws-sdk";
import { eventWrapper } from "@testing-library/user-event/dist/utils";



function MyPlanPage() {
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const language = i18n.language;
  const [cityList, setCityList] = useState([]);
  const [publicYn, setPublicYn] = useState(false);
  const [planFitList, setPlanFitList] = useState([]);
  const [active, setActive] = useState(false);

  const [imgaeSrc, setImageSrc] = useState(img_default);
  const [imageFile, setImageFile] = useState();
  const [imageFileName, setImageFileName] = useState();

  const [categoryList, setCategoryList] = useState([]);

  const navigate = useNavigate();

  const [email] = useState(window.sessionStorage.getItem("email"));
  //도시
  const [city, setCity] = useState('');
  //플랜 이름
  const [planName, setPlanName] = useState('');
  //최대 일수
  const [nightsNo, setNightsNo] = useState(0);


  useEffect(() => {
    console.log("언어:" + language);
    console.log(location);
    const getCityList = async () => {
      try {
        const res = await axios.get(`${server_url}/ord/cities/code/${language}`);
        console.log(res);
        setCityList(res.data);
      } catch (e) { }
    };

    const getCategoryList = async () => {
      try {
        const res = await axios.get(`${server_url}/ord/plan/categoryList/PLANF`);
        console.log(res);
        setCategoryList(res.data);
      } catch (e) { }
    }
    getCityList();
    getCategoryList();

    if (location.state != null) {
      let item = location.state;
      console.log(location.state);
      setCity(item.cityCd);
      setPlanName(item.pcLocalNm);
      setImageSrc(item.savenameNm);
      if (item.publicYn = 'Y') {
        setPublicYn(true)
      } else {
        setPublicYn(false);
      }
      console.log(item.planfitNm);
      const getPlanfitList = item.planfitNm.split('|').filter(item => item !== '');
      setPlanFitList(getPlanfitList);
      setNightsNo(item.nightsNo);
    }
  }, []);
  /* Select */
  const handleChange = (event) => {
    let id = event.target.id;
    let value = event.target.value;
    console.log("ssss");
    console.log(value);
    switch (id) {
      case 'sl_city':
        setCity(value);
        break;
      case 'ip_planName':
        setPlanName(value);
        break;
      case 'sl_nightsNo':
        setNightsNo(value);
        break;
    }
  }
  /* CheckBox - 전체 공개 */
  const handleCheckboxPublic = (event) => {
    setPublicYn(event.target.checked);
  };
  /* CheckBox - 플랜 테마 */
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    // 체크된 항목을 추가 또는 제거합니다
    if (isChecked) {
      setPlanFitList((prevItems) => [...prevItems, value]);
    } else {
      setPlanFitList((prevItems) => prevItems.filter((item) => item !== value));
    }
    console.log(planFitList);
  };

  useEffect(() => {
    if (city != "" && planName != "") {
      setActive(true);
    } else {
      setActive(false);
    }
    console.log("active:" + active);
  }, [city, planName, active]);

  /* Random */
  const generateRandomNumber = (digits) => {
    let randomNumber = '';

    for (let i = 0; i < digits; i++) {
      randomNumber += Math.floor(Math.random() * 10); // 0부터 9까지의 랜덤한 수
    }

    return randomNumber;
  };

  /* 12글자까지 자르기 설정 */
  const truncateString = (str) => {
    const index = str.indexOf("@");
    if (index !== -1 && index <= 12) {
      return str.slice(0, index);
    } else if (str.length > 12) {
      return str.slice(0, 12);
    } else {
      return str;
    }
  }

  /* 젤 앞 대문자 설정 */
  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) {
      return str;
    }

    const firstLetter = str.charAt(0).toUpperCase();
    const remainingLetters = str.slice(1);

    return firstLetter + remainingLetters;
  };
  /* Iamge 저장 설정 */
  const onUploadImage = (e) => {
    setImageFile(e.target.files[0]);
    setImageFileName(`${city}_` + generateRandomNumber(10));
  }

  useEffect(() => {
    if (imageFile && imageFileName) {
      const formData = new FormData();
      formData.append('file', imageFile);
      formData.append('name', imageFileName);
      uploadS3(formData);
    }
  }, [imageFile, imageFileName]);

  /* S3 저장 */
  const uploadS3 = (formData) => {
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_S3,
      secretAccessKey: process.env.REACT_APP_Secret_KEY_S3
    });

    const upload = new AWS.S3.ManagedUpload({
      params: {
        ACL: 'public-read',
        Bucket: 'mygmp',
        Key: `master/UserUpld/${email}/${imageFileName}.jpg`,
        Body: imageFile
      }
    });

    upload.promise().then(function (data) {
      setImageSrc(data.Location);
      if (!data) {
        console.log("이미지 업로드 완료!!");
      }
    });
  }
  /* 플랜 저장 */
  const onSave = () => {
    let planSq = '';
    let planCd = '';

    let planFitNm = '|' + planFitList.join("|") + '|';
    if (location.state != null) {
      planSq = location.state.planSq;
      planCd = location.state.planCd;
    } else {
      //PlanCd
      planCd = city + "_" + truncateString(email) + "_" + generateRandomNumber(5);
    }
    let savenameNm = "";
    if(imgaeSrc != img_default){
      savenameNm = imgaeSrc;
    }
    let body = {
      planSq: planSq,
      uploadUser: email,
      planCd: planCd,
      cityCd: city,
      titleEnNm: planName,
      titleKoNm: planName,
      titleZhchsNm: planName,
      titleZhchtNm: planName,
      planfitNm: planFitNm,
      publicYn: publicYn,
      nightsNo: nightsNo,
      uploadUser : email
    };

    console.log(body);
    //플랜 업데이트
    
    const res = axios.post(`${server_url}/ord/plan/save`, body);
      let img_res = "";
      res.then((response) => {
        console.log(response);
        if (response.data.result == "success") {
          alert("플랜이 저장되었습니다.");
          planSq = response.data.data.planSq;
          //PlanCd 저장
          sessionStorage.setItem("planCd", response.data.planCd);
          //이미지 저장 & 업데이트
          if(location.state != null){
            if(location.state.savenameNm != savenameNm){
              console.log(response.data.savenameNm);
              console.log(savenameNm);
              body = {
                imageCd: response.data.planCd,
                savenameNm: imgaeSrc,
                useYn: "Y"
              }
              console.log(body);
              img_res = axios.post(`${server_url}/img/save`, body).then((img_res) => {
                console.log(img_res)
              });
            }
          } else{
            body = {
              imageCd: response.data.planCd,
              savenameNm: imgaeSrc,
              useYn: "Y"
            }
            console.log(body);
            img_res = axios.post(`${server_url}/img/save`, body).then((img_res) => {
              console.log(img_res)
            });
          }
          
          navigate(`/mypage/makingPlanDetail/${planSq}/${planCd}`);
        }
      });
  }

  return (
    <div className="container m-10">
      <PageHeader title="플랜 만들기" />
      <div
        className="w-9"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <div style={{
          marginTop: "20px", display: "flex",
          flexDirection: "column", textAlign: "left", width: "100%"
        }}>
          <span style={{ fontWeight: "bold" }}>여행도시</span>
          <select id="sl_city" style={{ marginTop: "10px", height: "30px", outline: "none" }} onChange={handleChange} value={city}>
            <option>도시를 선택해주세요.</option>
            {cityList.map((el) => {
              return <option key={el.code} value={el.code}>{el.name}</option>;
            })}
          </select>
        </div>

        <div style={{
          marginTop: "20px", display: "flex",
          flexDirection: "column", textAlign: "left", width: "100%"
        }}>
          <span style={{ fontWeight: "bold" }}>플랜 이름</span>
          <input id="ip_planName" type="text" style={{ marginTop: "10px", height: "24px", outline: "none", paddingLeft: "10px" }} onChange={handleChange} value={planName} />
        </div>

        <div style={{
          marginTop: "20px", display: "flex",
          flexDirection: "column", textAlign: "left", width: "100%"
        }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontWeight: "bold", fontSize: "19px" }}>플랜 이미지</span>
            <label htmlFor="file" style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}><img src={img_addPhoto} style={{ width: "25px", height: "25px" }}></img></label>
            <input type="file" id="file" style={{ display: "none" }} onChange={e => onUploadImage(e)} accept="image/*" />
          </div>
          <div style={{ display: "flex", alignItems: "center", border: "1px solid", justifyContent: "center", height: "250px", marginTop: "10px" }}>
            <img src={imgaeSrc} style={{ maxWidth: "100%", maxHeight: "100%" }}></img>
          </div>
        </div>

        <div style={{ marginTop: "20px", display: "flex", textAlign: "left", width: "100%" }}>
          <input type="checkbox" id="cb_public" checked={publicYn} onChange={handleCheckboxPublic} />
          <label style={{ marginLeft: "5px" }} htmlFor="cb_public">전체 공개</label>
        </div>

        <div style={{ marginTop: "20px", display: "flex", textAlign: "left", width: "100%", alignItems: "center" }}>
          <span>최대 일수 설정</span>
          <select id="sl_nightsNo" onChange={handleChange} value={nightsNo} style={{ height: "30px", marginLeft: "10px" }}>
            {/* 0부터 30까지의 옵션을 생성 */}
            {Array.from({ length: 30 }, (_, index) => (
              <option key={index+1} value={index+1}>
                {index+1}
              </option>
            ))}
          </select>
          <span>일</span>
        </div>

        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%", maxHeight: "150px", overflow: "auto" }}>
          <span>플랜 테마(선택)</span>
          {categoryList.map((el, index) => {
            const cb_id = 'cb_' + index;
            let codeName = '';
            switch (language) {
              case 'ko':
                codeName = el.codeKoNm;
                break;
              case 'en':
                codeName = el.codeEnNm;
                break;
              case 'zhchs':
                codeName = el.codeZhchsNm;
                break;
              case 'zhcht':
                codeName = el.codeZhchtNm;
                break;
            }
            return (
              <div key={cb_id} style={{ marginTop: "10px" }}>
                <input type="checkbox" id={cb_id} checked={planFitList.includes(el.codeId)} onChange={handleCheckboxChange} value={el.codeId} />
                <label htmlFor={cb_id} style={{ marginLeft: "10px" }}>{codeName}</label>
              </div>
            )
          })}
        </div>

        <div style={{ width: "100%", marginTop: "10px" }}>
          <button onClick={onSave} className={active ? 'save_active' : 'save_notActive'} disabled={!active}>저장</button>
        </div>
      </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 10px;

  width: 40%;
`;

styled.active = `background : #000`;
export default MyPlanPage;
