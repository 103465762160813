import React from "react";
import styled from "styled-components";

import planBkgIcon1 from "../../images/img/planBkg/planBkgIcon1.png";
import planBkgIcon2 from "../../images/img/planBkg/planBkgIcon2.png";
import planBkgIcon3 from "../../images/img/planBkg/planBkgIcon3.png";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function PlanBkgBlock() {
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{
        width: "85%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <BkgBlock>
        <img src={planBkgIcon1} alt="" />
        <div>
          <Title>{t('PLANDETAIL.PDBK0101')}</Title>
          <Desc>{t('PLANDETAIL.PDBK0102')}</Desc>
        </div>
      </BkgBlock>
      <BkgBlock>
        <img src={planBkgIcon2} alt="" />
        <div>
          <Title>{t('PLANDETAIL.PDBK0201')}</Title>
          <Desc>{t('PLANDETAIL.PDBK0202')}</Desc>
        </div>
      </BkgBlock>
      <BkgBlock>
        <img src={planBkgIcon3} alt="" />
        <div>
          <Title>{t('PLANDETAIL.PDBK0301')}</Title>
          <Desc>{t('PLANDETAIL.PDBK0302')}</Desc>
        </div>
      </BkgBlock>
    </div>
  );
}

const BkgBlock = styled.div`
  padding: 10px 20px;

  border: 1px solid #5c80ff;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 1fr 4.5fr;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #121212;

  margin-bottom: 3px;
`;

const Desc = styled.div`
  font-size: 10px;
  color: #454545;
`;

export default PlanBkgBlock;
