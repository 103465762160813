import React from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getTodayDate, parseDatewithDash } from "../../js/getDate";
import remove from "../../images/icons/remove.png";

import styled from "styled-components";

import { img_url, server_url } from "../../server";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function WishListBlock(props) {
  // 다국어 관련 추가 (2023-06-22)
  const { t, i18n } = useTranslation();
  const [data] = React.useState(props.data);
  const navigate = useNavigate();
  //console.log(data);

  let start_date = new Date(data.planDateDt);
  let end_date = new Date(start_date.getTime() + Number(data.nightsNo)*24*60*60*1000);

  let end_date_formatted = end_date.toISOString().split('T')[0];
  
  let wishSq = data.wishSq

  const removeWishList = async() => {
    let body = {
      wishSq : wishSq
    }

    try{
      const res = await axios.delete(`${server_url}/wishlist/delete/${wishSq}`,body);
      //console.log(res);
      if(res.data != null){
        alert("삭제가 완료되었습니다.")
        props.getWishList();
      }
  } catch(e){}
  }
  return (
    <div style={{ margin: "20px 0" }} onClick={async () => {
      let body = {
        userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
        fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
      today: getTodayDate(),
      startDate: data.planDateDt,
    };

    const getPlanDetail = async () => {
      try {
        const res = await axios.post(
          `${server_url}/plans/${data.planSq}/${data.planCd}`,
          body
        );

        return res;
      } catch (e) {
        console.log(e);
      }
    };

    getPlanDetail().then((res) => {
      navigate(`/plans/${data.planSq}/${data.planCd}`, {
        state: { data: res.data, body: body },
      });
    });
    } }>
      
      <div style={{ position: "relative", width:"331px", height:"195px", objectFit:"cover",backgroundSize:"cover",backgroundPosition:"center center",  backgroundImage: `url(${img_url}/${data.typeCd}/${data.savenameNm})`}}>
        <div style={{display: "grid", marginLeft:"5px",marginTop:"10px", gridTemplateColumns:"1fr 1fr",gap:"5px",position:"absolute"}}><PlanFits>{t(data.cityCd)}</PlanFits><PlanFits>등록일 : {data.planDateDt}</PlanFits></div>
        {/* <img style={{ borderRadius: "10px", height:"172px", width:"331px"}} src={img_url+"/"+data.typeCd+"/"+data.savenameNm} alt="" />} */}
        <img
          style={{
            position: "absolute",
            top: 15,
            right: 15,
            width: 25,
            height: 25,
          }}
          src={remove}
          alt=""
          onClick={() => removeWishList()}
        />
      </div>
      <div  className="text-bold text-m"
        style={{ display: "flex", justifyContent: "space-between" }}>{t(data.cityCd)}</div>
      <div
        className="text-bold text-m"
        style={{ display: "flex", justifyContent: "space-between" }}
      >

        
        <div style={{width: "220px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{data.planTitleNm}</div>
        <div>{t("CURRENCY_before")}{data.planPriceLocal}{t("CURRENCY_after")}</div>
      </div>
      <div>{data.planDateDt} - {end_date_formatted}</div>
    </div>
  );
}

const PlanFits = styled.div`
  text-align: center;
  color: #252525;
  font-size: 10px;

  padding: 5px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 100px;
`;

export default WishListBlock;
