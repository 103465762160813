import React from "react";
import styled, { css } from "styled-components";
import { CircularButton } from "../../styles/GlobalStyle";

function PlanOptionSheet(props) {
  const [nightsNo, setNightsNo] = React.useState(props.planOptionList.nightsNo);
  const [accyn, setAccyn] = React.useState(props.planOptionList.accYn);
  const [planFitList, setPlanFitList] = React.useState(
    props.planOptionList.planFit?.split(",") || []
  );

  // console.log(props.planOptionList.planFit?.split(","));

  return (
    <div className="w-9" style={{ margin: "0 auto" }}>
      <div>원하는 옵션을 선택해주세요.</div>
      <div>
        <h3>플랜</h3>
        <div>
          <CircularOptionButton
            selected={nightsNo === "0" ? "Y" : "N"}
            onClick={() => {
              setNightsNo("0");
            }}
          >
            당일 플랜
          </CircularOptionButton>
          <CircularOptionButton
            selected={nightsNo === 1 ? "Y" : "N"}
            onClick={() => {
              setNightsNo(1);
            }}
          >
            1박 플랜
          </CircularOptionButton>
          <CircularOptionButton
            selected={nightsNo === 2 ? "Y" : "N"}
            onClick={() => {
              setNightsNo(2);
            }}
          >
            2박 플랜
          </CircularOptionButton>
          <CircularOptionButton
            selected={nightsNo === 3 ? "Y" : "N"}
            onClick={() => {
              setNightsNo(3);
            }}
          >
            3박 이상 플랜
          </CircularOptionButton>
        </div>
      </div>
      <div>
        <h3>숙소</h3>
        <div>
          <CircularOptionButton
            selected={accyn === "Y" ? "Y" : "N"}
            onClick={() => {
              setAccyn("Y");
            }}
          >
            포함
          </CircularOptionButton>
          <CircularOptionButton
            selected={accyn === "N" ? "Y" : "N"}
            onClick={() => {
              setAccyn("N");
            }}
          >
            미포함
          </CircularOptionButton>
        </div>
      </div>
      <div>
        <h3>테마</h3>
        <div>
          {props.planFit.map((el, i) => (
            // console.log(el)
            <CircularOptionButton
              key={i}
              selected={planFitList.includes(el.planfitCode) ? "Y" : "N"}
              onClick={() => {
                let tmp = planFitList;
                if (!tmp.includes(el.planfitCode)) {
                  tmp = [...tmp, el.planfitCode];
                } else {
                  tmp = tmp.filter((element) => element !== el.planfitCode);
                }

                setPlanFitList(tmp);
              }}
            >
              {el.planfitNm}
            </CircularOptionButton>
          ))}
        </div>
      </div>
      <CircularButton
        className="m-3 text-l"
        backgroundcolor="#5c80ff"
        onClick={() => {
          props.setPlanOptionList({
            nightsNo: nightsNo || "",
            accYn: accyn || "",
            planFit: planFitList.join(",") || "",
          });

          props.setOpen(false);
        }}
      >
        적용
      </CircularButton>
    </div>
  );
}

const CircularOptionButton = styled.div`
  display: inline-block;
  margin: 6px;
  border-radius: 20px;
  padding: 4px 10px;

  ${(props) =>
    props.selected === "Y"
      ? css`
          color: #5c80ff;
          border: 1px solid #5c80ff;
        `
      : css`
          color: #6b6b6b;
          border: 1px solid #cccccc;
        `}
`;

export default PlanOptionSheet;
