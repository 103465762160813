import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { server_url } from "../../server";

import backArrow from "../../images/icons/backArrow.png";
import img_add from "../../images/icons/file_add.svg";



function Comment(){
    const navigate = useNavigate();
    const location = useLocation();
    const[data,setData] = useState([]);
    const[commentList,setCommentList] = useState([]);
    const [email] = useState(window.sessionStorage.getItem("email"));
    let [ordNo,setOrdNo] = useState(0);
    let planSq = location.state.planSq


    const getComment = async() => {
        const res = await axios.post(`${server_url}/myplan/list/features/plan/${location.state.planSq}`).then((response) => {
            const commentData = response.data.map((comment,index)=>({
                ...comment,
                ordNo: index, // 데이터의 순서대로 인덱스를 ordNo로 설정
            }))
            setCommentList(commentData);
            console.log(response.data.length);
            setOrdNo(response.data.length);
        });
       
    };


    useEffect(()=>{
        getComment();
        
    },[]);

    console.log(commentList);

    const addComment = (groupId)=>{
        const newComment = {
            codeId: "",
            groupId: groupId,
            ordNo: commentList.length, // 배열의 길이를 사용하여 인덱스 설정
            planSq: planSq,
            titleEnNm: "",
            titleKoNm: "",
            titleZhchsNm: "", // 추가: 중국어 간체 버전
            titleZhchtNm: "", // 추가: 중국어 번체 버전
          };
          setCommentList([...commentList, newComment]);
    }

    const handelText = (e,idx) => {
        const newValue = e.target.value;
        const updatedCommentList = commentList.map((comment, index) =>
            index === idx ? { ...comment, titleKoNm: newValue, titleEnNm: newValue,titleZhchsNm:newValue,titleZhchtNm:newValue } : comment
        );
        console.log("update");
        console.log(updatedCommentList);
        setCommentList(updatedCommentList);
    }

    const ListChangeUp = (idx,groupId) => {
        setCommentList((prevList) => {
            const newList = [...prevList];
            const targetGroup = prevList[idx].groupId;
        
            // Find the index of the previous element
            let prevPlanInfoIdx = -1;
            for (let i = idx - 1; i >= 0; i--) {
              if (prevList[i].groupId === groupId) {
                prevPlanInfoIdx = i;
                break;
              }
            }
        
            if (prevPlanInfoIdx !== -1) {
              // Swap the elements
              const temp = newList[prevPlanInfoIdx];
              newList[prevPlanInfoIdx] = newList[idx];
              newList[idx] = temp;

              // Update ordNo for the swapped elements
              newList[prevPlanInfoIdx].ordNo = prevPlanInfoIdx + 1;
              newList[idx].ordNo = idx + 1;
            }
        
            return newList;
          });
    }

    const ListChangeDown = (idx,groupId) => {
        setCommentList((prevList) => {
            const newList = [...prevList];
            const targetGroup = prevList[idx].groupId;
        
            // Find the index of the next 'PLANINFO' element
            let nextPlanInfoIdx = -1;
            for (let i = idx + 1; i < prevList.length; i++) {
              if (prevList[i].groupId === groupId) {
                nextPlanInfoIdx = i;
                break;
              }
            }
        
            if (nextPlanInfoIdx !== -1) {
              // Swap the elements
              const temp = newList[nextPlanInfoIdx];
              newList[nextPlanInfoIdx] = newList[idx];
              newList[idx] = temp;

              // Update ordNo for the swapped elements
              newList[nextPlanInfoIdx].ordNo = nextPlanInfoIdx + 1;
              newList[idx].ordNo = idx + 1;
            }
        
            return newList;
          });
    }

    const ListChangeRemove = (idx) => {
        setCommentList((prevList) => {
            const newList = prevList.filter((item, index) => index !== idx);
            return newList;
        });
    };
    const SaveComment = async() => {
        console.log(commentList);
        const res = await axios.post(`${server_url}/features/savePlan`,commentList).then((response) => {
            console.log(response);
            if(response.data.result == "success"){
                alert("코멘트가 저장되었습니다");
                window.history.back();
            }
        });
       
    };

    return(
        <div>
             <div className={"w"}      style={{ display: "flex", justifyContent: "space-between", alignItems: "center",  top: "0",backgroundColor: "#ffffff",marginTop:"20px"}}>
                <img src={backArrow} alt=""  width="10px"  height="15px"  style={{ transform: "scaleX(-1)", marginLeft: "1%" }} className="c"  onClick={() => { window.history.back(); }}/>
                <div style={{color:"5c80ff",fontWeight:"800",fontSize:"20px",margin:"auto",marginLeft:"30px"}}>플랜 코멘트</div>
             </div>
             <div style={{overflow:"auto",paddingBottom:"30px"}}>
             <div style={{marginTop:"20px"}}>
                <span style={{fontWeight:"bold",fontSize:"18px",marginBottom:"10px",display:"inline-block"}}>플랜정보</span>
                {commentList.map((item,idx)=>(
                    item.groupId === 'PLANINFO' && (
                    <div key={idx} style={{marginTop:"5px"}}>
                        <input type="text" value={item.titleKoNm} onChange={(e)=>handelText(e,idx)} style={{height:"30px",outline:"none",boxSizing:"border-box"}}/>
                        <button onClick={()=>ListChangeUp(idx,"PLANINFO")} style={{height:"30px"}}>△</button>
                        <button onClick={()=>ListChangeDown(idx,"PLANINFO")} style={{height:"30px"}}>▽</button>  
                        <button onClick={()=>ListChangeRemove(idx)} style={{height:"30px"}}>X</button>
                    </div>
                    )
                    
                ))}
             </div>
             <div style={{display:"flex",alignItems:"center",marginTop:"50px"}} onClick={()=>addComment("PLANINFO")}>
                    <img src={img_add} style={{width:"25px",height:"25px",marginRight:"10px"}}></img>
                    <span>플랜정보 추가</span>
            </div>
            <div style={{marginTop:"20px"}}>
                <span style={{fontWeight:"bold",fontSize:"18px",marginBottom:"10px",display:"inline-block"}}>예약방법</span>
                {commentList.map((item,idx)=>(
                    item.groupId === 'PLANBKG' && (
                    <div key={idx} style={{marginTop:"5px"}}>
                        <input type="text" value={item.titleKoNm} onChange={(e)=>handelText(e,idx)} style={{height:"30px",outline:"none",boxSizing:"border-box"}}/>
                        <button onClick={()=>ListChangeUp(idx,"PLANBKG")} style={{height:"30px"}}>△</button>
                        <button onClick={()=>ListChangeDown(idx,"PLANBKG")} style={{height:"30px"}}>▽</button>
                        <button onClick={()=>ListChangeRemove(idx)} style={{height:"30px"}}>X</button>
                    </div>
                    )
                ))}
            </div>
            <div style={{display:"flex",alignItems:"center",marginTop:"50px"}} onClick={()=>addComment("PLANBKG")}>
                    <img src={img_add} style={{width:"25px",height:"25px",marginRight:"10px"}}></img>
                    <span>예약방법 추가</span>
            </div>
            <div style={{marginTop:"20px"}}>
                <span style={{fontWeight:"bold",fontSize:"18px",marginBottom:"10px",display:"inline-block"}}>이용규정</span>
                {commentList.map((item,idx)=>(
                    item.groupId === 'PLANTNC' && (
                    <div key={idx} style={{marginTop:"5px"}}>
                        <input type="text" value={item.titleKoNm} onChange={(e)=>handelText(e,idx)} style={{height:"30px",outline:"none",boxSizing:"border-box"}}/>
                        <button onClick={()=>ListChangeUp(idx,"PLANTNC")} style={{height:"30px"}}>△</button>
                        <button onClick={()=>ListChangeDown(idx,"PLANTNC")} style={{height:"30px"}}>▽</button>    
                        <button onClick={()=>ListChangeRemove(idx)} style={{height:"30px"}}>X</button>
                    </div>
                    )
                ))}
            </div>
            <div style={{display:"flex",alignItems:"center",marginTop:"50px"}} onClick={()=>addComment("PLANTNC")}>
                    <img src={img_add} style={{width:"25px",height:"25px",marginRight:"10px"}}></img>
                    <span>이용규정 추가</span>
            </div>

            <div style={{marginTop:"20px"}}>
                <span style={{fontWeight:"bold",fontSize:"18px",marginBottom:"10px",display:"inline-block"}}>취소규정</span>
                {commentList.map((item,idx)=>(
                    item.groupId === 'PLANCXLPLC' && (
                    <div key={idx} style={{marginTop:"5px"}}>
                        <input type="text" value={item.titleKoNm} onChange={(e)=>handelText(e,idx)} style={{height:"30px",outline:"none",boxSizing:"border-box"}}/>
                        <button onClick={()=>ListChangeUp(idx,"PLANCXLPLC")} style={{height:"30px"}}>△</button>
                        <button onClick={()=>ListChangeDown(idx,"PLANCXLPLC")} style={{height:"30px"}}>▽</button> 
                        <button onClick={()=>ListChangeRemove(idx)} style={{height:"30px"}}>X</button>   
                    </div>
                    )
                ))}
            </div>
            <div style={{display:"flex",alignItems:"center",marginTop:"50px"}} onClick={()=>addComment("PLANCXLPLC")}>
                    <img src={img_add} style={{width:"25px",height:"25px",marginRight:"10px"}}></img>
                    <span>취소규정 추가</span>
            </div>
            </div>

            <div style={{position: "fixed", bottom: 0, maxWidth: "740px", width:"100%",marginBottom:"15px"}}>
                <input type="button" value={"수정 완료"} onClick={()=>SaveComment()} style={{background:"#4f95d5",border:"none","borderRadius":"10px",color:"#fff",height:"30px",width:"100%"}}/>
            </div>
        </div>
    )

}

export default Comment;