import React from "react";
import { useEffect } from "react";
import styled from "styled-components";

function DateSelect(props) {
  const monthArray = new Array(12).fill();
  // const dayArr = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const dayArr = new Array(31).fill();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BorderNoneSelect
          name="year"
          required
          style={{ padding: "5px 4vw" }}
          onChange={(e) => {
            props.setYear(e.currentTarget.value);
          }}
          disabled={props.disabled}
        >
          <option value="" selected disabled style={{ display: "none" }}>
            년
          </option>
          <option value={2022}>2022년</option>
          <option value={2023}>2023년</option>
          <option value={2024}>2024년</option>
        </BorderNoneSelect>
        <BorderNoneSelect
          name="month"
          defaultValue=""
          required
          onChange={(e) => {
            props.setMonth(e.currentTarget.value);
            // console.log(month);
          }}
          disabled={props.disabled}
        >
          <option value="" disabled style={{ display: "none" }}>
            월
          </option>
          {monthArray.map((el, i) => {
            return <option value={i + 1}>{i + 1}월</option>;
          })}
        </BorderNoneSelect>
        <BorderNoneSelect
          name="day"
          required
          onChange={(e) => {
            props.setDay(e.currentTarget.value);
          }}
          disabled={props.disabled}
        >
          <option value="" selected disabled style={{ display: "none" }}>
            일
          </option>
          {/* {dayArrforMonth.map((el, i) => {
            return <option value={i + 1}>{i + 1}</option>;
          })} */}
          {dayArr.map((el, i) => {
            return <option value={i + 1}>{i + 1}일</option>;
          })}
        </BorderNoneSelect>
      </div>
    </div>
  );
}

const BorderNoneSelect = styled.select`
  border: 1px solid black;
  border-radius: 50px;
  padding: 5px 3vw;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

export default DateSelect;
