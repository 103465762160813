import React, { useEffect, useState } from "react";
import axios from "axios";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import Footer from "../../components/views/Layout/Footer/Footer";

import { server_url } from "../../server";
import {
  BorderNoneSelect,
  CircularButton,
  UnderbarInput,
} from "../../styles/GlobalStyle";
import clearButton from "../../images/icons/clearButton.png";
import { getOrdList } from "../../recoil/state";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import {capitalizeFirstLetter} from "../../js/common";


function ChangeInfoPage() {
  //init
  window.sessionStorage.removeItem("isFirstLogin");

  const getUserInfo = async () => {
    try {
      const countryRes = await axios.get(`${server_url}/ord/countries/ko`);

      setCountryList(countryRes.data);


      const res = await axios.get(`${server_url}/members/info`, {
        headers: { accessToken: window.sessionStorage.getItem("token") },
      });
      console.log(res.data);

      setEmail(res.data.data.email);
      setFirstnameNm(res.data.data.firstnameNm);
      setLastnameNm(res.data.data.lastnameNm);
      setNationalityCd(res.data.data.nationalityCd);
      setTell(res.data.data.tel1);
      setCountrydialCd(res.data.data.countrydialCd);
      setUserId(res.data.data.userId);

    } catch (e) {
      console.log(e);
    }
  };
  const [email, setEmail] = useState();
  const [firstnameNm, setFirstnameNm] = useState();
  const [lastnameNm, setLastnameNm] = useState();
  const [nationalityCd, setNationalityCd] = useState("HK");
  const [tell, setTell] = useState();
  const [countrydialCd, setCountrydialCd] = useState("HK");
  const [userId, setUserId] = useState();
  const [countryList, setCountryList] = useState([]);
  const navigate = useNavigate();
  


  useEffect(() => {
    getUserInfo();
  }, []);

  const changeUserInfo = async () => {
    console.log(nationalityCd);
    let body = {
      email: userId,
      userId: userId,
      lastnameNm: lastnameNm,
      firstnameNm: firstnameNm,
      nationalityCd: nationalityCd,
      tel1: tell,
      countrydialCd: countrydialCd,
    };

    console.log(body);

    const res = await axios.put(`${server_url}/members`, body, {
      headers: { accessToken: window.sessionStorage.getItem("token") },
    });

    if (res.status === 400) alert("모든 정보를 입력해주세요");
    if (res.data != null) {
      alert("회원 정보가 수정되었습니다")
    }

    return res.data;
  };

  const changeUserInfoStatus = async () => {
    let body = {
      email: userId,
      userId: userId,
      lastnameNm: lastnameNm,
      firstnameNm: firstnameNm,
      nationalityCd: nationalityCd,
      tel1: tell,
      countrydialCd: countrydialCd,
    };

    const isWithDrawal = window.confirm("정말 탈퇴하시곘습니까?");
    if (isWithDrawal) {
      //탈퇴 진행
      const res = await axios.put(`${server_url}/members/withDrawal`, body, {
        headers: { accessToken: window.sessionStorage.getItem("token") },
      });

      if (res.status === 400) alert("모든 정보를 입력해주세요");
      console.log("탈퇴진행중");
      console.log(res);
      if (res.data != null) {
        alert("회원 탈퇴되었습니다.")
        window.sessionStorage.removeItem("email");
        window.sessionStorage.removeItem("token");
        //메인페이지 이동
        window.location.href = window.location.protocol + '//' + window.location.host
      }
    } else {
      //탈퇴 진행 안함
      return false;
    }
  }

  function historyGoBack() {
    navigate(-1);
  }

  const ordList = useRecoilValue(getOrdList);

  

  return (
    <div className="m-8">


      <div className="container">
        <PageHeader title="회원정보변경" />
        <div className="w-9" style={{ marginTop: 30 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <div style={{ position: "relative" }}>
              <label className="text-bold">이메일</label>
              <UnderbarInput
                value={userId || ""}
                className="text-sm m-1"
                type="email"
                disabled
              />
            </div>
            <div style={{ position: "relative" }}>
              <label className="text-bold">여권 성문 성</label>
              <UnderbarInput
                value={firstnameNm || ""}
                className="text-sm m-1"
                onChange={(e) => setFirstnameNm(e.currentTarget.value)}
              />
              {firstnameNm && (
                <img
                  src={clearButton}
                  alt=""
                  onClick={() => setFirstnameNm("")}
                  style={{ position: "absolute", bottom: "10px", right: "2%" }}
                />
              )}
            </div>
            <div style={{ position: "relative" }}>
              <label className="text-bold">여권 영문 이름</label>
              <UnderbarInput
                value={lastnameNm || ""}
                className="text-sm m-1"
                onChange={(e) => setLastnameNm(e.currentTarget.value)}
              />
              {lastnameNm && (
                <img
                  src={clearButton}
                  alt=""
                  onClick={() => setLastnameNm("")}
                  style={{ position: "absolute", bottom: "10px", right: "2%" }}
                />
              )}
            </div>
            <div style={{ position: "relative" }}>
              <label className="text-bold">여권 상 국가</label>
              {/* <UnderbarInput
              value={nationalityCd || ""}
              className="text-sm m-1"
              onChange={(e) => setNationalityCd(e.currentTarget.value)}
            /> */}
              <BorderNoneSelect
                style={{ width: "100%" }}
                className="m-1"
                onChange={(e) => setNationalityCd(e.currentTarget.value)}
                value={nationalityCd}
              >
               {countryList &&
                countryList.map((el, i) => {
                  let language = "country"+capitalizeFirstLetter(localStorage.getItem("language"))+"Nm"
                  console.log(language);
                  return <option value={el.countryCd}>{el[language]}</option>;
                })}
              </BorderNoneSelect>
            </div>
            <div style={{ position: "relative" }}>
              <label className="text-bold">휴대전화 번호</label>
              <UnderbarInput
                value={tell || ""}
                className="text-sm m-1"
                onChange={(e) => setTell(e.currentTarget.value)}
              />
              {tell && (
                <img
                  src={clearButton}
                  alt=""
                  onClick={() => setTell("")}
                  style={{ position: "absolute", bottom: "10px", right: "2%" }}
                />
              )}
            </div>
            <div style={{ position: "relative" }}>
              <label className="text-bold">휴대전화 가입국가</label>
              <BorderNoneSelect
                style={{ width: "100%" }}
                className="m-1"
                onChange={(e) => setCountrydialCd(e.currentTarget.value)}
                value={countrydialCd}
              >
                {countryList &&
                countryList.map((el, i) => {
                  let language = "country"+capitalizeFirstLetter(localStorage.getItem("language"))+"Nm"
                  console.log(language);
                  return <option value={el.countryCd}>{el[language]}</option>;
                })}
              </BorderNoneSelect>
            </div>

            <CircularButton onClick={changeUserInfo} backgroundcolor="#42A9EC">
              완료
            </CircularButton>

            <CircularButton onClick={historyGoBack} backgroundcolor="#42A9EC">
              다음에 하기
            </CircularButton>

            <CircularButton onClick={changeUserInfoStatus} backgroundcolor="#42A9EC">
              회원탈퇴
            </CircularButton>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default ChangeInfoPage;
