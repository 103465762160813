import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import SavedOption from "../../components/Plan/SavedOption";
import ReservedPlan from "../../components/Plan/ReservedPlan";

import homeMenu from "../../images/icons/homeMenu.png";
import backArrow from "../../images/icons/backArrow.png";
import img_makePlan from "../../images/icons/main_makePlan.svg"

import { server_url } from "../../server";

function MyPlanPage() {
  const navigate = useNavigate();
   // 다국어 관련 추가 (2023-03-22)
   const { t, i18n } = useTranslation();
  const [email] = useState(window.sessionStorage.getItem("email"));
  const [savedOptionList, setSavedOptionList] = useState([]);


  useEffect(() => {
    console.log(email);
    if (email == null) {
      alert(t("MYPAGE.mypagePOP1"));
      navigate("/");
    }
    const getMyOptionList = async () => {
      let body = {
        uploadUser : email
      }
      try {
        const res = await axios.post(`${server_url}/ordplan/myOptionList`,body);
        console.log(res.data);
        setSavedOptionList(res.data);
      } catch (e) { }
    };
    getMyOptionList();
   
  }, []);

  return (
    <div className="container">
      <PageHeader title="옵션 만들기" />
      <div
        className="m-8 w-8"
        style={{
          boxShadow:
            "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px 2px 7px rgba(0, 0, 0, 0.1)",
          borderRadius: "100px",
        }}
      >
      </div>
      <div className="w-9" style={{ display: "flex",flexDirection: "column",alignItems: "center",maxHeight:"595px",overflow:"auto"}}>
          {savedOptionList &&
            savedOptionList.map((el) => {
              return <SavedOption data={el} />;
            })}
        </div>
        <div style={{display:"flex",flexDirection:"column",width:"100%",alignItems:"center",padding:"2.5rem 0"}} onClick={()=>navigate("/mypage/makingOptionDetail")}>
            <img src={img_makePlan} style={{width:"40px",paddingBottom:"10px"}}></img>
            <span>옵션만들기</span>
        </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 10px;

  width: 40%;
`;

export default MyPlanPage;
