import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";


import { server_url } from "../../server";
import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

import img_default from "../../images/img/img_default.png"
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";


function GetMyPlan(props) {
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [price, setPrice] = useState("0");
  /* 젤 앞 대문자 설정 */
  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) {
      return str;
    }

    const firstLetter = str.charAt(0).toUpperCase();
    const remainingLetters = str.slice(1);

    return firstLetter + remainingLetters;
  };

  const changeUnixTime = (value) => {
    const timestampInMilliseconds = value * 1000; // 밀리초로 변환
    const date = new Date(timestampInMilliseconds);

    // 원하는 형식으로 날짜와 시간을 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateTime;
  };


  const [data, setData] = React.useState(props.data);
  console.log(data);
  const [imgSrc, setImgSrc] = React.useState(img_default);
  let language = i18n.language;
  let title = "title" + capitalizeFirstLetter(language) + "Nm";
  let planTitle = data[title];
  let regTime = "-";
  if (data.regUt != null) {
    regTime = changeUnixTime(data.regUt);
  }
  let updTime = "-";
  if (data.updUt != null) {
    updTime = changeUnixTime(data.updUt);
  }
  const getImage = async () => {
    let body = {
      imageSq: data.imageSq
    };
    const res = await axios.post(`${server_url}/plans/${props.data.planSq}/${props.data.planCd}`, body).then((response) => {
      console.log(response);
      //setImgSrc(response.data.savenameNm);
      if (response.data.savenameNm != undefined) {
        setImgSrc(response.data.savenameNm);
      }
      if (response.data.planPriceLocal != undefined) {
        setPrice(response.data.planPriceLocal);
      }
    });

  };

  useEffect(() => {
    getImage();
  }, []);

  const deletePlan = async (planSq) => {
    console.log(planSq);
    let body = {
      planSq : planSq
    }
    if (window.confirm("삭제하시겠습니까?")) {
      //승인시
      const res = await axios.post(`${server_url}/ord/plan/delete`, body).then((response) => {
        console.log(response);
        if (response.result = "success") {
          // 페이지 새로 고침
          window.location.reload();
        }
      });
    } else {
      //거절시
    }
  };

  const sharePlan = ()=>{
    alert("링크가 복사되었습니다.");
  }

  return (
    <div className="w-9"
      style={{
        borderBottom: "1px solid #f4f4f4",
        padding: "10px 0",
        display: "flex"
      }}>
      <div>

     
      <div onClick={() => { navigate(`/mypage/makingPlanDetail/${data.planSq}/${data.planCd}`, { state: data }) }}>
        <img src={imgSrc} style={{ width: "120px", height: "120px" }}></img>
      </div>
      <div style={{display:"flex",justifyContent:"center"}}>
          <CopyToClipboard text={`https://gomyplan.com/plans/${data.planSq}/${data.planCd}`} onCopy={sharePlan}>
          <div style={{textAlign:"center"}}>
            <button style={{fontSize:"15px", background:"#91a9fd",border:"1px solid",borderRadius:"5px",color:"#fff", padding:"0 10px"}}>URL 복사</button>
            </div>
        </CopyToClipboard>
        </div>
        </div>
      <div style={{ display: "flex", flexDirection: "column", paddingLeft: "10px" }} onClick={() => { navigate(`/mypage/makingPlanDetail/${data.planSq}/${data.planCd}`, { state: data }) }}>
        <span style={{ fontSize: "10px", color: "rgb(107,107,107)", fontWeight: "600" }}>My 플랜 코드 : {data.planCd}</span>
        <span style={{ marginTop: "10px", fontSize: "18px", fontWeight: "700" }}>{planTitle}</span>
        <div style={{ display: "flex" }}>
          <span style={{ fontSize: "18px", fontWeight: "700" }}>{t("CURRENCY_before")}{price}{t("CURRENCY_after")}</span>
        </div>
        <span style={{ fontSize: "12px", marginTop: "20px", color: "#4472C4" }}>최초 등록 : {regTime}</span>
        <span style={{ fontSize: "12px", color: "#4472C4" }}>최근 수정 : {updTime}</span>
      </div>
      <div style={{ marginLeft: "auto",display:"flex",flexDirection:"column" }}>
        <div style={{textAlign:"center"}}>
          <button onClick={() => deletePlan(data.planSq)} style={{background:"#91a9fd",border:"1px solid",borderRadius:"5px",color:"#fff",padding:"0 6px"}}>X</button>
        </div>
      </div>
      
    </div>
    

  );
}

export default GetMyPlan;
