import React from "react";

function InformationPolicy() {
  return (
    <textarea
      style={{ width: "100%", height: "55vh", resize: "none", border: "none" }}
      readOnly
    >
      {`< 고마이플랜 >('www.gomyplan.com'이하 '고마이플랜')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.				
    
○ 이 개인정보처리방침은 2021년 1월 1부터 적용됩니다.				
    
    
제1조(개인정보의 처리 목적)
< 고마이플랜 >('www.gomyplan.com'이하 '고마이플랜')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.	
  
1. 홈페이지 회원가입 및 관리				
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.	
  
2. 민원사무 처리				
민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.		

3. 재화 또는 서비스 제공				
서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.	
  
4. 마케팅 및 광고에의 활용				
신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.				


제2조(개인정보의 처리 및 보유 기간)				
1. < 고마이플랜 >은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.				
2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.				
1) <홈페이지 회원가입 및 관리> 와 관련한 개인정보는 수집.이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유.이용됩니다.				
보유근거 : 고마이플랜 이용약관				
관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년				
2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년				
3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년				
4) 계약 또는 청약철회 등에 관한 기록 : 5년				
예외사유 : 기타 이용약관에 의거 필요하다고 판단되는 경우	

  
제3조(개인정보의 제3자 제공)				
1. < 고마이플랜 >은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.				
2. < 고마이플랜 >은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.				
1) 1. < 고마이플랜 및 여행관련 서비스 용역 공급자 >				
2) 개인정보를 제공받는 자 : 고마이플랜 및 여행관련 서비스 용역 공급자				
3) 제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 로그인ID, 성별, 생년월일, 이름				
4) 제공받는 자의 보유.이용기간: 지체없이 파기	


  
제4조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)				
1. 정보주체는 고마이플랜에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.				
2. 제1항에 따른 권리 행사는고마이플랜에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 고마이플랜은(는) 이에 대해 지체 없이 조치하겠습니다.				
3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.				
4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.				
5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.				
6. 고마이플랜은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.				



제5조(처리하는 개인정보의 항목 작성) 				
< 고마이플랜 >은(는) 다음의 개인정보 항목을 처리하고 있습니다.				
< 홈페이지 회원가입 및 관리 >				
1) 필수항목 : 이메일, 로그인ID, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록				
2) 선택항목 : 휴대전화번호, 생년월일, 이름		




제6조(개인정보의 파기)				
1. < 고마이플랜 > 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.	
  
2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.				
1)  법령 근거 :				
2)  보존하는 개인정보 항목 : 계좌정보, 거래날짜	
  
3.  개인정보 파기의 절차 및 방법은 다음과 같습니다.				
1)  파기절차				
< 고마이플랜 > 은(는) 파기 사유가 발생한 개인정보를 선정하고, < 고마이플랜 > 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.				
2) 파기방법				
전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.				
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다				



제7조(개인정보의 안전성 확보 조치)				
< 고마이플랜 >은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.				
1. 정기적인 자체 감사 실시				
개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.				
2. 개인정보 취급 직원의 최소화 및 교육				
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.				
3. 내부관리계획의 수립 및 시행				
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.				
4. 해킹 등에 대비한 기술적 대책				
<고마이플랜>('고마이플랜')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.				
5. 개인정보의 암호화				
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.				
6. 접속기록의 보관 및 위변조 방지				
개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.				
7. 개인정보에 대한 접근 제한				
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.				
8. 문서보안을 위한 잠금장치 사용				
개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.				
9. 비인가자에 대한 출입 통제				
개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.			




제8조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)				
1. 고마이플랜 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.				
2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.				
1)  쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.				
2)  쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.				
3)  쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.					
`}
    </textarea>
  );
}

export default InformationPolicy;
