import React from "react";
import { useEffect } from "react";
import styled from "styled-components";

function TimeSelect(props) {
  const hourArr = new Array(12).fill();
  const minuteArr = new Array(60).fill();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BorderNoneSelect
          name="month"
          defaultValue=""
          required
          onChange={(e) => {
            props.setHour(e.currentTarget.value);
          }}
          disabled={props.disabled}
        >
          <option value="" disabled style={{ display: "none" }}>
            시
          </option>
          {hourArr.map((el, i) => {
            return <option value={i + 1}>{i + 1}시</option>;
          })}
        </BorderNoneSelect>
        :
        <BorderNoneSelect
          name="day"
          required
          onChange={(e) => {
            props.setMinute(e.currentTarget.value);
          }}
          disabled={props.disabled}
        >
          <option value="" selected disabled style={{ display: "none" }}>
            분
          </option>
          {/* {dayArrforMonth.map((el, i) => {
            return <option value={i + 1}>{i + 1}</option>;
          })} */}
          {minuteArr.map((el, i) => {
            return <option value={i + 1}>{i + 1}분</option>;
          })}
        </BorderNoneSelect>
      </div>
    </div>
  );
}

const BorderNoneSelect = styled.select`
  border: 1px solid black;
  border-radius: 50px;
  padding: 5px 3vw;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

export default TimeSelect;
