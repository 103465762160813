import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import hotelIcon from "../../images/icons/hotelIcon.png";
import { img_url, server_url } from "../../server";

import SlotSelectModal from "../../pages/PlanPage/SlotSelectModal";
import MyModal from "../Modal/MyModal";
import MySheet from "../Sheet/MySheet";
import OptionDetailPage from "../../pages/PlanPage/OptionDetailPage";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function PlanSlot(props) {
  //console.log(props);
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const params = useParams();
  const [originalOption] = React.useState(props.item);
  const [item, setItem] = React.useState(props.item.options.newOptionSq ? props.item.options : props.item);
  const [departure] = React.useState(props.departure);
  const [arrival] = React.useState(props.arrival);

  const [optionDetailData, setOptionDetailData] = React.useState();
  const [optionDetailIsOpen, setOptionDetailIsOpen] = React.useState(false);
  const [changeModalIsOpen, setChangeModalIsOpen] = React.useState(false);

  const categoryCd = ["ACC01", "ACC02", "ACC03", "ACC04", "ACC05"];
  const [displayStyle, setDisplayStyle] = React.useState('grid');

  let imgURL = item.options.savenameNm;
  if (item.options.savenameNm != undefined) {
    if (item.options.savenameNm.includes("https:")) {
      imgURL = item.options.savenameNm;
    } else {
      imgURL = img_url + "/" + item.options.typeCd + "/" + item.options.savenameNm;
    }
  }

  React.useEffect(() => {
    setItem(props.item.options.newOptionSq ? props.item.options : props.item);
  }, [props.item]);

  React.useEffect(() => {
    const getOptionDetail = async () => {
      const optionSq = item.options.newOptionSq
        ? item.options.newOptionSq
        : item.optionSq;

      let body = {
        userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
        fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
        today: props.departure,
        startDate: props.departure,
      };
      await axios.post(`${server_url}/options/${optionSq}`, body).then((v) => {
        // console.log(v.data);
        setOptionDetailData(v.data);
      });
    };

    // props.price 값에 따라 display 값 변경
    if (props.price === '0') {
      setDisplayStyle('none');
    } else {
      setDisplayStyle('block');
    }
    getOptionDetail();
  }, [props]);

  const personStyle = {
    display : displayStyle,
    fontSize: "10px", 
    margin: "0", 
    color: "#6b6b6b"
  }
  const priceStyle ={
    display : displayStyle,
    margin: "5px 0px"
  }

  return (
    <div
      style={{display: "grid",
      gridTemplateColumns: "1fr 2.3fr",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.05)",
      borderRadius: "10px"}}
    >
      <div
        style={{
          position: "relative",
          minWidth : "160px",
          minHeight : "60px",
          borderRadius: "10px 0 0 10px",
          background: `url(${imgURL}) center center / cover no-repeat`,
        }}
        onClick={() => {
          const detailOpen = item.options.newOptionSq
            ? item.options.detailsYn
            : item.detailsYn;

          // console.log(detailOpen);
          // item.detailsYn === "Y" && setOptionDetailIsOpen(true);

          detailOpen === "Y" && setOptionDetailIsOpen(true);
        }}
      >
        {props.time && (
          <div
            style={{
              position: "absolute",
              width: "40%",
              height: "15%",
              maxHeight:"40px",
              background: "#ffd775",
              borderRadius: "100px",
              top: "9%",
              left: "4%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              fontWeight: "500",
              padding: "2px",
            }}
          >
            {props.time}
          </div>
        )}
        {item.detailsYn === "Y" && (
          <div
            style={{
              position: "absolute",
              width: "40%",
              height: "15%",
              maxHeight:"40px",
              background: "#62a5fa",
              borderRadius: "100px",
              bottom: "4%",
              right: "2%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              fontWeight: "500",
              padding: "2px",
              color: "white",
              border: "1px solid black",
            }}
          >
            자세히
          </div>
        )}
      </div>
      <div style={{ borderRadius: "0 10px 10px 0", paddingLeft: "15px" }}>
        <div
          style={{
            position: "relative",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                marginBottom: 0,
                fontWeight: 500,
              }}
              className="text-sm slotTitle"
            >
              {item.options.optionTitleNm}
            </p>
            {categoryCd.includes(item.options.categoryCd) && (
              <img
                src={hotelIcon}
                alt=""
                height="17px"
                style={{ marginTop: "14px" }}
              />
            )}
          </div>
          <pre
            style={{
              fontSize: "12px",
              marginTop: 5,
              color: "#454545",
              wordWrap:"break-word",
              whiteSpace:"pre-wrap",
              width:"100%",
              wordWrap:"anywhere"
            }}
          >
            {item.options.optionDescNm}
          </pre>
          {item.requiredYn === "N" ? (
            <div
              className="text-xsm"
              style={{
                position: "absolute",
                width: "",
                background: "#252525",
                borderRadius: "100px",
                color: "white",
                textAlign: "center",
                padding: "2px 10px",

                top: "0%",
                right: "3%",
              }}
              onClick={(event) => {
                event.stopPropagation();
                // console.log(props);
                setChangeModalIsOpen(!changeModalIsOpen);
              }}
            >
              {t('PLANDETAIL.DETAIL02')}
            </div>
          ) : (
            <></>
          )}
        </div>
        <MyModal
          isOpen={changeModalIsOpen}
          setIsOpen={setChangeModalIsOpen}
          element={
            <SlotSelectModal
              params={{ planSq: params.planSq, planCd: params.planCd }}
              slotSq={item.slotSq}
              departure={departure}
              arrival={arrival}
              setSlotItemAfterChange={props.setSlotItemAfterChange}
              changedSlotList={props.changedSlotList}
              setChangedSlotList={props.setChangedSlotList}
              setIsOpen={setChangeModalIsOpen}
              currentPrice={item.options.optionPriceAdultLocal}
              price={props.price}
              originalOption={originalOption}
              setChangedSlot={props.setChangedSlot}
            />
          }
        />
        <div
          style={{
            float: "right",
            textAlign: "right",
            paddingRight: "15px",
          }}
        >
          <p style={personStyle}>
            {t('PLANDETAIL.DETAIL03')}
          </p>
          {/* <p>{props.price}원</p> */}

          <p className="text-bold" style={priceStyle}>
            {setCurrencySymbol()} {props.price !== "-1" ? `${props.price} ` : "예약 마감"}
          </p>
          <p
            style={{
              fontSize: "12px",
              background: "#49c4ff",
            }}
          >
            {item.options.minpax > 1 ? `${t('PLANDETAIL.DETAIL05')}${item.options.minpax}${t('PLANDETAIL.FOOTLYR03')}` : ""}
          </p>
        </div>
      </div>
      <MySheet
        isOpen={optionDetailIsOpen}
        setOpen={setOptionDetailIsOpen}
        snapPoints={[800]}
        children={<OptionDetailPage data={optionDetailData} />}
      />
    </div>
  );
}

function setCurrencySymbol() {
  let currencySymbol;
  switch (localStorage.getItem("fxCodeCd")) {
    case "KRW":
      currencySymbol = "₩ "
      break;
    case "USD":
      currencySymbol = "US$ "
      break;
    case "HKD":
      currencySymbol = "HK$ "
      break;
    case "RMB":
      currencySymbol = "¥"
      break;
    default: currencySymbol = "₩ "
  }
  return currencySymbol;
}

export default PlanSlot;
