import React, { useState } from "react";

import Arrow from "../../images/icons/backArrow.png";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function FAQBlock({ item }) {
  // 다국어 관련 추가 (2023-06-21)
  const { t, i18n } = useTranslation();

  console.log(item);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="m-2"
      style={{ borderRadius: 10, backgroundColor: "#FAFAFA", padding: 15 }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{t(item.Question)}</div>
        <div>
          <img
            onClick={() => setIsOpen(!isOpen)}
            alt=""
            src={Arrow}
            style={{
              transform: `rotate(${isOpen ? "270deg" : "90deg"})`,
              width: 8,
              height: 15,
            }}
          />
        </div>
      </div>
      {isOpen && <div className="m-2 text-sm">{t(item.Answer)}</div>}
    </div>
  );
}

export default FAQBlock;
