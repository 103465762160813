import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { server_url, img_url } from "../../server";

import OptionDetailmodal from "../../components/Modal/OptionDetailModal";
import PreviewOption from "../MyPage/PreviewOption";


import PageHeader from "../../components/views/Layout/Header/PageHeader";
import img_modify from "../../images/img/planNameModify.svg"
import img_add from "../../images/icons/file_add.svg"
import img_camera from "../../images/img/img_camera.svg"

/* Drag&Drop */
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; // Import react-beautiful-dnd
import { HTML5Backend } from 'react-dnd-html5-backend';



function MakePlanSlot(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [data, setData] = useState();
    const [title, setTitle] = useState('');
    const [slotDtoList, setSlotDtoList] = useState([]);
    const [dayNo, setDayNo] = useState(0);
    const [planSq, setPlanSq] = useState("");
    const [planCd, setPlanCd] = useState("");
    const [imagesCount, setImagesCount] = useState(0);

    const [changeModalIsOpen, setChangeModalIsOpen] = React.useState(false);

    const [optionSq, setOptionSq] = useState("");
    const [element, setElement] = useState(null);
    const [active, setActive] = useState(false);

    console.log(location.state);




    useEffect(() => {
        setData(location.state);
    }, []);

    useEffect(() => {
        console.log(data);
        if (data != null && data != undefined) {
            console.log(data);
            setPlanSq(data.planSq);
            setPlanCd(data.planCd);
            if (data.planImages != undefined) {
                setImagesCount(data.planImages.length);
            }
            if (data.pcLocalNm != undefined) {
                setTitle(data.pcLocalNm);
            }
            if (data.slotDtoList != undefined) {
                setSlotDtoList(data.slotDtoList !== undefined ? data.slotDtoList : []);
            }

            // data.slotDtoList.length가 0이 아닌 경우에만 dayNo를 설정합니다.
            if (data.slotDtoList && data.slotDtoList.length !== 0) {
                let maxDay = 0;
                data.slotDtoList.forEach((item) => {
                    if (item.day > maxDay) {
                        maxDay = item.day; // 더 큰 "day" 값을 찾으면 변수에 할당
                    }
                });
                maxDay++;
                console.log(maxDay);
                setDayNo(maxDay);
                setActive(true);
            } else {
                // data.slotDtoList.length가 0인 경우 dayNo를 1로 설정합니다.
                //setDayNo(1);
                AddSlotDto();
            }
        }

    }, [data]);

    useEffect(() => {
        if (planSq != "" && planCd != "") {
            getData();
        }
    }, [planSq, planCd])

    const getData = async () => {
        const res = await axios.post(`${server_url}/plans/${planSq}/${planCd}`).then((response) => {
            console.log("0906");
            console.log(response);
            setData(response.data);
        });
    }

    const handleInputChange = (event) => {
        let target = event.target;
        if (target != undefined) {
            switch (target.id) {
                case "ip_title":
                    setTitle(event.target.value);
                    break;
            }
        }
    };
    const handleSlot = (item) => {
        console.log(item);
        let optionSq = item.optionSq;
        setOptionSq(optionSq);
        let uploadUser = item.options.uploadUser;
        let user = window.sessionStorage.getItem("email");

        console.log("uploadUser");
        console.log(uploadUser);
        console.log(user);
        console.log(optionSq);
        //업로드 유저와 현재 유저가 같으면 옵션 수정으로 이동
        if (uploadUser == user) {
            navigate("/mypage/makingPlan/slot/updateOptioin", { state: { optionSq: item.optionSq, planSq: item.planSq, planCd: item.planCd, dayNo: item.day, orderNo: item.ordNo } });
        } else {
            setElement(optionSq);
            setChangeModalIsOpen(!changeModalIsOpen);
        }

    }

    const AddSlotDto = () => {
        let lastIndex = slotDtoList.length - 1;
        console.log("0906TT");
        console.log(slotDtoList.length);
        if (slotDtoList.length === 0) {
            const newSlotDto = {
                slotSq: 0,
                day: dayNo,
                planSq: data.planSq,
                options: {}
            };
            setDayNo(dayNo + 1);
            setSlotDtoList((slotDtoList) => [...slotDtoList, newSlotDto]);
        } else {
            console.log(slotDtoList.length);
            console.log(Object.keys(slotDtoList[lastIndex].options).length);

            if (dayNo > 1 && Object.keys(slotDtoList[lastIndex].options).length === 0) {
                alert("이전 일정을 먼저 입력해주세요.");
            } else {
                const newSlotDto = {
                    slotSq: 0,
                    day: dayNo,
                    planSq: data.planSq,
                    options: {}
                };
                setDayNo(dayNo + 1);
                setSlotDtoList((slotDtoList) => [...slotDtoList, newSlotDto]);
            }
        }


    };

    // Drag-and-Drop 관련 함수 추가
    const onDragEnd = (result) => {
        console.log("1024");
        console.log(result);
        console.log(slotDtoList);
        if (!result.destination) {
            return;
        }
        let day = result.destination.droppableId;
        let no = result.destination.index;
        let id = result.draggableId;
        day = day.replace("day", "");

        const items = Array.from(slotDtoList);
        console.log(items);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        // Day 변경
        items.forEach((item,index) => {
            if (item.slotSq == id) {
                item.day = parseInt(day);
                //item.ordNo = no;
            }
            item.ordNo = index;
        });

        setSlotDtoList(items);
    };

    // 슬롯 삭제 함수
    const removeItem = async (indexToRemove) => {
        console.log(indexToRemove);
        console.log(slotDtoList[indexToRemove]);
        if (slotDtoList != undefined && Object.keys(slotDtoList[0].options).length == 0) {
            alert("일정은 최소 1개 이상 등록하셔야 합니다.")
        } else {
            //삭제 요청
            if (window.confirm("삭제하시겠습니까?")) {
                //승인시
                const res = await axios.post(`${server_url}/ordplan/deleteSlot`, slotDtoList[indexToRemove]).then((response) => {
                    console.log(response);
                    if (response.result = "success") {
                        // 삭제할 슬롯을 제외한 새로운 배열 생성
                        const updatedSlotDtoList = slotDtoList.filter((_, index) => index !== indexToRemove);
                        setSlotDtoList(updatedSlotDtoList);
                    }
                });
            } else {
                //거절시
            }


        }

    };

    const onSave = async () => {
        console.log(slotDtoList);
        const res = await axios.post(`${server_url}/ordplan/updateSlot`, slotDtoList).then((response) => {
            console.log(response);
            if (response.result = "success") {
                console.log(planSq)
                console.log(planCd)
                //alert("슬롯이 설정되었습니다");
                navigate(`/plans/preview/${planSq}/${planCd}`)
            }
        });
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow:"auto" }}>
            <PageHeader title="플랜만들기" />
            <div style={{ display: "flex", border: "1px solid #5B9BD5", height: "40px", alignItems: "center" }} className="w-9 m-10" onClick={() => navigate("/mypage/makingPlanDetail", { state: data })}>
                <input id="ip_title" type="text" defaultValue={title} style={{ border: "none", outline: "none", height: "30px", width: "80%", paddingLeft: "10px" }} onChange={handleInputChange} />
                <img style={{ marginLeft: "auto", width: "30px", height: "30px", paddingRight: "10px" }} src={img_modify} />
            </div>
            <div className="m-4 w-9">
                <div style={{ marginTop: "20px" }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        {slotDtoList != null && slotDtoList != undefined && slotDtoList.length > 0 && [...new Set(slotDtoList.map((el) => el.day))].map((el) => (

                            <div key={el.day} style={{ marginTop: "20px" }}>
                                <div style={{ display: "flex" }} onClick={() => navigate(`/mypage/makingPlan/selectOption`, { state: { data: data, dayNo: el } })}>
                                    <span style={{ fontSize: "18px", fontWeight: "600" }}>Day {el}</span>
                                    <img style={{ marginLeft: "10px", width: "30px", height: "30px", paddingRight: "10px" }} src={img_modify} />
                                </div>
                                {/* div 누르면 옵션 페이지로 이동 */}
                                <Droppable droppableId={`day` + el}>
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {slotDtoList.map((item, index) => {
                                                console.log(item); // map 함수 내에서 console.log 사용
                                                if (item.day === el) { // el과 item.day가 같을 때만 출력
                                                    let img = img_camera;
                                                    console.log(img);
                                                    let title = "일정을 선택하세요."
                                                    let adult = "";
                                                    let child = "";

                                                    if (item.options.optionTitleNm != undefined) {
                                                        title = item.options.optionTitleNm;
                                                    }
                                                    if (item.options.savenameNm != undefined) {
                                                        console.log(item.options.savenameNm);
                                                        if (item.options.savenameNm.includes("https://")) {
                                                            img = item.options.savenameNm;
                                                        } else {
                                                            img = img_url + "/" + item.options.typeCd + "/" + item.options.savenameNm;
                                                        }

                                                    }
                                                    if (item.options.optionPriceAdultLocal != undefined) {
                                                        adult = `Adult : ${item.options.optionPriceAdultLocal}`;
                                                    }
                                                    if (item.options.optionPriceChildLocal != undefined) {
                                                        child = `Child : ${item.options.optionPriceChildLocal}`;
                                                    }
                                                    return (
                                                        <Draggable
                                                            key={item.slotSq}
                                                            draggableId={`${item.slotSq}`}
                                                            index={index}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <div style={{ textAlign: "right", transform: "translate(0,40px)" }} >
                                                                        <button style={{ marginRight: "20px" }} onClick={() => { removeItem(index) }}>X</button>
                                                                    </div>
                                                                    <div key={item.day} style={{ display: "flex", marginTop: "10px" }} onClick={() => handleSlot(item)}>

                                                                        <img src={img} style={{ width: "120px", height: "120px", border: "1px solid" }} />
                                                                        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, padding: "10px", border: "1px solid" }}>
                                                                            <span>{title}</span>
                                                                            <span>{adult}</span>
                                                                            <span>{child}</span>
                                                                            <pre style={{ margin: 0, overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical" }}>{item.options.optionDescNm}</pre>

                                                                        </div>
                                                                    </div>
                                                                    <button style={{ width: "100%", border: "1px solid #969696", height: "30px", marginTop: "10px", borderRadius: "5px" }} onClick={() => { navigate("/mypage/selectAlternativePlanSlot", { state: { data: item, cityCd: data.cityCd, companySq: data.companySq } }) }}>대체일정 설정</button>

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                }
                                                return null; // 조건을 만족하지 않으면 null을 반환하여 아무것도 출력하지 않음

                                            })}

                                            {provided.placeholder}

                                            <div />
                                        </div>
                                    )}
                                </Droppable>


                            </div>
                        ))}
                    </DragDropContext>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }} onClick={AddSlotDto}>
                    <img src={img_add} style={{ width: "25px", height: "25px" }}></img>
                    <span>Day {dayNo}</span>
                </div>

                <div style={{ display: "flex", alignItems: "center", marginTop: "50px" }} onClick={() => { navigate(`/mypage/makingPlan/addPlanPhoto`, { state: data }) }}>
                    <img src={img_add} style={{ width: "25px", height: "25px" }}></img>
                    <span>사진 추가({imagesCount})</span>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "50px", marginBottom: "150px" }} onClick={() => { navigate(`/mypage/makingPlan/planComment`, { state: data }) }}>
                    <img src={img_add} style={{ width: "25px", height: "25px" }}></img>
                    <span>코멘트관리</span>
                </div>
                {element != null && <OptionDetailmodal key={element} isOpen={changeModalIsOpen} setIsOpen={setChangeModalIsOpen} element={element} />}

                <div className="container" style={{ position: "fixed", bottom:0, padding: "0 10px", justifyContent: "center", width: "100%", height: "100px", maxWidth: "540px", backgroundColor: "#ffffff", borderRadius: "15px 15px 0 0", boxShadow: "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px -2px 7px rgba(0, 0, 0, 0.2)", }}>
                    <div className="w" style={{ display: "flex", justifyContent: "center" }}></div>
                    <input type="button" onClick={onSave} className={active ? 'save_active' : 'save_notActive'} disabled={!active} value="미리보기" />
                </div>

            </div>


        </div>
    )
}

export default MakePlanSlot;