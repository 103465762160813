import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

import { server_url, img_url } from "../../server";

import homeMenu from "../../images/icons/homeMenu.png";
import backArrow from "../../images/icons/backArrow.png";
import img_search from "../../images/icons/search.svg";

import dayjs from "dayjs"; // dayjs 라이브러리 import 추가


import img_default from "../../images/img/img_default.png"






function SelectOptionSpot() {
  const location = useLocation();
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const [email] = useState(window.sessionStorage.getItem("email"));
  const [data, setData] = useState();

  const [active, setActive] = useState(false);

  const [memo, setMemo] = useState("");

  //OptionSq
  const [optionSq, setOptionSq] = useState();
  //CityCd
  const [cityCd, setCityCd] = useState("");

  const [spot, setSpot] = useState([]);
  //날짜별 List
  const [list, setList] = useState([]);
  const [checkedItems, setCheckedItems] = useState(Array(list.length).fill(false));
  //체크된 Spot
  const checkedList = list.filter((_, index) => checkedItems[index]);
  //이미 만들어진 스팟들
  const [madeLength, setMadeLength] = useState(0);

  const [selectedRadio, setSelectedRadio] = useState([]); // 선택된 라디오 버튼의 인덱스





  useEffect(() => {
    console.log("언어:" + language);
    console.log(location);
    if (location.state != null) {
      //값 세팅
      let item = location.state;
      console.log(item);
      setOptionSq(item.optionSq);
      setCityCd(item.cityCd);
    }
  }, []);

  const getSpots = () => {
    let body = {
      optionSq: optionSq
    }
    const res = axios.post(`${server_url}/myplan/option/getSpots`, body);
    res.then((response) => {
      console.log(response);
      setMadeLength(response.data.list.length);
    });
  }

  const handleInputChange = (event) => {
    let value = event.target.value;
    setMemo(value);
  }

  const handleCheckboxChange = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };

  /* 젤 앞 대문자 설정 */
  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) {
      return str;
    }

    const firstLetter = str.charAt(0).toUpperCase();
    const remainingLetters = str.slice(1);

    return firstLetter + remainingLetters;
  };

  const addParameterToCheckedList = () => {
    const newMadeLength = madeLength + 1;
    setMadeLength(newMadeLength);
    return checkedList.map((item, index) => {
      const { spotSq, descEnNm, descKoNm, descZhchsNm, descZhchtNm } = item;

      return {
        spotSq: spotSq,
        optionSq: optionSq,
        ordNo: newMadeLength,
        descEnNm,
        descKoNm,
        descZhchsNm,
        descZhchtNm
      };
    });
  };

  /* 검색 */
  const onSearch = (e) => {
    let body = {
      cityCd: cityCd,
      searchWord: memo
    }
    switch (e.target.id) {
      case "ip_search":
        if (e.key == 'Enter') {
          console.log(body);
          const res = axios.post(`${server_url}/option/spot/search/toppickSpot`, body);
          res.then((response) => {
            console.log(response);
            const updatedSpot = response.data.list.map((el) => ({
              ...el,
              isChecked: false, // 기본값으로 isChecked를 false로 설정
            }));
            setSpot(updatedSpot);
          });
        }
        break;
      case "img_search":
        console.log(body);
        const res = axios.post(`${server_url}/option/spot/search/toppickSpot`, body);
        res.then((response) => {
          console.log(response);
          const updatedSpot = response.data.list.map((el) => ({
            ...el,
            isChecked: false, // 기본값으로 isChecked를 false로 설정
          }));
          setSpot(updatedSpot);
        });
    }
  }

  useEffect(() => {
    if (optionSq != null) {
      //getSpots();
    }

  }, [optionSq])

  useEffect(() => {
    const selectedSpot = spot.filter((el) => el.isChecked === true).map(({ isChecked, ...rest }) => rest);
    console.log(selectedSpot);
    if(selectedSpot != null && selectedSpot.length > 0){
      console.log("통과");
      setActive(true);
    } else{
      console.log("미통과");
    }
  }, [spot]);

  /* 가격 저장 */
  const onSave = () => {
    if (window.confirm("선택 완료하시겠습니까?")) {
      //승인시
      // selectedActivity 배열에는 isChecked가 true인 항목만 포함됩니다.
      const selectedSpot = spot.filter((el) => el.isChecked === true).map(({ isChecked, ...rest }) => rest);
      console.log(selectedSpot);
      let body = {
        optionSq: optionSq,
        list : selectedSpot
      }

      const res = axios.post(`${server_url}/myplan/option/AddSpots`, body);
        res.then((response) => {
          console.log(response);
          if (response.data.result == "success") {
            alert("선택 완료되었습니다.")
            window.history.back();
          }
        });
    } else {
      //거절시
    }

  }

  console.log(list);
  return (
    <div className="container">
      <div className={"w"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "0", backgroundColor: "#ffffff", marginTop: "20px" }}>
        <img src={backArrow} alt="" width="10px" height="15px" style={{ transform: "scaleX(-1)", marginLeft: "1%" }} className="c" onClick={() => { window.history.back(); }} />
        <div className="w" style={{ color: "5c80ff", fontWeight: "800", fontSize: "20px", margin: "auto", display: "flex", paddingLeft: "20px", alignItems: "center" }}>
          <input id="ip_search" type="text" value={memo} className="w" onChange={handleInputChange} onKeyDown={onSearch} style={{ height: "35px" }} placeholder="검색어를 입력해 주세요." />
          <img id="img_search" src={img_search} style={{ width: "30px", height: "30px", transform: "translateX(-40px)" }} onClick={onSearch} />
        </div>
      </div>
      <div style={{ textAlign: "left", marginTop: "30px", paddingLeft: "33px", maxHeight: "650px", overflow: "auto" }} className="w">
        {spot.map((el, index) => {
          let title = "title" + capitalizeFirstLetter(language) + "Nm";
          let imageUrl = img_default;
          if (el.comImages != null) {
            if (el.comImages.savenameNm.includes("https://")) {
              imageUrl = el.comImages.savenameNm;
            } else {
              imageUrl = img_url + "/" + el.comImages.typeCd + "/" + el.comImages.savenameNm;
            }
          }

          return (
            <div className="w-9" style={{ display: "flex", border: "1px solid #000" }}>
              <img src={imageUrl} style={{ width: "100px", height: "100px" }} />
              <div style={{ margin: "10px", display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                <span style={{ margin: "5px 0", whiteSpace: "nowrap",  overflow: "hidden",     textOverflow: "ellipsis",    display: "block" }}>{el.comSpot[title]}</span>
                <span style={{ margin: "5px 0", color: "#56CBF5",  whiteSpace: "nowrap",  overflow: "hidden",     textOverflow: "ellipsis",    display: "block" }}>{el.comSpot.keywordNm}</span>
                {/* Media query for mobile */}
                <style jsx>{`
        @media (max-width: 500px) {
            span {
                max-width: 150px; // Mobile style, adjust as needed
            }
        }
    `}</style>
              </div>
              <div style={{ display: "flex", alignItems: "center", paddingRight: "10px" }}>
                <input
                  type="checkbox"
                  value={el.comSpot.spotSq}
                  style={{ height: "30px", width: "30px" }}
                  checked={el.isChecked}
                  onChange={() => {
                    const updatedSpot = [...spot];
                    const updatedItemIndex = updatedSpot.findIndex((item) => item.comSpot.spotSq === el.comSpot.spotSq);

                    if (updatedItemIndex !== -1) {
                      updatedSpot[updatedItemIndex].isChecked = !el.isChecked; // isChecked 값을 반전
                      setSpot(updatedSpot); // 상태 업데이트
                    }
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className="w-9" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px" }}>
        <input type="button" onClick={onSave} className={active ? 'save_active' : 'save_notActive'} disabled={!active} value="선택 완료" />
      </div>
    </div>
  );
}

styled.active = `background : #000`;

export default SelectOptionSpot;
