import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import Datebar from "../../components/views/Calender/Datebar";
import MySheet from "../../components/Sheet/MySheet";
import GmpCalender from "../../components/views/Calender/GmpCalender";
import PlanList from "../../components/Plan/PlanList";

import { Title } from "../../styles/GlobalStyle";
import {parseDatewithDash, getTodayDate, getDefaultDay} from "../../js/getDate";
import { server_url } from "../../server";

import sort from "../../images/icons/sort.png";
import selectOption from "../../images/icons/selectOption.png";
import PlanOptionSheet from "./PlanOptionSheet";
import PlanSortSheet from "./PlanSortSheet";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function PlanListPage() {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();

  const { state } = useLocation();
  const params = useParams();

  const [option, setOption] = useState();
  const [sortType, setSortType] = useState();

  const navigate = useNavigate();

  //   console.log(state);

  const [departure, setDeparture] = useState(state !== null ? state.departure : getDefaultDay());
  const [searchtype] = useState(state !== null ? state.searchtype : "");

  const [isOpen, setIsOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const [planList, setPlanList] = useState([]);
  const [planFit, setPlanFit] = useState([]);
    const [pcList, setPcList] = useState([]);

  const [planOptionList, setPlanOptionList] = useState({});

  console.log(departure);

  useEffect(() => {
    let body = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
      fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
      today: getTodayDate(),
      startDate: parseDatewithDash(departure),
    };

    let param = planOptionList;

    const getPlanList = async () => {

        let url = searchtype !== "all" ? `${server_url}/plans/${params.cityCd}` : `${server_url}/plans`;

      await axios
        .post(url, body, {
          params: { ...param, order: 1 },
        })
        .then((v) => {
          console.log(v);
          if (v.status === 200) {
            setPlanList(v.data.planList);
            setPlanFit(v.data.planfitMap);
            setPcList(v.data.pcList);

            if (sortType === 3) {
              setPlanList(
                v.data.planList.sort((a, b) => {
                  return (
                    Number(a.planPriceLocal.replace(/,/gi, "")) -
                    Number(b.planPriceLocal.replace(/,/gi, ""))
                  );
                })
              );
            } else if (sortType === 4) {
              setPlanList(
                v.data.planList.sort((a, b) => {
                  return (
                    Number(b.planPriceLocal.replace(/,/gi, "")) -
                    Number(a.planPriceLocal.replace(/,/gi, ""))
                  );
                })
              );
            }
          }
        });
    };

    getPlanList();
  }, [planOptionList, sortType, departure]);

  return (
    <div className="container">
      <PageHeader logo="true"/>
      <div style={{
          // position: "sticky",
          top: "0px",
          zIndex: 1,
          backgroundColor: "#ffffff",
      }} className="m-8 w">
        <Title>{state !== null ? state.title : ""}</Title>
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {/* <Datebar Departure={props.Departure} Arrival={props.Arrival} /> */}
          <Datebar departure={departure} />
        </div>
        <MySheet
          isOpen={isOpen}
          setOpen={setIsOpen}
          children={
            <GmpCalender
              isOpen={isOpen}
              setOpen={setIsOpen}
              setDeparture={setDeparture}
              departure={departure}
            />
          }
        />
      </div>
      {/* <div className="m-3" style={{ width: "100%" }}>
        <NarrowScrolling title="추천 플랜" list={planList} type="plan" />
      </div> */}

      <div className="m-4 w"
           style={{
               position: "sticky",
               top: "0px",
               background: "white",
               zIndex: "1",
            }}
      >
        <Title style={{ marginBottom: 0 }}>{t('PLAN.LIST01')}</Title>
        <div
          style={{
            float: "right",
            position: "absolute",
            top: "30%",
            right: "5%",
          }}
        >
          <img
            src={selectOption}
            alt=""
            style={{ width: "17px", height: "13px" }}
            onClick={() => setIsSelectOpen(true)}
          />
          <MySheet
            isOpen={isSelectOpen}
            setOpen={setIsSelectOpen}
            children={
              <PlanOptionSheet
                planFit={planFit}
                setOpen={setIsSelectOpen}
                planOptionList={planOptionList}
                setPlanOptionList={setPlanOptionList}
              />
            }
          />
          <img
            src={sort}
            alt=""
            style={{ width: "17px", height: "13px", marginLeft: "12px" }}
            onClick={() => setIsSortOpen(true)}
          />
          <MySheet
            isOpen={isSortOpen}
            setOpen={setIsSortOpen}
            children={
              <PlanSortSheet
                setOpen={setIsSortOpen}
                setSortType={setSortType}
              />
            }
          />
        </div>

        <div
          className="text-xsm text-bold"
          style={{ color: "#6b6b6b", marginLeft: "25px", marginTop: "5px" }}
        >
          {planList.length}{t('PLAN.LIST02')}
        </div>
      </div>
      <div className="w">
        <div>
          {planList.map((item) => {
            return (
              <PlanList
                key={item.planSq}
                item={item}
                pclist={pcList}
                departure={departure}
                planfit={true}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PlanListPage;
