import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import PlanSlot from "../../components/Plan/PlanSlot";
import MyList from "../../components/List/MyList";
import ClxList from "../../components/List/ClxList";
// import BottomTab from "../../components/Sheet/BottomTab";
import PreviewBottomTab from "../../components/Sheet/PreviewBottomTab";

import PlanDetailPageTopImage from "../../components/Plan/PlanDetailPageTopImage";

import { Title } from "../../styles/GlobalStyle";

import PlanBkgBlock from "../../components/Plan/PlanBkgBlock";
import { img_url, server_url } from "../../server";
import clearButton from "../../images/icons/clearButton.png";
import styled from "styled-components";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

import testData from '../../json/all.json'
let cnt = 0;


function PlanDetailPage() {
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const params = useParams();
  const [planSq,setPlanSq] = useState("");
  const [planCd,setPlanCd] = useState("");
  const [slotItemAfterChange, setSlotItemAfterChange] = useState();
  const [slotDtoList, setSlotDtoList] = useState([]);
  const [changedSlot, setChangedSlot] = useState();
  const [changedSlotList, setChangedSlotList] = useState([]);
  const [minpax, setMinpax] = useState(0);
  const [planImages, setPlanImages] = useState();
  const [pcList, setPcList] = useState();
  const [comment, setComment] = useState("");
  const [maxCommentSq, setMaxCommentSq] = useState(0);


  const getPlanDetail = async () => {
    console.log(planSq);
    console.log(planCd);
    if(planSq != "" && planCd != ""){
      const res = await axios.post(`${server_url}/plans/${planSq}/${planCd}`).then((response) => {
        console.log("planDetail");
        console.log(response);
        setData(response.data);
      });
    }
   
  
  };

  useEffect(() => {
    console.log(state);
    if (state != null) {
      setData(state.data);
      setSlotItemAfterChange(state.data.slotDtoList[0].options);
      setSlotDtoList(state.data.slotDtoList);
      setPlanImages(state.data.planImages);
      setPcList(state.data.pcList);
    } else{
      setPlanSq(params.planSq);
      setPlanCd(params.planCd);
      
    }
    
  }, [])

  useEffect(()=>{
    getPlanDetail();
  },[planSq,planCd]);

  useEffect(()=>{
    if(data != []){
      setPlanImages(data.planImages);
      setSlotDtoList(data.slotDtoList);
     
    }
  },[data]);

  useEffect(()=>{
    if(slotDtoList != []){
      setMinpax(Math.max(...slotDtoList.map((el) => { return Number(el.options.minpax); })));
    }
  },[slotDtoList]);


  const setting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const commentReg = async () => {
    let body = {
      planSq: data.planSq,
      planCd: data.planCd,
      countryCd: pcList[0].countryCd,
      cityCd: data.cityCd,
      commentSq: maxCommentSq + 1,
      reviewComment: comment,
      regId: window.sessionStorage.getItem("email"),
    };

    await axios
      .post(`${server_url}/plans/comment/insert/${data.planSq}`, body)
      .then((v) => console.log(v));

    window.location.reload();
  };

  useEffect(() => {
    if(slotDtoList != undefined){
      setSlotDtoList(
        slotDtoList.map((el) => {
          if (el.slotSq === slotItemAfterChange.slotSq) {
            el.options = slotItemAfterChange;
            return el;
          } else return el;
        })
      );
  
      setMinpax(
        Math.max(
          ...slotDtoList.map((el) => {
            return Number(el.options.minpax);
          })
        )
      );
    }
   
  }, [slotItemAfterChange]);

  useEffect(() => {
    let tmp = changedSlotList;

    console.log(tmp);

    tmp.map((v, i) => {
      if (v.slotSq === changedSlot.slotSq) {
        if (v.newOptionSq !== changedSlot.newOptionSq) {
          tmp.splice(i, 1);
        }
      }
    });

    console.log(tmp);

    const getChangedPlanDetailData = async () => {
      if (state) {
        let body = {
          ...state.body,
          // changedOptionList: changedSlotList,
          changedOptionList: tmp,
        };
    
        console.log(body);
    
        await axios
          .post(`${server_url}/plans/${params.planSq}/${params.planCd}`, body)
          .then((v) => {
            setData(v.data);
          })
          .catch((e) => console.log(e));
      }
    };

    getChangedPlanDetailData();
  }, [changedSlot]);
  console.log('1004');
  console.log(slotDtoList);
  return (
    <div className="container">
      {/* <PageHeader title="플랜 상세" /> */}
      <PageHeader />
      {data.length !== 0 && <PlanDetailPageTopImage data={data} minpax={minpax} setData={setData} />}
      <div className="m-4 w-9">
        {data.slotList &&
          Object.values(data.slotList).map((sl) => {
            return (
              <div key={sl.day}>
                <div className="m-4 text-l text-bold">Day {sl.day}</div>
                {/* optionDateDt 수정 요청 */}
                <div className="m-1 text-xsm" style={{ color: "#6b6b6b" }}>
                  {sl.dateName}
                </div>
                {slotDtoList != undefined &&slotDtoList
                  .filter((el) => {
                    if (el.day.toString() === sl.day) return el;
                  })
                  .map((el) => {
                    return (
                      <div key={el.slotSq} className="m-2">
                        <PlanSlot
                          item={el}
                          departure={data.startDateDt}
                          arrival={data.startDateDt}
                          time={
                            el.startTimeNm
                              ? el.startTimeNm + ":" + el.startEndNm
                              : ""
                          }
                          price={el.options.optionPriceAdultLocal}
                          changedSlotList={changedSlotList}
                          setChangedSlotList={setChangedSlotList}
                          setSlotItemAfterChange={setSlotItemAfterChange}
                          setChangedSlot={setChangedSlot}
                          minpax={minpax}
                        />
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>

      <div className="w m-4">
        <Title>{t('PLANDETAIL.FEAT01')}</Title>
        {/* 구분선 */}
        <div
          style={{
            border: "1px solid #d2d2d2",
            marginTop: "5%",
          }}
        ></div>

        {/* 플린크리에이터 영역 */}
        <div style={{ display: "flex" }}>
          {data.pcList != undefined && data.pcList.map((pclist) => {
            if (data.companySq !== null && pclist.pcSq === data.companySq) {
              let imgURL = "";
              if(pclist.savenameNm.includes("https")){
                imgURL = pclist.savenameNm;
              } else{
                imgURL = img_url+"/"+pclist.typeCd+"/"+pclist.savenameNm;
              }
              return (
                <div style={{ marginTop: "2%" }}>
                  <div
                    style={{
                      width: "70px",
                      height: "70px",
                      backgroundImage: `url(${imgURL})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",

                      borderRadius: "100px",
                      display: "inline-block",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        // display: "inline-block",
                        // marginLeft: "30px",
                        width: "350%",
                        marginLeft: "140%",
                        marginTop: "30%",
                      }}
                    >
                      {langNm(pclist)}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>

        {data.PLANINFO &&
          data.PLANINFO.map((el, idx) => {
            if (el.code === "") {
              return (
                <div
                  className="text-xsm"
                  key={el.code}
                  style={{
                    marginTop: "3%",
                    marginBottom: "3%",
                    marginLeft: "3%",
                    fontWeight: el.code !== "" ? "" : "bold",
                  }}
                >
                  {langTitle(el)}
                </div>
              );
            }
          })}
        {/* 구분선 */}
        <div
          style={{
            border: "1px solid #d2d2d2",
            marginTop: "2%",
          }}
        ></div>
        <MyList list={data.PLANINFO} />
      </div>

      <div className="w m-4">
        <Title>{t('PLANDETAIL.FEAT02')}</Title>
        <div className="container">
          <PlanBkgBlock />
        </div>
        <ul>
          {data.PLANBKG && data.PLANBKG.map((el, idx) => {
            return (
              <li
                className="text-xsm"
                key={el.title_ko}
                style={{
                  marginBottom: "5px",
                  marginRight: "5px",
                }}
              >
                {langTitle(el)}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="w m-4">
        <Title>{t('PLANDETAIL.FEAT03')}</Title>
        <ul>
          {data.PLANTNC && data.PLANTNC.map((el, idx) => {
            return (
              <li
                className="text-xsm"
                key={el.title_ko}
                style={{
                  marginBottom: "5px",
                  marginRight: "5px",
                }}
              >
                {langTitle(el)}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="w m-4">
        <Title>{t('PLANDETAIL.FEAT04')}</Title>
        <ClxList list={data.clxList} />
        <div className="w m-4">
        <ul>
            {data.PLANCXLPLC && data.PLANCXLPLC.map((el, idx) => {
              return (
                <li
                  className="text-xsm"
                  key={el.title_ko}
                  style={{
                    marginBottom: "5px",
                    marginRight: "5px",
                  }}
                >
                  {langTitle(el)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="w m-4" style={{ marginBottom: "20%" }}>
        <Title>{t('PLANDETAIL.FEAT05')}</Title>
        <input
          id={"input_review"}
          style={{
            width: "1px",
            border: "1px",
          }}
          readOnly
        />
        <ul style={{ padding: "0 25px" }}>
          {data.planCommentList &&
            data.planCommentList.map((el) => {
              if (maxCommentSq < el.commentSq) {
                setMaxCommentSq(el.commentSq)
              }
              return (
                <li
                  className="text-xsm m-2"
                  key={el.planSq}
                  style={{
                    marginBottom: "5px",
                    marginRight: "5px",
                    listStyle: "none",

                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    id={'div_' + el.planSq}
                    style={{
                      width: el.reviewComment.length > 50 ? "94%" : "100%",
                      overflow: el.reviewComment.length > 50 ? "hidden" : "",
                      display: el.reviewComment.length > 50 ? "-webkit-box" : "",
                      WebkitLineClamp: el.reviewComment.length > 50 ? "2" : "",
                      WebkitBoxOrient: el.reviewComment.length > 50 ? "vertical" : "",
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: "2%" }}>
                      {el.regId}
                      <div style={{ marginLeft: "5%" }}>
                        {timeComparison(el.regUt,t)}
                      </div>
                    </div>
                    {el.reviewComment}
                    <span
                      className={
                        el.reviewComment.length > 50 ? "" : "hidden"
                      }
                      style={{
                        fontSize: "12px",
                        marginLeft: "2%",
                        color: "gray",
                      }}
                      onClick={() => {
                        document.getElementById("div_" + el.planSq).style.display = "-webkit-box";
                        document.getElementById("div_" + el.planSq).style.width = "94%";
                        document.getElementById('span_' + el.planSq).className = "";
                      }}
                    >
                      감추기
                    </span>
                  </div>
                  <span
                    id={'span_' + el.planSq}
                    className={
                      el.reviewComment.length > 50 ? "" : "hidden"
                    }
                    style={{
                      fontSize: "12px",
                      marginTop: "7%",
                      color: "gray",
                    }}
                    onClick={() => {
                      document.getElementById("div_" + el.planSq).style.display = "";
                      document.getElementById("div_" + el.planSq).style.width = "94%";
                      document.getElementById('span_' + el.planSq).className = "hidden";
                    }}
                  >
                    더보기
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
      {data.length !== 0 && (
        <PreviewBottomTab
          data={data}
          travelPeriod={Object.values(data.slotList)}
          minpax={minpax}
        />
      )}
    </div>
  );
}

export function timeComparison(time,t) {
  const now = new Date(); // 현재 시간
  const date = new Date(time * 1000); // 비교할 unix time
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  let result = "최근";

  // console.log("오늘 날짜 : " + now);
  // console.log("전달받은 Time : " + time);
  // console.log("저장된 데이터 날짜 : " + date);
  // console.log("저장된 연도 : " + year);
  // console.log("저장된 월 : " + month);

  // 작성년도 >= 현재년도
  if (year >= currentYear) {

    // 작성년도 = 현재년도 && 작성월 < 현재월
    if (year === currentYear && month < currentMonth) {
      return (currentMonth - month) + `${t('PLANDETAIL.COMMENT01')}`;
    }
    // 작성년도 < 현재년도
    else if (year < currentYear) {
      return (currentYear - year) + `${t('PLANDETAIL.COMMENT02')}`;
    } else {
      return "최근";
    }

  }

  return result;
}

function goMainPage(t) {

  alert(`${t('COMMON.03')}`);
  // window.location.href = "https://gomyplan.com/";
  window.location.href = window.location.protocol + '//' + window.location.host;
  cnt++
}

// 공급자명 언어별추가 (2023-03-23)
function langNm(pclist) {
  let langNm;
  switch (localStorage.getItem("language")) {
    case "ko":
      langNm = pclist.localNm
      break;
    case "en":
      langNm = pclist.enNm
      break;
    case "zhchs":
      langNm = pclist.enNm
      break;
    case "zhcht":
      langNm = pclist.enNm
      break;
    default: langNm = pclist.localNm
  }
  return langNm;
}

// 공급자 설명 언어별추가 (2023-03-23)
function langTitle(data) {
  let langTitle;
  switch (localStorage.getItem("language")) {
    case "ko":
      langTitle = data.title_ko
      break;
    case "en":
      langTitle = data.title_en
      break;
    case "zhchs":
      langTitle = data.title_zhchs
      break;
    case "zhcht":
      langTitle = data.title_zhcht
      break;
    default: langTitle = data.title_ko
  }
  return langTitle;
}

export const RegistrationButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: "1%";
  width: 10%;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #5c80ff;

  background: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color || "white"};
`;

export const CircularInput = styled.input`
  margin: 10px;
  height: 20px;

  border: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

const InputBox = styled.div`
  position: relative;

  border: 1px solid #5c80ff;
  border-radius: 100px;
`;

export default PlanDetailPage;
