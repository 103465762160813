import React from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import remove from "../../images/icons/remove.png";

import { server_url } from "../../server";

function ReservedPlan(props) {
  const navigate = useNavigate();
  const [data] = React.useState(props.data);
  const [currencySymbol,setCurrencySymbol] = React.useState("");
  console.log('ssss');
  console.log(props);

  React.useEffect(() => {
    switch (props.data.currencyCd) {
      case "KRW":
        setCurrencySymbol("₩ ");
        break;
      case "USD":
        setCurrencySymbol("US$ ");
        break;
      case "HKD":
        setCurrencySymbol("HK$ ");
        break;
      case "RMB":
        setCurrencySymbol("¥ ");
        break;
      default:
        setCurrencySymbol("₩ ");
    }
  }, [props.data.currencyCd]);


  const removePlan = async () => {
    console.log("dddd"+data.uplanCd)
    try {
      // const res = await axios.delete(`${server_url}/myplan/${data.uplanCd}`);
      const res = await axios.put(
        `${server_url}/myplan/delete/${data.uplanCd}`
      );

      console.log(res.data);

      return res.data.successYn;
    } catch (e) {}
  };


  const PlanDetailPage = async() => {
    console.log(`${data.ordSq}`)
    navigate(`/plans/reserved/complete/${data.ordSq}`,{state : data});
  };

  return (
    <div
      className="w-9"
      style={{ margin: "5px", borderBottom: "1px solid #f4f4f4" }}
      onClick={ ()=> PlanDetailPage() }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
       
        <div style={{ fontSize: "10px", color: "#6b6b6b", fontWeight: "600" }}>
          예약번호 : {data.planbkgCd}
         
        </div>
        <div>
          <img
            src={remove}
            alt=""
            width="20"
            onClick={() => {
              removePlan().then((res) => {
                console.log(res);

                if (res === "Y") {
                  alert("삭제가 완료되었습니다!");
                  window.location.reload();
                }
              });
            }}
          />
        </div>
      </div>
      <div
        className="m-1 text-bold text-l"
        style={{ display: "flex", gap: "10px" }}
      >
        <div style={{ maxWidth: "180px" }}>
          {data.nicknameNm ? data.nicknameNm : data.uplanNm.title_ko}
        </div>
      </div>
      <div className="text-bold">{currencySymbol}{data.priceUserAmount}</div>
      <div className="m-2" style={{ fontSize: 12 }}>
        {data.dateDt}
      </div>
      <div className="text-xsm">
        성인 {data.adultCnt}, 아동 {data.childCnt}
      </div>
    </div>
  );
}

export default ReservedPlan;
