import styled from "styled-components";

export const HeaderTitle = styled.div`
  color: #5c80ff;
  font-weight: 800;
  font-size: 15px;
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: 700;

  margin-left: 25px;
  margin-bottom: 20px;
`;

export const Date = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const FooterText1 = styled.div`
  flex:1;
  font-size: 11px;
  font-weight: 600;
  color: #969696;
  margin-top: 15px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const FooterText2 = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #cccccc;
`;

export const CircularButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  border-radius: 100px;
  border: 1px solid #5c80ff;

  background: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color || "white"};
`;

export const CircularInput = styled.input`
  margin: 10px;
  height: 20px;

  border: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

export const ImgTitle = styled.div`
  font-size: 22px;
  font-weight: 700;

  margin-left: 25px;
  margin-bottom: 20px;
  color: #ffffff;
`;

export const UnderbarInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  background: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

export const BorderNoneSelect = styled.select`
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;
