import React from "react";
import Calendar from "react-calendar";

import { CircularButton } from "../../../styles/GlobalStyle";
import { parseDate } from "../../../js/getDate";

function GmpCalender(props) {
  console.log(props);
  return (
    <div>
      <Calendar
        onChange={(date) => {
          props.setDeparture(parseDate(date.toString()));
          console.log(parseDate(date.toString()));
        }}
        prevLabel="이전 월"
        nextLabel="다음 월"
        prev2Label={null}
        next2Label={null}
        calendarType="US"
        minDate={new Date()}
        minDetail="month"
        maxDetail="month"
      />
      <CircularButton
        style={{
          margin: "0 auto",
          width: "80%",
          textAlign: "center",
          lineHeight: "24px",
        }}
        backgroundcolor="#42A9EC"
        onClick={() => {
          props.setOpen(!props.isOpen);
        }}
      >
        {props.departure}
      </CircularButton>
    </div>
  );
}

export default GmpCalender;
