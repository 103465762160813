import React from "react";

import resExp from "./resExp.json";
import FAQBlock from "./FAQBlock";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

export default function FAQ() {
  // 다국어 관련 추가 (2023-06-21)
  const { t, i18n } = useTranslation();
  return (
    <div className="m-5">
      <div className="text-xl text-bold">{t("CS.faqMainTitle")}</div>

      {resExp.map((item, idx) => (
        <FAQBlock item={(item)} key={idx} />
      ))}
    </div>
  );
}
