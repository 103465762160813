import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import CountButton from "../CountButton.jsx/CountButton";
import MyModal from "../Modal/MyModal";

import { CircularButton } from "../../styles/GlobalStyle";
import { getAllPrice } from "../../js/getPrice";
import { getTodayDate } from "../../js/getDate";
import { img_url, server_url } from "../../server";

import backArrow from "../../images/icons/backArrow.png";

function MypageBottomTab(props) {
  console.log('tt');
  console.log(props);
  const navigate = useNavigate();

  const [adult, setAdult] = React.useState(props.data.adultCnt);
  const [child, setChild] = React.useState(props.data.childCnt);

  const [hidden, setHidden] = React.useState(true);

  const [email] = React.useState(window.sessionStorage.getItem("email"));
  const [isLogined, setIsLogined] = React.useState(false);

  React.useEffect(() => {
    setIsLogined(email ? true : false);
  }, [email]);

  function handleNotLogined() {
    navigate("/login");
  }

  React.useEffect(()=>{
    props.setData((prevData) => ({
      ...prevData,
      childCnt: child,
      adultCnt: adult
    }));
  },[child,adult]);

  async function reservePlan() {
    props.data.planAdultPriceLocal === "-1"
      ? alert("해당 플랜의 예약이 마감되었습니다.")
      : navigate("/ord/plan", {
          state: {
            data: props.data,
            adult: adult,
            child: child,
          },
        });
  }

  return (
    <div
      className="container"
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        maxWidth: "540px",

        backgroundColor: "#ffffff",
        borderRadius: "15px 15px 0 0",

        boxShadow:
          "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px -2px 7px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        className="w m-1"
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => {
          setHidden(!hidden);
        }}
      >
        <img
          src={backArrow}
          alt=""
          style={{
            transform: hidden ? "rotate(270deg)" : "rotate(90deg)",
            height: "15px",
          }}
        />
      </div>
      <div
        className="w-9 m-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div className="text-xl text-bold">{props.data.planTitleNm}</div>
          {props.data.planAdultPriceLocal !== "-1" && (
            <div
              className={
                hidden ? "hidden text-xl text-bold" : "text-xl text-bold"
              }
              style={{ display: "flex", alignItems: "end", marginTop: "5px" }}
            >
              총
              <div
                className="text-bold"
                style={{
                  fontSize: "30px",
                  marginLeft: "10px",
                }}
              >
                {/* 계산된 가격 */}

                {getAllPrice(
                  props.data.adultCnt || 0,
                  props.data.childCnt || 0,
                  props.data.planAdultPriceLocal || 0,
                  props.data.planChildPriceLocal || 0
                )}
              </div>
              원
            </div>
          )}
        </div>
      </div>
      <div
        className="w-9 m-2 text-sm"
        style={{ marginBottom: hidden ? "25px" : "0px" }}
      >
        <div style={{ display: "flex", position: "relative" }}>
          <div>성인</div>
          <div className={hidden ? "hidden" : ""} style={{ marginLeft: "5px" }}>
            {props.data.planAdultPriceLocal !== "-1" && (
              <CountButton
                num={adult}
                setNum={setAdult}
                minpax={props.minpax}
              />
            )}
          </div>
          <div
            style={{
              position: "absolute",
              left:
                hidden && props.data.planAdultPriceLocal !== "-1" ? "50px" : "70%",
            }}
          >
            {props.data.planAdultPriceLocal !== "-1"
              ? `${props.data.planAdultPriceLocal} 원 / 1인`
              : "예약 마감"}
          </div>
        </div>
          <div
            style={{ display: "flex", marginTop: "5px", position: "relative" }}
          >
            <div>아동</div>
            <div
              className={hidden ? "hidden" : ""}
              style={{ marginLeft: "5px" }}
            >
              {props.data.planChildPriceLocal !== "-1" && (
                <CountButton num={child} setNum={setChild} />
              )}
            </div>
            <div
              style={{
                position: "absolute",
                left:
                  hidden && props.data.planChildPriceLocal !== "-1" ? "50px" : "70%",
              }}
            >
              {props.data.planChildPriceLocal !== "-1"
                ? `${props.data.planChildPriceLocal} 원 / 1인`
                : "예약 마감"}
            </div>
          </div>
      </div>
      <div
        className={hidden ? "hidden" : "w-9 m-3"}
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "20px",
        }}
      >
        <CircularButton
          color="#5c80ff"
          style={{ width: "45%" }}
          onClick={async () => {
            console.log(isLogined);
            console.log(props.data);

            isLogined
              ? savePlan(props.data, adult, child).then((v) => {
                  console.log(v);
                  if (v.successYn === "Y") {
                    alert(`플랜이 저장되었습니다.`);
                  }
                })
              : handleNotLogined();
          }}
        >
          플랜 저장
        </CircularButton>
        <CircularButton
          backgroundcolor="#5c80ff"
          style={{ width: "45%" }}
          onClick={() => {
            // state가 props.data가 아닐 것 같은데, 일단 임시데이터로 구현

            isLogined ? reservePlan() : handleNotLogined();
          }}
        >
          바로 예약
        </CircularButton>
      </div>
    </div>
  );
}

async function savePlan(data, adult, child) {
  let body = {
    uplanSq : data.uplanSq,
    adultCnt: adult,
    childCnt: child
  };

  console.log(body);

  let returnData;

  await axios.post(`${server_url}/myplan/changeCnt`, body).then((res) => {
    console.log(res.data);
    returnData = res.data;
  });

  return returnData;
}

export default MypageBottomTab;
