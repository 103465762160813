import React from "react";
import Slider from "react-slick";

import backArrow from "../../images/icons/backArrow.png";

import { img_url } from "../../server";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function WideSwiper({ title, list, autoplay }) {
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );

  const setting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    centerMode: true,
    centerPadding: "0px",
    autoplay: autoplay === undefined ? true : false,
    prevArrow: (
      <SlickButtonFix>
        <img
          src={backArrow}
          alt=""
          width="12"
          height="20"
          style={{ transform: "scaleX(-1)" }}
        />
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <img src={backArrow} alt="" width="12" height="20" />
      </SlickButtonFix>
    ),
  };

  return (
    <div>
      <Slider {...setting}>
        {list.map((item) => {
          return (
            <div key={item}>
              <div
                className="w"
                style={{
                  height: "180px",
                  backgroundImage: `url(${img_url}/${item.typeCd}/${item.savenameNm})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",

                  borderRadius: "10px",
                }}
              ></div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default WideSwiper;
