import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import Footer from "../../components/views/Layout/Footer/Footer";

import SavedPlan from "../../components/Plan/SavedPlan";
import ReservedPlan from "../../components/Plan/ReservedPlan";

/* Img */
import img_makePlan from "../../images/icons/main_makePlan.svg"



import { server_url } from "../../server";
import GetMyPlan from "../../components/Plan/GetMyPlan";

import { useTranslation } from "react-i18next";

function MyPlanPage() {
  const navigate = useNavigate();
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  const [email] = useState(window.sessionStorage.getItem("email"));

  const [isSaved, setIsSaved] = useState(true);

  const [savePlanListPublic, setSavePlanListPublic] = useState([]);
  const [savePlanListPrivate, setSavePlanListPrivate] = useState([]);

  useEffect(() => {
    console.log(email);
    if (email == null) {
      alert(t("MYPAGE.mypagePOP1"));
      navigate("/");
    }
    const getMyPlanListPublic = async () => {
      let body = {
        uploadUser: email,
        publicYn: 'Y'
      }
      try {
        const res = await axios.post(`${server_url}/ordplan/myList`, body);
        console.log("비공개");
        console.log(res);
        setSavePlanListPublic(res.data);
      } catch (e) { }
    };

    const getMyPlanListPrivate = async () => {
      let body = {
        uploadUser: email,
        publicYn: 'N'
      }
      try {
        const res = await axios.post(`${server_url}/ordplan/myList`, body);
        setSavePlanListPrivate(res.data);
      } catch (e) { }
    };

    getMyPlanListPublic();
    getMyPlanListPrivate();
  }, []);

  return (
    <div>
      <div className="container">
        <PageHeader title="플랜 만들기" />
        <div
          className="m-8 w-9"
          style={{
            boxShadow:
              "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px 2px 7px rgba(0, 0, 0, 0.1)",
            borderRadius: "100px",
          }}
        >
          <ul
            className="text-m"
            style={{
              textAlign: "center",
              padding: "0",
              marginBottom: "0",
            }}
          >
            <LoginTool
              className={isSaved ? "active" : "inactive"}
              onClick={() => {
                setIsSaved(true);
              }}
            >
              비공개 플랜
            </LoginTool>
            <LoginTool
              className={!isSaved ? "active" : "inactive"}
              onClick={() => {
                setIsSaved(!true);
              }}
            >
              공개 된 플랜
            </LoginTool>
          </ul>
        </div>
        {isSaved && (
          <div className="w-9" style={{ display: "flex", flexDirection: "column", alignItems: "center", maxHeight: "500px", overflow: "auto" }}>
            {savePlanListPrivate && savePlanListPrivate.length > 0 ? (savePlanListPrivate.map((el) => <GetMyPlan data={el} />)) : (
              <div style={{ marginTop: "10px" }}><span>등록된 플랜이 없습니다.</span></div>)}
          </div>
        )}

        {!isSaved && (
          <div
            className="w-9"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxHeight: "500px",
              overflow: "auto"
            }}
          >
            {savePlanListPublic && savePlanListPublic.length > 0 ? (savePlanListPublic.map((el) => <GetMyPlan data={el} />)) : (
              <div style={{ marginTop: "10px" }}>
                <span>등록된 플랜이 없습니다.</span></div>
            )}
          </div>

        )}
        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", padding: "1rem 0" }} onClick={() => navigate("/mypage/makingPlanDetail")}>
          <img src={img_makePlan} style={{ width: "40px", paddingBottom: "10px" }}></img>
          <span>플랜만들기</span>
        </div>
        <div style={{ paddingTop: "10px" }} onClick={() => window.open("https://gomyplan.info/creatoryourplan")}><a href="">플랜 만들고 수익창출하기</a></div>

      </div>
      <Footer />
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 10px;

  width: 40%;
`;

export default MyPlanPage;
