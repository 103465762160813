import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import Header from "../../components/views/Layout/Header/Header";
import Footer from "../../components/views/Layout/Footer/Footer";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import SavedPlan from "../../components/Plan/SavedPlan";
import ReservedPlan from "../../components/Plan/ReservedPlan";

/* css */
import "../../styles/css/basic.css";
import "../../styles/css/style.css";
import "../../styles/css/fonts.css";
import "../../styles/css/xeicon.min.css";



import { server_url } from "../../server";

function MyPlanPage() {
  const [email] = useState(window.sessionStorage.getItem("email"));

  const [isSaved, setIsSaved] = useState(true);

  const [savePlanList, setSavePlanList] = useState([]);
  const [reservePlanList, setReservePlanList] = useState([]);

  useEffect(() => {
    const getMyPlanList = async () => {
      try {
        const res = await axios.get(`${server_url}/myPlanList?userId=${email}`);
        // let body = {
        //   email: email,
        // };
        // const res = await axios.post(`${server_url}/myplan/list`, body);

        setSavePlanList(res.data);
        console.log("0111");
        console.log(res.data);
      } catch (e) { }
    };

    const getMyReserveList = async () => {
      try {
        const res = await axios.get(`${server_url}/ordplan/list/${email}`);
        console.log("0921");
        console.log(res.data);
        setReservePlanList(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    getMyPlanList();
    getMyReserveList();
  }, []);

  return (
    <div>
      <div className="container">
        <PageHeader title="플랜 관리" />

        <div
          className="m-8 w-8"
          style={{
            boxShadow:
              "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px 2px 7px rgba(0, 0, 0, 0.1)",
            borderRadius: "100px",
          }}
        >

          <ul
            className="text-m"
            style={{
              textAlign: "center",
              padding: "0",
              marginBottom: "0",
            }}
          >
            <LoginTool
              className={isSaved ? "active" : "inactive"}
              onClick={() => {
                setIsSaved(true);
              }}
            >
              편집 중인 플랜
            </LoginTool>
            <LoginTool
              className={!isSaved ? "active" : "inactive"}
              onClick={() => {
                setIsSaved(!true);
              }}
            >
              예약 플랜
            </LoginTool>
          </ul>
        </div>
        {isSaved && (
          <div
            className="w-9"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow:"auto",
              maxHeight:"77vh"
            }}
          >
            {savePlanList &&
              savePlanList.map((el) => {
                return <SavedPlan data={el} />;
              })}
          </div>
        )}
        {!isSaved && (
          <div
            className="w-9"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow:"auto",
              maxHeight:"77vh"
            }}
          >
            {reservePlanList && reservePlanList.length > 0 ? (
              reservePlanList.map((el) => <ReservedPlan data={el} />)
            ) : (
              <div style={{
                marginTop:
                  "10px"
              }}><span>등록된 예약이 없습니다.</span></div>
            )}
          </div>
        )}
      </div>
      <Footer/>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 10px;

  width: 40%;
`;

export default MyPlanPage;
