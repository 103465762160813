import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";



import img_home from "../../../../images/icons/main_home.svg"
import img_plan from "../../../../images/icons/main_plan.svg"
import img_makePlan from "../../../../images/icons/main_makePlan.svg"
import img_wish from "../../../../images/icons/main_wish.svg"
import img_my from "../../../../images/icons/main_my.svg"

import img_home_on from "../../../../images/icons/main_home_on.svg"
import img_plan_on from "../../../../images/icons/main_plan_on.svg"
import img_makePlan_on from "../../../../images/icons/main_makePlan_on.svg"
import img_wish_on from "../../../../images/icons/main_wish_on.svg"
import img_my_on from "../../../../images/icons/main_my_on.svg"



import "../../../../i18n/i18n";
import { useTranslation } from "react-i18next";
import i18n from "i18next";





function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location.pathname);

  const [isLogined, setIsLogined] = useState(
    window.sessionStorage.getItem("email") && true
  );
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("companyName"));
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  (localStorage.getItem("language") !== 'undefined' && localStorage.getItem("language") !== null) ? localStorage.getItem("language") : localStorage.setItem("language", "ko");
  (localStorage.getItem("fxCodeCd") !== 'undefined' && localStorage.getItem("fxCodeCd") !== null) ? localStorage.getItem("fxCodeCd") : localStorage.setItem("fxCodeCd", "KRW");
  const changeLanguageSelectBox = (e) => {
    console.log("선택된 언어 : " + e.target.value);


    localStorage.setItem("language", e.target.value)
    let fxCodeCd = "KRW";
    let langText = "KO";
    switch (localStorage.getItem("language")) {
      case "ko":
        fxCodeCd = "KRW"
        langText = "KO | ₩"
        break;
      case "en":
        fxCodeCd = "USD"
        langText = "EN | US$"
        break;
      case "zhchs":
        fxCodeCd = "HKD"
        langText = "简 / HK$"
        break;
      case "zhcht":
        fxCodeCd = "HKD"
        langText = "繁 / HK$"
        break;
      default:
        fxCodeCd = "KRW"
        langText = "KO | ₩"
    }
    let el = document.getElementById("div_lang");
    el.textContent = langText;
    localStorage.setItem("fxCodeCd", fxCodeCd);
    i18n.changeLanguage(localStorage.getItem("language"));
    window.location.reload();
  }


  return (
    <div>
      <div style={{ position: "fixed", bottom: "0", background: "#fff", border: "1px solid #e3e5ef", width: "100%", padding: "0.5rem 0", maxWidth: "600px",filter: "drop-shadow(0px -2px 5px rgba(0, 0, 0, 0.25))" }}>
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>


          {location.pathname == '/' ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => { navigate("/"); }}>
              <img src={img_home_on}  ></img>
              <span style={{color:"#42A9EC",marginTop:"6px",fontSize:"12px"}}>Home</span>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => { navigate("/"); }}>
              <img src={img_home} ></img>
              <span style={{color:"#000",marginTop:"6px",fontSize:"12px"}}>Home</span>
            </div>
          )}

          {location.pathname == '/mypage/myplan' ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => {
              if (isLogined) {
                navigate("/mypage/myplan");
              } else {
                navigate("/login");
              }
            }}>
              <img src={img_plan_on}  ></img>
              <span style={{color:"#42A9EC",marginTop:"6px",fontSize:"12px"}}>Plans</span>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => {
              if (isLogined) {
                navigate("/mypage/myplan");
              } else {
                navigate("/login");
              }
            }}>
              <img src={img_plan} ></img>
              <span style={{color:"#000",marginTop:"6px",fontSize:"12px"}}>Plans</span>
            </div>
          )}
            
           {location.pathname == '/mypage/makingPlan' ? (
            <div style={{  display: companyName ? "flex" : "none", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => {
              if (isLogined) {
                navigate("/mypage/makingPlan");
              } else {
                navigate("/login");
              }
            }}>
              <img src={img_makePlan_on}  ></img>
              <span style={{color:"#42A9EC",marginTop:"6px",fontSize:"12px"}}>Create</span>
            </div>
          ) : (
            <div style={{ display: companyName ? "flex" : "none", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => {
              if (isLogined) {
                navigate("/mypage/makingPlan");
              } else {
                navigate("/login");
              }
            }}>
              <img src={img_makePlan} ></img>
              <span style={{color:"#000",marginTop:"6px",fontSize:"12px"}}>Create</span>
            </div>
          )}

          {location.pathname == '/mypage/wishlist' ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => {
              if (isLogined) {
                navigate("/mypage/wishlist");
              } else {
                navigate("/login");
              }
            }}>
              <img src={img_wish_on}  ></img>
              <span style={{color:"#42A9EC",marginTop:"6px",fontSize:"12px"}}>Wishlist</span>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => {
              if (isLogined) {
                navigate("/mypage/wishlist");
              } else {
                navigate("/login");
              }
            }}>
              <img src={img_wish} ></img>
              <span style={{color:"#000",marginTop:"6px",fontSize:"12px"}}>Wishlist</span>
            </div>
          )}

          {location.pathname == '/mypage/changeInfo' ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => {
              if (isLogined) {
                navigate("/mypage/changeInfo");
              } else {
                navigate("/login");
              }
            }}>
              <img src={img_my_on}  ></img>
              <span style={{color:"#42A9EC",marginTop:"6px",fontSize:"12px"}}>Profile</span>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex : 1}} onClick={() => {
              if (isLogined) {
                navigate("/mypage/changeInfo");
              } else {
                navigate("/login");
              }
            }}>
              <img src={img_my} ></img>
              <span style={{color:"#000",marginTop:"6px",fontSize:"12px"}}>Profile</span>
            </div>
          )}
        </div>
      </div>
      
    </div>
  );
}

export default Footer;
