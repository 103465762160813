import React, { useState, useEffect } from "react";
import {useParams, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import SpotImageCarousel from "../../components/Swiper/SpotImageCarousel";
import GoogleMapComponent from "../../components/GoogleMap/GoogleMapComponent";

import {CircularButton, Title} from "../../styles/GlobalStyle";
import { server_url } from "../../server";
import Datebar from "../../components/views/Calender/Datebar";
import MySheet from "../../components/Sheet/MySheet";
import GmpCalender from "../../components/views/Calender/GmpCalender";
import PlanList from "../../components/Plan/PlanList";
import {getDefaultDay, getTodayDate, parseDatewithDash} from "../../js/getDate";
import favicon from "../../images/favicon.png";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function SpotDetailPage() {
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  // console.log(params);
  // console.log(state);

  const [data, setData] = useState([]);
  const [spotImageList, setSpotImageList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [departure, setDeparture] = useState(
      parseDatewithDash(getDefaultDay())
  );
  const [planList, setPlanList] = useState([]);
  const [pcList, setPcList] = useState([]);
  const [planCount, setPlanCount] = useState([]);
  console.log("BBBBBBBBBBBBBBBBBBBBBB");

  useEffect(() => {
    let body = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
    };

    const getSpotDetail = async () => {
      try {
        const res = await axios.post(
          `${server_url}/spot/${params.spotSq}/${params.spotCd}`,
          body
        );

        console.log(res.data);

        setData(res.data);
        setSpotImageList(res.data.spotImageList);

      } catch (e) {
        console.log(e);
      }
    };
    getSpotDetail();
  }, []);

  useEffect(() => {
    let getCityCdBody = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
    };

    let body = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
      fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
      today: getTodayDate(),
      startDate: parseDatewithDash(departure),
    };

    console.log(body);
    console.log(departure);

    const getPlanList = async () => {
      try {
        const getCityCd = await axios.post(
            `${server_url}/spot/${params.spotSq}/${params.spotCd}`,
            getCityCdBody
        );
        const res = await axios.post(
            `${server_url}/plans/${getCityCd.data.cityCd}`,
            // `${server_url}/plans/HKG`,
            body
        );

        console.log(res.data);

        // setData(res.data);

        setPlanList(res.data.planList.filter((el) => el.toppickYn === "Y"));
        setPlanCount(res.data.countPlan);
      } catch (e) {
        console.log(e);
      }
    };

    getPlanList();
  }, [departure]);

  return (
    <div className="container">
      <PageHeader logo="true" />
      <div className="w m-3" style={{ marginBottom: "30px" }}>
        {spotImageList && <SpotImageCarousel list={spotImageList} />}
        <div className="m-4 w-9">
          <Title>{data.spotNm}</Title>
          <div
            className="text-xsm"
            style={{ color: "#6b6b6b", marginLeft: "25px" }}
          >
            {data.spotDesc}
          </div>
        </div>
        <div>
          <div
            className="m-3 text-m"
            style={{ fontWeight: 600, marginLeft: "25px" }}
          >
            {t('SPOTDETAIL.SDSPOT02')}
          </div>
          <div className="m-2">
            <GoogleMapComponent
              lat={Number(data.latNm)}
              lng={Number(data.longNm)}
            />
          </div>
        </div>

        <div>
          <div
            className="m-3 text-m"
            style={{ fontWeight: 600, marginLeft: "25px" }}
          >
            {t('SPOTDETAIL.SDSPOT05')}

          </div>
        </div>

        <div className="m-3" style={{ width: "100%" }}>
          <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
          >
            <Title>{t('SPOTDETAIL.SDSPOT06')}</Title>
          </div>

          <div
              onClick={() => {
                setIsOpen(true);
              }}
          >
            <Datebar departure={departure} />
          </div>
          <MySheet
              isOpen={isOpen}
              setOpen={setIsOpen}
              children={
                <GmpCalender
                    isOpen={isOpen}
                    setOpen={setIsOpen}
                    setDeparture={setDeparture}
                    departure={departure}
                />
              }
          />

          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {planList.map((item) => {
              return (
                  <PlanList key={item.planSq} item={item} pclist={pcList} departure={departure} />
              );
            })}
          </div>

          <div style={{width: "100%"}}>
          {/*  <div*/}
          {/*      style={{*/}
          {/*        display: "flex",*/}
          {/*        justifyContent: "center",*/}
          {/*        alignItems: "center",*/}
          {/*        border: "1px solid",*/}
          {/*        borderRadius: "30px",*/}
          {/*        width: "40%",*/}
          {/*        height: "5vh",*/}
          {/*        margin: "4% auto auto",*/}
          {/*      }}*/}
          {/*      onClick={() => {*/}
          {/*        navigate(`/plans/${planList[0].cityCd}`, {*/}
          {/*          state: {*/}
          {/*            departure: departure,*/}
          {/*            title: planList[0].localNm,*/}
          {/*          },*/}
          {/*        });*/}
          {/*      }}*/}
          {/*  >*/}
          {/*    <div>*/}
          {/*      <img src={favicon} height="40px" />*/}
          {/*    </div>*/}
          {/*    &nbsp;&nbsp;*/}
          {/*    <div style={{fontWeight: "600"}}>*/}
          {/*      {planCount}개 모든 플랜 보기*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
            <div
                style={{
                  margin: "auto",
                  width: "45%",
                }}
            >
              <CircularButton
                  className="text-xsm"
                  onClick={() => {
                    navigate(`/plans/${planList[0].cityCd}`, {
                      state: {
                        departure: departure,
                        title: planList[0].localNm,
                      },
                    });
                  }}
                  backgroundcolor="#5c80ff"
                  style={{ height: "26px", marginRight: "25px", padding: "0 5px" }}
              >
                {planCount}{t('SPOTDETAIL.SDSPOT03')}
              </CircularButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpotDetailPage;
