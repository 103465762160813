import React, { useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import MySheet from "../../components/Sheet/MySheet";

import remove from "../../images/icons/remove.png";
import clearButton from "../../images/icons/clearButton.png";
import { server_url } from "../../server";
import { parseDatewithDash, getTodayDate } from "../../js/getDate";
import { CircularButton } from "../../styles/GlobalStyle";
import { useLocation, useParams } from "react-router-dom";
import PageHeader from "../../components/views/Layout/Header/PageHeader";
import MypageBottomTab from "../../components/Sheet/MypageBottomTab";
import hotelIcon from "../../images/icons/hotelIcon.png";
import OptionDetailPage from "../../pages/PlanPage/OptionDetailPage";
import { findMinTime } from "./utils";


import { CopyToClipboard } from "react-copy-to-clipboard";
import BottomTab2 from "../../components/Sheet/BottomTab2";

function SavedPlanDetailPage() {
  const { state } = useLocation();
  const params = useParams();
  console.log(params);
  //console.log(JSON.stringify(state.data));
  const [data, setData] = React.useState();
  const [nameIsOpen, setNameIsOpen] = React.useState(false);
  const [changedName, setChangedName] = React.useState("");
  const [memoIsOpen, setMemoIsOpen] = React.useState(false);
  const [planMemo, setPlanMemo] = React.useState("");

  const categoryCdList = ["ACC01", "ACC02", "ACC03", "ACC04", "ACC05"];
  const [optionDetailIsOpen, setOptionDetailIsOpen] = React.useState(false);
  const [clickedOptionSq, setClickedOptionSq] = React.useState();
  const [optionDetailData, setOptionDetailData] = React.useState();
  const [updUt, setUpdUt] = React.useState();
  const [minpax, setMinpax] = React.useState(0);


  React.useEffect(() => {
    if (state == null) {
      console.log('ssss');
      getMyplan();
      console.log('ddd');
    } else {
      console.log('else');
     
      
      if (state.data.updUt != "") {
        setUpdUt(changeUnixTime(state.data.updUt));
        //setSlotDtoList(state.data.slotDtoList);
      }
      let fxCode = localStorage.getItem("fxCodeCd") !== "undefined"
      ? localStorage.getItem("fxCodeCd")
      : "KRW";
      let fxIncludeData = {
        ...state.data,
        fxCodeCd : fxCode,
        startDateDt : state.data.dateDt
      }
      console.log(fxIncludeData);
      setData(fxIncludeData);
    }
    
   
  }, [state]);

  React.useEffect(() => {
    const getOptionDetail = async () => {
      let body = {
        userLang:
          localStorage.getItem("language") !== "undefined"
            ? localStorage.getItem("language")
            : "ko",
        fxCodeCd:
          localStorage.getItem("fxCodeCd") !== "undefined"
            ? localStorage.getItem("fxCodeCd")
            : "KRW",
        today: data.startDateDt
        // startDate: props.departure,
      };
      await axios
        .post(`${server_url}/options/${clickedOptionSq}`, body)
        .then((v) => {
          setOptionDetailData(v.data);
          if(v.data.detailsYn == "Y"){
            setOptionDetailIsOpen(true);
          }
          
        });
    };
    if (clickedOptionSq) {
      getOptionDetail();
    }
  }, [clickedOptionSq]);

  // useEffect(()=>{
  //   if(slotDtoList != []){
  //     setMinpax(Math.max(...slotDtoList.map((el) => { return Number(el.options.minpax); })));
  //   }
  // },[slotDtoList]);

  const getMyplan = async () => {
    console.log('실행됨');
    try {
      let body = {
        fxCodeCd:
          localStorage.getItem("fxCodeCd") !== "undefined"
            ? localStorage.getItem("fxCodeCd")
            : "KRW",
        today: getTodayDate(),
      };
      let res = await axios.post(
        `${server_url}/myplan/ko/${params.uplanSq}`,
        body
      );
      console.log('data1');
      console.log(res.data);
      body = {
        fxCodeCd:
          localStorage.getItem("fxCodeCd") !== "undefined"
            ? localStorage.getItem("fxCodeCd")
            : "KRW",
        today: res.data.dateDt,
      }
      res = await axios.post(
        `${server_url}/myplan/ko/${params.uplanSq}`,
        body
      );
      console.log("data2");
      console.log(res.data);

      let fxCode = localStorage.getItem("fxCodeCd") !== "undefined"
      ? localStorage.getItem("fxCodeCd")
      : "KRW";
      let fxIncludeData = {
        ...res.data,
        fxCodeCd : fxCode,
        startDateDt : res.data.dateDt
      }
      console.log(fxIncludeData);
      setData(fxIncludeData);

      setUpdUt(changeUnixTime(res.data.updUt));
      //setSlotDtoList(res.data.slotDtoList);
    } catch (e) { }
  };

  const removePlan = async () => {
    try {
      // const res = await axios.delete(`${server_url}/myplan/${data.uplanCd}`);
      const res = await axios.put(
        `${server_url}/myplan/delete/${params.uplanSq}`
      );

      console.log(res.data);

      return res.data.successYn;
    } catch (e) { }
  };

  const changePlanName = async () => {
    await axios
      .put(`${server_url}/myplan/name/${params.uplanSq}?name=${changedName}`)
      .then((v) => console.log(v));

    setNameIsOpen(!nameIsOpen);
    getMyplan();
  };

  const changePlanMemo = async () => {
    await axios
      .put(`${server_url}/myplan/memo/${params.uplanSq}?memo=${planMemo}`)
      .then((v) => console.log(v));

    setMemoIsOpen(false);
    getMyplan();
  };

  console.log(data);

  const changeUnixTime = (value) => {
    const timestampInMilliseconds = value * 1000; // 밀리초로 변환
    const date = new Date(timestampInMilliseconds);

    // 원하는 형식으로 날짜와 시간을 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateTime;
  };

  const sharePlan = () => {
    alert("클립보드에 플랜 URL이 복사 되었습니다.");
  }

  return (
    <div className="container">
      {data && (
        <div>
          <PageHeader title="플랜 편집" />
          <div className="w-9 m-8">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="m-1" style={{ display: "flex", gap: "10px" }}>
                <div className="text-l text-bold" style={{ maxWidth: "180px" }}>
                  {data.nicknameNm ? data.nicknameNm : data.uplanNm.title_ko}
                </div>
                <CircularButton
                  className="text-sm"
                  backgroundcolor={"#454545"}
                  style={{ border: "none", height: 20, padding: "0px 5px" }}
                  onClick={(e) => {
                    // e.stopPropagation();
                    setNameIsOpen(true);
                  }}
                >
                  이름 변경
                </CircularButton>
              </div>
              <img
                src={remove}
                alt=""
                width="20"
                height="20"
                onClick={(e) => {
                  e.stopPropagation();
                  removePlan().then((res) => {
                    console.log(res);

                    if (res === "Y") {
                      alert("삭제가 완료되었습니다!");
                      //   window.location.reload();
                      window.history.back();
                    }
                  });
                }}
              />
            </div>
            <MySheet
              isOpen={nameIsOpen}
              setOpen={setNameIsOpen}
              header="이름 변경"
              headerButton="변경"
              handleClick={changePlanName}
              children={
                <div
                  className="w-8"
                  style={{ margin: "0 auto", position: "relative" }}
                >
                  <StyledInput
                    className="m-3"
                    value={changedName}
                    onChange={(e) => setChangedName(e.currentTarget.value)}
                  />
                  {changedName && (
                    <img
                      src={clearButton}
                      alt=""
                      onClick={() => {
                        setChangedName("");
                      }}
                      style={{ position: "absolute", bottom: "5px", right: "5px" }}
                    />
                  )}
                </div>
              }
            />
          </div>
          <div className="w-9 m-1">
            {data.planPriceLocal !== "-1" && (
              <div className="text-bold">{data.planPriceLocal}원</div>
            )}
            <div style={{ fontSize: "10px", color: "#6b6b6b", fontWeight: "600" }}>
              예약번호 : {params.uplanSq}
            </div>
            <div className="text-sm m-2" style={{ color: "#6b6b6b" }}>
              마지막 변경: {updUt}
            </div>
            <div className="m-1" style={{ display: "flex", gap: 15 }}>
              <CircularButton
                style={{ height: "40px" }}
                backgroundcolor="#5c80ff"
                className="w"
                onClick={(e) => e.stopPropagation()}
              >
                1:1 플랜 문의
              </CircularButton>
              <CircularButton
                style={{ height: "40px" }}
                backgroundcolor="#5c80ff"
                className="w"
                onClick={(e) => {
                  // console.log(isDetail);
                  e.stopPropagation();
                  setMemoIsOpen(true);
                }}
              >
                플랜 메모
              </CircularButton>
            </div>

            {/* <CircularButton
              className="m-1"
              color="black"
              style={{ height: "40px" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              일행 초대
            </CircularButton> */}

            <CopyToClipboard text={`https://gomyplan.com/mypage/myplan/${params.uplanSq}`} onCopy={sharePlan}>
              <CircularButton
                className="m-1"
                color="black"
                style={{ height: "40px" }}
              >
                플랜 링크 공유
              </CircularButton>

            </CopyToClipboard>

            <div className="m-4" style={{ marginBottom: 200 }}>
              {data.slotList.map((element) => {
                return (
                  <div
                    style={{
                      // border: "1px solid black",
                      margin: "20px 0",
                      padding: 20,
                      backgroundColor: "#FAFAFA",
                      borderRadius: "10px",
                    }}
                  >
                    <h3>Day {element.day}</h3>
                    <div className="text-sm">{element.dateName}</div>

                    {element.options.map((el) => {
                      console.log("start");
                      console.log(el);
                      const timeset = findMinTime(el.spotDtoList);
                      return (
                        <div className="m-2">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 5,
                              marginBottom: 5,
                            }}
                          >
                            <div
                              className="text-m text-bold"
                              onClick={() => setClickedOptionSq(el.optionSq)}
                            >
                              {el.optionTitleNm}
                            </div>
                            {categoryCdList.includes(el.categoryCd) && (
                              <img src={hotelIcon} alt="" height="17px" />
                            )}
                            {timeset && (
                              <div
                                style={{
                                  width: "10%",
                                  height: "15%",
                                  background: "#ffd775",
                                  borderRadius: "100px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  padding: "2px 5px",
                                }}
                              >
                                {timeset}
                              </div>
                            )}
                            {el.detailsYn == 'Y' &&(
                              <div style={{width: "10%",
                              height: "15%",background:"rgb(98, 165, 250)",color:"white", border:"1px solid black",borderRadius:"100px",fontSize:"12px",display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "2px 5px"}}
                              >
                                자세히
                              </div>
                            )

                            }
                          </div>
                          <div className="text-sm" style={{ color: "#969696" }}>
                            {el.optionDescNm}
                          </div>
                          <div className="m-1 text-bold">
                            
                            {el.optionPriceAdultLocal !== "-1" && (`${el.optionPriceAdultLocal}원 `)}
                            {el.optionPriceAdultLocal !== "-1" && (<span className="text-xsm">(성인 1명)</span>)}
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <MySheet
            isOpen={memoIsOpen}
            setOpen={setMemoIsOpen}
            header="플랜 메모"
            headerButton="변경"
            handleClick={changePlanMemo}
            children={
              <div className="w-8" style={{ margin: "0 auto" }}>
                <div style={{ position: "relative" }}>
                  <StyledInput
                    className="m-3"
                    placeholder={data.memoNm}
                    value={planMemo}
                    onChange={(e) => setPlanMemo(e.currentTarget.value)}
                  />
                  {planMemo && (
                    <img
                      src={clearButton}
                      alt=""
                      onClick={() => {
                        setPlanMemo("");
                      }}
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "5px",
                      }}
                    />
                  )}
                </div>
                <div className="m-4">{data.memoNm}</div>
              </div>
            }
          />
         <MypageBottomTab data={data} setData={setData} />

          {/*{data.length !== 0 && (
        <BottomTab2
          data={data}
          travelPeriod={Object.values(data.slotList)}
          minpax={minpax}
        />
          )}*/}
          <MySheet
            isOpen={optionDetailIsOpen}
            setOpen={setOptionDetailIsOpen}
            snapPoints={[800]}
            children={<OptionDetailPage data={optionDetailData} />}
          />
        </div>
      )}






    </div>
  );
}

const StyledInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  background: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

export default SavedPlanDetailPage;
