import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { generateRandomNumber } from "../../js/common"
import { uploadS3 } from "../../js/common"

import backArrow from "../../images/icons/backArrow.png";
import img_add from "../../images/icons/file_add.svg";
import "./AddPlanPhoto.css"
import { server_url } from "../../server";



function AddPlanPhoto() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [files, setFiles] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [email] = useState(window.sessionStorage.getItem("email"));
    let planSq = "";

    useEffect(() => {
        setData(location.state);
        const getImages = async () => {
            let tmpImages = localStorage.getItem("tmpImagesPlan");
            if (tmpImages.length > 0) {
                tmpImages = JSON.parse(localStorage.getItem("tmpImagesPlan"));
                console.log(tmpImages)
            }
            let body = {
                planSq: data.planSq
            };
            console.log(body);

            try {
                const res = await axios.post(`${server_url}/img/getByPlanSq`, body);
                console.log(res);
                const getImages = res.data;

                for (let i = 0; i < tmpImages.length; i++) {
                    let item = tmpImages[i];
                    setImageFiles(prevImageFiles => [...prevImageFiles, { imageSq: item.imageSq, imageCd: data.planCd, uploadUser: email, planSq: data.planSq, "savenameNm": item.savenameNm, "ordNo": i, "publicYn": item.publicYn, "internalMemoNm": "" }]);
                }

                for (let i = 0; i < getImages.length; i++) {
                    setImageFiles(prevImageFiles => [...prevImageFiles, getImages[i]]);
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (data.planSq) {
            getImages();
        }
    }, [data, email, location.state]);




    const handleCheckboxChange = (index) => {
        setImageFiles((prevImageFiles) => {
            const updatedImageFiles = [...prevImageFiles]; // 이전 상태 복사
            updatedImageFiles[index] = { ...updatedImageFiles[index] }; // 해당 객체 복사
            updatedImageFiles[index].publicYn = updatedImageFiles[index].publicYn === "N" ? "Y" : "N"; // publicYn 값을 토글
            return updatedImageFiles; // 새로운 상태 반환
        });
    };

    const handleInternalMemoChange = (index, value) => {
        setImageFiles((prevImageFiles) => {
            const updatedImageFiles = [...prevImageFiles];
            updatedImageFiles[index].internalMemoNm = value;
            return updatedImageFiles;
        });
    };

    const handleMoveUp = (index) => {
        if (index > 0) {
            setImageFiles((prevImageFiles) => {
                const updatedImageFiles = [...prevImageFiles];
                // Swap the elements except for ordno
                [updatedImageFiles[index - 1], updatedImageFiles[index]] = [
                    { ...updatedImageFiles[index], ordNo: updatedImageFiles[index - 1].ordNo },
                    { ...updatedImageFiles[index - 1], ordNo: updatedImageFiles[index].ordNo },
                ];
                return updatedImageFiles;
            });
        }
    };

    const handleMoveDown = (index) => {
        if (index < imageFiles.length - 1) {
            setImageFiles((prevImageFiles) => {
                const updatedImageFiles = [...prevImageFiles];
                // Swap the elements except for ordno
                [updatedImageFiles[index], updatedImageFiles[index + 1]] = [
                    { ...updatedImageFiles[index + 1], ordNo: updatedImageFiles[index].ordNo },
                    { ...updatedImageFiles[index], ordNo: updatedImageFiles[index + 1].ordNo },
                ];
                return updatedImageFiles;
            });
        }
    };

    const handleRemoveImage = (index) => {
        setImageFiles((prevImageFiles) => {
            const updatedImageFiles = prevImageFiles.filter((_, i) => i !== index);
            return updatedImageFiles;
        });
    };

    const updateImages = () => {
        // 이미지 파일 목록 업데이트
        const updatedImageFiles = imageFiles.map((file, index) => ({
            ...file,
            ordNo: index, // 0부터 시작하는 순서로 재설정
        }));
        console.log(imageFiles);
        axios.post(`${server_url}/img/updateImages/plan`, imageFiles).then((res) => {
            console.log(res);
            if (res.data.result == "success") {
                alert("사진 추가되었습니다");
                localStorage.setItem("tmpImagesPlan", []);
                window.history.back();
            }
        })
    }



    return (
        <div>
            <div className={"w"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "0", backgroundColor: "#ffffff", marginTop: "20px" }}>
                <img src={backArrow} alt="" width="10px" height="15px" style={{ transform: "scaleX(-1)", marginLeft: "1%" }} className="c" onClick={() => { window.history.back(); }} />
                <div style={{ color: "5c80ff", fontWeight: "800", fontSize: "20px", margin: "auto", marginLeft: "30px" }}>플랜 사진 추가</div>
                <div onClick={() => { navigate(`/mypage/makingPlan/selectPlanPhoto/${email}`, { state: data }) }}>
                    <img src={img_add} style={{ width: "30px" }} />
                </div>
            </div>
            <div style={{ height: "85vh", width: "100%", display: "flex", alignItems: "center", flexDirection: "column", overflow: "auto", textAlign: "center" }}>
                {imageFiles.length === 0 ? (
                    <span>사진이 없습니다</span>
                ) : (
                    imageFiles.map((el, index) => (
                        <div key={index} style={{ display: "inline-block", verticalAlign: "top", width: "100%", }}>
                            <div className="img_div" style={{ background: `url(${el.savenameNm})`, height: "35vh", backgroundSize: "auto 100%", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }}>
                                <div style={{ float: "right" }}>
                                    <button onClick={() => handleMoveUp(index)}>△</button>
                                    <button onClick={() => handleMoveDown(index)}>▽</button>
                                    <button onClick={() => handleRemoveImage(index)}>X</button>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div>
                                    <input type="checkbox" checked={el.publicYn === "Y"} onChange={() => handleCheckboxChange(index)} /><span>전체 공개</span>
                                </div>
                                <div>
                                    <span>메모 : </span><input type="text" value={el.internalMemoNm} onChange={(e) => handleInternalMemoChange(index, e.target.value)} />
                                </div>

                            </div>
                        </div>
                    ))
                )}
            </div>
            <div>
                <input type="button" value="사진 추가 완료" onClick={() => updateImages()} style={{
                    background:
                        "rgb(79, 149, 213)", border: "none", borderRadius: "10px", color: "rgb(255, 255, 255)", height: "30px", width: "100%"
                }} />
            </div>
        </div>
    )

}

export default AddPlanPhoto;