import React, { useState, useEffect } from "react";
import axios from "axios";

import { getTodayDate, parseDatewithDash } from "../../js/getDate";
import { server_url, img_url } from "../../server";

import OptionDetailPage from "../../pages/PlanPage/OptionDetailPage";
import MySheet from "../Sheet/MySheet";

function OptionSlot(props) {
  //console.log(props);
  const [optionSq] = useState(
    // props.item.masterPlanSlotAlternativeDtoMapping.optionSq
    props.optionSq
  );

  const [optionDetailList, setOptionDetailList] = useState([]);
  const [slotDetailIsOpen, setSlotDetailIsOpen] = useState(false);

  // console.log(props.activeOption);

  const [differenceOfPrice, setDifferenceOfPrice] = useState(0);
  const [optionDetailData, setOptionDetailData] = useState();
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    let body = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
      fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
      today: getTodayDate(),
      startDate: parseDatewithDash(props.departure),
    };

    const getOptionDetail = async () => {
      try {
        const res = await axios.post(`${server_url}/options/${optionSq}`, body);

        setOptionDetailList(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    getOptionDetail();

  }, []);

  useEffect(() => {
    console.log("1006");
    console.log(optionDetailList);
    console.log(optionDetailList.savenameNm);
    if (optionDetailList.optionPriceAdultLocal != undefined) {
      setDifferenceOfPrice(parseInt(optionDetailList.optionPriceAdultLocal?.split(",").join("")) - parseInt(props.price?.split(",").join("")));
    }

    if (optionDetailList.savenameNm != undefined && optionDetailList.savenameNm != null) {
      let imageUrl = "";
      if (optionDetailList.savenameNm.includes("https://")) {
        imageUrl = optionDetailList.savenameNm;
      } else {
        imageUrl = img_url + "/" + optionDetailList.typeCd + "/" + optionDetailList.savenameNm;
      }
      setImageUrl(imageUrl);

    } else {
      let imageUrl = "";
      if (optionDetailList.optionImages != null && optionDetailList.optionImages.length > 0) {
        if (optionDetailList.optionImages[0].savenameNm.includes("https://")) {
          imageUrl = optionDetailList.optionImages[0].savenameNm;
        } else {
          imageUrl = img_url + "/" + optionDetailList.typeCd + "/" + optionDetailList.savenameNm;
        }
      }
      console.log(imageUrl);
      setImageUrl(imageUrl);
    }
  }, [optionDetailList]);







  return (
    <>
      <div
        className={
          props.activeOption === optionDetailList.optionSq ? "activeSlot" : ""
        }
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2.3fr",
          boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.05)",
          borderRadius: "10px",
          minHeight: "115px",
        }}
        onClick={() => {
          props.setOptionSq(optionDetailList.optionSq);
          console.log(optionDetailList);

          // console.log(props.activeOption);

          setOptionDetailData(optionDetailList);
          console.log(optionDetailList);
          if (
            optionDetailList.length !== 0 &&
            props.activeOption === optionDetailList.optionSq
          )
            setSlotDetailIsOpen(true);
        }}
      >
        <div
          style={{
            borderRadius: "10px 0 0 10px",
            background: `url(${imageUrl}) center / cover no-repeat`,
          }}
        ></div>
        <div style={{ paddingLeft: "15px" }}>
          <p className="text-sm slotTitle" style={{ marginBottom: 0 }}>
            {optionDetailList.optionTitleNm}
          </p>
          <p style={{ fontSize: "10px", color: "#6b6b6b", marginTop: 5 }}>
            {optionDetailList.optionDescNm}
          </p>
          <div style={{ float: "right", margin: "15px 15px 10px 0" }}>
            {differenceOfPrice >= 0 ? "+ " : "- "}
            {setCurrencySymbol()}<strong className="text-xl">{Math.abs(differenceOfPrice)}</strong>
            <div
              style={{
                fontSize: "small",
                fontWeight: "bold",
                backgroundColor: "#01a2f9",
              }}
            >
              {optionDetailList.minipaxNo > 1 ? `최소 인원(정원) ${optionDetailList.minipaxNo} 명` : ""}
            </div>
          </div>
        </div>
      </div>
      <MySheet
        isOpen={slotDetailIsOpen}
        setOpen={setSlotDetailIsOpen}
        snapPoints={[800]}
        children={<OptionDetailPage data={optionDetailList} />}
      />
    </>
  );
}

function setCurrencySymbol() {
  let currencySymbol;
  switch (localStorage.getItem("fxCodeCd")) {
    case "KRW":
      currencySymbol = "₩ "
      break;
    case "USD":
      currencySymbol = "US$ "
      break;
    case "HKD":
      currencySymbol = "HK$ "
      break;
    case "RMB":
      currencySymbol = "¥"
      break;
    default: currencySymbol = "₩ "
  }
  return currencySymbol;
}

export default OptionSlot;
