import React, { useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

import MySheet from "../../components/Sheet/MySheet";

import remove from "../../images/icons/remove.png";
import clearButton from "../../images/icons/clearButton.png";
import { server_url } from "../../server";
import { parseDatewithDash, getTodayDate } from "../../js/getDate";
import { CircularButton } from "../../styles/GlobalStyle";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/views/Layout/Header/PageHeader";
import MypageBottomTab from "../../components/Sheet/MypageBottomTab";
import hotelIcon from "../../images/icons/hotelIcon.png";
import OptionDetailPage from "./OptionDetailPage";
import { findMinTime } from "./utils";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import expData from "./resExp.json";

import { CopyToClipboard } from "react-copy-to-clipboard";

function ReservedPlanDetailPage(props) {
  const id = useParams();
  const params = useParams();
  console.log(id.uplanSq); // 추출한 값 출력
  //const [data, setData] = React.useState();
  const [data, setData] = React.useState();
  const [ordData, setOrdData] = React.useState();
  const [ordPlan, setordPlan] = React.useState();
  const [adultList, setAdultList] = React.useState();
  const [childList, setChildList] = React.useState();

  const [airportList, setAirportList] = React.useState([]);
  const [airlineList, setAirlineList] = React.useState([]);

  const [nameIsOpen, setNameIsOpen] = React.useState(false);
  const [changedName, setChangedName] = React.useState("");
  const [memoIsOpen, setMemoIsOpen] = React.useState(false);
  const [planMemo, setPlanMemo] = React.useState("");

  const categoryCdList = ["ACC01", "ACC02", "ACC03", "ACC04", "ACC05"];
  const [optionDetailIsOpen, setOptionDetailIsOpen] = React.useState(false);
  const [clickedOptionSq, setClickedOptionSq] = React.useState();
  const [optionDetailData, setOptionDetailData] = React.useState();
  const [regUt, setRegUt] = React.useState();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [currencySymbol, setCurrencySymbol] = React.useState("");
  const [price, setPrice] = React.useState("0");


  React.useEffect(() => {

    const getPlanDetail = async () => {
      try {
        const airportRes = await axios.get(`${server_url}/ord/airports/${language}`);
        const airlineRes = await axios.get(`${server_url}/ord/airlines/${language}`);
        setAirportList(airportRes.data);
        setAirlineList(airlineRes.data);
        console.log(airlineRes.data);
        let body = {
          ordSq: id.uplanSq
        }
        await axios.get(`${server_url}/ord/plan/${id.uplanSq}`, body)
          .then((v) => {
            console.log("psj");
            console.log(v.data);
            if (v != null) {
              console.log(v.data.priceUserAmount);
              setPrice(Number(v.data.priceUserAmount));
              setordPlan(v.data.ordPlan);
              setAdultList(v.data.adultList);
              setChildList(v.data.childList);
              setOrdData(v.data);
              setRegUt(changeUnixTime(v.data.ordPlan.regUt));
              switch (v.data.currencyCd) {
                case "KRW":
                  setCurrencySymbol("₩ ");
                  break;
                case "USD":
                  setCurrencySymbol("US$ ")
                  break;
                case "HKD":
                  setCurrencySymbol("HK$ ")
                  break;
                case "RMB":
                  setCurrencySymbol("¥ ")
                  break;
                default: setCurrencySymbol("₩ ")
              }

              body = {
                fxCodeCd: v.data.currencyCd,
                today: v.data.dateDt,
                status: "reserved"
              };
              axios.post(`${server_url}/myplan/${language}/${v.data.uplanCd}`, body)
                .then((result) => {
                  console.log(result.data);
                  setData(result.data);
                });
            }

          });
      } catch (e) { }
    }
    getPlanDetail();

    const getOptionDetail = async () => {
      let body = {
        userLang:
          localStorage.getItem("language") !== "undefined"
            ? localStorage.getItem("language")
            : "ko",
        fxCodeCd:
          localStorage.getItem("fxCodeCd") !== "undefined"
            ? localStorage.getItem("fxCodeCd")
            : "KRW",
        today: getTodayDate(),
        // startDate: props.departure,
      };
      await axios
        .post(`${server_url}/options/${clickedOptionSq}`, body)
        .then((v) => {
          setOptionDetailData(v.data);
          setOptionDetailIsOpen(true);
        });
    };
    if (clickedOptionSq) {
      getOptionDetail();
    }

  }, []);

  React.useEffect(() => {
    const getOptionDetail = async () => {
      let body = {
        userLang:
          localStorage.getItem("language") !== "undefined"
            ? localStorage.getItem("language")
            : "ko",
        fxCodeCd:
          localStorage.getItem("fxCodeCd") !== "undefined"
            ? localStorage.getItem("fxCodeCd")
            : "KRW",
        today: getTodayDate(),
        // startDate: props.departure,
      };
      await axios
        .post(`${server_url}/options/${clickedOptionSq}`, body)
        .then((v) => {
          setOptionDetailData(v.data);
          setOptionDetailIsOpen(true);
        });
    };
    if (clickedOptionSq) {
      getOptionDetail();
    }
  }, [clickedOptionSq]);

  const getMyplan = async () => {
    try {
      let body = {
        fxCodeCd:
          localStorage.getItem("fxCodeCd") !== "undefined"
            ? localStorage.getItem("fxCodeCd")
            : "KRW",
        today: getTodayDate(),
      };
      const res = await axios.post(
        `${server_url}/myplan/ko/${data.uplanCd}`,
        body
      );

      console.log(res.data);

      setData(res.data);
    } catch (e) { }
  };

  const removePlan = async () => {
    try {
      // const res = await axios.delete(`${server_url}/myplan/${data.uplanCd}`);
      const res = await axios.put(
        `${server_url}/myplan/delete/${data.uplanCd}`
      );

      console.log(res.data);

      return res.data.successYn;
    } catch (e) { }
  };

  const changePlanName = async () => {
    await axios
      .put(`${server_url}/myplan/name/${data.uplanCd}?name=${changedName}`)
      .then((v) => console.log(v));

    setNameIsOpen(!nameIsOpen);
    getMyplan();
  };

  const changePlanMemo = async () => {
    await axios
      .put(`${server_url}/myplan/memo/${data.uplanCd}?memo=${planMemo}`)
      .then((v) => console.log(v));

    setMemoIsOpen(false);
    getMyplan();
  };

  const changeUnixTime = (value) => {
    const timestampInMilliseconds = value * 1000; // 밀리초로 변환
    const date = new Date(timestampInMilliseconds);

    // 원하는 형식으로 날짜와 시간을 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateTime;
  };

  const sharePlan = () => {
    alert("클립보드에 플랜 URL이 복사 되었습니다.");
  }

  return (

    <div className="container m-8">
      <PageHeader title="예약 완료" />
      {data && (
        <div>
          <div className="w-9 m-8">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="m-1" style={{ display: "flex", gap: "10px" }}>
                <div className="text-l text-bold" style={{ maxWidth: "180px" }}>
                  {data.nicknameNm ? data.nicknameNm : data.uplanNm.title_ko}
                </div>
                <CircularButton
                  className="text-sm"
                  backgroundcolor={"#454545"}
                  style={{ border: "none", height: 20, padding: "0px 5px" }}
                  onClick={(e) => {
                    // e.stopPropagation();
                    setNameIsOpen(true);
                  }}
                >
                  이름 변경
                </CircularButton>
              </div>
            </div>
            <MySheet
              isOpen={nameIsOpen}
              setOpen={setNameIsOpen}
              header="이름 변경"
              headerButton="변경"
              handleClick={changePlanName}
              children={
                <div
                  className="w-8"
                  style={{ margin: "0 auto", position: "relative" }}
                >
                  <StyledInput
                    className="m-3"
                    value={changedName}
                    onChange={(e) => setChangedName(e.currentTarget.value)}
                  />
                  {changedName && (
                    <img
                      src={clearButton}
                      alt=""
                      onClick={() => {
                        setChangedName("");
                      }}
                      style={{ position: "absolute", bottom: "5px", right: "5px" }}
                    />
                  )}
                </div>
              }
            />
          </div>

          <div className="w-9 m-1">
            {data.planPriceLocal !== "-1" && (
              <div className="text-bold"> {currencySymbol}{price.toLocaleString()}</div>
            )}
            <div style={{ fontSize: "10px", color: "#6b6b6b", fontWeight: "600" }}>
              예약번호 : {data.uplanCd}
            </div>
            <div className="text-sm m-2" style={{ color: "#6b6b6b" }}>
              예약 접수 시간 : {regUt}
            </div>
            <div className="m-1" style={{ display: "flex", gap: 15 }}>
              <CircularButton
                style={{ height: "40px" }}
                backgroundcolor="#5c80ff"
                className="w"
                onClick={(e) => e.stopPropagation()}
              >
                1:1 플랜 문의
              </CircularButton>
              <CircularButton
                style={{ height: "40px" }}
                backgroundcolor="#5c80ff"
                className="w"
                onClick={(e) => {
                  // console.log(isDetail);
                  e.stopPropagation();
                  setMemoIsOpen(true);
                }}
              >
                플랜 메모
              </CircularButton>
            </div>

            {/* <CircularButton
          className="m-1"
          color="black"
          style={{ height: "40px" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          일행 초대
        </CircularButton> */}

            <CopyToClipboard text={`https://gomyplan.com/plans/reserved/complete/${id.uplanSq}`} onCopy={sharePlan}>
              <CircularButton
                className="m-1"
                color="black"
                style={{ height: "40px" }}
              >
                플랜 링크 공유
              </CircularButton>

            </CopyToClipboard>

            <div className="m-4" style={{}}>
              {data.slotList.map((element) => {
                return (
                  <div
                    style={{
                      // border: "1px solid black",
                      margin: "20px 0",
                      padding: 20,
                      backgroundColor: "#FAFAFA",
                      borderRadius: "10px",
                    }}
                  >
                    <h3>Day {element.day}</h3>
                    <div className="text-sm">{element.dateName}</div>

                    {element.options.map((el) => {
                      console.log(el);
                      const timeset = findMinTime(el.spotDtoList);
                      return (
                        <div className="m-2">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 5,
                              marginBottom: 5,
                            }}
                          >
                            <div
                              className="text-m text-bold"
                              onClick={() => setClickedOptionSq(el.optionSq)}
                            >
                              {el.optionTitleNm}
                            </div>
                            {categoryCdList.includes(el.categoryCd) && (
                              <img src={hotelIcon} alt="" height="17px" />
                            )}
                            {timeset && (
                              <div
                                style={{
                                  width: "10%",
                                  height: "15%",
                                  background: "#ffd775",
                                  borderRadius: "100px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  padding: "2px 5px",
                                }}
                              >
                                {timeset}
                              </div>
                            )}
                          </div>
                          <div className="text-sm" style={{ color: "#969696" }}>
                            {el.optionDescNm}
                          </div>
                          <div className="m-1 text-bold">
                            {el.optionPriceAdultLocal !== "-1" && (`${currencySymbol} ${el.optionPriceAdultLocal}`)}
                            {el.optionPriceAdultLocal !== "-1" && (<span className="text-xsm">(성인 1명)</span>)}
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            {ordPlan && (
              <div className="m-4" style={{ marginBottom: "40px" }}>
                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <Label>여권 영문 성</Label>
                  <StyledInput
                    className="m-2"
                    readOnly
                    value={ordPlan.cstmLastnameNm}
                  ></StyledInput>
                </div>

                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <Label>여권 영문 이름</Label>
                  <StyledInput
                    className="m-2"
                    readOnly
                    value={ordPlan.cstmFirstnameNm}
                  ></StyledInput>
                </div>

                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <Label>여권 상 국가</Label>
                  <StyledInput
                    className="m-2"
                    readOnly
                    value={ordPlan.cstmCountryCd}
                  ></StyledInput>
                </div>

                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <Label>휴대전화 번호</Label>
                  <StyledInput
                    className="m-2"
                    readOnly
                    value={ordPlan.cstmTelNm}
                  ></StyledInput>
                </div>

                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <Label>휴대전화 가입 국가</Label>
                  <StyledInput
                    className="m-2"
                    readOnly
                    value={ordPlan.cstmCountrydialNm}
                  ></StyledInput>
                </div>

                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <Label>휴대전화 가입 국가</Label>
                  <StyledInput
                    className="m-2"
                    readOnly
                    value={ordPlan.cstmCountrydialNm}
                  ></StyledInput>
                </div>

                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <SemiTitle>일행 정보</SemiTitle><div style={{ marginLeft: "5px" }} className="sc-kbhJrz dbYBSv div_people_text"></div>
                  {adultList.map((el, index) => {
                    return (
                      <FlexDiv>
                        <Label>일행 {index + 1}</Label>
                        <StyledInput style={{ width: "85%" }} value={el.usernameNm} />
                      </FlexDiv>
                    )
                  })}
                  {childList.map((el, index) => {
                    return (
                      <FlexDiv>
                        <Label>아이 {index + 1} 나이</Label>
                        <StyledInput style={{ width: "85%" }} value={el.ageNo} />
                      </FlexDiv>
                    )
                  })}
                </div>

                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <SemiTitle>출도착 항공편</SemiTitle><div style={{ marginLeft: "5px" }} className="sc-kbhJrz dbYBSv div_people_text"></div>
                  <Label className="m-2">출발 항공편</Label>
                  <FlexDiv>
                    <Label>공항 정보</Label>
                    {airportList.map((el) => {
                      if (el.codeId == ordData.arrAirportCd) {
                        return (
                          <StyledInput style={{ width: "80%" }} value={el.codeUserNm} />
                        )

                      }
                    })}

                  </FlexDiv>

                  <FlexDiv>
                    <Label>항공사 정보</Label>
                    {airlineList.map((el) => {
                      if (el.codeId == ordData.arrAirlineCd) {
                        return (
                          <StyledInput style={{ width: "80%" }} value={el.codeUserNm} />
                        )

                      }
                    })}
                  </FlexDiv>

                  <FlexDiv>
                    <Label>편명</Label>
                    <StyledInput style={{ width: "80%" }} value={ordData.arrFlightnoNm} />
                  </FlexDiv>

                  <FlexDiv>
                    <Label>도착 날짜</Label>
                    <StyledInput style={{ width: "80%" }} value={ordData.arrDateyNm + "-" + ordData.arrDatemNm + "-" + ordData.arrDatedNm} />
                  </FlexDiv>

                  <FlexDiv>
                    <Label>도착 시간</Label>
                    <StyledInput style={{ width: "80%" }} value={ordData.arrTimehhNm + ":" + ordData.arrTimemmNm} />
                  </FlexDiv>
                </div>

                <div className="m-2" style={{ margin: "20px 0px", padding: "20px", backgroundColor: "rgb(250, 250, 250)", borderRadius: "10px" }}>
                  <Label>도착 항공편</Label>
                  <FlexDiv>
                    <Label>공항 정보</Label>
                    {airportList.map((el) => {
                      if (el.codeId == ordData.dptAirportCd) {
                        return (
                          <StyledInput style={{ width: "80%" }} value={el.codeUserNm} />
                        )

                      }
                    })}

                  </FlexDiv>

                  <FlexDiv>
                    <Label>항공사 정보</Label>
                    {airlineList.map((el) => {
                      if (el.codeId == ordData.dptAirlineCd) {
                        return (
                          <StyledInput style={{ width: "80%" }} value={el.codeUserNm} />
                        )

                      }
                    })}
                  </FlexDiv>

                  <FlexDiv>
                    <Label>편명</Label>
                    <StyledInput style={{ width: "80%" }} value={ordData.dptFlightnoNm} />
                  </FlexDiv>

                  <FlexDiv>
                    <Label>출발 날짜</Label>
                    <StyledInput style={{ width: "80%" }} value={ordData.dptDateyNm + "-" + ordData.dptDatemNm + "-" + ordData.dptDatedNm} />
                  </FlexDiv>

                  <FlexDiv>
                    <Label>출발 시간</Label>
                    <StyledInput style={{ width: "80%" }} value={ordData.dptTimehhNm + ":" + ordData.dptTimemmNm} />
                  </FlexDiv>
                </div>




              </div>
            )}


          </div>

          <MySheet
            isOpen={memoIsOpen}
            setOpen={setMemoIsOpen}
            header="플랜 메모"
            headerButton="변경"
            handleClick={changePlanMemo}
            children={
              <div className="w-8" style={{ margin: "0 auto" }}>
                <div style={{ position: "relative" }}>
                  <StyledInput
                    className="m-3"
                    placeholder={data.memoNm}
                    value={planMemo}
                    onChange={(e) => setPlanMemo(e.currentTarget.value)}
                  />
                  {planMemo && (
                    <img
                      src={clearButton}
                      alt=""
                      onClick={() => {
                        setPlanMemo("");
                      }}
                      style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "5px",
                      }}
                    />
                  )}
                </div>
                <div className="m-4">{data.memoNm}</div>
              </div>
            }
          />
          {/*<MypageBottomTab data={data} />*/}
          <MySheet
            isOpen={optionDetailIsOpen}
            setOpen={setOptionDetailIsOpen}
            snapPoints={[800]}
            children={<OptionDetailPage data={optionDetailData} />}
          />
        </div>
      )}
    </div>
  );
}


const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  background: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

const SemiTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;


const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 25px;
`;


export default ReservedPlanDetailPage;
