import React from "react";

import { img_url } from "../../server";

function OptionDetailTopImage(props) {
  console.log(props.data);
  let imageUrl = "";
  if (props.data.savenameNm != null && props.data.savenameNm.includes("https://")) {
    imageUrl = props.data.savenameNm;
  } else {
    imageUrl = img_url + "/" + props.data.typeCd + "/" + props.data.savenameNm;
  }
  if(props.data.savenameNm == null){
    if(props.data.optionImages != null && props.data.optionImages.length > 0){
      imageUrl = props.data.optionImages[0].savenameNm;
    }
  }
  return (
    <div className="w">
      <div
        style={{
          height: "35vh",
          background: `url(${imageUrl}) center center / cover no-repeat`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
    </div>
  );
}

export default OptionDetailTopImage;
