import React, { useState, useEffect } from "react";
import axios from "axios";

import OptionSlot from "../../components/Plan/OptionSlot";

import { parseDatewithDash, getTodayDate } from "../../js/getDate";
import { server_url } from "../../server";
import { CircularButton } from "../../styles/GlobalStyle";
import { useTranslation } from "react-i18next";

function SlotSelectModal(props) {
   // 다국어 관련 추가 (2023-03-22)
   const { t, i18n } = useTranslation();
  const [departure] = useState(props.departure);

  const [optionList, setOptionList] = useState([]);

  const [optionSq, setOptionSq] = useState();

  // console.log(props.originalOption);

  useEffect(() => {
    let body = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
      fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
      today: getTodayDate(),
      startDate: parseDatewithDash(departure),
    };

    // console.log(body);

    const getSlotSelect = async () => {
      try {
        const res = await axios.post(
          `${server_url}/plans/${props.params.planSq}/${props.params.planCd}/${props.slotSq}`,
          body
        );

        console.log(res.data);

        setOptionList(res.data);
        setOptionSq(res.data.optionSq);
      } catch (e) {
        console.log(e);
      }
    };

    getSlotSelect();
  }, []);

  return (
    <div className="container" style={{ height: "80vh" }}>
      <div className="w" style={{ height: "85%", overflow: "auto" }}>
        {optionList.alternativeOptionList?.map((el) => {
          // console.log(el);
          return (
            <div
              className="m-2"
              key={el.masterPlanSlotAlternativeDtoMapping.optionSq}
            >
              <OptionSlot
                item={el}
                departure={departure}
                activeOption={optionSq}
                optionSq={el.masterPlanSlotAlternativeDtoMapping.optionSq}
                setOptionSq={setOptionSq}
                price={props.price}
              />
            </div>
          );
        })}
        <div className="m-2">
          <OptionSlot
            item={props.originalOption}
            departure={departure}
            activeOption={optionSq}
            optionSq={props.originalOption.optionSq}
            setOptionSq={setOptionSq}
            price={props.price}
          />
        </div>
      </div>

      <div className="w-9 m-4">
        <CircularButton
          backgroundcolor="#5c80ff"
          onClick={() => {
            console.log(optionSq);
            let body = {
              userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
              fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
              today: getTodayDate(),
              startDate: parseDatewithDash(departure),
            };

            const handleChange = async () => {
              await axios
                .post(
                  `${server_url}/plans/${props.params.planSq}/${props.params.planCd}/${props.slotSq}/${optionList.ordNo}/${optionSq}`,
                  body
                )
                .then((res) => {
                  // console.log(res.data);
                  let changedSlot = {
                    slotSq: res.data.slotSq.toString(),
                    newOptionSq: res.data.newOptionSq.toString(),
                  };

                  props.setChangedSlot(changedSlot);

                  props.setChangedSlotList([
                    ...props.changedSlotList,
                    changedSlot,
                  ]);

                  props.setSlotItemAfterChange(res.data);
                });
            };

            handleChange();

            props.setIsOpen(false);
          }}
        >
           {t('PLANDETAIL.DETAIL02')}
        </CircularButton>
      </div>
    </div>
  );
}

export default SlotSelectModal;
