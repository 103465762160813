import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import DateSelect from "../../components/Select/DateSelect";
import TimeSelect from "../../components/Select/TimeSelect";
import { CircularButton } from "../../styles/GlobalStyle";

import { getTodayDate } from "../../js/getDate";
import { server_url } from "../../server";
import findAdditionalInfo from "./findAdditional";

import {capitalizeFirstLetter} from "../../js/common";

function ReserveNowPage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [data] = useState(state.data);
  const [adult] = useState(state.adult);
  const [child] = useState(state.child);

  console.log(data);
  console.log(adult);
  console.log(child);

  const categoryList = data.slotDtoList?.map((v) => v.options.categoryCd);
  const optionSqList = data.slotDtoList?.map((v) => v.optionSq);

  const additionalInfo = findAdditionalInfo(optionSqList);

  const [countryList, setCountryList] = useState([]);
  const [airportList, setAirportList] = useState([]);
  const [airlineList, setAirlineList] = useState([]);
  const [member,setMember] = useState();

  const [countryCdList] = useState(["HKG", "KO", "MFM","TH"]);

  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [country, setCountry] = useState("HK");
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneCountry, setPhoneCountry] = useState("HK");

  const [adultList, setAdultList] = useState([]);
  const [childList, setChildList] = useState([]);

  const [dptAirportCd, setDptAirportCd] = useState();
  const [dptAirlineCd, setDptAirlineCd] = useState();
  const [dptFlightnoNm, setDptFlightnoNm] = useState();
  const [dptYear, setDptYear] = useState();
  const [dptMonth, setDptMonth] = useState();
  const [dptDay, setDptDay] = useState();
  const [dptTimehhNm, setDptTimehhNm] = useState();
  const [dptTimemmNm, setDptTimemmNm] = useState();

  const [arrAirportCd, setArrAirportCd] = useState();
  const [arrAirlineCd, setArrAirlineCd] = useState();
  const [arrFlightnoNm, setArrFlightnoNm] = useState();
  const [arrYear, setArrYear] = useState();
  const [arrMonth, setArrMonth] = useState();
  const [arrDay, setArrDay] = useState();
  const [arrTimehhNm, setArrTimehhNm] = useState();
  const [arrTimemmNm, setArrTimemmNm] = useState();

  const [requestNm, setRequestNm] = useState();

  const [checked, setChecked] = useState(false);
  const [separateChk, setSeparateChk] = useState(false);
  const [separatePriceNo, setSeparatePriceNo] = useState(3);
  const [separatePaymentList, setSeparatePaymentList] = useState([{}]);

  useEffect(() => {
    const getList = async () => {
      try {
        const airlineRes = await axios.get(`${server_url}/ord/airlines/ko`);
        const airportRes = await axios.get(`${server_url}/ord/airports/ko`);
        const countryRes = await axios.get(`${server_url}/ord/countries/ko`);
        console.log(countryRes);
        const memberRes = await axios.get(`${server_url}/members/info`, {headers: { accessToken: window.sessionStorage.getItem("token") },});


        setAirlineList(airlineRes.data);
        setAirportList(airportRes.data);
        setCountryList(countryRes.data);
        setMember(memberRes.data.data);
        
      } catch (e) {
        console.log(e);
      }
    };
    getList();
  }, []);

  useEffect(()=>{
    if(member != null){
      setFirstName(member.firstnameNm);
      setLastName(member.lastnameNm);
      setCountry(member.nationalityCd);
      setPhoneNumber(member.tel1);
      setPhoneCountry(member.countrydialCd);

    }
  },[member]);

  function handleAdultList(e, i) {
    let clonedList = [...adultList];
    clonedList[i] = e.currentTarget.value;

    setAdultList(clonedList);
  }

  function handleChildList(e, i) {
    let clonedList = [...childList];
    clonedList[i] = Number(e.currentTarget.value);

    setChildList(clonedList);
  }

  function handleSeperateList(e, i) {
    const { value, name } = e.target;

    let clonedList = [...separatePaymentList];

    clonedList[i] === undefined
      ? (clonedList = [...clonedList, { [name]: value }])
      : (clonedList[i][name] = value);

    clonedList[i]["currencyCd"] = "KRW";

    setSeparatePaymentList(clonedList);
  }

  async function addReservation() {
    let body = {
      "ordPlanSaveRequestDto": {
        //uplanSq: data.planSq || "",
        userId: window.sessionStorage.getItem("email") || "",
        planCd: data.planCd || "",
        currencyCd: data.fxCodeCd || "",
        today: getTodayDate() || "",
        dateDt: data.startDateDt || "",

        cstmEmailNm: email || "",
        cstmLastnameNm: lastName || "",
        cstmFirstnameNm: firstName || "",
        cstmCountryCd: country || "",
        cstmTelNm: phoneNumber || "",
        cstmCountrydialNm: phoneCountry || "",

        adultList: adultList || "",
        childList: childList || "",

        dptAirportCd: dptAirportCd || "",
        dptAirlineCd: dptAirlineCd || "",
        dptFlightnoNm: dptFlightnoNm || "",
        dptDateyNm: dptYear || "",
        dptDatemNm: dptMonth || "",
        dptDatedNm: dptDay || "",
        dptTimehhNm: dptTimehhNm || "",
        dptTimemmNm: dptTimemmNm || "",

        arrAirportCd: arrAirportCd || "",
        arrAirlineCd: arrAirlineCd || "",
        arrFlightnoNm: arrFlightnoNm || "",
        arrDateyNm: arrYear || "",
        arrDatemNm: arrMonth || "",
        arrDatedNm: arrDay || "",
        arrTimehhNm: arrTimehhNm || "",
        arrTimemmNm: arrTimemmNm || "",

        requestNm: requestNm || "",

        separatePriceYn: separateChk ? "Y" : "N",
        separatePriceNo: 0,
        separatePaymentList: separatePaymentList || "",

        referenceCd: "",
        documentList: [],
        adultCnt: adult,
        childCnt: child,
        useYn: data.useYn
      },
      "planSlotDtoList": data.slotDtoList

    };

    console.log(body);

    await axios.post(`${server_url}/ord/plan/reservation`, body).then((res) => {
      console.log(res);
      if (res.data.data.successYn === "Y") {
        let ordSq = res.data.data.ordSq;
        alert("예약이 완료되었습니다.");
        navigate("/plans/reserved/complete/"+ordSq);
      } else alert(res.data.resultDesc);
    });
  }

  async function getReservation() {
    let body = {
      //uplanSq: data.planSq || "",
      userId: window.sessionStorage.getItem("email") || "",
      planCd: data.planCd || "",
      currencyCd: data.fxCodeCd || "",
      today: getTodayDate() || "",
      dateDt: data.startDateDt || "",

      cstmEmailNm: email || "",
      cstmLastnameNm: lastName || "",
      cstmFirstnameNm: firstName || "",
      cstmCountryCd: country || "",
      cstmTelNm: phoneNumber || "",
      cstmCountrydialNm: phoneCountry || "",

      adultList: adultList || "",
      childList: childList || "",

      dptAirportCd: dptAirportCd || "",
      dptAirlineCd: dptAirlineCd || "",
      dptFlightnoNm: dptFlightnoNm || "",
      dptDateyNm: dptYear || "",
      dptDatemNm: dptMonth || "",
      dptDatedNm: dptDay || "",
      dptTimehhNm: dptTimehhNm || "",
      dptTimemmNm: dptTimemmNm || "",

      arrAirportCd: arrAirportCd || "",
      arrAirlineCd: arrAirlineCd || "",
      arrFlightnoNm: arrFlightnoNm || "",
      arrDateyNm: arrYear || "",
      arrDatemNm: arrMonth || "",
      arrDatedNm: arrDay || "",
      arrTimehhNm: arrTimehhNm || "",
      arrTimemmNm: arrTimemmNm || "",

      requestNm: requestNm || "",

      separatePriceYn: separateChk ? "Y" : "N",
      separatePriceNo: 0,
      separatePaymentList: separatePaymentList || "",

      referenceCd: "",
      documentList: [],
    };

    console.log(JSON.stringify(body));

    navigate("/plans/reserved/complete/123");

    // await axios.post(`${server_url}/ord/plan/add`, body).then((res) => {
    //   if (res.data.successYn === "Y") {
    //     alert("에약이 완료되었습니다.");
    //     navigate("/");
    //   } else alert(res.data.resultDesc);
    // });
  }

  function chnageDivPeople() {
    const divPeople = document.querySelector(".div_people");
    const divPeopleText = document.querySelector(".div_people_text");
    
    let text = divPeopleText.textContent;
    switch(text){
      case '▼':
        divPeopleText.textContent  = "▲"
        divPeople.classList.add("hidden");
        break;
      case '▲':
        divPeopleText.textContent  = "▼"
        divPeople.classList.remove("hidden");
        break;
    }
  }

  function chnageDivAir(){
    const divAir = document.querySelector(".div_air");
    const divAirText = document.querySelector(".div_air_text");

    let text = divAirText.textContent;
    switch(text){
      case '▼':
        divAirText.textContent  = "▲"
        divAir.classList.add("hidden");
        break;
      case '▲':
        divAirText.textContent  = "▼"
        divAir.classList.remove("hidden");
        break;
    }
    
  }

  return (
    <div className="container">
      <PageHeader title="예약하기" />
      <div className="w-9 m-8">
        {/* 플랜 이름 및 정보 div */}
        <div
          style={{
            background: "#fafafa",
            borderRadius: "10px",
            height: "100px",
            paddingLeft: "10px",
          }}
        >
          <SemiTitle
            style={{
              paddingTop: "20px",
            }}
          >
            {data.planTitleNm}
          </SemiTitle>
          <div className="m-1" style={{ color: "#454545" }}>
            <div style={{ fontSize: "11px", display: "flex" }}>
              현지 도착
              <div style={{ marginLeft: "20px" }}>{data.startDateDt}</div>
            </div>
            <div style={{ fontSize: "11px", display: "flex" }}>
              현지 출국
              <div style={{ marginLeft: "20px" }}>{data.endDateDt}</div>
            </div>
          </div>
        </div>

        {/* 회원정보 및 변경 div */}
        <div className="m-4">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SemiTitle>회원 정보</SemiTitle>
            <CircularButton
              backgroundcolor={"black"}
              style={{ height: "22px", fontSize: "11px", padding: "0 8px" }}
            >
              정보 변경
            </CircularButton>
          </div>
          <div style={{ fontSize: "9px", color: "#969696" }}>
            알려드릴 사항이 있는 경우 아래 연락처로 안내 드립니다.
          </div>
        </div>

        {/* 회원정보 출력 및 입력 div */}
        <div className="m-4">
          <div>
            <Label>이메일</Label>
            <StyledInput
              className="m-2"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            ></StyledInput>
          </div>
          <div className="m-2">
            <Label>여권 영문 성</Label>
            <StyledInput
              className="m-2"
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            ></StyledInput>
          </div>
          <div className="m-2">
            <Label>여권 영문 이름</Label>
            <StyledInput
              className="m-2"
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            ></StyledInput>
          </div>
          <div className="m-2">
            <Label>여권 상 국가</Label>
            <BorderNoneSelect
              className="m-2"
              style={{ width: "100%" }}
              required
              onChange={(e) => {
                setCountry(e.currentTarget.value);
              }}
              value={country}
              defaultValue=""
            >
              <option value="" disabled style={{ display: "none" }}>
                국가 선택
              </option>
              ;
              {countryList &&
                countryList.map((el, i) => {
                  let language = "country"+capitalizeFirstLetter(localStorage.getItem("language"))+"Nm"
                  console.log(language);
                  return <option value={el.countryCd}>{el[language]}</option>;
                })}
            </BorderNoneSelect>
          </div>
          <div className="m-2">
            <Label>휴대전화 번호</Label>
            <StyledInput
              className="m-2"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.currentTarget.value)}
            ></StyledInput>
          </div>
          <div className="m-2">
            <Label>휴대전화 가입 국가</Label>
            <BorderNoneSelect
              className="m-2"
              style={{ width: "100%" }}
              onChange={(e) => setPhoneCountry(e.currentTarget.value)}
              value={phoneCountry}
              defaultValue=""
            >
              <option value="" disabled style={{ display: "none" }}>
                국가 선택
              </option>
              {countryList &&
                countryList.map((el, i) => {
                  let language = "country"+capitalizeFirstLetter(localStorage.getItem("language"))+"Nm"
                  console.log(language);
                  return <option value={el.countryCd}>{el[language]}</option>;
                })}
            </BorderNoneSelect>
          </div>
        </div>

        {/*  */}
        <div className="m-4" style={{display:"flex"}} onClick={() => chnageDivPeople()}>
          <SemiTitle>일행 정보 입력</SemiTitle><div style={{marginLeft:"5px"}} className="sc-kbhJrz dbYBSv div_people_text">▲</div>
        </div>
        <div >
          <div>
            <div className="div_people hidden">
              <div style={{ fontSize: "10px", color: "#6b6b6b" }}>
                <div className="m-1">
                  <strong>성인 일행</strong> 전원의 정보를 입력해주세요
                </div>
                <div>
                  숙소, 차량등을 포함하신 경우 입력하신 내용대로 예약이
                  진행됩니다.
                </div>
              </div>
              <div className="m-1">
                {[...Array(state.adult)].map((n, index) => {
                  return (
                    <FlexDiv>
                      <Label>일행 {index + 1}</Label>
                      <StyledInput
                        style={{ width: "85%" }}
                        placeholder="예) Hong Gil Dong"
                        onChange={(e) => {
                          handleAdultList(e, index);
                        }}
                        disabled={
                          !categoryList?.some((i) =>
                            [
                              "ACC01",
                              "ACC02",
                              "ACC03",
                              "ACC04",
                              "ACC05",
                            ]?.includes(i)
                          )
                        }
                      ></StyledInput>
                    </FlexDiv>
                  );
                })}
              </div>
              {state.child !== 0 && (
                <div>
                  <div
                    className="m-4"
                    style={{ fontSize: "10px", color: "#6b6b6b" }}
                  >
                    <div>
                      여행일 기준의 <strong>동반 아동의 나이</strong>를
                      입력해주세요.
                    </div>
                    <div>입력하신 정보대로 숙소 예약이 진행됩니다.</div>
                  </div>
                  <div className="m-1">
                    {[...Array(state.child)].map((n, index) => {
                      return (
                        <FlexDiv>
                          <Label>아동 {index + 1}</Label>
                          <BorderNoneSelect
                            style={{ width: "85%" }}
                            onChange={(e) => handleChildList(e, index)}
                          >
                            <option defaultValue="">(필수) 선택</option>
                            {new Array(10).fill().map((el, idx) => {
                              return <option value={idx + 3}>{idx + 3}</option>;
                            })}
                          </BorderNoneSelect>
                        </FlexDiv>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>



            {/* 출도착 항공편 입력 */}
            <div className="m-4" style={{display:"flex"}} onClick={() => chnageDivAir()}>
             <SemiTitle>출도착 항공편 입력</SemiTitle><div style={{marginLeft:"5px"}} className="sc-kbhJrz dbYBSv div_air_text">▲</div>
            </div>
            <div className="div_air hidden">
              
              <div style={{ fontSize: "10px", color: "#6b6b6b" }}>
                <div className="m-1">
                  <strong>도착 공항 정보</strong>를 정확하게 입력해주세요.
                </div>
              </div>
              <div>
                <FlexDiv>
                  <Label>공항 정보</Label>
                  <BorderNoneSelect
                    style={{ width: "80%" }}
                    defaultValue=""
                    onChange={(e) => setArrAirportCd(e.currentTarget.value)}
                    disabled={!categoryList?.includes("TRANS02")}
                  >
                    <option value="" disabled style={{ display: "none" }}>
                      공항을 선택해주세요.
                    </option>
                    {airportList &&
                      airportList.map((el) => {
                        return <option value={el.codeId}>{el.codeKoNm}</option>;
                      })}
                  </BorderNoneSelect>
                </FlexDiv>
                <FlexDiv>
                  <Label>항공사 정보</Label>
                  <BorderNoneSelect
                    style={{ width: "80%" }}
                    defaultValue=""
                    onChange={(e) => setArrAirlineCd(e.currentTarget.value)}
                    disabled={!categoryList?.includes("TRANS02")}
                  >
                    <option value="" disabled style={{ display: "none" }}>
                      항공사를 선택해주세요.
                    </option>
                    {airlineList &&
                      airlineList.map((el) => {
                        return <option value={el.codeId}>{el.codeKoNm}</option>;
                      })}
                  </BorderNoneSelect>
                </FlexDiv>
                <FlexDiv>
                  <Label>편명</Label>
                  <StyledInput
                    style={{ width: "80%" }}
                    placeholder="예) AB123"
                    onChange={(e) => setArrFlightnoNm(e.currentTarget.value)}
                    disabled={!categoryList?.includes("TRANS02")}
                  ></StyledInput>
                </FlexDiv>
                <FlexDiv>
                  <Label>도착 날짜</Label>
                  <div className="w-8">
                    <DateSelect
                      setYear={setArrYear}
                      setMonth={setArrMonth}
                      setDay={setArrDay}
                      disabled={!categoryList?.includes("TRANS02")}
                    />
                  </div>
                </FlexDiv>
                <FlexDiv>
                  <Label>도착 시간</Label>
                  <div className="w-8">
                    <TimeSelect
                      setHour={setArrTimehhNm}
                      setMinute={setArrTimemmNm}
                      disabled={!categoryList?.includes("TRANS02")}
                    />
                  </div>
                </FlexDiv>
              </div>
              <div style={{ fontSize: "10px", color: "#6b6b6b" }}>
              <div className="m-4">
                <strong>출발 공항 정보</strong>를 정확하게 입력해주세요.
              </div>
            </div>
            <div>
              <FlexDiv>
                <Label>공항 정보</Label>
                <BorderNoneSelect
                  style={{ width: "80%" }}
                  defaultValue=""
                  onChange={(e) => setDptAirportCd(e.currentTarget.value)}
                  disabled={!categoryList?.includes("TRANS01")}
                >
                  <option value="" disabled style={{ display: "none" }}>
                    공항을 선택해주세요.
                  </option>
                  {airportList &&
                    airportList.map((el) => {
                      return <option value={el.codeId}>{el.codeKoNm}</option>;
                    })}
                </BorderNoneSelect>
              </FlexDiv>
              <FlexDiv>
                <Label>항공사 정보</Label>
                <BorderNoneSelect
                  style={{ width: "80%" }}
                  defaultValue=""
                  onChange={(e) => setDptAirlineCd(e.currentTarget.value)}
                  disabled={!categoryList?.includes("TRANS01")}
                >
                  <option value="" disabled style={{ display: "none" }}>
                    항공사를 선택해주세요.
                  </option>
                  ;
                  {airlineList &&
                    airlineList.map((el) => {
                      return <option value={el.codeId}>{el.codeKoNm}</option>;
                    })}
                </BorderNoneSelect>
              </FlexDiv>
              <FlexDiv>
                <Label>편명</Label>
                <StyledInput
                  style={{ width: "80%" }}
                  placeholder="예) AB123"
                  onChange={(e) => {
                    setDptFlightnoNm(e.currentTarget.value);
                  }}
                  disabled={!categoryList?.includes("TRANS01")}
                ></StyledInput>
              </FlexDiv>
              <FlexDiv>
                <Label>출발 날짜</Label>
                <div className="w-8">
                  <DateSelect
                    setYear={setDptYear}
                    setMonth={setDptMonth}
                    setDay={setDptDay}
                    disabled={!categoryList?.includes("TRANS01")}
                  />
                </div>
              </FlexDiv>
              <FlexDiv>
                <Label>출발 시간</Label>
                <div className="w-8">
                  <TimeSelect
                    setHour={setDptTimehhNm}
                    setMinute={setDptTimemmNm}
                    disabled={!categoryList?.includes("TRANS01")}
                  />
                </div>
              </FlexDiv>
            </div>
            </div>

            
            

            {/* 추가 정보 */}
            {additionalInfo.codeKoNmList.map((v, i) => {
              return (
                <div className="m-5">
                  <SemiTitle>추가 정보</SemiTitle>
                  <div
                    style={{
                      fontSize: "10px",
                      color: "#6b6b6b",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="m-1">
                      <strong>{v}</strong>를 입력해주세요.
                    </div>
                    <CircularButton
                      style={{ height: "20px", padding: "0 10px" }}
                      color="#5c80ff"
                    >
                      파일 첨부
                    </CircularButton>
                  </div>
                  <div className="m-2 w">
                    <textarea
                      className="w"
                      style={{
                        height: "100px",
                        border: "1px solid #454545",
                        borderRadius: "15px",
                        resize: "none",
                        boxSizing: "border-box",
                        padding: "15px",
                      }}
                      placeholder="내용을 입력해주세요."
                    ></textarea>
                  </div>
                </div>
              );
            })}
            {/* 요청 사항 */}
            <div className="m-4">
              <SemiTitle>요청 사항</SemiTitle>
              <div className="m-2 w">
                <textarea
                  className="w"
                  style={{
                    height: "100px",
                    border: "1px solid #454545",
                    borderRadius: "15px",
                    resize: "none",
                    boxSizing: "border-box",
                    padding: "15px",
                  }}
                  placeholder="요청하실 내용을 입력해주세요."
                  onChange={(e) => setRequestNm(e.currentTarget.value)}
                ></textarea>
              </div>
            </div>

            {/* 나눠서 내기 */}
            <div className="m-2" style={{display:"none"}}>
              <input
                type="checkbox"
                onChange={() => {
                  setSeparateChk(!separateChk);
                }}
              ></input>
              <span style={{ color: "#969696", fontSize: "13px" }}>
                일행이 나누어 내기
              </span>
              <div
                className="m-1"
                style={{ display: separateChk ? "block" : "none" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label>결재자 인원 수</label>
                  <BorderRadiusSelect
                    defaultValue={Number(state.adult)}
                    onChange={(e) => {
                      setSeparatePriceNo(e.currentTarget.value);
                    }}
                  >
                    {[...Array(state.adult)].fill().map((el, i) => {
                      return <option value={i + 1}>{i + 1}</option>;
                    })}
                  </BorderRadiusSelect>
                </div>
                {[
                  new Array(Number(separatePriceNo)).fill().map((el, i) => {
                    return (
                      <div
                        className="m-2"
                        style={{
                          background: "#FAFAFA",
                          display: "grid",
                          gridTemplateColumns: "1fr 4fr",
                          padding: "15px",
                        }}
                      >
                        <div>결제자 {i + 1}</div>
                        <div>
                          <div>
                            <BorderRadiusSelect
                              className="w"
                              name="typeCd"
                              defaultValue={2}
                              onChange={(e) => handleSeperateList(e, i)}
                            >
                              <option value={2}>직접 입력</option>
                              <option value={1}>예약자</option>
                            </BorderRadiusSelect>
                          </div>
                          <StyledInput
                            className="m-1"
                            name="emailNm"
                            onChange={(e) => handleSeperateList(e, i)}
                            placeholder="결제링크를 받으실 이메일 주소를 입력해주세요."
                          />
                          <div
                            className="w m-3"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                              gap: "10px",
                            }}
                          >
                            <BorderRadiusSelect
                              name="adultCnt"
                              onChange={(e) => handleSeperateList(e, i)}
                            >
                              <option style={{ display: "none" }}>
                                어른 수
                              </option>
                              {[...Array(state.adult)].fill().map((el, idx) => {
                                return (
                                  <option value={idx + 1}>{idx + 1}</option>
                                );
                              })}
                            </BorderRadiusSelect>
                            <BorderRadiusSelect
                              name="childCnt"
                              onChange={(e) => handleSeperateList(e, i)}
                            >
                              <option style={{ display: "none" }}>
                                아이 수
                              </option>
                              {[...Array(state.child)].fill().map((el, idx) => {
                                return (
                                  <option value={idx + 1}>{idx + 1}</option>
                                );
                              })}
                            </BorderRadiusSelect>
                          </div>
                        </div>
                      </div>
                    );
                  }),
                ]}
              </div>
            </div>

            {/* 이용약관동의 체크박스 */}
            <div className="m-2">
              <input
                type="checkbox"
                onChange={() => {
                  setChecked(!checked);
                }}
              ></input>
              <span style={{ color: "#969696", fontSize: "13px" }}>
                입력하신 여행 정보 및 플랜 일정을 확인하였으며 플랜의 취소규정
                및 이용약관에 동의합니다.
              </span>
            </div>

            <div className="w m-5" style={{ marginBottom: "40px" }}>
              <CircularButton
                backgroundcolor="#5c80ff"
                onClick={() => {
                  !checked
                    ? alert("취소규정 및 이용약관에 동의해주세요.")
                    //: getReservation();
                    : addReservation();
                }}
              >
                완료
              </CircularButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SemiTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  background: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

const BorderRadiusSelect = styled.select`
  border: 1px solid black;
  border-radius: 20px;
  padding: 3px 20px;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

const BorderNoneSelect = styled.select`
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 25px;
`;
export default ReserveNowPage;
