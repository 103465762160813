import React, { useEffect } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import { server_url } from "../../server";
import { parseDatewithDash, getTodayDate } from "../../js/getDate";

function SavedPlan(props) {
  const navigate = useNavigate();

  const [data, setData] = React.useState(props.data);
  const [currencySymbol,setCurrencySymbol] = React.useState("");
  let fxCodeCd = "KRW"

  const getMyplan = async () => {
    try {
     
      fxCodeCd = localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW";
      switch (fxCodeCd) {
        case "KRW":
          setCurrencySymbol("₩ ");
          break;
        case "USD":
          setCurrencySymbol("US$ ")
          break;
        case "HKD":
          setCurrencySymbol("HK$ ")
          break;
        case "RMB":
          setCurrencySymbol("¥ ")
          break;
        default: setCurrencySymbol("₩ ")
      }
      let body = {
        fxCodeCd: fxCodeCd,
        today: data.dateDt,
        status: "reserved"

      };
      console.log(fxCodeCd);
      const res = await axios.post(
        `${server_url}/myplan/ko/${data.uplanCd}`,
        body
      );

      //console.log(res.data);

      setData(res.data);
    } catch (e) {}
  };

  useEffect(() => {
    getMyplan();
  }, []);

  return (
    <div
      className="w-9"
      style={{
        borderBottom: "1px solid #f4f4f4",
        padding: "10px",
      }}
      onClick={async () => {
        let body = {
          fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
          today: parseDatewithDash(getTodayDate()),
        };
        try {
          const res = await axios.post(
            `${server_url}/myplan/ko/${data.uplanCd}`,
            body
          );

          //console.log(res.data);
          navigate(`/mypage/myplan/${data.uplanCd}`, {
            state: { data: data, detailData: res.data },
          });
        } catch (e) {}
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontSize: "10px", color: "#6b6b6b", fontWeight: "600" }}>
          예약번호 : {data.uplanCd}
        </div>
      </div>
      <div
        className="m-1 text-bold text-l"
        style={{ display: "flex", gap: "10px" }}
      >
        <div className="text-bold" style={{ maxWidth: "180px" }}>
          {data.nicknameNm ? data.nicknameNm : data.uplanNm.title_ko}
        </div>
      </div>
      <div>
        <span className="m-1 text-bold">{currencySymbol}{data.planPriceLocal}</span>
      </div>
      <div className="m-2" style={{ fontSize: 12 }}>
        {data.dateDt}
      </div>
      <div className="text-xsm">
        성인 {data.adultCnt}, 아동 {data.childCnt}
      </div>
    </div>
  );
}

export default SavedPlan;
