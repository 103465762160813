import React, { useState } from "react";

function Robots() {
    const robotsTxt = () => {
        return {
            __html:
          `<script src="../../robots.txt"></script>`
        }
    }

  return (
      <div
          dangerouslySetInnerHTML={robotsTxt()}
      />

  );
}

export default Robots;
