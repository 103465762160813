import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";

import { generateRandomNumber } from "../../js/common"
import { uploadS3 } from "../../js/common"

import backArrow from "../../images/icons/backArrow.png";
import img_search from "../../images/icons/search.svg";

import { useInView } from "react-intersection-observer"

import { server_url,img_url } from "../../server";



function AddPlanPhoto() {
    const navigate = useNavigate();
    const params = useParams();
    const [ref, inView] = useInView();
    const [items, setItems] = useState([]);
    const [checkedItems, setCheckedItems] = useState(new Set());
    const [page, setPage] = useState(0);
    const size = 2;
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const [data, setData] = useState([]);
    const [files, setFiles] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [email,setEmail] = useState(params.email);
    const [memo, setMemo] = useState("");

    console.log(email);

    // 서버에서 아이템을 가지고 오는 함수
    const getItems = useCallback(async () => {
        console.log("page:" + page);
        console.log("memo:" + memo);
        setLoading(true)
        console.log(memo.length)
        if (memo.length > 0) {
            console.log("search")
            axios.get(`${server_url}/img/getImages/search?page=${page}&size=${size}&uploadUser=${email}&memo=${memo}`).then((res) => {
                console.log(res);
                let item = res.data;
                item.forEach((img)=>{
                    console.log(img);
                    if(!img.savenameNm.includes("https://")){
                        img.savenameNm = `${img_url}/${img.typeCd}/${img.savenameNm}`;
                    }
                });
                setItems(prevState => [...prevState, item]);
            })

        } else {
            console.log("not search")
            await axios.get(`${server_url}/img/getImages?page=${page}&size=${size}&uploadUser=${email}`).then((res) => {
                let item = res.data;
                item.forEach((img)=>{
                    console.log(img);
                    if(!img.savenameNm.includes("https://")){
                        img.savenameNm = `${img_url}/${img.typeCd}/${img.savenameNm}`;
                    }
                });
                setItems(prevState => [...prevState, item]);
            })
        }
        setLoading(false);


        console.log(items);

    }, [page, memo]);

    useEffect(() => {
        setData(location.state);
        getItems();
    }, [location.state, getItems]);

    // `getItems` 가 바뀔 때 마다 함수 실행
    useEffect(() => {
        // 사용자가 마지막 요소를 보고 있고, 로딩 중이 아니라면
        if (inView && !loading) {
            setPage(prevState => prevState + 1)
        }
    }, [inView, loading]);



    const handleFileChange = (event) => {
        const files = event.target.files;
        const selectedFilesArray = Array.from(files); // 파일 목록을 배열로 변환
        setFiles(selectedFilesArray);
    };

    // 항목의 체크 상태를 토글하는 함수
    const toggleItemChecked = (item) => {
        console.log("0915");
        console.log(item);
        const updatedCheckedItems = new Set(checkedItems);
        if (updatedCheckedItems.has(item)) {
            updatedCheckedItems.delete(item);
        } else {
            updatedCheckedItems.add(item);
        }
        setCheckedItems(updatedCheckedItems);
    };


    const handleSelectionComplete = () => {
        // checkedItems Set을 배열로 변환하여 사용하면 선택된 항목들을 얻을 수 있습니다.
        const selectedItems = Array.from(checkedItems);

        // 선택된 항목들을 사용하거나 처리할 수 있습니다.
        console.log("선택된 항목들:", selectedItems);
        localStorage.setItem("tmpImagesPlan", JSON.stringify(selectedItems));
        navigate(-1);

    };

    const handleEnterKey = async (e) => {
        if (e.key == 'Enter') {
            setMemo(e.target.value);
        }
    }

    useEffect(() => {
        const uploadData = async () => {
            console.log("0926-1");
            console.log(files.length);
            if (files.length > 0) {
                let title = data.cityCd + generateRandomNumber(10);
                console.log(files);
                try {
                    setImageFiles(await uploadS3(files, email, data.cityCd));
                } catch (error) {
                    console.log(error);
                }
            }
        }
        uploadData();
    }, [files]);

    useEffect(() => {
        console.log("0926");
        console.log(imageFiles);
        //버킷이 올라간 이미지 저장
        let imgList = [];

        // 기존 items 배열에 첫 번째 배열로 선택된 파일들을 추가하여 업데이트합니다.
        for (let i = 0; i < imageFiles.length; i++) {
            let item = location.state;
            imgList.push({planSq : item.planSq, imageCd :item.planCd,ordNo : i, uploadUser:email,savenameNm : imageFiles[i].Location,internalMemoNm:item.internalMemoNm})
        }
        if(imgList.length > 0){
            console.log(imgList);
            //comImages 추가
            axios.post(`${server_url}/img/plan/save`,imgList).then((res) => {
             console.log("0926-3")
             console.log(res);
             if(res.data.result = "success"){
                window.location.reload();
             }
         })
        }
        
        
    }, [imageFiles]);

    console.log(items);
    return (
        <div>
            <div className={"w"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "0", backgroundColor: "#ffffff", marginTop: "20px" }}>
                <img src={backArrow} alt="" width="10px" height="15px" style={{ transform: "scaleX(-1)", marginLeft: "1%" }} className="c" onClick={() => { window.history.back(); }} />
                <div style={{ color: "5c80ff", fontWeight: "800", fontSize: "20px", margin: "auto", marginLeft: "30px", display: "flex", width: "100%" }}>
                    <input type="text" style={{ width: "100%" }} onKeyDown={handleEnterKey} />
                    <img src={img_search} style={{ width: "30px", marginLeft: "auto", transform: "translate(-40px)" }} />
                </div>
            </div>
            <div style={{ height: "500px", overflow: "auto" }}>
                {items.map((list, idx) => (
                    <React.Fragment key={idx}>
                        {items.length - 1 === idx ? (
                            <div className="list-item" style={{ display: "inline-block", verticalAlign: "top", width: "100%" }} ref={ref}>
                            </div>
                        ) : (
                            <div >
                                {list.map((subItem, subIdx) => (
                                    
                                    <React.Fragment key={subIdx}>
                                        {((memo.length > 1 && subItem.internalMemoNm != null && subItem.internalMemoNm.includes(memo)) || memo.length <= 1) && (  // memo 상태가 1자리 이상이면서 subItem의 memo에 memo가 포함된 경우 또는 memo가 1자리 이하인 경우에만 표시
                                            <div className="list-item" style={{ display: "inline-block", verticalAlign: "top", width: "50%" }}>
                                                <input type="checkbox" checked={checkedItems.has(subItem)} onChange={() => toggleItemChecked(subItem)} />
                                                <img src={subItem.savenameNm} style={{ width: "100%",maxHeight:"250px",objectFit:"cover" }} alt="" />
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}

                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
            <div style={{ marginTop: "50px" }}>
                <button style={{ border: "none", background: "#4f95d5", "borderRadius": "10px", height: "30px", width: "100%", color: "#FFF" }} onClick={handleSelectionComplete}>선택 완료</button>
                <label htmlFor="file" style={{ display: "flex", marginTop: "10px", border: "1px solid #4f95d5", "borderRadius": "10px", height: "30px", width: "100%", "alignItems": "center", "justifyContent": "center" }}>내 사진 가져오기</label>
                <input id="file" type="file" style={{ display: "none" }} multiple onChange={handleFileChange} />

            </div>
        </div>
    )

}

export default AddPlanPhoto;