import React from "react";

import { Date } from "../../../styles/GlobalStyle";

import calender from "../../../images/icons/calender.png";

import { useTranslation } from "react-i18next";


function Datebar(props) {
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  return (
    <div className="new_calendar_btn home">
      <div className="fl">
        <i className="xi-calendar-check"></i>
        <p>{t('MAIN.TRAVEL')}</p>
      </div>
      <div className="fr">
        <p>{props.departure}</p>
        <i className="xi-caret-down"></i>
      </div>
    </div>
  );
}

export default Datebar;
