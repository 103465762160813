import React from "react";
import Slider from "react-slick";

import { img_url } from "../../server";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SpotImageCarousel(props) {
  const setting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div>
      <Slider {...setting}>
        {props.list.map((item) => {
          return (
            <div key={item} style={{ width: "100%", height: "130%" }}>
              {/* <img
                // src={img}
                src={`${img_url}/${item.typeCd}/${item.savenameNm}`}
                alt=""
                width="100%"
                height="270px"
                style={{ margin: "0 auto" }}
              /> */}
              <div
                style={{
                  height: "270px",
                  // backgroundImage: `url(${promotion})`,
                  backgroundImage: `url(${img_url}/${item.typeCd}/${item.savenameNm})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default SpotImageCarousel;
