import React from "react";

import OptionDetailTopImage from "../../components/Plan/OptionDetailTopImage";

import { Title } from "../../styles/GlobalStyle";

import recommend from "../../images/icons/optionDetailPage/recommend_.png";
import photoSpot from "../../images/icons/optionDetailPage/photoSpot_.png";
import weekly from "../../images/icons/optionDetailPage/weekly_.png";
import cityTour from "../../images/icons/optionDetailPage/cityTour_.png";
import filledChk from "../../images/icons/optionDetailPage/filledChk.png";
import filledX from "../../images/icons/optionDetailPage/filledX.png";

import SpotDescComponent from "../../components/Plan/SpotDescComponent";
import {img_url} from "../../server";
import GoogleMapPolylineComponent from "../../components/GoogleMap/GoogleMapPolylineComponent";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function OptionDetailPage(props) {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();

  const [data] = React.useState(props.data);
  const [pcList] = React.useState(props.data.pcList);
  const [googleMarker, setGoogleMarker] = React.useState([]);
  const coordinate = [];

  console.log(data);

  let dayEn = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let dayKo = ["일", "월", "화", "수", "목", "금", "토"];

  const [dayNm] = React.useState(
    data.dayNm
      .split("|")
      .filter((v) => v !== "")
      .map((v) => (v = dayKo[dayEn.indexOf(v)]))
  );

  return (
    <div>
      <OptionDetailTopImage data={data} />

      <Title className="m-1">{props.data.optionTitleNm}</Title>

      <div style={{ marginLeft: 25 }}><pre style={{overflowWrap:"break-word",whiteSpace:"pre-wrap"}}>{data.optionDescNm}</pre></div>

      <div
        className="m-4"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            maxWidth: 52,
            margin: "0 auto",
          }}
        >
          <img src={cityTour} alt="" />
          <div style={{ fontSize: "12px", textAlign: "center" }}>
            {data.categoryCd}
          </div>
        </div>
        {dayNm.length !== 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
              maxWidth: 52,
              margin: "0 auto",
            }}
          >
            <img src={weekly} alt="" />
            {dayNm.length === 7 ? (
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                매주 진행
              </div>
            ) : (
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                {dayNm.join(",")}
                <br />
                진행
              </div>
            )}
          </div>
        )}

        {data.toppickYn === "Y" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
              maxWidth: 52,
              margin: "0 auto",
            }}
          >
            <img src={recommend} alt="" />
            <div style={{ fontSize: "12px", textAlign: "center" }}>
              추천 옵션
            </div>
          </div>
        )}
        {data.phtoSpotYn === "Y" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
              maxWidth: 52,
              margin: "0 auto",
            }}
          >
            <img src={photoSpot} alt="" />
            <div style={{ fontSize: "12px", textAlign: "center" }}>
              포토 스팟
            </div>
          </div>
        )}
      </div>

        <div
            style={{
                fontSize: "small",
                fontWeight: "bold",
                backgroundColor: "#01a2f9",
                marginTop: "2%",
                marginLeft: "3%",
                width: "17%",
                textAlign: "center",
            }}
        >
            {props.data.minipaxNo > 1 ? `${t('PLANDETAIL.DETAIL05')} ${props.data.minipaxNo} ${t('PLANDETAIL.FOOTLYR03')}` : ""}
        </div>
        {/* 구분선 */}
        <div
            style={{
                border: "1px solid #d2d2d2",
                marginTop: "5%",
            }}
        ></div>

        {pcList.map((pclist) => {
            if (props.data.companySq !== null && pclist.pcSq === props.data.companySq) {
                console.log(pclist);
                let imageUrl = "";
                    if(pclist.savenameNm.includes("https://")){
                        imageUrl = pclist.savenameNm;
                    } else{
                        imageUrl = img_url +"/"+pclist.typeCd+"/"+pclist.savenameNm;
                    }
                return (
                    <div
                        style={{
                            marginTop: "4%",
                            marginLeft: "3%",
                        }}
                    >
                        <div
                            style={{
                                width: "70px",
                                height: "70px",
                                backgroundImage: `url(${imageUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",

                                borderRadius: "100px",
                                display: "inline-block",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    // display: "inline-block",
                                    // marginLeft: "30px",
                                    width: "350%",
                                    marginLeft: "140%",
                                    marginTop: "30%",
                                }}
                            >
                                {localStorage.getItem("language") == "ko" ? pclist.localNm : pclist.enNm}
                            </div>
                        </div>
                    </div>
                );
            }
        })}

        {data.featureList1.map((el) => {
            if(el.codeId === "") {
                return (
                    <div
                        className="text-sm m-1" key={el.optionfSq}
                        style={{
                            fontWeight: "bold",
                            marginTop: "3%",
                            marginLeft: "3%",
                        }}
                    >
                        {langTitle(el)}
                    </div>
                );
            }
        })}

        {/* 구분선 */}
        <div
            style={{
                border: "1px solid #d2d2d2",
                marginTop: "5%",
            }}
        ></div>

      <div className="container">
        <div className="w-9 m-1">
          <ul style={{ listStyle: "none", color: "#454545", padding: 0 }}>
            {data.featureList1.map((el) => {
                if(el.codeId !== "") {
                    return (
                        <li className="text-sm m-1" key={el.optionfSq} >
                            {langTitle(el)}
                        </li>
                    );
                }
            })}
          </ul>
        </div>
      </div>

        <div className="m-2">
            <Title className="m-4" style={{ marginBottom: 0 }}>
                {t('OPTIONDETAIL.ODITIN02')}
            </Title>
            {data.spotList.map((el) => {
                coordinate.push({
                    lat: Number(`${el.latNm}`),
                    lng: Number(`${el.longNm}`),
                    orderNo: el.masterOptionSpots.ordNo,
                    descNm: spotListDesc(el)
                })
            })}
            <GoogleMapPolylineComponent
                coordinate = {coordinate}
            />
        </div>
      {data.spotList.length !== 0 && (
        <Title className="m-4" style={{ marginBottom: 0 }}>
            {t('OPTIONDETAIL.ODITIN01')}
        </Title>
      )}
      <div className="container">
        <div className="w-9">
          {data.spotList.map((el) => {
            return <SpotDescComponent data={el} />;
          })}
        </div>
      </div>

      <Title>{t('OPTIONDETAIL.ODGRUP02')}</Title>
      <div className="w-9">
        <ul
          style={{
            listStyleImage: `url(${filledChk})`,
            color: "#454545",
          }}
        >
          {data.featureList2.map((el) => {
            return (
              <li className="text-sm m-2" key={el.optionfSq}>
                  {langTitle(el)}
              </li>
            );
          })}
        </ul>
      </div>

      <Title>{t('OPTIONDETAIL.ODGRUP03')}</Title>

      <div className="w-9">
        <ul
          style={{
            listStyleImage: `url(${filledX})`,
            color: "#454545",
          }}
        >
          {data.featureList3.map((el) => {
            return (
              <li className="text-sm m-2" key={el.optionfSq}>
                {langTitle(el)}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function spotListDesc(el) {
    let descNm;

    switch (localStorage.getItem("language")) {
        case "ko" :
            descNm = el.masterOptionSpots.descKoNm
            break;
        case "en" :
            descNm = el.masterOptionSpots.descEnNm
            break;
        case "zhchs" :
            descNm = el.masterOptionSpots.descZhchsNm
            break;
        case "zhcht" :
            descNm = el.masterOptionSpots.descZhchtNm
            break;
        default : descNm = el.masterOptionSpots.descKoNm
    }
    return descNm;
}

function langTitle(el) {
    let titleNm;

    switch (localStorage.getItem("language")) {
        case "ko" :
            titleNm = el.titleKoNm
            break;
        case "en" :
            titleNm = el.titleEnNm
            break;
        case "zhchs" :
            titleNm = el.titleZhchsNm
            break;
        case "zhcht" :
            titleNm = el.titleZhchtNm
            break;
        default : titleNm = el.titleKoNm
    }
    return titleNm;
}
export default OptionDetailPage;
