import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

import { server_url, img_url } from "../../server";

import homeMenu from "../../images/icons/homeMenu.png";
import PageHeader from "../../components/views/Layout/Header/PageHeader";
import backArrow from "../../images/icons/backArrow.png";

/* Img */
import img_addPhoto from "../../images/icons/file_add.svg"
import img_default from "../../images/img/img_default.png"
import img_modify from "../../images/icons/pencil.svg"
import img_camera from "../../images/img/img_camera.svg"
import img_add from "../../images/icons/file_add.svg"

/* AWS */
import AWS from "aws-sdk";


/* Switch */
import Switch from '@mui/material/Switch';

/* Drag&Drop */
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; // Import react-beautiful-dnd


function MyPlanPage() {
  const location = useLocation();
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const [email] = useState(window.sessionStorage.getItem("email"));
  const [data, setData] = useState();

  //Switch
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  //Time
  const hours = [];
  const minutes = [];

  for (let i = 0; i <= 24; i++) {
    hours.push(i);
  }
  for (let i = 0; i < 60; i++) {
    let no = i;
    if (i < 10) {
      no = '0' + i;
    }
    minutes.push(no);
  }

  //SwitchList
  const [spotActivation, setSpotActivation] = useState([]);


  //옵션 ID
  const [optionSq, setOptionSq] = useState(0);
  //옵션 이름
  const [optionName, setOptionName] = useState('');
  //Spot List
  const [spotList, setSpotList] = useState([]);
  //언어 세팅
  const [languageComment, setLanguageComment] = useState(language);
  //한국어 코멘트
  const [koComment, setKoComment] = useState("");
  //영어 코멘트
  const [enComment, setEnComment] = useState("");
  //간자체 코멘트
  const [zhchsComment, setzhchsComment] = useState("");
  //번자체 코멘크
  const [zhchtComment, setzhchtComment] = useState("");
  //코멘트
  const [comment, setComment] = useState("");
  //이미지 size
  const [imageSize, setIamgeSize] = useState(0);

  const [active, setActive] = useState(false);

  const [imageUrl, setImageUrl] = useState(img_camera);

  const [masterOptionSpot, setMasterOptionSpot] = useState([]);

  const [title, setTitle] = useState("");

  const [desc, setDesc] = useState("");

  //이미지
  const [comSpots, setComSpots] = useState();




  /* 젤 앞 대문자 설정 */
  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) {
      return str;
    }

    const firstLetter = str.charAt(0).toUpperCase();
    const remainingLetters = str.slice(1);

    return firstLetter + remainingLetters;
  };

  const handleButtonClick = (lang) => {
    setLanguageComment(lang);
    switch (lang) {
      case "ko": case "한국어":
        setComment(koComment);
        break;
      case "English":
        setComment(enComment);
        break;
      case "中文繁體":
        setComment(zhchsComment);
        break;
      case "中文簡體":
        setComment(zhchtComment);
        break;
    }
  }

  const handleChange = (event) => {
    let value = event.target.value;
    console.log(languageComment);
    setComment(value);
    switch (languageComment) {
      case "ko": case "한국어":
        setKoComment(value);
        break;
      case "English":
        setEnComment(value);
        break;
      case "中文繁體":
        setzhchsComment(value);
        break;
      case "中文簡體":
        setzhchtComment(value);
        break;
    }
  }

  useEffect(() => {
    console.log("언어:" + language);
    console.log(location);

    if (location.state != undefined) {
      //값 세팅
      let getOption = location.state.data;
      const getOptionDetail = async () => {
        let body = {
          optionSq: getOption
        };
        try {
          const res = await axios.post(`${server_url}/myplan/option/getAllOption`, body);
          console.log("0913");
          console.log(res);
          let item = res.data.data;
          let title = "title" + capitalizeFirstLetter(language) + "Nm";
          setTitle(title);
          let desc = "desc" + capitalizeFirstLetter(language) + "Nm";
          setDesc(desc);
          setOptionSq(item.masterOption.optionSq);
          setOptionName(item.masterOption[title]);
          setData(item);
          if (item.masterOption.descEnNm == null) {
            setEnComment("");
          } else {
            setEnComment(item.masterOption.descEnNm);
          }
          if (item.masterOption.descKoNm == null) {
            setKoComment("");
          } else {
            setKoComment(item.masterOption.descKoNm);
            setComment(item.masterOption.descKoNm);
          }
          if (item.masterOption.descZhchsNm == null) {
            setzhchsComment("");
          } else {
            setzhchsComment(item.masterOption.descZhchsNm);
          }
          if (item.masterOption.descZhchtNm == null) {
            setzhchtComment("");
          } else {
            setzhchtComment(item.masterOption.descZhchtNm);
          }


          setMasterOptionSpot(item.masterOptionSpots);
          setComSpots(item.comSpots);
          setIamgeSize(item.masterOptionImages.length);

          let spotActivationList = [];
          if (item.masterOptionSpots != null && item.masterOptionSpots != undefined) {
            console.log('ssss');


            for (let i = 0; i < item.masterOptionSpots.length; i++) {
              if (item.masterOptionSpots[i].timehhNm != "" || item.masterOptionSpots[i].timemmNm != "") {
                spotActivationList.push(true); // 조건을 충족하는 경우 true 추가
              } else {
                spotActivationList.push(false); // 조건을 충족하지 않는 경우 false 추가
              }
            }

            setSpotActivation(spotActivationList);

          }

          //임시 설정
          setActive(true);
        } catch (error) {
          console.error(error);
        }
      };

      getOptionDetail();
    }


  }, []);


  const handleSpotSwitchChange = (index) => {
    console.log("swicth");
    let updatedSpotActivationList = [...spotActivation]; // 복사본 생성
    updatedSpotActivationList[index] = !updatedSpotActivationList[index]; // 해당 인덱스의 값을 반전
    setSpotActivation(updatedSpotActivationList); // 업데이트된 리스트로 상태 업데이트
  };

  const handleSelectTimeChange = (e, index) => {
    // 선택된 값 가져오기
    const selectedValue = e.target.value;

    // masterOptionSpot 배열 복사
    const updatedMasterOptionSpot = [...masterOptionSpot];

    // 해당 인덱스의 masterOptionSpot 업데이트
    updatedMasterOptionSpot[index] = {
      ...updatedMasterOptionSpot[index],
      timehhNm: selectedValue, // 또는 timehhNm, 필요한 것을 업데이트
    };

    // 업데이트된 masterOptionSpot로 상태 업데이트
    setMasterOptionSpot(updatedMasterOptionSpot);

    console.log(updatedMasterOptionSpot);
  };

  const handleSelectMinuteChange = (e, index) => {
    // 선택된 값 가져오기
    const selectedValue = e.target.value;

    // masterOptionSpot 배열 복사
    const updatedMasterOptionSpot = [...masterOptionSpot];

    // 해당 인덱스의 masterOptionSpot 업데이트
    updatedMasterOptionSpot[index] = {
      ...updatedMasterOptionSpot[index],
      timemmNm: selectedValue, // 또는 timemmNm, 필요한 것을 업데이트
    };

    // 업데이트된 masterOptionSpot로 상태 업데이트
    setMasterOptionSpot(updatedMasterOptionSpot);

    console.log(updatedMasterOptionSpot);
  };


  const handleRemove = async (indexToRemove) => {

    //삭제 요청
    if (window.confirm("삭제하시겠습니까?")) {
      //승인시
      const res = await axios.post(`${server_url}/myplan/option/DeleteSpot`, masterOptionSpot[indexToRemove]).then((response) => {
        console.log(response);
        if (response.result = "success") {
          // success인 경우 해당 요소를 제외한 새로운 배열을 생성
          const updatedMasterOptionSpot = masterOptionSpot.filter((spot, index) => index !== indexToRemove);
          const updatedSpotActivation = spotActivation.filter((spot, index) => index !== indexToRemove);
          const updatedComSpot = comSpots.filter((spot, index) => index !== indexToRemove);
          // 업데이트된 배열로 상태 업데이트
          setMasterOptionSpot(updatedMasterOptionSpot);
          setSpotActivation(updatedSpotActivation);
          setComSpots(updatedComSpot);

          const sortSpot = Array.from(updatedMasterOptionSpot);
          sortSpot.forEach((spot, index) => {
            spot.ordNo = index;
          });

          const res = axios.post(`${server_url}/myplan/option/UpdateSpots`, sortSpot);
          res.then((response) => {
            console.log(response);
            alert("삭제가 완료되었습니다.");
          })
        }
      });
    } else {
      //거절시
    }
  };


  /* 옵션 저장 */
  const onSave = () => {
    let body = {
      optionSq: optionSq,
      descKoNm: koComment,
      descEnNm: enComment,
      descZhchsNm: zhchsComment,
      descZhchtNm: zhchtComment

    }
    console.log(body);
    console.log(masterOptionSpot);
    // 업데이트된 masterOptionSpot의 ordNo를 0부터 순서대로 설정
    const updatedMasterOptionSpot = Array.from(masterOptionSpot);
    updatedMasterOptionSpot.forEach((spot, index) => {
      spot.ordNo = index;
    });
    const res = axios.post(`${server_url}/ord/option/save`, body);
    res.then((response) => {
      console.log(response);
      if (response.data.result == "success") {
        //setData(response.data.data);
        const res = axios.post(`${server_url}/myplan/option/UpdateSpots`, updatedMasterOptionSpot);
        res.then((response) => {
          console.log(response);
          alert("옵션 저장이 완료되었습니다.");
          navigate("/mypage/makingOption/previewOption", { state: optionSq });
        })


      }
    });
  }

  // Drag-and-Drop 관련 함수 추가
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedMasterOptionSpot = Array.from(masterOptionSpot);
    const [reorderedItem] = updatedMasterOptionSpot.splice(result.source.index, 1);
    updatedMasterOptionSpot.splice(result.destination.index, 0, reorderedItem);

    const updatedSpotActivation = Array.from(spotActivation);
    const [reorderedActivation] = updatedSpotActivation.splice(result.source.index, 1);
    updatedSpotActivation.splice(result.destination.index, 0, reorderedActivation);

    const updatedComSpot = Array.from(comSpots);
    const [reorderSpot] = updatedComSpot.splice(result.source.index, 1);
    updatedComSpot.splice(result.destination.index, 0, reorderSpot);

    setMasterOptionSpot(updatedMasterOptionSpot);
    setSpotActivation(updatedSpotActivation);
    setComSpots(updatedComSpot);

  };



  return (
    <div className="container">
      <PageHeader title="옵션 만들기"/>
      <div
        className="w-9 m-8"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <div style={{ marginTop: "20px", display: "flex", textAlign: "left", width: "100%", border: "1px solid #5B9BD5", height: "35px", alignItems: "center" }} onClick={() => { navigate("/mypage/makingOptionDetail", { state: { data: data.masterOption } }) }}>
          <span style={{ marginLeft: "10px" }}>{optionName}</span>
          <img src={img_modify} style={{ marginLeft: "auto", width: "30px", marginRight: "5px" }} />
        </div>
        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>스팟</span>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="spots">
              {(provided) => (
                <div className="spots" ref={provided.innerRef} {...provided.droppableProps}>
                  {masterOptionSpot && masterOptionSpot.map((spot, index) => {
                    let ordNo = spot.ordNo;
                    let spotImageUrl = "";
                    if (comSpots[index] != null && comSpots[index] != undefined) {
                      if (comSpots[index].comImages != null && comSpots[index].comImages != undefined) {
                        if (comSpots[index].comImages.savenameNm.includes("https://")) {
                          spotImageUrl = comSpots[index].comImages.savenameNm;
                        } else {
                          spotImageUrl = img_url + "/" + comSpots[index].comImages.typeCd + "/" + comSpots[index].comImages.savenameNm;
                        }
                      }

                    }


                    return (
                      <Draggable key={index.toString()} draggableId={index.toString()} index={index}>

                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div>
                              <div style={{ marginTop: "10px", display: "flex" }} onClick={() => { navigate("/mypage/updateOptionSpot", { state: { data: data, no: index } }) }}>
                                <div style={{ width: "120px", height: "120px", display: "flex", justifyContent: "center", alignItems: "center", border: "2px solid" }}>
                                  <img src={spotImageUrl} style={{ width: "100%", height: "100%" }} />
                                </div>
                                <div style={{ border: "2px solid", borderLeft: "none", flex: "1", display: "flex", flexDirection: "column" }}>
                                  <span style={{ margin: "5px", fontWeight: "bold" }}>{spot[title]}</span>
                                  <span style={{ margin: "5px", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "4", WebkitBoxOrient: "vertical" }}>{spot[desc]}</span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className={"div_" + index} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                <Switch {...label} checked={spotActivation[index] === true} onChange={() => handleSpotSwitchChange(index)} />
                                <span>Set Time</span>
                                <select
                                  style={{ marginLeft: "auto", marginRight: "10px", height: "30px" }}
                                  value={spot.timehhNm} // 이 부분을 수정
                                  onChange={(e) => handleSelectTimeChange(e, index)}
                                  disabled={!spotActivation[index]} // 스위치가 꺼져있을 때 비활성화
                                >
                                  {hours.map(el => (
                                    <option key={el} value={el}>
                                      {el}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  style={{ marginRight: "20px", height: "30px" }}
                                  value={spot.timemmNm} // 이 부분을 수정
                                  onChange={(e) => handleSelectMinuteChange(e, index)}
                                  disabled={!spotActivation[index]} // 스위치가 꺼져있을 때 비활성화
                                >
                                  {minutes.map(el => (
                                    <option key={el} value={el}>
                                      {el}
                                    </option>
                                  ))}
                                </select>
                                <div>
                                  <button onClick={() => handleRemove(index)}>X</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>



          <div style={{ marginTop: "10px", display: "flex" }} onClick={() => { navigate("/mypage/selectOptionSpot", { state: data.masterOption }) }}>
            <div style={{ width: "120px", height: "120px", display: "flex", justifyContent: "center", alignItems: "center", border: "2px solid" }}>
              <img src={img_camera} style={{ width: "80px", height: "80px" }} />
            </div>
            <div style={{ border: "2px solid", borderLeft: "none", flex: "1" }}>
              <span style={{ color: "#5B9BD5", margin: "10px", display: "inline-block" }}>스팟을 선택하세요</span>
            </div>
          </div>



        </div>

        <div style={{ width: "100%", marginTop: "20px" }}>
          <LanguageButton
            type="button"
            value="한국어"
            active={languageComment === "한국어" || languageComment === "ko"}
            onClick={() => handleButtonClick("한국어")}
          />
          <LanguageButton
            type="button"
            value="English"
            style={{ marginLeft: "10px" }}
            active={languageComment === "English"}
            onClick={() => handleButtonClick("English")}
          />
          <LanguageButton
            type="button"
            value="中文繁體"
            style={{ marginLeft: "10px" }}
            active={languageComment === "中文繁體"}
            onClick={() => handleButtonClick("中文繁體")}
          />
          <LanguageButton
            type="button"
            value="中文簡體"
            style={{ marginLeft: "10px" }}
            active={languageComment === "中文簡體"}
            onClick={() => handleButtonClick("中文簡體")}
          />
        </div>

        <div style={{ width: "100%", marginTop: "20px", display: "flex" }}>
          <textarea style={{ width: "100%", height: "100px", padding: "10px" }} value={comment} placeholder="상품 코멘트 하기" onChange={handleChange}></textarea>
        </div>


        <div style={{ display: "flex", alignItems: "center", marginTop: "30px", width: "100%" }} onClick={() => { navigate(`/mypage/makingPlan/addOptionPhoto`, { state: data }) }}>
          <img src={img_add} style={{ width: "25px", height: "25px" }}></img>
          <span>사진 추가({imageSize})</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: "30px", width: "100%" }} onClick={() => { navigate(`/mypage/makingOption/price`, { state: data.masterOption }) }}>
          <img src={img_add} style={{ width: "25px", height: "25px" }}></img>
          <span>요금 입력</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: "30px", width: "100%", paddingBottom: "110px" }} onClick={() => { navigate(`/mypage/makingOption/optionComment`, { state: data.masterOption }) }}>
          <img src={img_add} style={{ width: "25px", height: "25px" }}></img>
          <span>상품 코멘트 하기</span>
        </div>

        <div className="container" style={{ position: "fixed", bottom: 0, padding: "0 10px", justifyContent: "center", width: "100%", height: "100px", maxWidth: "540px", backgroundColor: "#ffffff", borderRadius: "15px 15px 0 0", boxShadow: "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px -2px 7px rgba(0, 0, 0, 0.2)", }}>
          <div className="w" style={{ display: "flex", justifyContent: "center" }}></div>
          <input type="button" onClick={onSave} className={active ? 'save_active' : 'save_notActive'} disabled={!active} value="미리보기" />
        </div>

      </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 10px;

  width: 40%;
`;

const LanguageButton = styled.input`
  padding: 3px 10px;
  color: ${props => (props.active ? "#FFF" : "#000")};
  background: ${props => (props.active ? "#5B9BD5" : "#FFF")};
`

styled.active = `background : #000`;
export default MyPlanPage;
