import axios from "axios";
import { server_url } from "./server";

export const request = async (method, url, data) => {
  const reqHeader = {
    accessToken: sessionStorage.getItem("token"),
  };
  document.body.style.cursor = "wait";

  return axios({
    method,
    url: server_url + url,
    data,
    headers: reqHeader,
  })
    .then((res) => {
      document.body.style.cursor = "default";
      return res.data;
    })
    .catch((err) => console.log(err));
};
