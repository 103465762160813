import React from "react";

import { Date } from "../../../styles/GlobalStyle";

import calender from "../../../images/icons/calender.png";

function Datebar(props) {
  return (
    <div className="container">
      <div
        style={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          boxShadow:
            "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px 2px 7px rgba(0, 0, 0, 0.1)",
          borderRadius: "100px",

          justifyContent: "space-between",
        }}
      >
        <img
          src={calender}
          alt=""
          width="24px"
          style={{ margin: "1rem 1.5rem" }}
        />
        <div style={{ paddingRight: "3rem" }}>
          <Date>{props.departure}</Date>
        </div>
      </div>
    </div>
  );
}

export default Datebar;
