import React from "react";
import { useEffect } from "react";
import Modal from "react-modal";

function MyModal(props) {
  Modal.setAppElement("#root");

  useEffect(() => {
    props.isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [props.isOpen]);
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {
        props.setIsOpen(false);
      }}
      style={customStyles}
    >
      {props.element}
    </Modal>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",

    width: "85%",
    maxWidth: "592px",

    maxHeight: "80%",
  },
};

export default MyModal;
