import React from "react";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import { CircularButton } from "../../styles/GlobalStyle";
import FAQ from "../../components/FAQ";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function CustomerService() {
    // 다국어 관련 추가 (2023-06-21)
    const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <PageHeader title={t("CS.DESC")} />

      <div className="m-8 w-8">
        <div className="m-2">
          <div className="text-l text-bold">{t("CS.title01")}</div>
          <div className="m-1 text-sm">
          {t("CS.subTitle01")}
          </div>
        </div>
        <div className="m-2">
          <div className="text-l text-bold">{t("CS.title02")}</div>
          <div className="m-1 text-sm">
          {t("CS.subTitle02")}
          </div>
        </div>
        <div className="m-2">
          <div className="text-l text-bold">{t("CS.title03")}</div>
          <div className="m-1 text-sm">{t("CS.subTitle03")}</div>
        </div>

        <CircularButton className="m-4" backgroundcolor="#5c80ff" onClick={() => {
            window.open("https://gomyplan.channel.io/lounge");
          }}>
        {t("CS.plan")}
        </CircularButton>

        <FAQ />
      </div>
    </div>
  );
}

export default CustomerService;
