import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

import { server_url } from "../../server";

import homeMenu from "../../images/icons/homeMenu.png";
import PageHeader from "../../components/views/Layout/Header/PageHeader";
import backArrow from "../../images/icons/backArrow.png";

/* Img */
import img_addPhoto from "../../images/icons/file_add.svg"
import img_default from "../../images/img/img_default.png"

/* AWS */
import AWS from "aws-sdk";


function MyPlanPage() {
  const location = useLocation();
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const [email] = useState(window.sessionStorage.getItem("email"));

  const [cityList, setCityList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [numberOfPeople] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [hours] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]);
  const [days, setDays] = useState([{ "ko": "월요일", "en": "|Mon" }, { "ko": "화요일", "en": "|Tue" }, { "ko": "수요일", "en": "|Wed" }, { "ko": "목요일", "en": "|Thu" }, { "ko": "금요일", "en": "|Fri" }, { "ko": "토요일", "en": "|Sat" }, { "ko": "일요일", "en": "|Sun" }]);
  const [saveStr, setSaveStr] = useState("저장");


  const [imageFile, setImageFile] = useState();
  const [imageFileName, setImageFileName] = useState();
  const [imgaeSrc, setImageSrc] = useState(img_default);

  //회사
  const [companySq, setCompanySq] = useState(window.sessionStorage.getItem("companyName"));

  //도시
  const [city, setCity] = useState('');
  //옵션 ID
  const [optionSq, setOptionSq] = useState(0);
  //옵션 이름
  const [optionName, setOptionName] = useState('');
  //옵션 대표 사진
  const [imgSaveName, setImgSaveName] = useState('');
  //옵션 설명
  //const [optionDesc, setOptionDesc] = useState('');
  //옵션 카테고리
  const [optionCategory, setOptionCategory] = useState('');
  //최소 인원수
  const [people, setPeople] = useState(1);
  //소요시간
  const [hour, setHour] = useState('');
  //운영일자
  const [day, setDay] = useState([]);
  //공개여부
  const [publicYn, setPublicYn] = useState(false);
  //사용여부
  const [useYn, setUseYn] = useState(true);

  const [active, setActive] = useState(false);

  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) {
      return str;
    }

    const firstLetter = str.charAt(0).toUpperCase();
    const remainingLetters = str.slice(1);

    return firstLetter + remainingLetters;
  };


  /* Random */
  const generateRandomNumber = (digits) => {
    let randomNumber = '';

    for (let i = 0; i < digits; i++) {
      randomNumber += Math.floor(Math.random() * 10); // 0부터 9까지의 랜덤한 수
    }

    return randomNumber;
  };

  /* 12글자까지 자르기 설정 */
  const truncateString = (str) => {
    const index = str.indexOf("@");
    if (index !== -1 && index <= 12) {
      return str.slice(0, index);
    } else if (str.length > 12) {
      return str.slice(0, 12);
    } else {
      return str;
    }
  }



  useEffect(() => {
    console.log("언어:" + language);
    console.log(location);

    if (location.state != null) {
      //저장 => 수정
      setSaveStr("수정");
      //값 세팅
      let item = location.state.data;
      let title = "title" + capitalizeFirstLetter(language) + "Nm";
      let desc = "desc" + capitalizeFirstLetter(language) + "Nm";
      let publicYnStr = item.publicYn;
      let useYnStr = item.useYn;
      console.log(item);
      setOptionSq(item.optionSq);
      setCity(item.cityCd);
      setOptionName(item[title]);
      setImageSrc(item.savenameNm);
      setImgSaveName(item.savenameNm);
      //setOptionDesc(item[desc]);
      setOptionCategory(item.categoryCd);
      setPeople(item.minpaxNo);
      setHour(item.durationNo);
      let dayStr = item.dayNm;
      console.log(dayStr);
      if (dayStr != null && dayStr.includes('|')) {
        let dayList = dayStr.split("|");
        console.log("0823");
        console.log(dayStr.split("|"));
        let selectedDays = dayList.filter(day => day !== "").map(day => "|" + day);
        setDay(selectedDays);
      }
      if (publicYnStr != null & publicYnStr != "") {
        if (publicYnStr == 'Y') {
          setPublicYn(true);
        } else {
          setPublicYn(false);
        }
      }
      if(useYnStr != null & useYnStr != ""){
        if(useYnStr == 'Y'){
          setUseYn(true);
        } else{
          setUseYn(false);
        }
      }


    }

    const getCityList = async () => {
      try {
        const res = await axios.get(`${server_url}/ord/cities/code/${language}`);
        console.log(res);
        setCityList(res.data);
      } catch (e) { }
    };

    const getCategoryList = async () => {
      try {
        const res = await axios.get(`${server_url}/ord/plan/categoryList/OPCTG`);
        console.log(res);
        setCategoryList(res.data);
      } catch (e) { }
    }

    getCityList();
    getCategoryList();
  }, []);

  /* Select,Input */
  const handleChange = (event) => {
    let id = event.target.id;
    let value = event.target.value;
    console.log("ssss");
    console.log(value);
    switch (id) {
      case 'sl_city':
        setCity(value);
        break;
      case 'ip_optionName':
        setOptionName(value);
        break;
      /*case 'txt_desc':
        setOptionDesc(value)
        break;*/
      case 'sl_optionCategory':
        value = value.replaceAll("'", "`");
        setOptionCategory(value);
        break;
      case 'sl_optionPeople':
        setPeople(value);
        break;
      case 'sl_hour':
        setHour(value);
        break;
    }
  }

  /* CheckBox */
  const handleCheckboxChange = (value) => {
    if (day.includes(value)) {
      // 값이 이미 배열에 있는 경우 제거
      setDay(day.filter((item) => item !== value));
    } else {
      // 값이 배열에 없으면 값을 추가
      setDay([...day, value]);
    }
  };
  /* Radio */
  const handleRadioChange = (event) => {
    switch (event.target.id) {
      case "rd_publicY":
        setPublicYn(true); // 공개 선택 시 true로 업데이트
        break;
      case "rd_publicN":
        setPublicYn(false); // 비공개 선택 시 false로 업데이트
        break;
      case "rd_useY":
        setUseYn(true); // 사용 선택 시 true로 업데이트
        break;
      case "rd_useN":
        setUseYn(false); // 비사용 선택 시 false로 업데이트
        break;
    }

  };

  useEffect(() => {
    if (city != "" && optionName != "" && optionCategory != "") {
      setActive(true);
    } else {
      setActive(false);
    }
    console.log("active:" + active);
  }, [city, optionName, optionCategory, active]);

  /* Iamge 저장 설정 */
  const onUploadImage = (e) => {
    setImageFile(e.target.files[0]);
    setImageFileName(`${city}_` + generateRandomNumber(10));
  }

  useEffect(() => {
    if (imageFile && imageFileName) {
      const formData = new FormData();
      formData.append('file', imageFile);
      formData.append('name', imageFileName);
      uploadS3(formData);
    }
  }, [imageFile, imageFileName]);

  /* S3 저장 */
  const uploadS3 = (formData) => {
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_S3,
      secretAccessKey: process.env.REACT_APP_Secret_KEY_S3
    });

    const upload = new AWS.S3.ManagedUpload({
      params: {
        ACL: 'public-read',
        Bucket: 'mygmp',
        Key: `master/UserUpld/${email}/${imageFileName}.jpg`,
        Body: imageFile
      }
    });

    upload.promise().then(function (data) {
      setImageSrc(data.Location);
      setImgSaveName(data.Location);
      if (!data) {
        console.log("이미지 업로드 완료!!");
      }
    });
  }



  console.log(categoryList)
  /* 옵션 저장 */
  const onSave = () => {
    let optionCd = "OPT_" + city + "_" + truncateString(email) + "_" + generateRandomNumber(5);
    let sendPublicYn = "";
    let sendUseYn = "";
    //Enum 변환
    if (publicYn) {
      sendPublicYn = 'Y'
    } else {
      sendPublicYn = 'N'
    }
    if(useYn){
      sendUseYn = 'Y'
    } else{
      sendUseYn = 'N'
    }

    let body = {

      optionSq: optionSq,
      optionCd: optionCd,
      cityCd: city,
      uploadUser: email,
      savenameNm: imgSaveName,
      titleEnNm: optionName,
      titleKoNm: optionName,
      titleZhchsNm: optionName,
      titleZhchtNm: optionName,
      categoryCd: optionCategory,
      minpaxNo: people,
      dayNm: day.join(""),
      durationNo: hour,
      companySq: companySq,
      publicYn: sendPublicYn,
      useYn: sendUseYn
    }
    console.log(body);
    const res = axios.post(`${server_url}/ord/option/save`, body);
    res.then((response) => {
      console.log(response);
      if (response.data.result == "success") {
        alert("옵션 등록이 완료되었습니다.");
        navigate("/mypage/makingOptionSpot", { state: { data: response.data.data.optionSq }, replace: true });
      }
    });
  }
  console.log(day);
  console.log(days);

  return (
    <div className="container">
      <PageHeader title="옵션 만들기" />
      <div
        className="w-9 m-8"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <div style={{
          marginTop: "20px", display: "flex",
          flexDirection: "column", textAlign: "left", width: "100%"
        }}>
          <span style={{ fontWeight: "bold" }}>도시</span>
          <select id="sl_city" style={{ marginTop: "10px", height: "30px", outline: "none", paddingLeft: "10px" }} onChange={handleChange} value={city}>
            <option>도시를 선택해주세요.</option>
            {cityList.map((el) => {
              return <option key={el.code} value={el.code}>{el.name}</option>;
            })}
          </select>
        </div>

        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>옵션 이름</span>
          <input id="ip_optionName" type="text" style={{ marginTop: "10px", height: "24px", outline: "none", paddingLeft: "10px" }} placeholder="옵션 이름을 입력해주세요." onChange={handleChange} value={optionName} />
        </div>
        <div style={{
          marginTop: "20px", display: "flex",
          flexDirection: "column", textAlign: "left", width: "100%"
        }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontWeight: "bold", fontSize: "19px" }}>옵션 이미지</span>
            <label htmlFor="file" style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}><img src={img_addPhoto} style={{ width: "25px", height: "25px" }}></img></label>
            <input type="file" id="file" style={{ display: "none" }} onChange={e => onUploadImage(e)} accept="image/*" />
          </div>
          <div style={{ display: "flex", alignItems: "center", border: "1px solid", justifyContent: "center", height: "250px", marginTop: "10px" }}>
            <img src={imgaeSrc} style={{ maxWidth: "100%", maxHeight: "100%" }}></img>
          </div>
        </div>
        {/*<div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>옵션 설명</span>
          <textarea id="txt_desc" onChange={handleChange} value={optionDesc}></textarea>
          </div>*/}
        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>옵션 카테고리</span>
          <select id="sl_optionCategory" style={{ marginTop: "10px", height: "30px", outline: "none", paddingLeft: "10px" }} onChange={handleChange} value={optionCategory}>
            <option>카테고리를 선택해주세요.</option>
            {categoryList.map((el) => {
              let codeName = '';
              switch (language) {
                case 'ko':
                  codeName = el.codeKoNm;
                  break;
                case 'en':
                  codeName = el.codeEnNm;
                  break;
                case 'zhchs':
                  codeName = el.codeZhchsNm;
                  break;
                case 'zhcht':
                  codeName = el.codeZhchtNm;
                  break;
              }
              return <option key={el.codeId} value={el.codeId}>{codeName}</option>;
            })}
          </select>
        </div>

        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>최소 인원(선택)</span>
          <select id="sl_optionPeople" style={{ marginTop: "10px", height: "30px", outline: "none", paddingLeft: "10px" }} onChange={handleChange} value={people}>
            {numberOfPeople.map((el) => {
              let minPeople = el;
              if (el == 1) {
                minPeople = "없음(" + el + "명)"
              } else {
                minPeople = el + "명";
              }
              return <option key={el} value={el}>{minPeople}</option>;
            })}
          </select>
        </div>

        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>소요시간(선택)</span>
          <select id="sl_hour" style={{ marginTop: "10px", height: "30px", outline: "none", paddingLeft: "10px" }} onChange={handleChange} value={hour}>
            <option value="">없음</option>
            {hours.map((el) => {
              let hour = el + "hr(s)";
              return <option key={el} value={el}>{hour}</option>;
            })}
          </select>
        </div>

        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>운영요일</span>
          <div style={{ marginTop: "10px" }}>
            {days.map((el) => {
              let id = "cb_" + el.en + "day";
              return <label htmlFor={id} style={{ marginRight: "10px" }}><input id={id} type="checkbox" value={el.en} checked={day.includes(el.en)} onChange={() => handleCheckboxChange(el.en)} /><span>{el.ko}</span></label>;
            })}
          </div>
        </div>

        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>공개여부</span>
          <div style={{ marginTop: "10px" }}>
            <label htmlFor="rd_publicY" style={{ marginRight: "10px" }}><input type="radio" id="rd_publicY" value={true} checked={publicYn === true} onChange={handleRadioChange} /><span>공개</span></label>
            <label htmlFor="rd_publicN"><input type="radio" id="rd_publicN" value={false} checked={publicYn === false} onChange={handleRadioChange} /><span>비공개</span></label>
          </div>
        </div>

        <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", textAlign: "left", width: "100%" }}>
          <span style={{ fontWeight: "bold" }}>사용여부</span>
          <div style={{ marginTop: "10px" }}>
            <label htmlFor="rd_useY" style={{ marginRight: "10px" }}><input type="radio" id="rd_useY" value={true} checked={useYn === true} onChange={handleRadioChange} /><span>사용중</span></label>
            <label htmlFor="rd_useN"><input type="radio" id="rd_useN" value={false} checked={useYn === false} onChange={handleRadioChange} /><span>비사용</span></label>
          </div>
        </div>

        <div style={{ width: "100%", marginTop: "30px", marginBottom:"30px" }}>
          <input type="button" onClick={onSave} className={active ? 'save_active' : 'save_notActive'} disabled={!active} value={saveStr} />
        </div>
      </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 10px;

  width: 40%;
`;

styled.active = `background : #000`;
export default MyPlanPage;
