import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Title } from "../../styles/GlobalStyle";
import { img_url, server_url } from "../../server";
import Slider from "react-slick";
import trumpet from "../../images/icons/trumpet.png";
import heart from "../../images/icons/heart.png";
import messageBox from "../../images/icons/messageBox.png";
import Datebar from "../views/Calender/Datebar";
import MySheet from "../Sheet/MySheet";
import GmpCalender from "../views/Calender/GmpCalender";
import { getDefaultDay, getTodayDate, parseDatewithDash } from "../../js/getDate";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import filledheart from "../../images/icons/filledheart.png";
import axios from "axios";

import img_default from "../../images/img/img_default.png"

function PlanDetailPageTopImage(props) {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [departure, setDeparture] = useState(
        parseDatewithDash(getDefaultDay())
    );
    const [isWished, setIsWished] = React.useState(false);

    const [likeCount, setLikeCount] = useState(props.data.likeCount);

    const setting = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };







    React.useEffect(() => {
        const getWishList = async () => {
            let body = {
                fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
                today: getTodayDate(),
                email: window.sessionStorage.getItem("email"),
                userLang: "ko"
            };

            await axios.post(`${server_url}/wishlist/ko`, body).then((v) => {
                //console.log(v.data);
                v.data.wishDtoList.map((el) => {
                    if (el.planCd === props.data.planCd) {
                        /* 위시리스트가 1개라도 있으면 표기 */
                        //console.log("위시리스트 있음")
                        setIsWished(true);
                        //Delete Wishlist를 위한 wish_sq 세팅
                        window.sessionStorage.setItem("wishSq", el.wishSq);
                    } else {
                        //console.log("위시리스트 없음")
                        //setIsWished(false);
                    }
                }
                );
            });
        };




        getWishList();
    }, []);

    React.useEffect(() => {
        let filledheartUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAXCAYAAAD+4+QTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALMSURBVHgBtZVfSFNxFMfPvXe7ls55h7oUJa6hboLS8MEJEUzqIQkqeskegoIeghLzTeih+RI+JvjQQ5B7yl5s9tQfzElCGYiXSh1ztSWOdI3truYc5rbO79Idt3XnZm5f2C6/3+/8zuf8zj3ndylQkdls5skzkUiIfr9fhDyS7d1ut19tnVIOTCbTQDqdvl1ZruH1Oi2sb8bJtAt/jtXV1XGlLc/zHMuykr2+QsvJ9hRFOWmaHlQCKXmDVqt9au2osfX3maGroybjbHJ6DcYm3BAIbts9Hs+wHHkqlZrpaq/mlfaBYBxevfsGY4/d8DO+m7GXIC0tLY8unjp6dWSgU+200uYrd+YkEEbpIIBbfSa+/7I5p/35gRmIxnZ6vF6vi8KobPXVZTPO0R7AY0MuySBMiYjOuVwAWfMfQ8Tej2luYgwGw13cYLEqUqQmEsDp7nryPJQPQNR4pBwmX69xGrZylsaxpa2pCgpRg7EcCgHIsrbXAMMwFhqrgWtAailEgsLq42j8E/+UatG14ouSkhZJumbffwpBKUQgGo3GRWPO7juefYb1YHFPM44+A5txYWlpSWBCoZBYoauipuc3bNgrUMYycFCtfInCzXvzIh6gh/iXPIbDYRfD6gxzwvfusycbDwQiANJPiZ1kL3a8QOYy3iKRyPMUXdH0ZjFo+V+QDPix9esadrpTnv/LE57ISUC+QEwC7UfknV4ffguHyzSDC4vLD5Rr/4RLQOI2a8NrhCcdXihAunI2toYXhOWR7HXVnOj1+inP19gZBNXlAykBmCK7mo0qRBTFBIKeEBAO63Lda4UAckJkkNFofIG36QUcctkgGbCbTI4KH9xDsIf2LCFS47W1tVPZIBnA6VjHS5dwA/Iob51mg9qOVUlV1GmuFh5OzPVCMUU+ua2trT7biePpS+esi2mfnYNSiICam5vt6ZUhfj/7fgPe614cdXzBJQAAAABJRU5ErkJggg==";
        let heartUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAXCAYAAAAV1F8QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHbSURBVHgBtZXLbcJAEIZ3zevqdOCIpzgtFcR0kFQQu4LQQaCCpAPogBJwByQXhAQS7gAfEQjIP9GstBhwsGN+ydqHZ/3NzM6upbigVqvl7Pd7l/qFQiGcz+eBSFC73Va73U5JKaNisRjOZrOvuI00B/V6XaH5wEOLApo7Ho+KXw8Wi8Uo5pALh8jeIXvY2tTndf5yuQzOvKpWq88ArWq1Wi/+DnMuvWs0Gu96jvqYW9O6uD3mPbJvNpu9k4g4VVN0u/D6LGzDZgLPR5Siw+HQQ7q6IZRgP0VkLxSZZA9GFC0gvkiQhqFrA9K5BjGi7gP0hO92NWh9y0IDZl+L3JTjOHapVFrh249Sh4iFD+IOQhBTwHyLx5G4n6Ltdmtbm82GIA6FKe4jVS6XIwvbQqCgUql4ImfROUMT0QH+TR0qY4Byfcs7Kuz9EM3gZBKHsk8blxcM3xqivMd6rItB4FD10XyjQib/hREETQdF4J+BSChxj2FjkVGA0N3Xwdlxef8TjSfsVSrx/be6lBHr0gJ484JGpYERBEXl8f13+7kkr6g4OBV/QjgSR2QRw05+D7lDtOguvAbLDZIEQ/81DUSKG2X++DAMsfGfIuFHmRlkwtC100AyidOo0q77Aa7xBkKDm1tHAAAAAElFTkSuQmCC";
        document.getElementById("img_wished1").src = isWished ? filledheart : heartUrl;

    }, [isWished]);


    async function addWishList() {
        let body = {
            countryCd: "",
            cityCd: props.data.cityCd,
            dateDt: getTodayDate(),
            planCd: props.data.planCd,
            email: window.sessionStorage.getItem("email"),
        };
        //console.log(body);
        if (window.sessionStorage.getItem("email") === null) {
            alert("로그인 후 이용해 주세요!");
            return false;
        }

        await axios
            .post(`${server_url}/wishlist/add`, body)
            .then((v) => window.sessionStorage.setItem("wishSq", v.data.memWishlist.wishSq));

        setIsWished(true);
        setLikeCount(likeCount + 1)

    }

    async function deleteWishList() {
        let wishSq = sessionStorage.getItem("wishSq")
        let body = {
            wishSq: wishSq
        }

        // await axios.delete(`${server_url}/wishlist/delete`);

        // setIsWished(false);

        try {
            const res = await axios.delete(`${server_url}/wishlist/delete/${wishSq}`, body);
            //console.log(res);
            if (res.data != null) {
                alert("삭제가 완료되었습니다.")
                setIsWished(false);
                setLikeCount(likeCount - 1)
            }
        } catch (e) { }
    }
    let minpax = 0;
    if (props.minpax != '-Infinity') {
        minpax = props.minpax;
    }
    return (
        <div className="w">
            <div>
                <Slider {...setting}>
                    {props.data.planImages.length == 0 && <div><div
                        style={{
                            margin: "0px auto",
                            borderRadius: "10px",
                            height: "45vh",
                            maxHeight: "45vh",
                            objectFit: "cover",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            border: "1px solid"
                        }}
                    ><img src={img_default} style={{ width: "100%", maxHeight: "100%", borderRadius: "10px" }}></img>
                    </div></div>}
                    {props.data.planImages.map((imgList) => {
                        let imageUrl = img_default;
                        if (imgList.savenameNm.includes("https://")) {
                            imageUrl = imgList.savenameNm;
                        } else {
                            imageUrl = img_url + "/" + imgList.typeCd + "/" + imgList.savenameNm;
                        }
                        return (
                            <div key={imgList.savenameNm} style={{ width: "100%", height: "130%" }} >
                                <div
                                    style={{
                                        margin: "0px auto",
                                        borderRadius: "10px",
                                        height: "45vh",
                                        maxHeight: "45vh",
                                        objectFit: "cover",
                                        backgroundImage: `url(${imageUrl})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat",
                                    }}
                                >
                                </div>
                            </div>
                        );
                    })}
                </Slider>
                <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                    <button style={{ background: "#FFC000", border: "1px solid", height: "30px" }} onClick={() => { props.navigate(`/mypage/makingPlanSlot`, { state: props.data }) }}>계속 편집하기</button>
                    <button style={{ background: "#5B9BD5", border: "1px solid", height: "30px", marginTop: "10px" }} onClick={() => window.open("https://gomyplan.info/creatoryourplan")}>플랜 공개 신청</button>
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        position: "absolute",
                        // bottom: "5%",
                        top: "46%",
                        paddingLeft: "10px",
                        // right: "22%",
                        gap: "5px",
                    }}
                >
                    {props.data.planfitDesc.map((el) => {
                        return <PlanFits key={el}>{el}</PlanFits>;
                    })}
                </div>
                <div>
                    <div
                        style={{
                            position: "absolute",
                            top: "9%",
                            right: "1px",
                            background: "white",
                            marginRight: "18%",
                            borderRadius: "100px",
                            fontSize: "10px",
                            padding: "5px",
                            textAlign: "center",
                        }}
                    >
                        {props.data.cityNm}
                    </div>
                </div>
            </div>


            <div className="m-2">
                <Title>{props.title}</Title>
                <div
                    // className="text-sm"
                    style={{
                        marginLeft: "25px",
                        color: "#454545",
                        fontWeight: "400",
                        fontSize: "14px",
                    }}
                >
                    {/*<p>좋아요 : {props.data.likeCount} / 댓글 : {props.data.commentCount}</p>*/}
                    {props.data.hotspotDesc.join(", ")}
                </div>

                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            background: `url(${trumpet})`,
                            marginLeft: "25px",
                            marginTop: "3%",
                            color: "#5c80ff",
                            fontWeight: "400",
                            fontSize: "14px",
                            width: "30px",
                            height: "30px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                    >
                    </div>
                    <div
                        style={{
                            marginTop: "4%",
                            marginLeft: "2%",
                            color: "#5c80ff",
                        }}>
                        {minpax}{t('PLANDETAIL.DETAIL04')}
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", marginTop: "1%" }} >
                {/* 플랜별 좋아요 영역 */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "13%",
                        height: "30px",
                        border: "1px solid",
                        borderRadius: "100px",
                        margin: "auto",
                        marginTop: "2%",
                        marginLeft: "35%",
                        backgroundColor: "#adadad",
                        minWidth: "75px"
                    }}
                >
                    <img
                        style={{
                            float: "left",
                            marginRight: "5%",
                            marginLeft: "16%",
                            marginTop: "3%",
                            width: "18px",
                            height: "16px"
                        }}
                        className={"test"}
                        id={"img_wished"}
                        src={isWished ? filledheart : heart}
                        alt=""
                        onClick={isWished ? deleteWishList : addWishList}
                    />
                    <div style={{
                        marginLeft: "auto",
                        marginRight: "20px",
                        color: "white",
                    }}
                    >
                        {likeCount}
                    </div>
                </div>

                {/* 댓글 개수 영역 */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "13%",
                        height: "30px",
                        border: "1px solid",
                        borderRadius: "100px",
                        margin: "auto",
                        marginRight: "35%",
                        marginTop: "2%",
                        backgroundColor: "#adadad",
                        minWidth: "75px"
                    }}
                    onClick={() => { goReview() }}
                >
                    <img
                        style={{
                            float: "left",
                            marginRight: "5%",
                            marginLeft: "16%",
                            marginTop: "3%",
                            width: "18px",
                            height: "16px"
                        }}
                        src={messageBox}
                        alt="" />
                    <div style={{
                        marginLeft: "auto",
                        marginRight: "20px",
                        color: "white",
                    }}
                    >
                        {props.data.commentCount}
                    </div>
                </div>
            </div>

            <div style={{ marginTop: "2%" }}
                onClick={() => {
                    setIsOpen(true);
                }}
            >
                {/* <Datebar Departure={props.Departure} Arrival={props.Arrival} /> */}
                <Datebar departure={departure} />
            </div>
            <MySheet
                isOpen={isOpen}
                setOpen={setIsOpen}
                children={
                    <GmpCalender
                        isOpen={isOpen}
                        setOpen={setIsOpen}
                        setDeparture={setDeparture}
                        departure={departure}
                    />
                }
            />

            <div
                style={{
                    border: "1px solid #d2d2d2",
                    marginTop: "5%",
                }}
            ></div>
        </div>
    );
}

const PlanFits = styled.div`
  text-align: center;
  color: #252525;
  font-size: 10px;

  padding: 5px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 100px;
`;

function goReview() {
    document.getElementById("input_review").focus();
    document.getElementById("input_review").blur();
}

export default PlanDetailPageTopImage;
