import React from "react";
import pointbox from "../../images/icons/pointbox.png";

function MyList(props) {
  return (
    <ul style={{paddingLeft:"7%"}}>
      {props.list &&
        props.list.map((el, idx) => {
            return (
                <li
                    className="text-xsm"
                    key={el.code}
                    style={{
                      marginBottom: "5px",
                      marginRight: "5px",
                      wordWrap: "anywhere",
                      maxWith: "92%"
                    }}
                >
                  {langTitle(el)}
                </li>
            );
          
        })}
    </ul>
  );
}

// 공급자 설명 언어별추가 (2023-03-23)
function langTitle(data) {
  let langTitle;
  switch (localStorage.getItem("language")) {
    case "ko" :
      langTitle = data.title_ko
      break;
    case "en" :
      langTitle = data.title_en
      break;
    case "zhchs" :
      langTitle = data.title_zhchs
      break;
    case "zhcht" :
      langTitle = data.title_zhcht
      break;
    default : langTitle = data.title_ko
  }
  return langTitle;
}

export default MyList;
