import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App_v0.0.2";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  //</React.StrictMode>
);
