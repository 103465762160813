import axios from "axios";
import { server_url } from "../../server";

export default function findAdditionalInfo(list) {
  let codeKoNmList = [];
  const bookingAttachmentYnList = [];
  list?.map(
    async (v) =>
      await getAdditionalInfo(v).then((c) => {
        if (c.codeKoNm == "None") codeKoNmList = [...codeKoNmList, c.codeKoNm];
        if (c.bookingAttachmentYn !== "N")
          bookingAttachmentYnList.push(c.bookingAttachmentYn);
      })
  );

  const body = {
    codeKoNmList: codeKoNmList,
    bookingAttachmentYnList: bookingAttachmentYnList,
  };

  return body;
}

async function getAdditionalInfo(optionSq) {
  try {
    const res = await axios.get(`${server_url}/ord/additional/${optionSq}/ko`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
}
