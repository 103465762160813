import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import PageHeader from "../../components/views/Layout/Header/PageHeader";

import kakaotalk_2 from "../../images/logo/kakaotalk_2.png";
import naver from "../../images/logo/naver.png";
import apple from "../../images/logo/apple.png";
import email_img from "../../images/logo/email.png";

import clearButton from "../../images/icons/clearButton.png";

import { CircularButton, CircularInput } from "../../styles/GlobalStyle";
import axios from "axios";
import { server_url } from "../../server";

function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async (email, password) => {
    let body = { email: email, password: password };

    try {
      const res = await axios.post(`${server_url}/auth/login`, body);

      window.sessionStorage.setItem("token", res.data.data.token);
      window.sessionStorage.setItem("companyName", res.data.data.companyName);

      return res;
    } catch (e) {
      console.log(e);
      alert("아이디 또는 비밀번호를 확인해 주세요.");
      return e.response.status;
    }
  };

  const openChannel = ()=>{
    alert("고객센터를 통해 문의 부탁 드립니다.");
    window.open("https://gomyplan.channel.io/home");
  }

  return (
    <div className="container">
      <PageHeader title="로그인" />
      {/* 이메일 비밀번호 입력 칸 */}
      <div className="m-10 w-9">
        <div>
          <label className="text-m">이메일</label>
          <InputBox className="m-1">
            <CircularInput
              className="w-8"
              type="email"
              onChange={(e) => setEmail(e.currentTarget.value)}
              value={email}
            />
            {email && (
              <img
                src={clearButton}
                alt=""
                onClick={() => {
                  setEmail("");
                }}
                style={{ position: "absolute", bottom: "15px", right: "5%" }}
              />
            )}
          </InputBox>
        </div>
        <div className="m-2">
          <label className="text-m">비밀번호</label>
          <InputBox className="m-1">
            <CircularInput
              className="w-8"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  login(email, password).then((res) => {
                    if (res.data.code === 1) {
                      window.sessionStorage.setItem("email", email);

                        // 토큰값 sessionStorage 및 headers(새로고침시 사라짐) 에 넣어주기
                      window.sessionStorage.setItem("token", res.data.data.token);
                      window.sessionStorage.setItem("companyName", res.data.data.companyName);
                      axios.defaults.headers.common["accessToken"] = `${res.data.data.token}`;
                      alert("로그인 되었습니다.");
                      /* 회원가입 후 로그인 페이지 이동 체크 */
                      let isFirstLogin = window.sessionStorage.getItem("isFirstLogin");
                      console.log(isFirstLogin);
                      if(isFirstLogin != null){
                        // 회원가입 후 로그인
                        navigate("/mypage/changeInfo");
                      } else{
                        // 로그인
                        navigate("/");
                      }
                    } else if (res.data.code === "401") {
                      alert("아이디 / 비밀번호를 다시 확인해주세요");
                    }
                  });
                }
              }}
            />
            {password && (
              <img
                src={clearButton}
                alt=""
                onClick={() => {
                  setPassword("");
                }}
                style={{ position: "absolute", bottom: "15px", right: "5%" }}
              />
            )}
          </InputBox>
        </div>
      </div>
      {/* 로그인 버튼 */}
      <div className="m-4 w-9">
        <CircularButton
          className="text-l"
          backgroundcolor="#5c80ff"
          onClick={() => {
            login(email, password).then((res) => {
              console.log(res);
              if (res.data.code === 1) {
                window.sessionStorage.setItem("email", email);

                // 토큰값 sessionStorage 및 headers(새로고침시 사라짐) 에 넣어주기
                window.sessionStorage.setItem("token", res.data.data.token);
                window.sessionStorage.setItem("companyName", res.data.data.companyName);
                axios.defaults.headers.common["accessToken"] = `${res.data.data.token}`;
                alert("로그인 되었습니다.");
                /* 회원가입 후 로그인 페이지 이동 체크 */
                let isFirstLogin = window.sessionStorage.getItem("isFirstLogin");
                console.log(isFirstLogin);
                if(isFirstLogin != null){
                  // 회원가입 후 로그인
                  navigate("/mypage/changeInfo");
                } else{
                  // 로그인
                  navigate("/");
                }
              } else if (res.data.code === "401") {
                alert("아이디 / 비밀번호를 다시 확인해주세요");
              }
            });
          }}
        >
          로그인
        </CircularButton>
      </div>
      {/* tool-box */}
      <div className="m-2">
        <ul className="text-sm" style={{ textAlign: "center", padding: "0" }}>
          <LoginTool>
            <Link to="/register">회원가입</Link>
          </LoginTool>
          <LoginTool onClick={()=>{openChannel();}}>아이디 찾기</LoginTool>
          <LoginTool onClick={()=>{openChannel();}}>비밀번호 찾기</LoginTool>
        </ul>
      </div>
      {/* or 경계선 */}
      <div
        className="m-4 w-8"
        style={{
          borderBottom: "0.6px solid #6b6b6b",
          position: "relative",
          display:"none"
        }}
      >
        <div
          className="text-sm"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "5px",
            color: "#6b6b6b",
          }}
        >
          OR
        </div>
      </div>
      <div className="m-4 w-8" style={{display:"none"}}>
        <div
          className="text-sm"
          style={{ color: "#6b6b6b", textAlign: "center" }}
        >
          간편 로그인
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: "25px 0",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              alert("준비중 입니다. 이메일로 로그인 해 주세요");
            }}
          >
            <img alt="" src={kakaotalk_2} style={{ borderRadius: "50%" }} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              alert("준비중 입니다. 이메일로 로그인 해 주세요");
            }}
          >
            <img alt="" src={naver} style={{ borderRadius: "50%" }} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              alert("준비중 입니다. 이메일로 로그인 해 주세요");
            }}
          >
            <img alt="" src={apple} style={{ borderRadius: "50%" }} />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              alert("준비중 입니다. 이메일로 로그인 해 주세요");
            }}
          >
            <img alt="" src={email_img} style={{ borderRadius: "50%" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 0 3px;

  &:after {
    content: "|";
    color: "#ccc";
    padding-left: 6px;
  }

  &:last-child:after {
    content: "";
  }
`;

const InputBox = styled.div`
  position: relative;

  border: 1px solid #5c80ff;
  border-radius: 100px;
`;

export default LoginPage;
