import React, { useState, useEffect } from "react";
import axios from "axios";


import PageHeader from "../../components/views/Layout/Header/PageHeader";
import Footer from "../../components/views/Layout/Footer/Footer";

import WishListBlock from "../../components/WishListBlock";


import { server_url } from "../../server";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function WishList() {
  // 다국어 관련 추가 (2023-06-21)
  const { t, i18n } = useTranslation();
  let language = i18n.language;
  let email = window.sessionStorage.getItem("email");

  const [wishlist, setWishList] = useState([]);

  const getWishList = async () => {
    let body = {
      userLang: language,
      email: email
    };
    try {
      const res = await axios.post(`${server_url}/wishlist/${language}`, body);
      //console.log(res);
      console.log(res.data.wishDtoList);
      setWishList(res.data.wishDtoList);

    } catch (e) { }
  };

  useEffect(() => {
    getWishList();
  }, []);

  return (
    <div className="m-8">
      <div className="container">
        <PageHeader title={t("MAIN.MENU03")} />
        {wishlist && wishlist.length > 0 ? (
          wishlist.map((el) => {
            return <WishListBlock data={el} getWishList={getWishList} />;
          })
        ) : (
          <span>등록된 위시리스트가 없습니다.</span>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default WishList;
