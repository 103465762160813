export const findMinTime = (list) => {
  const timeList = list
    ?.map((el) => {
      if (el.timehhNm !== ""){
        return el.timehhNm + el.timemmNm;
      }
    })
    .filter((el) => el !== undefined);

  if (timeList.length !== 0) {
    const TimeNumberList = timeList.map((el) => Number(el));
    const minTime = Math.min(...TimeNumberList);
    const minTimeString = minTime.toString();
    return minTimeString.slice(0, -2) + ":" + minTimeString.slice(-2);
  }
};
