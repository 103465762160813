import React from "react";
import { CircularButton } from "../../styles/GlobalStyle";

function PlanSortSheet(props) {
  const [sortType, setSortType] = React.useState();

  return (
    <div className="w-9" style={{ margin: "0 auto" }}>
      <div>원하는 순서를 선택해주세요.</div>
      <div>
        <h3>정렬</h3>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div>
            <label>
              <input
                name="sortType"
                type="radio"
                id="1"
                onChange={(e) => setSortType(e.currentTarget.id)}
              />
              최신 순
            </label>
          </div>
          <div>
            <label>
              <input
                name="sortType"
                type="radio"
                id="2"
                onChange={(e) => setSortType(e.currentTarget.id)}
              />
              인기 순
            </label>
          </div>
          <div>
            <label>
              <input
                name="sortType"
                type="radio"
                id="3"
                onChange={(e) => setSortType(e.currentTarget.id)}
              />
              낮은 가격 순
            </label>
          </div>
          <div>
            <label>
              <input
                name="sortType"
                type="radio"
                id="4"
                onChange={(e) => setSortType(e.currentTarget.id)}
              />
              높은 가격 순
            </label>
          </div>
        </div>
      </div>
      <CircularButton
        className="m-4"
        backgroundcolor="#5c80ff"
        onClick={() => {
          props.setSortType(Number(sortType));
          props.setOpen(false);
        }}
      >
        적용
      </CircularButton>
    </div>
  );
}

export default PlanSortSheet;
