import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import CountButton from "../CountButton.jsx/CountButton";
import MyModal from "../../components/Modal/MyModal";

import { CircularButton } from "../../styles/GlobalStyle";
import { getAllPrice } from "../../js/getPrice";
import { getTodayDate } from "../../js/getDate";
import { img_url, server_url } from "../../server";

import heart from "../../images/icons/heart.png";
import filledheart from "../../images/icons/filledheart.png";
import backArrow from "../../images/icons/backArrow.png";
import closeIcon from "../../images/icons/closeIcon.png";

import "../../styles/css/BottomTab.css";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function BottomTab(props) {
  console.log(props);
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  let language = i18n.language;

  const navigate = useNavigate();

  const [adult, setAdult] = React.useState(2);
  const [child, setChild] = React.useState(0);

  const [hidden, setHidden] = React.useState(true);

  const [email] = React.useState(window.sessionStorage.getItem("email"));
  const [isLogined, setIsLogined] = React.useState(false);

  const [isWished, setIsWished] = React.useState(false);

  const [likeCount, setLikeCount] = React.useState(props.data.likeCount);

  const [slotItemAfterChange, setSlotItemAfterChange] = React.useState([]);
  const [slotDtoList, setSlotDtoList] = React.useState(props.data.slotDtoList);

  const [minpax, setMinpax] = React.useState(
    Math.max(
      ...slotDtoList.map((el) => {
        return Number(el.options.minpax);
      })
    )
  );
  React.useEffect(() => {
    if (props.data.slotDtoList.length > 0) {
      setSlotItemAfterChange(props.data.slotDtoList[0].options);
    }
    setSlotDtoList(
      slotDtoList.map((el) => {
        if (el.slotSq === slotItemAfterChange.slotSq) {
          el.options = slotItemAfterChange;
          return el;
        } else return el;
      })
    );

    setMinpax(
      Math.max(
        ...slotDtoList.map((el) => {
          return Number(el.options.minpax);
        })
      )
    );
  }, [slotItemAfterChange]);

  React.useEffect(() => {
    const getWishList = async () => {
      let body = {
        fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
        today: getTodayDate(),
        email: window.sessionStorage.getItem("email"),
        userLang: "ko"
      };

      await axios.post(`${server_url}/wishlist/ko`, body).then((v) => {
        console.log(v.data);
        v.data.wishDtoList.map((el) => {
          if (el.planCd === props.data.planCd) {
            /* 위시리스트가 1개라도 있으면 표기 */
            //console.log("위시리스트 있음")
            setIsWished(true);
            //Delete Wishlist를 위한 wish_sq 세팅
            window.sessionStorage.setItem("wishSq", el.wishSq);
          } else {
            //console.log("위시리스트 없음")
            //setIsWished(false);
          }
        }
        );
      });
    };

    getWishList();
  }, []);

  React.useEffect(() => {
    setIsLogined(email ? true : false);
  }, [email]);

  React.useEffect(() => {
    let filledheartUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAXCAYAAAD+4+QTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALMSURBVHgBtZVfSFNxFMfPvXe7ls55h7oUJa6hboLS8MEJEUzqIQkqeskegoIeghLzTeih+RI+JvjQQ5B7yl5s9tQfzElCGYiXSh1ztSWOdI3truYc5rbO79Idt3XnZm5f2C6/3+/8zuf8zj3ndylQkdls5skzkUiIfr9fhDyS7d1ut19tnVIOTCbTQDqdvl1ZruH1Oi2sb8bJtAt/jtXV1XGlLc/zHMuykr2+QsvJ9hRFOWmaHlQCKXmDVqt9au2osfX3maGroybjbHJ6DcYm3BAIbts9Hs+wHHkqlZrpaq/mlfaBYBxevfsGY4/d8DO+m7GXIC0tLY8unjp6dWSgU+200uYrd+YkEEbpIIBbfSa+/7I5p/35gRmIxnZ6vF6vi8KobPXVZTPO0R7AY0MuySBMiYjOuVwAWfMfQ8Tej2luYgwGw13cYLEqUqQmEsDp7nryPJQPQNR4pBwmX69xGrZylsaxpa2pCgpRg7EcCgHIsrbXAMMwFhqrgWtAailEgsLq42j8E/+UatG14ouSkhZJumbffwpBKUQgGo3GRWPO7juefYb1YHFPM44+A5txYWlpSWBCoZBYoauipuc3bNgrUMYycFCtfInCzXvzIh6gh/iXPIbDYRfD6gxzwvfusycbDwQiANJPiZ1kL3a8QOYy3iKRyPMUXdH0ZjFo+V+QDPix9esadrpTnv/LE57ISUC+QEwC7UfknV4ffguHyzSDC4vLD5Rr/4RLQOI2a8NrhCcdXihAunI2toYXhOWR7HXVnOj1+inP19gZBNXlAykBmCK7mo0qRBTFBIKeEBAO63Lda4UAckJkkNFofIG36QUcctkgGbCbTI4KH9xDsIf2LCFS47W1tVPZIBnA6VjHS5dwA/Iob51mg9qOVUlV1GmuFh5OzPVCMUU+ua2trT7biePpS+esi2mfnYNSiICam5vt6ZUhfj/7fgPe614cdXzBJQAAAABJRU5ErkJggg==";
    let heartUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAXCAYAAAAV1F8QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHbSURBVHgBtZXLbcJAEIZ3zevqdOCIpzgtFcR0kFQQu4LQQaCCpAPogBJwByQXhAQS7gAfEQjIP9GstBhwsGN+ydqHZ/3NzM6upbigVqvl7Pd7l/qFQiGcz+eBSFC73Va73U5JKaNisRjOZrOvuI00B/V6XaH5wEOLApo7Ho+KXw8Wi8Uo5pALh8jeIXvY2tTndf5yuQzOvKpWq88ArWq1Wi/+DnMuvWs0Gu96jvqYW9O6uD3mPbJvNpu9k4g4VVN0u/D6LGzDZgLPR5Siw+HQQ7q6IZRgP0VkLxSZZA9GFC0gvkiQhqFrA9K5BjGi7gP0hO92NWh9y0IDZl+L3JTjOHapVFrh249Sh4iFD+IOQhBTwHyLx5G4n6Ltdmtbm82GIA6FKe4jVS6XIwvbQqCgUql4ImfROUMT0QH+TR0qY4Byfcs7Kuz9EM3gZBKHsk8blxcM3xqivMd6rItB4FD10XyjQib/hREETQdF4J+BSChxj2FjkVGA0N3Xwdlxef8TjSfsVSrx/be6lBHr0gJ484JGpYERBEXl8f13+7kkr6g4OBV/QjgSR2QRw05+D7lDtOguvAbLDZIEQ/81DUSKG2X++DAMsfGfIuFHmRlkwtC100AyidOo0q77Aa7xBkKDm1tHAAAAAElFTkSuQmCC";
    document.getElementById("img_wished").src = isWished ? filledheart : heartUrl;

  }, [isWished]);

  function handleNotLogined() {
    navigate("/login");
  }

  async function reservePlan() {
    props.data.planPriceLocal === "-1"
      ? alert("해당 플랜의 예약이 마감되었습니다.")
      :
      savePlan(props.data, adult, child).then((v) => {
        console.log(v);
        if (v.successYn === "Y") {
          navigate("/ord/plan", {
            state: {
              data: props.data,
              adult: adult,
              child: child,
            },
          });
        }
      })

  }

  async function addWishList() {
    let body = {
      countryCd: "",
      cityCd: props.data.cityCd,
      dateDt: getTodayDate(),
      planCd: props.data.planCd,
      email: window.sessionStorage.getItem("email"),
    };
    console.log(body);
    if (window.sessionStorage.getItem("email") === null) {
      alert("로그인 후 이용해 주세요!");
      return false;
    }

    await axios
      .post(`${server_url}/wishlist/add`, body)
      .then((v) => console.log(v));

    setIsWished(true);
  }

  async function deleteWishList() {
    let wishSq = sessionStorage.getItem("wishSq")
    let body = {
      wishSq: wishSq
    }

    // await axios.delete(`${server_url}/wishlist/delete`);

    // setIsWished(false);

    try {
      const res = await axios.delete(`${server_url}/wishlist/delete/${wishSq}`, body);
      console.log(res);
      if (res.data != null) {
        alert("삭제가 완료되었습니다.")
        setIsWished(false);
        setLikeCount(likeCount - 1)
      }
    } catch (e) { }
  }
  return (
    <div
      className="container"
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        maxWidth: "600px",

        backgroundColor: "#ffffff",
        borderRadius: "15px 15px 0 0",

        boxShadow:
          "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px -2px 7px rgba(0, 0, 0, 0.2)",
        // height: hidden ? "10%" : "70%", Bang 임시주석 (2023-03-13)
        height: hidden ? "12%" : "35%",
        overflow: hidden ? "" : "scroll",
      }}
    >
      {/*<div*/}
      {/*    className={hidden ? "hidden" : ""}*/}
      {/*    style={{*/}
      {/*        width: "100%",*/}
      {/*        marginTop: "2%",*/}
      {/*        marginLeft: "5%",*/}
      {/*    }}*/}
      {/*>*/}
      {/*    <img*/}
      {/*        src={closeIcon}*/}
      {/*        alt=""*/}
      {/*        width="10px"*/}
      {/*        onClick={() => {*/}
      {/*            setHidden(!hidden);*/}
      {/*        }}*/}
      {/*    />*/}
      {/*</div>*/}
      <div
        className="w m-1"
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => {
          setHidden(!hidden);
        }}
      >
        <img
          src={backArrow}
          alt=""
          style={{
            transform: hidden ? "rotate(270deg)" : "rotate(90deg)",
            height: "15px",
          }}
        />
      </div>

      {/* Bang 추가 Start */}
      <div
        className="w-9 m-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className={
            !hidden ? "hidden text-xl text-bold" : "text-xl text-bold"
          }
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "700",
          }}
        >
          <img
            id={"img_wished1"}
            src={isWished ? filledheart : heart}
            alt=""
            onClick={isWished ? deleteWishList : addWishList}
          />
          <div
            style={{ display: "flex", alignItems: "end", marginTop: "5px" }}
          >
            <div
              // className="text-bold"
              style={{
                display: "flex",
                fontSize: "19px",
                marginLeft: "10px",
              }}
            >
              {/* 계산된 가격 */}
              {setCurrencySymbol()}
              {getAllPrice(
                adult,
                child,
                props.data.planPriceLocal,
                props.data.planChildLocal
              )}
              <div
                style={{
                  fontWeight: "200",
                }}
              >
                ({adult + child} {t('PLANDETAIL.FOOTLYR04')} , {props.data.slotDtoList.filter((f) => f.options.optionPriceAdultLocal !== "0" && f.options.optionPriceChildLocal !== "0").length}{t('PLANDETAIL.DETAIL07')}) 
                {/* 윗줄에 수정 들어가야함 */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Bang 추가 End */}

      {/*Bang 임시주석 (2023-03-13)*/}
      {/*<div*/}
      {/*    className={ hidden ? "hidden m-4 w-9" : "m-4 w-9" }*/}
      {/*>*/}
      {/*    {props.data.slotList &&*/}
      {/*        Object.values(props.data.slotList).map((sl) => {*/}
      {/*            return (*/}
      {/*                <div>*/}
      {/*                    <div key={sl.day}>*/}
      {/*                        <div >Day {sl.day}</div>*/}
      {/*                        <div  style={{ color: "#6b6b6b" }}>*/}
      {/*                            {sl.dateName}*/}
      {/*                        </div>*/}
      {/*                        {slotDtoList*/}
      {/*                            .filter((el) => {*/}
      {/*                                if (el.day.toString() === sl.day) return el;*/}
      {/*                            })*/}
      {/*                            .map((el) => {*/}
      {/*                                return (*/}
      {/*                                    <div key={el.slotItemAfterChange}>*/}
      {/*                                        <div>*/}
      {/*                                            <p*/}
      {/*                                                style={{*/}
      {/*                                                    fontSize: "xx-small",*/}
      {/*                                                    color: "#76baff",*/}
      {/*                                                }}*/}
      {/*                                            >*/}
      {/*                                                {el.options.optionTitleNm}*/}
      {/*                                            </p>*/}
      {/*                                            <p*/}
      {/*                                                style={{*/}
      {/*                                                    fontSize: "x-small",*/}
      {/*                                                }}*/}
      {/*                                            >*/}
      {/*                                                {el.options.optionDescNm}*/}
      {/*                                            </p>*/}
      {/*                                            <p*/}
      {/*                                                style={{*/}
      {/*                                                    fontSize: "small",*/}
      {/*                                                    fontWeight: "bold",*/}
      {/*                                                    color: "#01a2f9",*/}
      {/*                                                    marginBottom: "3%",*/}
      {/*                                                }}>*/}
      {/*                                                {el.options.minpax > 1 ? `${el.options.minpax}명 정원` : ""}*/}
      {/*                                            </p>*/}
      {/*                                        </div>*/}
      {/*                                    </div>*/}
      {/*                                );*/}
      {/*                            })}*/}
      {/*                    </div>*/}
      {/*                    /!* 구분선 *!/*/}
      {/*                    <div*/}
      {/*                        className={ hidden ? "hidden" : "" }*/}
      {/*                        style={{*/}
      {/*                            border: "1px solid #d2d2d2",*/}
      {/*                            marginBottom: "2%",*/}
      {/*                            width: "100%"*/}
      {/*                        }}*/}
      {/*                    ></div>*/}
      {/*                </div>*/}
      {/*            );*/}
      {/*    })}*/}
      {/*</div>*/}

      <div
        className="w-9 m-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div
            className={
              hidden ? "hidden" : ""
            }
          >
            {adult + child} {t('PLANDETAIL.FOOTLYR04')} , {props.data.slotDtoList.filter((f) => f.options.optionPriceAdultLocal !== "0" && f.options.optionPriceChildLocal !== "0").length}{t('PLANDETAIL.DETAIL07')}
          </div>
          {props.data.planPriceLocal !== "-1" && (
            <div
              className={
                hidden ? "hidden text-xl text-bold" : "text-xl text-bold"
              }
              style={{ display: "flex", alignItems: "end", marginTop: "5px" }}
            >
              <div
                className="text-bold"
                style={{
                  fontSize: "30px",
                }}
              >
                {/* 계산된 가격 */}
                {setCurrencySymbol()}
                {getAllPrice(
                  adult,
                  child,
                  props.data.planPriceLocal,
                  props.data.planChildLocal
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="w-9 m-2 text-sm"
        style={{ marginBottom: hidden ? "25px" : "0px" }}
      >
        <div
          className={hidden ? "hidden" : ""}
          style={{ display: "flex", position: "relative" }}>
          <div>{t('PLANDETAIL.FOOTLYR05')}</div>
          <div className={hidden ? "hidden" : ""} style={{ marginLeft: "5px" }}>
            {props.data.planPriceLocal !== "-1" && (
              <CountButton
                num={adult}
                setNum={setAdult}
                minpax={props.minpax}
              />
            )}
          </div>
          <div
            style={{
              position: "absolute",
              left:
                hidden && props.data.planPriceLocal !== "-1" ? "50px" : "70%",
            }}
          >
            {setCurrencySymbol()}
            {props.data.planPriceLocal !== "-1"
              ? `${props.data.planPriceLocal} ${t('PLANDETAIL.FOOTLYR07')}`
              : "예약 마감"}
          </div>
        </div>
        <div
          className={hidden ? "hidden" : ""}
          style={{ display: "flex", marginTop: "5px", position: "relative" }}
        >
          <div>{t('PLANDETAIL.FOOTLYR06')}</div>
          <div className={hidden ? "hidden" : ""} style={{ marginLeft: "5px" }}>
            {props.data.planChildLocal !== "-1" && (
              <CountButton num={child} setNum={setChild} />
            )}
          </div>
          <div
            style={{
              position: "absolute",
              left:
                hidden && props.data.planChildLocal !== "-1" ? "50px" : "70%",
            }}
          >
            {setCurrencySymbol()}
            {props.data.planChildLocal !== "-1"
              ? `${props.data.planChildLocal} ${t('PLANDETAIL.FOOTLYR07')}`
              : "예약 마감"}
          </div>
        </div>
      </div>
      <div
        className={hidden ? "hidden" : "w-9 m-3"}
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "20px",
        }}
      >
        <CircularButton
          color="#5c80ff"
          style={{ width: "45%" }}
          onClick={async () => {
            console.log(isLogined);
            console.log(props.data);

            isLogined
              ? savePlan(props.data, adult, child).then((v) => {
                console.log(v);
                if (v.successYn === "Y") {
                  alert(
                    `플랜이 저장되었습니다.
(My플랜 코드: ${v.uplanCd}).
플랜 편집 화면으로 이동합니다.`
                  );
                  navigate("/mypage/myplan");
                }
              })
              : handleNotLogined();
          }}
        >
          {t('PLANDETAIL.FOOTLYR01')}
        </CircularButton>
        <CircularButton
          backgroundcolor="#5c80ff"
          style={{ width: "45%" }}
          onClick={() => {
            // state가 props.data가 아닐 것 같은데, 일단 임시데이터로 구현

            isLogined ? reservePlan() : handleNotLogined();
          }}
        >
          {t('PLANDETAIL.FOOTLYR02')}
        </CircularButton>
      </div>
    </div>
  );
}

async function savePlan(data, adult, child) {
  let body = {
    userId: window.sessionStorage.getItem("email"),
    planId: data.planCd,
    //dateDt: getTodayDate(),
    dateDt : data.startDateDt,
    image: `${img_url}/${data.typeCd}/${data.savenameNm}`,
    adultCnt: adult,
    childCnt: child,
    titleEn: data.planTitleNm,
    titleKo: data.planTitleNm,

    // planfitKo: data.planfitDesc,
    // hotspotKo: data.hotspotDesc,
    featureList: {
      PLANBKG: data.PLANBKG,
      PLANTNC: data.PLANTNC,
      PLANINFO: data.PLANINFO,
      PLANCXLPLC: data.PLANCXLPLC,
    },
    clxList: data.clxList,
    slotList: data.slotList,
    cityCd: data.cityCd,

    countryCd: "HK",
    hotspotEn: [],
    hotspotKo: [],
    planfitEn: [],
    planfitKo: [],
    titleZhchs: data.planTitleNm,
    titleZhcht: data.planTitleNm,
    titleJa: data.planTitleNm,
    plannumberNm: data.plannumberNm,
    // clxList: [],
  };

  console.log(body);

  let returnData;

  await axios.post(`${server_url}/myplan`, body).then((res) => {
    console.log(res.data);
    returnData = res.data;
  });

  return returnData;
}

function setCurrencySymbol() {
  let currencySymbol;
  switch (localStorage.getItem("fxCodeCd")) {
    case "KRW":
      currencySymbol = "₩ "
      break;
    case "USD":
      currencySymbol = "US$ "
      break;
    case "HKD":
      currencySymbol = "HK$ "
      break;
    case "RMB":
      currencySymbol = "¥"
      break;
    default: currencySymbol = "₩ "
  }
  return currencySymbol;
}

export default BottomTab;
