import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { server_url, img_url } from "../../server";

import backArrow from "../../images/icons/backArrow.png";

import { generateRandomNumber, capitalizeFirstLetter, truncateString } from "../../js/common"

function SelectAlternativePlanSlot(props) {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const location = useLocation();
    const navigate = useNavigate();

    const [data, setData] = useState();
    const [slotSq, setSlotSq] = useState("");
    const [activity, setActivity] = useState([]);
    const [spot, setSpot] = useState([]);
    const [planSq, setPlanSq] = useState("");
    const [companySq,setCompanySq] = useState(0);
    const [dayNo, setDayno] = useState(0);
    const [ordNo, setOrdNo] = useState(0);
    const [cityCd, setCityCd] = useState("");
    const [email] = useState(window.sessionStorage.getItem("email"));
    const [spotTitle, setSpotTitle] = useState("");

    useEffect(() => {
        setData(location.state.data);
        setCityCd(location.state.cityCd);
        setCompanySq(location.state.companySq);
    }, []);

    useEffect(() => {
        if (data != null && data != undefined) {
            let title = "title" + capitalizeFirstLetter(language) + "Nm";
            setSpotTitle(data.options.optionTitleNm);
            setPlanSq(data.planSq);
            setSlotSq(data.slotSq);
            setOrdNo(data.ordNo);
            getOption();
            setDayno(data.day);
        }
    }, [data]);

    console.log(location.state);

    const getOption = async () => {
        const res = await axios.post(`${server_url}/plan/option/recommended`).then((response) => {
            console.log(response);
            const updatedActivity = response.data.activity.map((el) => ({
                ...el,
                isChecked: false, // 기본값으로 isChecked를 false로 설정
            }));
            setActivity(updatedActivity);
            const updatedSpot = response.data.spot.map((el) => ({
                ...el,
                isChecked: false, // 기본값으로 isChecked를 false로 설정
            }));
            setSpot(updatedSpot);
        });
    }


    const SaveComment = async () => {
        // isChecked가 true인 항목만 필터링
        const selectedActivity = activity.filter((el) => el.isChecked === true).map((el, index) => ({
            planSq: planSq,
            dayNo: dayNo,
            ordNo: ordNo,
            optionSq: el.optionSq,
            startTimeNm: "",
            startEndNm: ""
        }));
        // selectedActivity 배열에는 isChecked가 true인 항목만 포함됩니다.
        const selectedSpot = spot.filter((el) => el.isChecked === true).map(({ isChecked, ...rest }) => rest);
        console.log(selectedActivity);
        console.log(selectedSpot);

        try {
            if (selectedActivity.length > 0 || selectedSpot.length > 0) {
                console.log("start");
                let optionCd = "OPT_" + cityCd + "_" + truncateString(email) + "_" + generateRandomNumber(5);
                let body = {
                    planSq: planSq,
                    dayNo: dayNo,
                    ordNo : ordNo,
                    optionCd: optionCd,
                    cityCd: cityCd,
                    uploadUser: email,
                    companySq: companySq,
                    slot: selectedActivity,
                    slotSq : slotSq,
                    spot: selectedSpot
                }
                console.log(body);
                const res = await axios.post(`${server_url}/plan/altSlot/save/makeOption/save`, body).then((response) => {
                    console.log(response);
                    if (response.data.result == "success") {
                        alert("등록 완료되었습니다");
                        if(selectedSpot.length >0){
                            const sendData = {
                                ...data,
                                optionSq: response.data.optionSq,
                                planSq: planSq,
                                orderNo : response.data.orderNo,
                              };
                            navigate("/mypage/makingPlan/slot/updateOptioin",{ state:  sendData});
                        } else{
                            window.history.back();
                        }

                    }
                });
            } else {
                alert("1개 이상의 액티비티 혹은 스팟을 선택해주세요");
            }




        } catch (e) {
            console.log(e);
        }
    };




    return (
        <div>
            {data && (
                <div>
                    <div className={"w"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "0", backgroundColor: "#ffffff", marginTop: "20px" }}>
                        <img src={backArrow} alt="" width="10px" height="15px" style={{ transform: "scaleX(-1)", marginLeft: "1%" }} className="c" onClick={() => { window.history.back(); }} />
                        <div style={{ color: "5c80ff", fontWeight: "800", fontSize: "20px", margin: "auto", marginLeft: "30px" }}>Day {data.day}</div>
                    </div>
                    <div style={{ overflow: "auto", paddingTop: "30px" }}>
                        <span style={{ fontWeight: "bold" }}>기존 일정</span>
                        <div className="w-9" style={{ display: "flex", border: "1px solid #000",marginTop:"20px" }}>
                            <img src={data.options.savenameNm} style={{ width: "100px", height: "100px" }} />
                            <div style={{ margin: "10px", display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                                <span style={{ margin: "5px 0" }}>{data.options.optionTitleNm}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ overflow: "auto", paddingTop: "30px" }}>
                        <span style={{ fontWeight: "bold" }}>추천 액티비티</span>
                        <div style={{ marginTop: "20px" }}>
                            {activity != undefined && activity.length > 0 && activity.map((el, index) => {
                                let title = "title" + capitalizeFirstLetter(language) + "Nm";
                                return (
                                    <div key={el.savenameNm} className="w-9" style={{ display: "flex", border: "1px solid #000" }}>
                                        <img src={el.savenameNm} style={{ width: "100px", height: "100px" }} />
                                        <div style={{ margin: "10px", display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                                            <span style={{ margin: "5px 0" }}>{el[title]}</span>
                                            <span style={{ margin: "5px 0", color: "#56CBF5" }}>{el.categoryCd}</span>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
                                            <input
                                                type="checkbox"
                                                style={{ height: "30px", width: "30px" }}
                                                checked={el.isChecked} // isChecked 상태에 따라 체크박스 상태 설정
                                                onChange={() => {
                                                    const updatedActivity = [...activity];
                                                    const updatedItem = { ...el, isChecked: !el.isChecked }; // isChecked 값을 반전
                                                    const itemIndex = updatedActivity.findIndex((item) => item.optionSq === el.optionSq); // 고유 식별자로 요소 찾기
                                                    if (itemIndex !== -1) {
                                                        updatedActivity[itemIndex] = updatedItem;
                                                        setActivity(updatedActivity);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ textAlign: "center", marginTop: "20px" }} onClick={() => navigate(`/mypage/makingPlan/searchAltOption`, { state: { data: data, activityYn: "Y" } })}>
                            <input type="button" value="더보기" className="w-8" style={{ height: "30px", color: "#FFF", background: "#C8C8C8", border: "1px solid #C8C8C8" }} />
                        </div>
                    </div>
                    <div style={{ overflow: "auto", paddingTop: "30px" }}>
                        <span style={{ fontWeight: "bold" }}>추천 스팟</span>
                        <div style={{ marginTop: "20px", maxHeight: "300px", overflow: "auto" }}>
                            {spot != undefined && spot.length > 0 && spot.map((el, index) => {
                                let title = "title" + capitalizeFirstLetter(language) + "Nm";
                                console.log("ssssss");
                                let savenameNm = '';
                                if(el.comImages != null){
                                    savenameNm = el.comImages.savenameNm;
                                    if (!savenameNm.includes("https")) {
                                        savenameNm = img_url + "/" + el.comImages.typeCd + "/" + el.comImages.savenameNm;
                                    }
                                }
                                
                                
                                return (
                                    <div key={savenameNm} className="w-9" style={{ display: "flex", border: "1px solid #000" }}>
                                        <img src={savenameNm} style={{ width: "100px", height: "100px" }} />
                                        <div style={{ margin: "10px", display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                                            <span style={{ margin: "5px 0" }}>{el.comSpot[title]}</span>
                                            <span style={{ margin: "5px 0", color: "#56CBF5" }}>{el.comSpot.categoryCd}</span>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
                                            <input
                                                type="checkbox"
                                                value={el.comSpot.spotSq}
                                                style={{ height: "30px", width: "30px" }}
                                                checked={el.isChecked} // isChecked 상태에 따라 체크박스 상태 설정
                                                onChange={() => {
                                                    const updatedSpot = [...spot];
                                                    const updatedItemIndex = updatedSpot.findIndex((item) => item.comSpot.spotSq === el.comSpot.spotSq);

                                                    if (updatedItemIndex !== -1) {
                                                        updatedSpot[updatedItemIndex].isChecked = !el.isChecked; // isChecked 값을 반전
                                                        setSpot(updatedSpot); // 상태 업데이트
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ textAlign: "center", marginTop: "20px",marginBottom:"50px" }} onClick={() => navigate(`/mypage/makingPlan/searchAltSpot`, { state: { data: data,cityCd : cityCd,companySq : companySq } })}>
                            <input type="button" value="더보기" className="w-8" style={{ height: "30px", color: "#FFF", background: "#C8C8C8", border: "1px solid #C8C8C8" }} />
                        </div>
                    </div>


                    <div style={{ position: "static", bottom: 0, maxWidth: "740px", width: "100%",paddingBottom:"10px"}}>
                        <input type="button" value={"수정 완료"} onClick={() => SaveComment()} style={{ background: "#4f95d5", border: "none", "borderRadius": "10px", color: "#fff", height: "30px", width: "100%" }} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SelectAlternativePlanSlot;