import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";

import MainPage from "./pages/MainPage/MainPage";
import MyPage from "./pages/MyPage/MyPage";
import LoginPage from "./pages/MyPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import TosPage from "./pages/RegisterPage/TosPage";
import RegisterEndPage from "./pages/RegisterPage/RegisterEndPage";

import CitySpotPage from "./pages/SpotPage/CitySpotPage";
import PlanListPage from "./pages/PlanPage/PlanListPage";
import PlanDetailPage from "./pages/PlanPage/PlanDetailPage";
import PreviewPlanDetailpage from "./pages/PlanPage/PreviewPlanDetailPage"

import SpotDetailPage from "./pages/SpotPage/SpotDetailPage";

import ReserveNowPage from "./pages/ReservationPage/ReserveNowPage";
import MakingPlan from "./pages/MyPage/MakingPlan"
import MakingPlanDetail from "./pages/MyPage/MakePlanDetail"
import MakingOptionDetail from "./pages/MyPage/MakeOptionDetail"
import MakingOptionSpot from "./pages/MyPage/MakeOptionSpot"
import PlanSlotUpdateOption from "./pages/MyPage/PlanSlotUpdateOption";
import SelectOptionSpot from "./pages/MyPage/SelectOptionSpot"
import UpdateOptionSpot from "./pages/MyPage/UpdateOptionSpot"
import MakingPlanDetailModify from "./pages/MyPage/MakingPlanDetail";
import MakingPlanSlot from "./pages/MyPage/MakePlanSlot";
import SelectAlternativePlanSlot from "./pages/MyPage/SelectAlternativePlanSlot";
import PlanSelectOption from "./pages/MyPage/PlanSelectOption"
import PlanSerachOption from "./pages/MyPage/PlanSearchOption"
import PlanSerachAltOption from "./pages/MyPage/PlanSearchAltOption"
import PlanSearchSpot from "./pages/MyPage/PlanSearchSpot"
import PlanSearchAltSpot from "./pages/MyPage/PlanSearchAltSpot"
import AddPlanPhoto from "./pages/MyPage/AddPlanPhoto";
import AddOptionPhoto from "./pages/MyPage/AddOptionPhoto";
import SelectPlanPhoto from "./pages/MyPage/SelectPlanPhoto"
import SelectOptionPhoto from "./pages/MyPage/SelectOptionPhoto"
import PlanCommentPage from "./pages/MyPage/PlanCommentPage";
import OptionCommentPage from "./pages/MyPage/OptionCommentPage";
import MakeOptionPricePage from "./pages/MyPage/MakeOptionPrice"
import MyPlanPage from "./pages/MyPage/MyPlanPage";
import MyOptionPage from "./pages/MyPage/MyOptionPage"
import PreviewOption from "./pages/MyPage/PreviewOption"
import SavedPlanDetailPage from "./pages/PlanPage/SavedPlanDetailPage";
import Tos from "./pages/MainPage/Tos";
import ChangeInfoPage from "./pages/MyPage/ChangeInfoPage";
import ChangePwPage from "./pages/MyPage/ChangePwPage";
import WishList from "./pages/MyPage/WishList";
import CustomerService from "./pages/MyPage/CustomerService";

import { Suspense } from "react";
import CompanyPage from "./pages/CompanyPage/CompanyPage";
import NaverHtml from "./pages/Naver/Naver";
import RobotsTxt from "./pages/Naver/Robots";
import ReservedPlanDetailPage from "./pages/PlanPage/ReservedPlanDetailPage";

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route exact path="/" element={<MainPage />} />
            <Route exact path="/tos" element={<Tos />} />
            <Route
              exact
              path="/naver2b9a0ec0b751a1d09204e819cb9f633c.html"
              element={<NaverHtml />}
            />
            <Route exact path="/robots.txt" element={<RobotsTxt />} />
            <Route path="/mypage" element={<MyPage />} />
            <Route path="/mypage/makingPlan" element={<MakingPlan/>}/>
            <Route path="/mypage/makingPlanDetail" element={<MakingPlanDetail/>}/>
            <Route path="/mypage/makingOptionDetail" element={<MakingOptionDetail/>}/>
            <Route path="/mypage/makingOptionSpot" element={<MakingOptionSpot/>}/>
            <Route path="/mypage/makingPlan/slot/updateOptioin" element={<PlanSlotUpdateOption/>}/>
            <Route path="/mypage/selectOptionSpot" element={<SelectOptionSpot/>}/>
            <Route path="/mypage/updateOptionSpot" element={<UpdateOptionSpot/>}/>
            <Route path="/mypage/makingPlanDetail/:planSq/:planCd" element={<MakingPlanDetailModify/>}/>
            <Route path="/mypage/makingPlanSlot" element={<MakingPlanSlot/>}/>
            <Route path="/mypage/selectAlternativePlanSlot" element={<SelectAlternativePlanSlot/>}/>
            <Route path="/mypage/makingPlan/selectOption" element={<PlanSelectOption/>}/>
            <Route path="/mypage/makingPlan/searchOption" element={<PlanSerachOption/>}/>
            <Route path="/mypage/makingPlan/searchAltOption" element={<PlanSerachAltOption/>}/>
            
            <Route path="/mypage/makingPlan/searchSpot" element={<PlanSearchSpot/>}/>
            <Route path="/mypage/makingPlan/searchAltSpot" element={<PlanSearchAltSpot/>}/>
            <Route path="/mypage/makingPlan/addPlanPhoto" element={<AddPlanPhoto/>}/>
            <Route path="/mypage/makingPlan/addOptionPhoto" element={<AddOptionPhoto/>}/>
            <Route path="/mypage/makingPlan/selectPlanPhoto/:email" element={<SelectPlanPhoto/>}/>
            <Route path="/mypage/makingPlan/selectOptionPhoto" element={<SelectOptionPhoto/>}/>
            <Route path="/mypage/makingPlan/planComment" element={<PlanCommentPage/>}/>
            <Route path="/mypage/makingOption/optionComment" element={<OptionCommentPage/>}/>
            <Route path="/mypage/makingOption/price" element={<MakeOptionPricePage/>}/>
            <Route path="/mypage/makingOption/previewOption" element={<PreviewOption/>}/>
            <Route path="/mypage/myplan" element={<MyPlanPage />} />
            <Route path="/mypage/myoption" element={<MyOptionPage/>}/>

            <Route path="/mypage/changeInfo" element={<ChangeInfoPage />} />
            <Route path="/mypage/changePassword" element={<ChangePwPage />} />
            <Route
              path="/mypage/myplan/:uplanSq"
              element={<SavedPlanDetailPage />}
            />
            <Route path="/mypage/wishlist" element={<WishList />} />
            <Route path="/mypage/cs" element={<CustomerService />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/register/tos" element={<TosPage />} />
            <Route path="/register/end" element={<RegisterEndPage />} />
            <Route path="/spots/:citySq/:cityCd" element={<CitySpotPage />} />
            <Route path="/plans/:cityCd" element={<PlanListPage />} />
            <Route path="/plans/:planSq/:planCd" element={<PlanDetailPage />} />
            <Route path="/plans/preview/:planSq/:planCd" element={<PreviewPlanDetailpage />}/>
            <Route path="/plans/reserved/complete/:uplanSq"    
            //element={<SavedPlanDetailPage />}
              element={<ReservedPlanDetailPage />}
            />
            <Route path="/spot/:spotSq/:spotCd" element={<SpotDetailPage />} />
            <Route path="/ord/plan/" element={<ReserveNowPage />} />
            <Route path="/mgr/:pcSq/" element={<CompanyPage />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
