import React, { useState } from "react";
import axios from "axios";
import PageHeader from "../../components/views/Layout/Header/PageHeader";
import { server_url } from "../../server";
import { CircularButton, UnderbarInput } from "../../styles/GlobalStyle";
import clearButton from "../../images/icons/clearButton.png";

function ChangePwPage() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const changePassword = async () => {
    let body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    console.log(body);

    const res = await axios.put(`${server_url}/members/password`, body, {
      headers: { accessToken: window.sessionStorage.getItem("token") },
    });
    console.log(res);

    if(res.data != null){
      alert("회원 비밀번호가 수정되었습니다");
      //init
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    }

    return res.data;

    /*try {
     
      return res.data.code;
    } catch (e) {
      console.log(e);
      return e;
    }*/
  };

  return (
    <div className="container">
      <PageHeader title="비밀번호 재설정" />

      <div
        className="w-8 m-4"
        style={{ display: "flex", flexDirection: "column", gap: 20 }}
      >
        <div className="text-bold text-l" style={{ alignSelf: "flex-start" }}>
          비밀번호 재설정
        </div>
        <div style={{ position: "relative" }}>
          <label className="m-4">기존 비밀번호</label>
          <UnderbarInput
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.currentTarget.value)}
          />
          {oldPassword && (
            <img
              src={clearButton}
              alt=""
              onClick={() => setOldPassword("")}
              style={{ position: "absolute", bottom: "10px", right: "2%" }}
            />
          )}
        </div>
        <div style={{ position: "relative" }}>
          <label>새 비밀번호</label>
          <UnderbarInput
            placeholder="새로운 비밀번호를 입력해주세요"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.currentTarget.value)}
          />
          {newPassword && (
            <img
              src={clearButton}
              alt=""
              onClick={() => setNewPassword("")}
              style={{ position: "absolute", bottom: "10px", right: "2%" }}
            />
          )}
        </div>
        <div style={{ position: "relative" }}>
          <label>새 비밀번호 확인</label>
          <UnderbarInput
            type="password"
            placeholder="확인을 위해 새로운 비밀번호를 다시 입력해주세요."
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.currentTarget.value)}
          />
          {confirmNewPassword && (
            <img
              src={clearButton}
              alt=""
              onClick={() => setConfirmNewPassword("")}
              style={{ position: "absolute", bottom: "10px", right: "2%" }}
            />
          )}
        </div>
        <CircularButton
          backgroundcolor="#5c80ff"
          onClick={() => {
            changePassword();
          }}
        >
          변경하기
        </CircularButton>
      </div>
    </div>
  );
}

export default ChangePwPage;
