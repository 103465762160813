import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

import { server_url } from "../../server";

import homeMenu from "../../images/icons/homeMenu.png";
import backArrow from "../../images/icons/backArrow.png";

import PageHeader from "../../components/views/Layout/Header/PageHeader";

/* 달력 */
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateRangePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs"; // dayjs 라이브러리 import 추가






function MakeOptionPrice() {
  const location = useLocation();
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const [email] = useState(window.sessionStorage.getItem("email"));
  const [data, setData] = useState();
  const [isHidden, setIsHidden] = useState(true);
  const [days, setDays] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
  const [day, setDay] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
  const [currentYear, setCurrentYear] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');

  //OptionSq
  const [optionSq, setOptionSq] = useState();
  //OptionCd
  const [optionCd, setOptionCd] = useState();
  //시작 날짜
  const [startDate, setStartDate] = useState();
  //끝 날짜
  const [endDate, setEndDate] = useState();
  //성인 금액
  const [adultPrice, setAdultPrice] = useState(0.0);
  //아동 금액
  const [childPrice, setChildPrice] = useState(0.0);
  //연도
  const [year, setYear] = useState('');
  //월
  const [month, setMonth] = useState('');
  //날짜별 List
  const [list, setList] = useState([]);




  useEffect(() => {
    console.log("언어:" + language);
    console.log(location);
    console.log(day);

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;

    setCurrentYear(year);
    setCurrentMonth(month);

    //init
    setYear(year);
    setMonth(month);

    setYearList([Number(year - 1), Number(year), Number(year) + 1]);

    if (location.state != null) {
      //값 세팅
      let item = location.state;
      console.log(item);
      setOptionSq(item.optionSq);
      setOptionCd(item.optionCd);
    }


  }, []);

  const handleCheckboxChange = (value) => {
    if (day.includes(value)) {
      // 값이 이미 배열에 있는 경우 제거
      setDay(day.filter((item) => item !== value));
    } else {
      // 값이 배열에 없으면 값을 추가
      setDay([...day, value]);
    }
  };

  const toggleHidden = () => {
    setIsHidden(!isHidden); // isHidden 상태 반전
  };

  const handleDoubleChange = (event) => {
    switch (event.target.id) {
      case 'ip_adult':
        setAdultPrice(event.target.value);
        break;
      case 'ip_child':
        setChildPrice(event.target.value);
        break;
    }
  };

  const handleChangeSelect = (event) => {
    let value = event.target.value;
    let id = event.target.id;
    switch (id) {
      case 'sl_year':
        setYear(value);
        break;
      case 'sl_month':
        setMonth(value);
        break;
    }
  }

  const updateListValue = (index, key, value) => {
    const updatedList = [...list];
    updatedList[index][key] = value;
    setList(updatedList);
  };

  /* 검색 */
  const onSearch = () => {
    let body = {
      "year": year,
      "month": month,
      "optionSq": optionSq
    }
    const res = axios.post(`${server_url}/myplan/option/price/search`, body);
    res.then((response) => {
      console.log(response);
      setList(response.data);
    });
  }

  /* 가격 저장 */
  const onSave = () => {
    let sDate = dayjs(startDate).format("YYYY-MM-DD");
    let eDate = dayjs(endDate).format("YYYY-MM-DD");
    let body = {
      optionSq: optionSq,
      startDate: sDate,
      endDate: eDate,
      adultAmt: adultPrice,
      childAmt: childPrice,
      day: day.join(","),
      optionCd: optionCd,
      list: list
    }
    console.log(body);
    const res = axios.post(`${server_url}/myplan/option/savePrice`, body);
    res.then((response) => {
      console.log(response);
      if (response.data.result == "success") {
        alert("가격 입력이 완료되었습니다.")
        window.history.back();
      }
    });
  }
  console.log(list);
  return (
    <div className="container m-8">
       <PageHeader title="옵션 만들기" />
      <div style={{ textAlign: "left", marginTop:"30px" }} className="w" onClick={toggleHidden}>
        <span>요금 입력 </span> <span>{isHidden ? "▲" : "▼"}</span>
      </div>
      <div className="w">
        {!isHidden && (
          <div className="w" style={{ textAlign: "left", marginTop: "20px" }}>
            {days.map(el => (
              <label key={el} htmlFor={"cb_" + el}>
                <input id={"cb_" + day} type="checkbox" value={el} checked={day.includes(el)} onChange={() => handleCheckboxChange(el)}
                />
                <span style={{ display: "inline-block", margin: "0px 5px", color: el === "Sun" ? "red" : el === "Sat" ? "blue" : "black" }}>{el}</span>
              </label>
            ))}
            <div style={{ marginTop: "20px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker value={startDate} onChange={(newValue) => setStartDate(newValue)} />
              </LocalizationProvider>
              <span style={{ marginRight: "10px" }}> </span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker value={endDate} onChange={(newValue) => setEndDate(newValue)} />
              </LocalizationProvider>
            </div>
            <div style={{ display: "flex", marginTop: "20px", alignItems: "center" }}>
              <span style={{ width: "150px" }}>Adult</span>
              <input id="ip_adult" type="number" step="0.1" // 소수점 이하 한 자리까지 허용
                value={adultPrice} onChange={handleDoubleChange} style={{ marginLeft: "20px", height: "38px" }} />
            </div>
            <div style={{ display: "flex", marginTop: "20px", alignItems: "center" }}>
              <span style={{ width: "150px" }}>Child<br></br>(under 12)</span>
              <input id="ip_child" type="number" step="0.1" // 소수점 이하 한 자리까지 허용
                value={childPrice} onChange={handleDoubleChange} style={{ marginLeft: "20px", height: "38px" }} />
            </div>
          </div>
        )}
        <div style={{ marginTop: "50px" }}>
          <span>요금검색</span>
          <div style={{ marginTop: "20px" }}>
            <select id="sl_year" value={year} onChange={handleChangeSelect} style={{ height: "30px", width: "100px" }}>
              {yearList.map(el => (
                <option key={el} value={el}>{el}</option>
              ))}
            </select>
            <select id="sl_month" value={month} onChange={handleChangeSelect} style={{ height: "30px", width: "110px", margin: "0 10px" }}>
              {monthList.map((el, index) => (
                <option key={el} value={el}>{el}</option>
              ))}
            </select>
            <input type="button" onClick={onSearch} style={{ border: "1px solid #FAD085", background: "#FFF", color: "#FAD085", height: "30px" }} value="Search" />
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <table style={{ borderCollapse: "collapse", borderSpacing: "0", width: "100%" }}>
            <thead style={{ background: "#F0F3F7", height: "30px" }}>
              <tr>
                <th>Date</th>
                <th>Day</th>
                <th>Adult (USD)</th>
                <th>Child (USD)</th>
                <th>Close Out</th>
              </tr>
            </thead>
            <tbody>
              {list.map((el, index) => {
                let targetDate = new Date(el.dateDt);
                let dayIndex = targetDate.getDay();
                let dayOfWeek = days[dayIndex];
                return (
                  <tr key={el.priceSq} style={{ textAlign: "center", borderBottom: "1px solid #EAEEF1", height: "30px" }}>
                    <td>{el.dateDt}</td>
                    <td>{dayOfWeek}</td>
                    <td><input type="number" style={{textAlign:"center"}} step="0.1" value={el.adultAmt} onChange={(e) => updateListValue(index, "adultAmt", e.target.value)} /></td>
                    <td><input type="number" style={{textAlign:"center"}} step="0.1" value={el.childAmt} onChange={(e) => updateListValue(index, "childAmt", e.target.value)} /></td>
                    <td><input type="checkbox" checked={el.closeoutYn === 'Y'} onChange={(e) => updateListValue(index, "closeoutYn", e.target.checked ? 'Y' : 'N')} /></td>
                  </tr>
                )
              })}
            </tbody>
          </table>


        </div>
      </div>
      <div
        className="w-9"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px"
        }}>
        <input type="button" onClick={onSave} className={'save_active'} value="입력 완료" />
      </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 10px;

  width: 40%;
`;

const LanguageButton = styled.input`
  padding: 3px 10px;
  color: ${props => (props.active ? "#FFF" : "#000")};
  background: ${props => (props.active ? "#5B9BD5" : "#FFF")};
`

styled.active = `background : #000`;
export default MakeOptionPrice;
