import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

import { server_url,img_url } from "../../server";

import homeMenu from "../../images/icons/homeMenu.png";
import backArrow from "../../images/icons/backArrow.png";
import img_search from "../../images/icons/search.svg";

import dayjs from "dayjs"; // dayjs 라이브러리 import 추가

import Switch from '@mui/material/Switch';






function UpdateOptionSpot() {
  const location = useLocation();
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const [email] = useState(window.sessionStorage.getItem("email"));
  const [data, setData] = useState();

  const [active, setActive] = useState(false);

  //Switch
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [switchBool, setSwitchBool] = useState(false);

  //Time
  const hours = [];
  const minutes = [];

  for (let i = 0; i <= 24; i++) {
    hours.push(i);
  }
  for (let i = 0; i < 60; i++) {
    let no = i;
    if (i < 10) {
      no = '0' + i;
    }
    minutes.push(no);
  }
  const [optionsSq, setOptionsSq] = useState("");
  const [optionSq, setOptionSq] = useState("");
  const [spotSq, setSpotSq] = useState("");
  const [ordNo, setOrdNo] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [spotTitle, setSpotTitle] = useState("");
  const [timehhNm, setTimehhNm] = useState("0");
  const [timemmNm, setTimemmNm] = useState("00");

  //언어 세팅
  const [languageComment, setLanguageComment] = useState(language);
  //한국어 코멘트
  const [koComment, setKoComment] = useState("");
  //영어 코멘트
  const [enComment, setEnComment] = useState("");
  //간자체 코멘트
  const [zhchsComment, setzhchsComment] = useState("");
  //번자체 코멘크
  const [zhchtComment, setzhchtComment] = useState("");
  //코멘트
  const [comment, setComment] = useState("");


  //날짜별 List
  const [list, setList] = useState([]);
  const [checkedItems, setCheckedItems] = useState(Array(list.length).fill(false));
  //체크된 Spot
  const checkedList = list.filter((_, index) => checkedItems[index]);
  //이미 만들어진 스팟들
  const [madeLength, setMadeLength] = useState(0);






  useEffect(() => {
    console.log("언어:" + language);
    console.log(location);
    if (location.state != null) {
      //값 세팅
      let no = location.state.no;
      let item = location.state.data.masterOptionSpots[no];
      console.log(item);
      setData(item);
      setOptionsSq(item.optionsSq);
      setOptionSq(item.optionSq);
      setSpotSq(item.spotSq);
      setOrdNo(item.ordNo);
      setTimehhNm(item.timehhNm);
      setTimemmNm(item.timemmNm);
      if(item.timemmNm == ""){
        setTimemmNm("00");
      }
      let title = "title" + capitalizeFirstLetter(language) + "Nm";
      setSpotTitle(item[title]);


      setKoComment(item.descKoNm);
      setEnComment(item.descEnNm);
      setzhchsComment(item.descZhchsNm);
      setzhchtComment(item.descZhchtNm);
      setComment(item.descKoNm);

      if (item.timehhNm != "" && item.timemmNm) {
        setSwitchBool(true);
      }

      let imageUrl = "";

      let image = location.state.data.comSpots[no].comImages;
      if(image != null){
        if (image.savenameNm.includes("https://")) {
          imageUrl = image.savenameNm;
        } else {
          imageUrl = img_url + "/" + image.typeCd + "/" + image.savenameNm;
        }
      }
      setImageUrl(imageUrl);
    }
  }, []);


  const handleChange = (event) => {
    let value = event.target.value;
    console.log(languageComment);
    setComment(value);
    switch (languageComment) {
      case "ko": case "한국어":
        setKoComment(value);
        break;
      case "English":
        setEnComment(value);
        break;
      case "中文繁體":
        setzhchsComment(value);
        break;
      case "中文簡體":
        setzhchtComment(value);
        break;
    }
  }


  /* 젤 앞 대문자 설정 */
  const capitalizeFirstLetter = (str) => {
    if (str.length === 0) {
      return str;
    }

    const firstLetter = str.charAt(0).toUpperCase();
    const remainingLetters = str.slice(1);

    return firstLetter + remainingLetters;
  };

  const handleSpotSwitchChange = () => {
    setSwitchBool(!switchBool);
  }

  const handleSelectChange = (e) => {
    let value = e.target.value;
    let id = e.target.id;
    switch (id) {
      case "sl_hour":
        setTimehhNm(value);
        break;
      case "sl_min":
        setTimemmNm(value);
        break;
    }
  }

  const handleButtonClick = (lang) => {
    setLanguageComment(lang);
    switch (lang) {
      case "ko": case "한국어":
        setComment(koComment);
        break;
      case "English":
        setComment(enComment);
        break;
      case "中文繁體":
        setComment(zhchsComment);
        break;
      case "中文簡體":
        setComment(zhchtComment);
        break;
    }
  }


  /* 가격 저장 */
  const onSave = () => {
    let body = {
      optionsSq: optionsSq,
      optionSq: optionSq,
      spotSq: spotSq,
      ordNo: ordNo,
      timehhNm: timehhNm,
      timemmNm: timemmNm,
      descEnNm: enComment,
      descKoNm: koComment,
      descZhchsNm: zhchsComment,
      descZhchtNm: zhchtComment
    }
    if (window.confirm("수정하시겠습니까?")) {
      //승인시
      const res = axios.post(`${server_url}/myplan/option/UpdateSpot`, body);
      res.then((response) => {
        console.log(response);
        if (response.data.result == "success") {
          alert("수정 완료되었습니다.")
          window.history.back();
        }
      });
    } else {
      //거절시
    }

  }
  return (
    <div className="container">
      <div className={"w"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "0", backgroundColor: "#ffffff", marginTop: "20px" }}>
        <img src={backArrow} alt="" width="10px" height="15px" style={{ transform: "scaleX(-1)", marginLeft: "1%" }} className="c" onClick={() => { window.history.back(); }} />
        <div style={{ color: "5c80ff", fontWeight: "800", fontSize: "20px", margin: "auto", marginLeft: "30px" }}>스팟 정보 수정</div>
      </div>
      <div style={{ textAlign: "left", marginTop: "30px", paddingLeft: "70px", overflow: "auto" }} className="w">
        <div>
          <img src={imageUrl} style={{width:"100%"}} />
          <div style={{ margin: "15px 0 0 15px" }}>
            <span style={{ margin: "5px 0", fontWeight: "bold" }}>{spotTitle}</span>
          </div>
          <div>
            <Switch {...label} checked={switchBool} onChange={() => handleSpotSwitchChange()} />
            <span>시간 설정</span>
          </div>
          <div style={{ display: "flex" }}>
            <select id="sl_hour" style={{ marginRight: "14px", height: "30px", width: "47%", textAlign: "center" }} value={timehhNm} disabled={!switchBool} onChange={(e) => handleSelectChange(e)}>
              {hours.map(el => (
                <option key={el} value={el}>{el}</option>
              ))}
            </select>
            <select id="sl_min" style={{ height: "30px", width: "47%", textAlign: "center" }} value={timemmNm} disabled={!switchBool} onChange={(e) => handleSelectChange(e)}>
              {minutes.map(el => (
                <option key={el} value={el}>{el}</option>
              ))}
            </select>
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <LanguageButton
              type="button"
              value="한국어"
              active={languageComment === "한국어" || languageComment === "ko"}
              onClick={() => handleButtonClick("한국어")}
            />
            <LanguageButton
              type="button"
              value="English"
              style={{ marginLeft: "10px" }}
              active={languageComment === "English"}
              onClick={() => handleButtonClick("English")}
            />
            <LanguageButton
              type="button"
              value="中文繁體"
              style={{ marginLeft: "10px" }}
              active={languageComment === "中文繁體"}
              onClick={() => handleButtonClick("中文繁體")}
            />
            <LanguageButton
              type="button"
              value="中文簡體"
              style={{ marginLeft: "10px" }}
              active={languageComment === "中文簡體"}
              onClick={() => handleButtonClick("中文簡體")}
            />
          </div>
          <div style={{ marginTop: "20px", display: "flex", width: "96%" }}>
            <textarea style={{ width: "100%", height: "100px", padding: "10px" }} value={comment} placeholder="상품 코멘트 하기" onChange={handleChange}></textarea>
          </div>


        </div>

      </div>
      <div className="w" style={{ textAlign: "center", marginTop: "30px" }}>
        <div className="w-9" style={{ display: "inline-block" }}>
          <input type="button" onClick={onSave} style={{ marginLeft: "15px" }} className={'save_active'} value="선택 완료" />
        </div>
      </div>
    </div>
  );
}

styled.active = `background : #000`;

const LanguageButton = styled.input`
  padding: 3px 10px;
  color: ${props => (props.active ? "#FFF" : "#000")};
  background: ${props => (props.active ? "#5B9BD5" : "#FFF")};
`

export default UpdateOptionSpot;
