import { onlyNum, numWithComma } from "./regax";

export function getAllPrice(adult, child, adultPrice, childPrice) {
  adultPrice = adultPrice.toString().replace(onlyNum, "");
  childPrice = childPrice.toString().replace(onlyNum, "");

  Number(adultPrice);
  Number(childPrice);

  return (adult * adultPrice + child * childPrice)
    .toString()
    .replace(numWithComma, ",");
}
