import React from "react";

import WideSwiperWithNoDots from "../Swiper/WideSwiperWithNoDots";

function SpotDescComponent(props) {
  // console.log(props.data);
  return (
    <div className="m-4">
      <WideSwiperWithNoDots list={props.data.imagesDtoList} autoplay={false} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="text-m text-bold" style={{ color: "#121212" }}>
            <span>{props.data.masterOptionSpots.ordNo}.</span> {props.data.spotNm}
            <input
                id={"input_" +props.data.masterOptionSpots.ordNo}
                style={{
                    width: "1px",
                    border: "1px",
                }}
                readonly
            />
        </div>
        {props.data.masterOptionSpots.timehhNm !== "" && (
          <div
            style={{
              height: "15%",
              background: "#ffd775",
              borderRadius: "100px",
              fontSize: "12px",
              padding: "2px 9px",
            }}
          >
            {props.data.masterOptionSpots.timehhNm} :{" "}
            {props.data.masterOptionSpots.timemmNm}
          </div>
        )}
      </div>
      <div
        className="text-xsm m-1"
        style={{ color: "#454545", marginBottom: "20px" }}
      >
        <pre style={{overflowWrap:"break-word",whiteSpace:"pre-wrap"}}>{props.data.masterOptionSpots.descKoNm}</pre>
      </div>
    </div>
  );
}

export default SpotDescComponent;
