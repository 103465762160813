import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Header from "../../components/views/Layout/Header/Header";
import Footer from "../../components/views/Layout/Footer/Footer";

import NewDatebar from "../../components/views/Calender/NewDatebar";
import WideSwiper from "../../components/Swiper/WideSwiper";
import NarrowScrolling from "../../components/Swiper/NarrowScrolling";
import NewNarrowScrolling from "../../components/Swiper/NewNarrowScrolling";
import MySheet from "../../components/Sheet/MySheet";
import GmpCalender from "../../components/views/Calender/GmpCalender";

import { Title, CircularButton } from "../../styles/GlobalStyle";
import { getDefaultDay, getTodayDate, parseDatewithDash } from "../../js/getDate";
import { server_url,img_url } from "../../server";

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import "../../styles/css/Calendar.css";
/* css */
import "../../styles/css/basic.css";
import "../../styles/css/style.css";
import "../../styles/css/fonts.css";
import "../../styles/css/xeicon.min.css";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

import v1 from "../../images/new/v01.jpg"
import v2 from "../../images/new/v02.jpg"
import v3 from "../../images/new/v03.jpg"

/* Swiper */

/* Footer */
import { FooterText1, FooterText2 } from "../../styles/GlobalStyle";
import kakaotalk from "../../images/logo/kakaotalk.png";
import naver_blog from "../../images/logo/naver_blog.png";
import instagram from "../../images/logo/instagram.svg";
import facebook from "../../images/logo/facebook.svg";
import youtube from "../../images/logo/youtube.svg";
import twitter from "../../images/logo/twitter.svg";

function MainPage() {
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  const [departure, setDeparture] = useState(
    parseDatewithDash(getDefaultDay())
  );

  const [userLang, setUserLang] = useState("ko");
  // const [fxCodeCd, setFxCodeCd] = useState("KRW");
  const date = parseDatewithDash(getDefaultDay());

  const [cityList, setCityList] = useState([]);
  const [spotList, setSpotList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [pcList, setPcList] = useState([]);

  const [isLogined, setIsLogined] = useState(
    window.sessionStorage.getItem("email") && true
  );

  (localStorage.getItem("language") !== 'undefined' && localStorage.getItem("language") !== null) ? localStorage.getItem("language") : localStorage.setItem("language", "ko");
  (localStorage.getItem("fxCodeCd") !== 'undefined' && localStorage.getItem("fxCodeCd") !== null) ? localStorage.getItem("fxCodeCd") : localStorage.setItem("fxCodeCd", "KRW");
  const changeLanguageSelectBox = (e) => {
    console.log("선택된 언어 : " + e.target.value);


    localStorage.setItem("language", e.target.value)
    let fxCodeCd = "KRW";
    let langText = "KO";
    switch (localStorage.getItem("language")) {
      case "ko":
        fxCodeCd = "KRW"
        langText = "KO | ₩"
        break;
      case "en":
        fxCodeCd = "USD"
        langText = "EN | US$"
        break;
      case "zhchs":
        fxCodeCd = "HKD"
        langText = "简 / HK$"
        break;
      case "zhcht":
        fxCodeCd = "HKD"
        langText = "繁 / HK$"
        break;
      default:
        fxCodeCd = "KRW"
        langText = "KO | ₩"
    }
    let el = document.getElementById("div_lang");
    el.textContent = langText;
    localStorage.setItem("fxCodeCd", fxCodeCd);
    i18n.changeLanguage(localStorage.getItem("language"));
    window.location.reload();
  }

  useEffect(() => {
    let body = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
      fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
      date: date,
    };

    // console.log(body);

    const getPlans = async () => {
      try {
        const res = await axios.post(`${server_url}/main`, body);
        console.log(res.data);

        setCityList(res.data.cityList);
        setPlanList(res.data.planList);
        setSpotList(res.data.spotList);
        setPcList(res.data.pcList);

        setPromotionList(res.data.promotionList);
      } catch (e) {
        console.log(e);
      }
    };

    getPlans();
  }, [date]);

  /* token이 있지만 여권 성문 성이 없을 경우 -> 회원정보변경으로 이동 */
  const tutorialPromotions = promotionList.filter((el) => el.typeCd === "MAINBANNER"); 
  console.log(tutorialPromotions);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div>
      <Header userLang={userLang} setUserLang={setUserLang} />
      <div className="new_visual">
        <div className="text">
          <p className="t1">Go My Plan</p>
          <p className="t2">{t('MAIN.MACOPY01')}</p>
        </div>
        <div className="rolling swiper-initialized swiper-horizontal swiper-backface-hidden">
          <div className="swiper-wrapper">
            <Swiper
              style={{
                '--swiper-navigation-color': '#ff9415',
                '--swiper-pagination-color': '#ff9415',
              }}
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {tutorialPromotions.map((item)=>{
                let imgURL = `${img_url}/${item.typeCd}/${item.savenameNm}`;
                return (
                  <SwiperSlide style={{height:"600px"}}><a style={{cursor:"pointer",width:"100%",height:"100%"}}><div onClick={()=>window.open(item.promoLink)}  className="bg"></div>
                  <div style={{display:"flex",height:"100%"}}><img  style={{
                    objectFit: 'cover',
                    width: windowWidth >= 600 ? '600px' : '100%',
                    maxHeight: '600px',
                    margin: "auto"
                  }} src={imgURL} /></div></a></SwiperSlide>
                )
              }
                
                
              )}
              {/* <SwiperSlide><a><div className="bg"></div><img src={v1} /></a></SwiperSlide>
              <SwiperSlide><a><div className="bg"></div><img src={v2} /></a></SwiperSlide>
              <SwiperSlide><a><div className="bg"></div><img src={v3} /></a></SwiperSlide> */}
            </Swiper>
          </div>

        </div>

      </div>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        <NewDatebar departure={departure} />
      </div>
      <div className="new_home_city">
        <div className="new_home_head">
          <p className="tit">Hot Travel City</p>
          <a className="more" onClick={() => {
            navigate(`/plans/HKG`, {
              state: {
                departure: departure,
                searchtype: "all",
              },
            });
          }}>
            <p>{t('MAIN.ALLPLAN')}</p>
            <i className="xi-angle-right"></i>
            <i className="xi-angle-right"></i>
          </a>
        </div>
        <div className="rolling swiper-initialized swiper-horizontal swiper-backface-hidden">
          <NewNarrowScrolling
            list={cityList}
            type="city"
            departure={departure} />
        </div>
      </div>


      <div className="m">
        <div style={{ margin: "0 auto" }}>
          <div className="m">

            {/* <Title>{t('MAIN.MACOPY01')}</Title> */}
            <div className="m-4">

              <MySheet
                isOpen={isOpen}
                setOpen={setOpen}
                children={
                  <GmpCalender
                    isOpen={isOpen}
                    setOpen={setOpen}
                    setDeparture={setDeparture}
                    departure={departure}
                  />
                }
              />
            </div>
          </div>
          {/* <div>
            <NarrowScrolling
              list={cityList}
              type="city"
              departure={departure}
            />
          </div> */}

          {/* <div style={{ width: "100%" }}>
            <div
              style={{
                margin: "auto",
                marginTop: "2%",
                width: "45%",
              }}
            >
              <CircularButton
                className="text-xsm"
                onClick={() => {
                  navigate(`/plans/HKG`, {
                    state: {
                      departure: departure,
                      searchtype: "all",
                    },
                  });
                }}
                backgroundcolor="#42A9EC"
                style={{ height: "26px", marginRight: "25px", padding: "0 5px" }}
              >
                {t('COMMON.02')}
              </CircularButton>
            </div>
          </div> */}

          <div className="m-4">
            <WideSwiper
              title={t('MAIN.MACOPY02')}
              list={promotionList.filter((el) => {
                return el.typeCd === "TUTORIAL";
              })}
            />
          </div>
          <div className="m-4">
            <NarrowScrolling
              title={t('MAIN.MACOPY04')}
              list={pcList}
              type="PLNCreator"
              departure={departure}
            />
          </div>
          <div className="m-4">
            <NarrowScrolling title={t('MAIN.MACOPY03')} list={spotList} type="spot" />
          </div>
          {/* <div className="m-4">
            <WideSwiper
              title={t('MAIN.MACOPY05')}
              list={promotionList.filter((el) => {
                return el.typeCd === "MAINBANNER";
              })}
            />
          </div> */}
          <div className="m-4">
            <NarrowScrolling
              title={t('MAIN.MACOPY06')}
              list={planList}
              type="plan"
              departure={departure}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "20px 10px", backgroundColor: "#EEEEEE" }}>
        <div style={{ display: "flex", justifyContent: "center", display: "none" }}>
          <select
            style={{
              width: "100%",
              borderRadius: "90px",
              height: "40px",
            }}
            onChange={changeLanguageSelectBox}
            id={"select_lang"}
            defaultValue={
              localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko"
            }
          >
            <option key="ko" value="ko">
              Language / 언어 : 한국어
            </option>
            <option key="en" value="en">
              Language / 언어 : English
            </option>
            <option key="zhcht" value="zhcht">
              Language / 언어 : 简 体
            </option>
            <option key="zhchs" value="zhchs">
              Language / 언어 : 繁 体
            </option>
          </select>
          <input
            id={"input_lang"}
            style={{
              width: "1px",
              border: "1px",
            }}
            readOnly
          />
        </div>
        <div style={{ display: "flex" }}>
          <FooterText1 style={{ textAlign: "right" }}
           onClick={() => {
            navigate("/mypage/cs");
          }}>{t('MAIN.MALINK01')}</FooterText1>
          <FooterText1 style={{ textAlign: "center" }}
            onClick={() => {
              window.open("https://www.gomyplan.info");
            }}
          >
            {t('MAIN.MALINK02')}
          </FooterText1>
          <FooterText1
            onClick={() => {
              navigate("/tos");
            }}
          >
            {t('MAIN.MALINK03')}
          </FooterText1>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "25px 0",
            backgroundColor: "#EEEEEE"
          }}
        >
          <div
            className="c" style={{ marginRight: "15px" }}
            onClick={() => {
              window.open("https://www.facebook.com/GMP88", "_blank");
            }}
          >
            <img alt="" src={facebook} />
          </div>
          <div
            className="c" style={{ marginRight: "15px",display:"none" }}
            onClick={() => {
              window.open("https://www.twitter.com/gomyplan", "_blank");
            }}
          >
            <img
              alt=""
              src={twitter}
            />
          </div>

          <div
            className="c" style={{ marginRight: "15px" }}
            onClick={() => {
              window.open("https://www.instagram.com/gomyplan/", "_blank");
            }}
          >
            <img alt="" src={instagram} />
          </div>
          <div
            className="c"
            onClick={() => {
              window.open("https://www.youtube.com/@gomyplan", "_blank");
            }}
          >
            <div
              className="c"
            >
              <img alt="" src={youtube} style={{ width: "30px" }} />
            </div>
          </div>

        </div>

        <div style={{textAlign:"center"}}>
          <FooterText2>
            {t('MAIN.MAFOOT02')}
            <br />
            {t('MAIN.MAFOOT03')}
            <br />
            {t('MAIN.MAFOOT04')}
            <br />
            <br />
            <br />
            {t('MAIN.MAFOOT05')}
            <br />
            {t('MAIN.MAFOOT06')}
            <br />
            <br />
            <br />

            {t('MAIN.MAFOOT07')}
            <br />
            <a href="mailto:biz@gomyplan.com">{t('MAIN.MAFOOT08')}</a>
            <br />
            <br />
            {t('MAIN.MAFOOT09')} 
            <br />
            <a href="mailto:cs@gomyplan.com">{t('MAIN.MAFOOT10')}</a>
            <br />
            <br />
            <br />
            <br />
            {t('MAIN.MAFOOT01')}
            <br />
          </FooterText2>
        </div>


      </div>
      <div style={{ backgroundColor: "#EEEEE", display: "flex", height: "70px" }}>

      </div>
      <Footer />
    </div>
  );
}

export default MainPage;
