import React from "react";
import Slider from "react-slick";

import backArrow from "../../images/icons/backArrow.png";

import { img_url } from "../../server";
import { Title } from "../../styles/GlobalStyle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import promotion from "../../images/img/promotion.png";

function WideSwiper({ title, list }) {
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );

  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    prevArrow: (
      <SlickButtonFix>
        <img
          src={backArrow}
          alt=""
          width="12"
          height="20"
          style={{ transform: "scaleX(-1)" }}
        />
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <img src={backArrow} alt="" width="12" height="20" />
      </SlickButtonFix>
    ),
  };

  return (
    <div>
      <Title>{title}</Title>
      <Slider {...setting}>
        {list.map((item) => {
          return (
            <div key={item}>
              <div
                className="w"
                style={{
                  height: "140px",
                  // backgroundImage: `url(${promotion})`,
                  backgroundImage: `url(${img_url}/${item.typeCd}/${item.savenameNm})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",

                  borderRadius: "10px",
                }}
                onClick={() => {
                  item.promoLink && window.open(item.promoLink);
                }}
              ></div>
              {/* <img
                // src={img}
                // src={}
                src={promotion}
                alt=""
                height="140px"
                style={{ position: "absolute", borderRadius: "10px" }}
              /> */}
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default WideSwiper;
