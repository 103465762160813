import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import { server_url } from "../../server";
import { parseDatewithDash, getTodayDate } from "../../js/getDate";

import img_default from "../../images/img/img_default.png"

function SavedOption(props) {
  const navigate = useNavigate();

  const [data, setData] = React.useState(props.data);

  const [publicYn, setPublicYn] = useState("");

  const [regTime, setRegTime] = useState();
  const [updateTime, setUpdateTime] = useState();

  const [img,setImg] = useState(img_default);

  useEffect(() => {
    if (data.publicYn == 'Y') {
      setPublicYn('공개');
    } else {
      setPublicYn('비공개');
    }
    if (data.regUt != null && data.regUt != "") {
      let regUt = changeUnixTime(data.regUt);
      setRegTime(regUt);
    }
    if(data.updUt != null && data.updUt != ""){
      let updUt = changeUnixTime(data.updUt);
      setUpdateTime(updUt);
    }
    if(data.savenameNm != null && data.savenameNm != ''){
      setImg(data.savenameNm);
    }

  }, [data]);

  const changeUnixTime = (value)=>{
    const timestampInMilliseconds = value * 1000; // 밀리초로 변환
    const date = new Date(timestampInMilliseconds);

      // 원하는 형식으로 날짜와 시간을 추출
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDateTime;
  };

  return (
    <div
      className="w-9"
      style={{
        borderBottom: "1px solid #f4f4f4",
        padding: "10px",
        display: "flex"
      }} onClick={()=>{navigate("/mypage/makingOptionSpot",{state:{data:data.optionSq}})}}>
      <div style={{ border: "2px solid", position: "relative",width:"124px",height:"124px"}}>
        <span style={{ margin: "10px", background: "#FFC73C", color: "#FFF", borderRadius: "10px", padding: "3px", position: "absolute" }}>{publicYn}</span>
        <img src={img} style={{ width: "120px", height: "120px" }} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
          , marginLeft: "10px", width:"100%"
        }}>

        <div style={{ fontSize: "10px", color: "#6b6b6b", fontWeight: "600"}}>
          옵션 코드 : {data.optionCd}
        </div>

        <div style={{ maxWidth: "100%" ,fontWeight:"800", fontSize:"20px",marginTop:"10px"}}>
          {data.titleKoNm}
        </div>
        <div style={{color:"#4472C4",marginTop:"5px"}}>
          <span>최초 등록 : </span>
          <span>{regTime}</span>
        </div>
        <div style={{color:"#4472C4",marginTop:"5px"}}>
          <span>최근 수정 : </span>
          <span>{updateTime}</span>
        </div>
      </div>

    </div>
  );
}

export default SavedOption;
