import React, { useState } from "react";

import styled from "styled-components";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import InformationPolicy from "../RegisterPage/InformationPolicy";
import Terms from "../RegisterPage/Terms";

function Tos() {
  const [isTos, setIsTos] = useState(true);

  return (
    <div className="container">
      <PageHeader title="이용약관" />
      <div
        className="m-8 w-9"
        style={{
          boxShadow:
            "-2px -5px 4px rgba(255, 255, 255, 0.4), 2px 2px 7px rgba(0, 0, 0, 0.1)",
          borderRadius: "100px",
        }}
      >
        <ul
          className="text-m"
          style={{
            textAlign: "center",
            padding: "0",
            marginBottom: "0",
          }}
        >
          <LoginTool
            className={isTos ? "active" : "inactive"}
            onClick={() => {
              setIsTos(true);
            }}
          >
            이용약관
          </LoginTool>
          <LoginTool
            className={!isTos ? "active" : "inactive"}
            onClick={() => {
              setIsTos(!true);
            }}
          >
            개인정보 처리방침
          </LoginTool>
        </ul>
      </div>
      <div className="m-2 w-9">
        {isTos && <Terms />}
        {!isTos && <InformationPolicy />}
      </div>
    </div>
  );
}

const LoginTool = styled.li`
  list-style: none;
  display: inline-block;
  padding: 5px 5px;
  font-weight: 500;

  padding-bottom: 16px;

  width: 40%;
`;

export default Tos;
