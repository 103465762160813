import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { server_url, img_url } from "../../server";

import backArrow from "../../images/icons/backArrow.png";
import img_search from "../../images/icons/search.svg";
import img_add from "../../images/icons/file_add.svg";

import img_default from "../../images/img/img_default.png"


import { generateRandomNumber } from "../../js/common"



function PlanSearchOption() {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [activityYn, setActivityYn] = useState("");
    const [planSq, setPlanSq] = useState("");
    const [planCd, setPlanCd] = useState("");
    const [dayNo, setDayno] = useState(0);
    const [activity, setActivity] = useState([]);
    const [spot, setSpot] = useState([]);
    const [email] = useState(window.sessionStorage.getItem("email"));
    const [searchWord, setSearchWord] = useState("");
    const [title, setTitle] = useState("");
    const [isInputEnabled, setInputEnabled] = useState(false); // 새로운 상태 추가


    useEffect(() => {
        if (location.state != null) {
            setData(location.state.data);
        }
    }, []);

    useEffect(() => {
        if (data != null && data.data != undefined) {
            console.log(data);
            setDayno(data.dayNo);
            setPlanSq(data.data.planSq);
            setPlanCd(data.data.planCd);
            switch (activityYn) {
                case 'Y':
                    setTitle("액티비티");
                    break;
                case 'N':
                    setTitle("스팟");
                    break;
            }
        }
    }, [data])


    /* 젤 앞 대문자 설정 */
    const capitalizeFirstLetter = (str) => {
        if (str.length === 0) {
            return str;
        }

        const firstLetter = str.charAt(0).toUpperCase();
        const remainingLetters = str.slice(1);

        return firstLetter + remainingLetters;
    };






    const handleEnterKey = async (e) => {
        if (e.key == 'Enter') {
            console.log(searchWord);
            setInputEnabled(false);
            let body = {
                searchWord: searchWord
            }
            const res = await axios.post(`${server_url}/plan/spot/search/toppickSpot`, body).then((response) => {
                console.log(response);
                const updatedSpot = response.data.list.map((el) => ({
                    ...el,
                    isChecked: false, // 기본값으로 isChecked를 false로 설정
                }));
                setSpot(updatedSpot);
            });
            setInputEnabled(true);
        }
    }

    const handleSearch = async (e) => {
        console.log(searchWord);
        //setInputEnabled(false);
        let body = {
            searchWord: searchWord
        }
        const res = await axios.post(`${server_url}/plan/spot/search/toppickSpot`, body).then((response) => {
            console.log(response);
            const updatedSpot = response.data.list.map((el) => ({
                ...el,
                isChecked: false, // 기본값으로 isChecked를 false로 설정
            }));
            setSpot(updatedSpot);
        });
        //setInputEnabled(true);

    }

    const handleChange = (e) => {
        let value = e.target.value;
        setSearchWord(value);
    }

    const handleCategoryButton = (e) => {
        let value = e.target.value;
        console.log("1004");
        console.log(value);
        setSearchWord(value);
        //setActivity([]); // 카테고리가 변경될 때, 이전 데이터 초기화
    }

    useEffect(() => {
        setSpot([]); //초기화
        handleSearch();
    }, [searchWord]);

    useEffect(() => {
        console.log("1004");
        console.log(spot);
    }, [spot]);

    /* 12글자까지 자르기 설정 */
    const truncateString = (str) => {
        const index = str.indexOf("@");
        if (index !== -1 && index <= 12) {
            return str.slice(0, index);
        } else if (str.length > 12) {
            return str.slice(0, 12);
        } else {
            return str;
        }
    }

    useEffect(() => {
        if (spot.length > 0) {
            setInputEnabled(true); // spot.map이 렌더링된 후 input을 활성화
        }
    }, [spot]);




    const SaveComment = async () => {
        // selectedActivity 배열에는 isChecked가 true인 항목만 포함됩니다.
        const selectedSpot = spot.filter((el) => el.isChecked === true).map(({ isChecked, ...rest }) => rest);
        console.log(selectedSpot);

        let optionCd = "OPT_" + data.data.cityCd + "_" + truncateString(email) + "_" + generateRandomNumber(5);
        console.log(spot);

        let body = {
            planSq: planSq,
            planCd: planCd,
            day: dayNo,
            optionCd: optionCd,
            cityCd: data.data.cityCd,
            uploadUser: email,
            companySq: data.data.companySq,
            list: selectedSpot
        }
        console.log(body);

        const res = await axios.post(`${server_url}/plan/makeOption/selectSpot/slot/save`, body).then((response) => {
            console.log("0919");
            console.log(response);
            if (response.data.result == "success") {
                const sendData = {
                    ...data,
                    optionSq: response.data.optionSq,
                    planSq: planSq,
                    planCd: planCd,
                    orderNo: response.data.orderNo,

                };
                navigate("/mypage/makingPlanSlot", { state: sendData });
            }
        });


    };


    return (
        <div>
            <div className={"w"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "0", backgroundColor: "#ffffff", marginTop: "20px" }}>
                <img src={backArrow} alt="" width="10px" height="15px" style={{ transform: "scaleX(-1)", marginLeft: "1%" }} className="c" onClick={() => { window.history.back(); }} />
                <div style={{ color: "5c80ff", fontWeight: "800", fontSize: "20px", margin: "auto", marginLeft: "30px", display: "flex", width: "100%" }}>
                    <input type="text" style={{ width: "100%" }} onKeyDown={handleEnterKey} value={searchWord} onChange={handleChange} disabled={!isInputEnabled} />
                    <img src={img_search} style={{ width: "30px", marginLeft: "auto", transform: "translate(-40px)" }} onClick={handleSearch} />
                </div>
            </div>
            <div style={{ overflow: "auto", paddingTop: "30px" }}>
                <span style={{ fontWeight: "bold" }}>Day {dayNo} 추천 {title}</span>
                <div style={{ marginTop: "20px" }}>
                    <div>
                        <input type="button" value="Airport" onClick={handleCategoryButton} />
                    </div>
                    <div style={{ maxHeight: "450px", marginTop: "20px", overflow: "auto" }}>
                        {spot.map((el, index) => {
                            let title = "title" + capitalizeFirstLetter(language) + "Nm";
                            console.log(searchWord);

                            let savenameNm = img_default;
                            if (el.comImages != null) {
                                if (savenameNm.includes("https")) {
                                    savenameNm = el.comImages.savenameNm;
                                } else {
                                    savenameNm = img_url + "/" + el.comImages.typeCd + "/" + el.comImages.savenameNm;
                                }
                            }
                            // title 값이 비어 있으면 해당 요소를 렌더링하지 않음
                            if (!el.comSpot[title]) {
                                return null;
                            }

                            return (
                                <div key={el.comSpot.spotSq} className="w-9" style={{ display: "flex", border: "1px solid #000" }}>
                                    <img src={savenameNm} style={{ width: "100px", height: "100px" }} />
                                    <div style={{ margin: "10px", display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                                        <span style={{ margin: "5px 0",whiteSpace: "nowrap",  overflow: "hidden",     textOverflow: "ellipsis",    display: "block"}}>{el.comSpot[title]}</span>
                                        <span style={{
                                            margin: "5px 0",
                                            color: "#56CBF5",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "block",
                                        }}>
                                            {el.comSpot.keywordNm}
                                        </span>
                                        {/* Media query for mobile */}
                                        <style jsx>{`
        @media (max-width: 500px) {
            span {
                max-width: 150px; // Mobile style, adjust as needed
            }
        }
    `}</style>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
                                        <input
                                            type="checkbox"
                                            value={el.comSpot.spotSq}
                                            style={{ height: "30px", width: "30px" }}
                                            checked={el.isChecked} // isChecked 상태에 따라 체크박스 상태 설정
                                            onChange={() => {
                                                const updatedSpot = [...spot];
                                                const updatedItemIndex = updatedSpot.findIndex((item) => item.comSpot.spotSq === el.comSpot.spotSq);

                                                if (updatedItemIndex !== -1) {
                                                    updatedSpot[updatedItemIndex].isChecked = !el.isChecked; // isChecked 값을 반전
                                                    setSpot(updatedSpot); // 상태 업데이트
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>


            <div style={{ position: "fixed", bottom: 0, width: "100%", maxWidth: "740px" }}>
                <input type="button" value={"수정 완료"} onClick={() => SaveComment()} style={{ background: "#4f95d5", border: "none", "borderRadius": "10px", color: "#fff", height: "30px", width: "100%" }} />
            </div>
        </div>
    )

}

export default PlanSearchOption;