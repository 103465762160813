import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import PageHeader from "../../components/views/Layout/Header/PageHeader";
import Datebar from "../../components/views/Calender/Datebar";
import MySheet from "../../components/Sheet/MySheet";
import GmpCalender from "../../components/views/Calender/GmpCalender";
import NarrowScrolling from "../../components/Swiper/NarrowScrolling";
import PlanList from "../../components/Plan/PlanList";

import { Title, CircularButton, ImgTitle } from "../../styles/GlobalStyle";
import { parseDatewithDash, getTodayDate } from "../../js/getDate";
import {img_url, server_url} from "../../server";
import { parseCityName } from "../../js/parseString";
import favicon from "../../images/favicon.png";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

function CitySpotPage() {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();

  const { state } = useLocation();
  const params = useParams();

  const navigate = useNavigate();

  const [departure, setDeparture] = useState(state.departure);

  const [isOpen, setIsOpen] = useState(false);

  const [hotspotList, setHotSpotList] = useState([]);
  const [planList, setPlanList] = useState([]);

  const [data, setData] = useState([]);

  const [pcList, setPcList] = useState([]);

  // 2번 API
  useEffect(() => {
    let body = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
      fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
      date: parseDatewithDash(departure),
      countryCd: state.item.countryCd,
      cityEnNm: parseCityName(state.item.cityNm)[1]
        ? parseCityName(state.item.cityNm)[1]
        : "kor",
      cityKoNm: parseCityName(state.item.cityNm)[0],
      //   imageId: state.item.imageId,
      imageId: 2,
      countPlan: state.item.countPlan,
    };

    const getSpotList = async () => {
      try {
        const res = await axios.post(
          `${server_url}/spots/${params.citySq}/${params.cityCd}`,
          body
        );
        // console.log(res.data);

        setHotSpotList(res.data.hotspotList);
        setPcList(res.data.pcList);
      } catch (e) {
        console.log(e);
      }
    };

    getSpotList();
  }, []);

  useEffect(() => {
    let body = {
      userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
      fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
      today: getTodayDate(),
      startDate: parseDatewithDash(departure),
    };

    console.log(body);
    console.log(departure);

    const getPlanList = async () => {
      try {
        const res = await axios.post(
          `${server_url}/plans/${params.cityCd}`,
          body
        );

        console.log(res.data);

        setData(res.data);

        setPlanList(res.data.planList.filter((el) => el.toppickYn === "Y"));
      } catch (e) {
        console.log(e);
      }
    };

    getPlanList();
  }, [departure]);

  return (
    <div className="container">
      <PageHeader logo="true"/>
      <div className="m-8" style={{ width: "100%" }}>
        <div className="w">
          <div
              style={{
                position: "relative",

                height: "45vh",
                background: `url(${img_url}/${state.item.typeCd}/${state.item.savenameNm})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "20px 20px 0px 0px",
              }}
          >
              <br/>
              <div
                  style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                  }}
              >
                  <ImgTitle>{state.item.cityNm}</ImgTitle>
              </div>
          </div>
        </div>

          <div style={{width: "100%"}}>
              {/*<div*/}
              {/*    style={{*/}
              {/*        display: "flex",*/}
              {/*        justifyContent: "center",*/}
              {/*        alignItems: "center",*/}
              {/*        border: "1px solid",*/}
              {/*        borderRadius: "30px",*/}
              {/*        width: "40%",*/}
              {/*        height: "5vh",*/}
              {/*        margin: "4% auto auto",*/}
              {/*    }}*/}
              {/*    onClick={() => {*/}
              {/*        navigate(`/plans/${params.cityCd}`, {*/}
              {/*            state: {*/}
              {/*                departure: departure,*/}
              {/*                title: state.item.cityNm,*/}
              {/*            },*/}
              {/*        });*/}
              {/*    }}*/}
              {/*>*/}
              {/*    <div>*/}
              {/*        <img src={favicon} height="40px" />*/}
              {/*    </div>*/}
              {/*    &nbsp;&nbsp;*/}
              {/*    <div style={{fontWeight: "600"}}>*/}
              {/*        {data.countPlan}개 모든 플랜 보기*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div
                  style={{
                      margin: "auto",
                      marginTop: "2%",
                      width: "45%",
                  }}
              >
                  <CircularButton
                      className="text-xsm"
                      onClick={() => {
                          navigate(`/plans/${params.cityCd}`, {
                              state: {
                                  departure: departure,
                                  title: state.item.cityNm,
                              },
                          });
                      }}
                      backgroundcolor="#5c80ff"
                      style={{ height: "26px", marginRight: "25px", padding: "0 5px" }}
                  >
                      {data.countPlan}{t('CITY.CITY04')}
                  </CircularButton>
              </div>
          </div>
      </div>
      <div className="m-3" style={{ width: "100%" }}>
        <NarrowScrolling
            title={t('CITY.CITY02')}
            type="PLNCreator"
            list={pcList}
            departure={departure}
        />
      </div>
      <div className="m-3" style={{ width: "100%" }}>
        <NarrowScrolling title={t('CITY.CITY01')} type="spot" list={hotspotList} />
      </div>
      {/* 인기 스팟을 제외하지 않은 전체 스팟. 인기 스팟을 제외하려면 spotList에서 hotspotYn: N 인 항목만 표시 */}
      {/* <div className="m-3" style={{ width: "100%" }}>
        <NarrowScrolling title="전체 스팟" type="plan" list={spotList} />
      </div> */}
      <div className="m-3" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Title>추천 플랜</Title>
        </div>

        <div
          onClick={() => {
              setIsOpen(true);
          }}
        >
          <Datebar departure={departure} />
        </div>
        <MySheet
          isOpen={isOpen}
          setOpen={setIsOpen}
          children={
              <GmpCalender
                  isOpen={isOpen}
                  setOpen={setIsOpen}
                  setDeparture={setDeparture}
                  departure={departure}
              />
          }
        />

        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          {planList.map((item) => {
            return (
              <PlanList key={item.planSq} item={item} pclist={pcList} departure={departure} />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CitySpotPage;
