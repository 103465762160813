import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-modal";

import axios from "axios";
import styled from "styled-components";

import Slider from "react-slick";

import { useTranslation } from "react-i18next";

import { server_url } from "../../server";

/* img */
import homeMenu from "../../images/icons/homeMenu.png";
import backArrow from "../../images/icons/backArrow.png";
import img_search from "../../images/icons/search.svg";
import cityTour from "../../images/icons/optionDetailPage/cityTour_.png";
import weekly from "../../images/icons/optionDetailPage/weekly_.png";
import recommend from "../../images/icons/optionDetailPage/recommend_.png";
import photoSpot from "../../images/icons/optionDetailPage/photoSpot_.png";

import { Title } from "../../styles/GlobalStyle";
import GoogleMapPolylineComponent from "../../components/GoogleMap/GoogleMapPolylineComponent";



function MyModal(props) {
  const [optionSq, setOptionSq] = useState(props.element);

  const location = useLocation();
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const [email] = useState(window.sessionStorage.getItem("email"));
  const [data, setData] = useState();
  const [lang, setLang] = useState("ko");

  const [active, setActive] = useState(false);
  const [spotSq, setSpotSq] = useState("");
  const [ordNo, setOrdNo] = useState("");
  const [photoReference, setPhotoReference] = useState("");
  const [spotTitle, setSpotTitle] = useState("");
  const [timehhNm, setTimehhNm] = useState("");
  const [timemmNm, setTimemmNm] = useState("");

  let dayEn = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let dayKo = ["일", "월", "화", "수", "목", "금", "토"];
  let dayZhchs = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
  let dayZhcht = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];

  //언어 세팅
  const [languageComment, setLanguageComment] = useState(language);
  //옵션 제목
  const [optionTitle, setOptionTitle] = useState("");
  //옵션 설명
  const [optionDesc, setOptionDesc] = useState("");
  //옵션 카테고리
  const [optionCategory, setOptionCategory] = useState("");
  //옵션 영업일
  const [optionDayNm, setOptionDayNm] = useState([]);
  //옵션 추천옵션
  const [optionTopick, setOptionTopick] = useState("");
  //옵션 포토스팟
  const [optionPhotoSpot, setOptionPhotoSpot] = useState("");
  //회사 이미지
  const [companyImage, setCompanyImage] = useState("");
  //회사 이름
  const [companyTitle, setCompanyTitle] = useState("");
  //옵션 상세 설명
  const [optionFeatures, setOptionFeatures] = useState([]);
  //구글 좌표
  const [coordinate, setCoordinate] = useState([]);
  //스팟 리스트 공통
  const [spotList, setSpotList] = useState([]);
  //MasterOptionImage
  const [masterOptionImage,setMasterOptionImage] = useState([]);




  const setting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
};



  Modal.setAppElement("#root");

  useEffect(() => {
    props.isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [props.isOpen]);

  useEffect(()=>{
    console.log("0914");
    console.log(props);
    console.log(optionSq);
    
    if(optionSq != ""){
      getAllOption();
    }
   
  },[optionSq]);

  const getAllOption = () => {
    let body = {
      optionSq: optionSq
    }
    const res = axios.post(`${server_url}/myplan/option/getAllOption`, body);
    res.then((response) => {
      console.log("ttttttttt")
      console.log(response);
      if (response.data != null) {
        let item = response.data.data;
        setData(item);
        setLang(language);
      }
    });
  }

  useEffect(() => {
    console.log(lang);
    console.log(data);
    if (data != null || data != undefined) {
      settingOption();
    }

  }, [lang, data]);

  const settingOption = () => {
    if (data != null) {
      let title = "title" + capitalizeFirstLetter(lang) + "Nm";
      let desc = "desc" + capitalizeFirstLetter(lang) + "Nm";
      let code = "code" + capitalizeFirstLetter(lang) + "Nm";
      let companyTitle = "title_" + lang;

      let masteroption = data.masterOption;
      let comCode = data.comCode;
      let mgrCompany = data.mgrCompany;
      setOptionTitle(masteroption[title]);
      setPhotoReference(masteroption.savenameNm);
      setOptionDesc(masteroption[desc]);
      if (comCode[code] != null) {
        setOptionCategory(comCode[code])
      } else {
        setOptionCategory("No value entered");
      }
      let dayNmStr = masteroption.dayNm;
      if (dayNmStr.includes("|")) {
        let day = "day" + capitalizeFirstLetter(lang);
        let dayArray;
        switch (lang) {
          case "en":
            dayArray = dayEn;
            break;
          case "ko":
            dayArray = dayKo;
            break;
          case "zhcht":
            dayArray = dayZhcht;
            break;
          case "zhchs":
            dayArray = dayZhchs;
            break;
          default:
            dayArray = dayEn;
            break;
        }
        setOptionDayNm(dayNmStr.split("|").filter((v) => v !== "").map((v) => (v = dayArray[dayEn.indexOf(v)])));
      }
      setOptionTopick(masteroption.toppickYn);
      setOptionPhotoSpot(masteroption.phtospotYn);
      setCompanyImage(data.companyImage);
      setCompanyTitle(mgrCompany[companyTitle]);
      setOptionFeatures(data.masterOptionFeatures)
      setSpotList(data.comSpots);
      if (data.comSpots != null) {
        const updatedCoordinate = data.comSpots.map((el) => {
          let spotDesc = "desc" + capitalizeFirstLetter(lang) + "Nm";
          return {
            lat: Number(`${el.comSpot.latNm}`),
            lng: Number(`${el.comSpot.longNm}`),
            orderNo: el.orderNo,
            descNm: el[spotDesc]
          };
        });
        // setCoordinate 함수를 사용하여 상태를 업데이트
        setCoordinate(updatedCoordinate);
      }
      setMasterOptionImage(data.masterOptionImages);
    }
  }

   /* 젤 앞 대문자 설정 */
   const capitalizeFirstLetter = (str) => {
    if (str.length === 0) {
      return str;
    }
    const firstLetter = str.charAt(0).toUpperCase();
    const remainingLetters = str.slice(1);

    return firstLetter + remainingLetters;
  };


  const handleButtonClick = (lang) => {
    setLanguageComment(lang);
    switch (lang) {
      case "ko": case "한국어":
        setLang("ko");
        break;
      case "English":
        setLang("en");
        break;
      case "中文繁體":
        setLang("zhcht");
        break;
      case "中文簡體":
        setLang("zhchs");
        break;
    }
  }
  
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => {
        props.setIsOpen(false);
      }}
      style={customStyles}
    >
      <div className="container">
      <div className={"w"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "0", backgroundColor: "#ffffff", marginTop: "20px" }}>
        <div style={{ color: "5c80ff", fontWeight: "800", fontSize: "20px", margin: "auto" }}>미리 보기</div>
      </div>
      <div style={{ textAlign: "left", marginTop: "30px", overflow: "auto" }} className="w">
        <div>
        <div style={{marginTop:"15px"}}>
          <Slider {...setting}>
                {masterOptionImage.map((imgList) => {
                    return(
                        <div key={imgList} style={{ width: "100%", height: "130%"}} >
                            <div
                                style={{
                                    margin: "0px auto",
                                    borderRadius: "10px",
                                    height: "45vh",
                                    maxHeight: "45vh",
                                    objectFit: "cover",
                                    backgroundImage: `url(${imgList.savenameNm})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                            </div>
                        </div>
                    );
                })}
      </Slider>
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <LanguageButton
              type="button"
              value="한국어"
              active={languageComment === "한국어" || languageComment === "ko"}
              onClick={() => handleButtonClick("한국어")}
            />
            <LanguageButton
              type="button"
              value="English"
              style={{ marginLeft: "10px" }}
              active={languageComment === "English"}
              onClick={() => handleButtonClick("English")}
            />
            <LanguageButton
              type="button"
              value="中文繁體"
              style={{ marginLeft: "10px" }}
              active={languageComment === "中文繁體"}
              onClick={() => handleButtonClick("中文繁體")}
            />
            <LanguageButton
              type="button"
              value="中文簡體"
              style={{ marginLeft: "10px" }}
              active={languageComment === "中文簡體"}
              onClick={() => handleButtonClick("中文簡體")}
            />
          </div>
          
          <div style={{ marginTop: "30px" }}>
            <img src={photoReference} style={{ height: "35vh", width: "100%" }} />
          </div>
          <div style={{ margin: "15px 0 0 15px" }}>
            <span style={{ margin: "5px 0", fontWeight: "bold" }}>{optionTitle}</span>
          </div>
          <div style={{ margin: "15px 0 0 15px" }}>
            <pre style={{ margin: "5px 0" }}>{optionDesc}</pre>
          </div>

          <div className="m-4" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px", maxWidth: 52, margin: "0 auto", }}>
              <img src={cityTour} alt="" />
              <div style={{ fontSize: "12px", textAlign: "center" }}>        {optionCategory}  </div>
            </div>
            {optionDayNm.length !== 0 && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px", maxWidth: 52, margin: "0 auto", }}              >
                <img src={weekly} alt="" />
                {optionDayNm.length === 7 ? (
                  <div style={{ fontSize: "12px", textAlign: "center" }}>
                    매주 진행
                  </div>
                ) : (
                  <div style={{ fontSize: "12px", textAlign: "center" }}>
                    {optionDayNm.join(",")}
                    <br />
                    진행
                  </div>
                )}
              </div>
            )}
            {optionTopick == "Y" && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px", maxWidth: 52, margin: "0 auto", }}>
                <img src={recommend} alt="" />
                <div style={{ fontSize: "12px", textAlign: "center" }}>
                  추천 옵션
                </div>
              </div>
            )}
            {optionPhotoSpot == "Y" && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px", maxWidth: 52, margin: "0 auto", }}>
                <img src={photoSpot} alt="" />
                <div style={{ fontSize: "12px", textAlign: "center" }}>
                  포토 스팟
                </div>
              </div>
            )}
          </div>
          <div style={{ border: "1px solid rgb(210, 210, 210)", marginTop: "10px" }}></div>
          <div style={{ marginTop: "4%", marginLeft: "3%", }}                    >
            <div
              style={{ width: "70px", height: "70px", backgroundImage: `url(${companyImage})`, backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat", borderRadius: "100px", display: "inline-block", justifyContent: "space-between", }}            >
              <div style={{ width: "350%", marginLeft: "140%", marginTop: "30%", }}              >
                <pre>{companyTitle}</pre>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "30px" }}>
            <span style={{ marginLeft: "3%" }}>{t('OPTIONDETAIL.ODGRUP04')}</span>
          </div>

          {optionFeatures.map((el) => {

            if (el.groupId == "OPTIONINFO") {
              let title = "title" + capitalizeFirstLetter(lang) + "Nm";
              return (
                <div  className="text-sm m-1" key={el.optionfSq} style={{ fontWeight: "bold", marginTop: "3%", marginLeft: "3%", }}                >
                  {el[title]}
                </div>
              );
            }


          })}

          {/* 구분선 */}
          <div style={{ border: "1px solid #d2d2d2", marginTop: "5%", }}          ></div>
          <div className="container">

            <div className="w-9 m-1">
              <span>{t('OPTIONDETAIL.ODGRUP05')}</span>
              <ul style={{ listStyle: "none", color: "#454545", padding: 0 }}>

                {optionFeatures.map((el) => {
                  if (el.groupId == "OPTIONBKG") {
                    let title = "title" + capitalizeFirstLetter(lang) + "Nm";
                    return (
                      <li className="text-sm m-1" key={el.optionfSq+"OPTIONBKG"} >
                        {el[title]}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          {/* 구분선 */}
          <div style={{ border: "1px solid #d2d2d2", marginTop: "5%", }}          ></div>
          <div className="container">

            <div className="w-9 m-1">
              <span>{t('OPTIONDETAIL.ODGRUP06')}</span>
              <ul style={{ listStyle: "none", color: "#454545", padding: 0 }}>

                {optionFeatures.map((el) => {
                  if (el.groupId == "OPTIONTNC") {
                    let title = "title" + capitalizeFirstLetter(lang) + "Nm";
                    return (
                      <li className="text-sm m-1" key={el.optionfSq+"OPTIONTNC"} >
                        {el[title]}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          {/* 구분선 */}
          <div style={{ border: "1px solid #d2d2d2", marginTop: "5%", }}          ></div>
          <div className="container">
            <div className="w-9 m-1">
              <span>{t('OPTIONDETAIL.ODGRUP07')}</span>
              <ul style={{ listStyle: "none", color: "#454545", padding: 0 }}>
                {optionFeatures.map((el) => {
                  if (el.groupId == "OPTIONCXLPLC") {
                    let title = "title" + capitalizeFirstLetter(lang) + "Nm";
                    return (
                      <li className="text-sm m-1" key={el.optionfSq+"OPTIONCXLPLC"} >
                        {el[title]}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="m-2">
            <Title className="m-4" style={{ marginBottom: 0 }}>
              {t('OPTIONDETAIL.ODITIN02')}
            </Title>
            <GoogleMapPolylineComponent
              coordinate={coordinate}
            />
          </div>
          <div className="container">
            <div className="w-9">
              <Title className="m-4" style={{ marginBottom: 0 }}>
                {t('OPTIONDETAIL.ODITIN01')}
              </Title>
              <div className="m-4">
                {spotList.map((el, index) => {
                  let title = "title" + capitalizeFirstLetter(lang) + "Nm";
                  let desc = "desc" + capitalizeFirstLetter(lang) + "Nm";
                  let spot = el.comSpot;
                  let masterSpot = data.masterOptionSpots[index];
                  let hour = "00";
                  if (masterSpot.timehhNm != "") {
                    hour = masterSpot.timehhNm
                  }
                  let minute = "00";
                  if (masterSpot.timemmNm != "") {
                    minute = masterSpot.timemmNm
                  }
                  return (
                    <div className="w" key={el.orderNo}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="text-m text-bold w" style={{ color: "#121212" }} key={el.orderNo}>
                        <div className="w" style={{ display: "flex", flexDirection: "column" }}>
                          <img src={spot.photoReference} style={{ height: "180px", width: "100%", borderRadius: "10px" }} />
                          <div style={{ display: "flex", margin: "10px 0" }}>
                            <span>{el.orderNo}.</span> <span style={{ marginLeft: "10px" }}>{spot[title]}</span>
                            <input id={"input_" + el.orderNo} style={{ width: "1px", border: "1px", }} readOnly />
                            <div style={{ height: "15%", background: "#ffd775", borderRadius: "100px", fontSize: "12px", padding: "2px 9px", display: "inline-block", marginLeft: "auto" }}>
                              {hour}:{minute}
                            </div>

                          </div>
                          <div className="text-xsm m-1" style={{ color: "#454545", marginBottom: "20px" }}      >
                            <span>{masterSpot[desc]}</span>
                          </div>


                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* 작업중*/}


        </div>

      </div>
      <div className="w" style={{ textAlign: "center", marginTop: "30px" }}>
        <div className="w-9" style={{ display: "inline-block" }}>
        </div>
      </div>
    </div>
    </Modal>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",

    width: "80%",
    maxWidth: "700px",

    maxHeight: "80%",
  },
};


styled.active = `background : #000`;

const LanguageButton = styled.input`
  padding: 3px 10px;
  color: ${props => (props.active ? "#FFF" : "#000")};
  background: ${props => (props.active ? "#5B9BD5" : "#FFF")};
`

export default MyModal;
