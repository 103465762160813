import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";

import PageHeader from "../../components/views/Layout/Header/PageHeader";

import { server_url } from "../../server";

import backArrow from "../../images/icons/backArrow.png";
import img_add from "../../images/icons/file_add.svg";



function Comment(){
    const navigate = useNavigate();
    const location = useLocation();
    const[data,setData] = useState([]);
    const[commentList,setCommentList] = useState([]);
    const [email] = useState(window.sessionStorage.getItem("email"));
    let [ordNo,setOrdNo] = useState(0);
    let optionSq = location.state.optionSq


    const getComment = async() => {
        const res = await axios.post(`${server_url}/myplan/list/features/option/${location.state.optionSq}`).then((response) => {
            setCommentList(response.data);
            console.log(response.data.length);
            setOrdNo(response.data.length);
        });
       
    };


    useEffect(()=>{
        getComment();
        
    },[]);

    console.log(commentList);

    const addComment = (groupId)=>{
       let changeOrdNo = ordNo +1;
       setOrdNo(changeOrdNo);
       setCommentList([...commentList, {"codeId":"","groupId":groupId,"ordNo":changeOrdNo,"optionSq":optionSq,"titleEnNm":"","titleKoNm":""}]);
    }

    const handelText = (e,idx) => {
        const newValue = e.target.value;
        const updatedCommentList = commentList.map((comment, index) =>
            index === idx ? { ...comment, titleKoNm: newValue, titleEnNm: newValue } : comment
        );
        console.log("update");
        console.log(updatedCommentList);
        setCommentList(updatedCommentList);
    }

    const ListChangeUp = (idx,groupId) => {
        setCommentList((prevList) => {
            const newList = [...prevList];
            const targetGroup = prevList[idx].groupId;
        
            // Find the index of the previous element
            let prevPlanInfoIdx = -1;
            for (let i = idx - 1; i >= 0; i--) {
              if (prevList[i].groupId === groupId) {
                prevPlanInfoIdx = i;
                break;
              }
            }
        
            if (prevPlanInfoIdx !== -1) {
              // Swap the elements
              const temp = newList[prevPlanInfoIdx];
              newList[prevPlanInfoIdx] = newList[idx];
              newList[idx] = temp;

              // Update ordNo for the swapped elements
              newList[prevPlanInfoIdx].ordNo = prevPlanInfoIdx + 1;
              newList[idx].ordNo = idx + 1;
            }
        
            return newList;
          });
    }

    const ListChangeDown = (idx,groupId) => {
        setCommentList((prevList) => {
            const newList = [...prevList];
            const targetGroup = prevList[idx].groupId;
        
            // Find the index of the next 'PLANINFO' element
            let nextPlanInfoIdx = -1;
            for (let i = idx + 1; i < prevList.length; i++) {
              if (prevList[i].groupId === groupId) {
                nextPlanInfoIdx = i;
                break;
              }
            }
        
            if (nextPlanInfoIdx !== -1) {
              // Swap the elements
              const temp = newList[nextPlanInfoIdx];
              newList[nextPlanInfoIdx] = newList[idx];
              newList[idx] = temp;

              // Update ordNo for the swapped elements
              newList[nextPlanInfoIdx].ordNo = nextPlanInfoIdx + 1;
              newList[idx].ordNo = idx + 1;
            }
        
            return newList;
          });
    }

    const SaveComment = async() => {
        const res = await axios.post(`${server_url}/features/saveOption`,commentList).then((response) => {
            console.log(response);
            if(response.data.result = "success"){
                alert("코멘트가 수정되었습니다.")
                navigate(-1);
            } else{
                alert("Error.")
            }
        });
       
    };

    return(
        <div className="m-8">
            <PageHeader title="옵션 만들기" />
             <div style={{overflow:"auto",paddingBottom:"30px", paddingLeft:"20px"}}>
             <div style={{marginTop:"20px"}}>
                <span style={{fontWeight:"bold",fontSize:"18px",marginBottom:"10px",display:"inline-block"}}>옵션정보</span>
                {commentList.map((item,idx)=>(
                    item.groupId === 'OPTIONINFO' && (
                    <div key={idx} style={{marginTop:"5px"}}>
                        <input type="text" value={item.titleKoNm} onChange={(e)=>handelText(e,idx)} style={{height:"30px",outline:"none",boxSizing:"border-box"}}/>
                        <button onClick={()=>ListChangeUp(idx,"OPTIONINFO")} style={{height:"30px",padding:"5px"}}>△</button>
                        <button onClick={()=>ListChangeDown(idx,"OPTIONINFO")} style={{height:"30px",padding:"5px"}}>▽</button>  
                    </div>
                    )
                    
                ))}
             </div>
             <div style={{display:"flex",alignItems:"center",marginTop:"50px"}} onClick={()=>addComment("OPTIONINFO")}>
                    <img src={img_add} style={{width:"25px",height:"25px",marginRight:"10px"}}></img>
                    <span>플랜정보 추가</span>
            </div>
            <div style={{marginTop:"20px"}}>
                <span style={{fontWeight:"bold",fontSize:"18px",marginBottom:"10px",display:"inline-block"}}>예약방법</span>
                {commentList.map((item,idx)=>(
                    item.groupId === 'OPTIONBKG' && (
                    <div key={idx} style={{marginTop:"5px"}}>
                        <input type="text" value={item.titleKoNm} onChange={(e)=>handelText(e,idx)} style={{height:"30px",outline:"none",boxSizing:"border-box"}}/>
                        <button onClick={()=>ListChangeUp(idx,"OPTIONBKG")} style={{height:"30px"}}>△</button>
                        <button onClick={()=>ListChangeDown(idx,"OPTIONBKG")} style={{height:"30px"}}>▽</button>    
                    </div>
                    )
                ))}
            </div>
            <div style={{display:"flex",alignItems:"center",marginTop:"50px"}} onClick={()=>addComment("OPTIONBKG")}>
                    <img src={img_add} style={{width:"25px",height:"25px",marginRight:"10px"}}></img>
                    <span>예약방법 추가</span>
            </div>
            <div style={{marginTop:"20px"}}>
                <span style={{fontWeight:"bold",fontSize:"18px",marginBottom:"10px",display:"inline-block"}}>이용규정</span>
                {commentList.map((item,idx)=>(
                    item.groupId === 'OPTIONTNC' && (
                    <div key={idx} style={{marginTop:"5px"}}>
                        <input type="text" value={item.titleKoNm} onChange={(e)=>handelText(e,idx)} style={{height:"30px",outline:"none",boxSizing:"border-box"}}/>
                        <button onClick={()=>ListChangeUp(idx,"OPTIONTNC")} style={{height:"30px"}}>△</button>
                        <button onClick={()=>ListChangeDown(idx,"OPTIONTNC")} style={{height:"30px"}}>▽</button>    
                    </div>
                    )
                ))}
            </div>
            <div style={{display:"flex",alignItems:"center",marginTop:"50px"}} onClick={()=>addComment("OPTIONTNC")}>
                    <img src={img_add} style={{width:"25px",height:"25px",marginRight:"10px"}}></img>
                    <span>이용규정 추가</span>
            </div>

            <div style={{marginTop:"20px"}}>
                <span style={{fontWeight:"bold",fontSize:"18px",marginBottom:"10px",display:"inline-block"}}>취소규정</span>
                {commentList.map((item,idx)=>(
                    item.groupId === 'OPTIONCXLPLC' && (
                    <div key={idx} style={{marginTop:"5px"}}>
                        <input type="text" value={item.titleKoNm} onChange={(e)=>handelText(e,idx)} style={{height:"30px",outline:"none",boxSizing:"border-box"}}/>
                        <button onClick={()=>ListChangeUp(idx,"OPTIONCXLPLC")} style={{height:"30px"}}>△</button>
                        <button onClick={()=>ListChangeDown(idx,"OPTIONCXLPLC")} style={{height:"30px"}}>▽</button>    
                    </div>
                    )
                ))}
            </div>
            <div style={{display:"flex",alignItems:"center",marginTop:"50px"}} onClick={()=>addComment("OPTIONCXLPLC")}>
                    <img src={img_add} style={{width:"25px",height:"25px",marginRight:"10px"}}></img>
                    <span>취소규정 추가</span>
            </div>
            </div>

            <div style={{position: "fixed", bottom: 0,maxWidth: "600px",width:"100%"}}>
                <input type="button" value={"수정 완료"} onClick={()=>SaveComment()} style={{background:"#4f95d5",border:"none","borderRadius":"10px",color:"#fff",height:"30px",width:"100%"}}/>
            </div>
        </div>
    )

}

export default Comment;