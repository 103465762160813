import React, { useState } from "react";

function Naver() {
    const naverHtml = () => {
        return {
            __html:
          `<script src="../../naver2b9a0ec0b751a1d09204e819cb9f633c.html"></script>`
        }
    }

  return (
      <div
          dangerouslySetInnerHTML={naverHtml()}
      />

  );
}

export default Naver;
