import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { HeaderTitle } from "../../../../styles/GlobalStyle";

import gmpmainlogo from "../../../../images/gmp-mainlogo.png";
import newbailogo from "../../../../images/new/figma_logo.png";
import newlogo from "../../../../images/new/figma_logo.png";
import korean from "../../../../images/new/korean.svg"
import usa from "../../../../images/new/usa.svg"
import ct from "../../../../images/new/ct.svg"
import cs from "../../../../images/new/cs.svg"
import btnClose from "../../../../images/icons/btn_close.png";
import btnLClose from "../../../../images/icons/btn_close_l.png";
import homeMenu from "../../../../images/icons/homeMenu.png";
import "../../../../i18n/i18n";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Circle = styled.div`
  width: 81px;
  height: 24px;
  border-radius: 10%;
  background: #5c80ff;

  position: relative;
`;



function Header(props) {
  (localStorage.getItem("language") !== 'undefined' && localStorage.getItem("language") !== null) ? localStorage.getItem("language") : localStorage.setItem("language", "ko");
  // 다국어 관련 추가 (2023-03-22)
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    // document.getElementById("input_lang").focus();
    // document.getElementById("input_lang").blur();
  }, [props.userLang]);
  const [isLogined, setIsLogined] = useState(
    window.sessionStorage.getItem("email") && true
  );
  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("companyName"));

  const navigate = useNavigate();

  const [scrollWindow, setScrollWindow] = useState(0);
  const [language,setLanguage] = useState(localStorage.getItem("language"));
  const [fxCodeCd,setFxCodeCd] = useState(localStorage.getItem("fxCodeCd"));
  const [fxCode,setFxCode] = useState("₩") ;

  useEffect(() => {
    // Scroll 이벤트 처리
    const handleScroll = () => {
      if (window.scrollY > 1) {
        if (scrollWindow === 1) return;
        setScrollWindow(1);
        document.querySelector(".new_header")?.classList.add("on");
        document.querySelector(".new_scroll_top")?.classList.add("on");
      } else {
        setScrollWindow(0);
        document.querySelector(".new_header")?.classList.remove("on");
        document.querySelector(".new_scroll_top")?.classList.remove("on");
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up 이벤트 리스너
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollWindow]);

  useEffect(() => {
    //통화 Setting
    let fxCode;
    switch(fxCodeCd){
      case 'KRW':
        fxCode = "₩";
        break;
      case 'USD':
        fxCode = "$";
        break;
      case 'HKD':
        fxCode = "$";
        break;
      case 'RMB':
        fxCode = "¥";
        break;
      default:
        fxCode = "₩";
    }
    setFxCode(fxCode)
  }, [fxCodeCd]);

  const handleFxCodeChange = (code) => {
    localStorage.setItem("fxCodeCd", code);
    setFxCodeCd(code);
  };

  const handleLanguageChange = (lang) => {
    localStorage.setItem("language",lang);
    switch(lang){
      case "ko":
        handleFxCodeChange("KRW");
        break;
      case "en":
        handleFxCodeChange("USD");
        break;
      case "zhcht":
        handleFxCodeChange("HKD");
        break;
      case "zhchs":
        handleFxCodeChange("RMB");
        break;
    }
    setLanguage(lang);
  }



  const handleAllMenuClick = () => {
    const allMenu = document.querySelector(".new_all_menu");
    allMenu?.classList.add("on");
    const closeButton = allMenu?.querySelector(".close");
    closeButton?.addEventListener("click", () => {
      allMenu?.classList.remove("on");
    });
    const closeImg = allMenu?.querySelector(".closeImg");
    closeImg?.addEventListener("click", () => {
      allMenu?.classList.remove("on");
    });
  };

  const handleLanguageMenuClick = () => {
    const allMenu = document.querySelector(".new_language");
    allMenu?.classList.add("on");
    const closeButton = allMenu?.querySelector(".close");
    closeButton?.addEventListener("click", () => {
      allMenu?.classList.remove("on");
      window.location.reload();
    }); 
    const closeImg = allMenu?.querySelector(".closeImg");
    closeImg?.addEventListener("click", () => {
      allMenu?.classList.remove("on");
      window.location.reload();
    }); 
  }

  const handleScrollTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  return (
    <div>
      <div className="new_scroll_top" onClick={handleScrollTopClick}>TOP</div>
      <div className="new_language">
        <div className="row">
          <div className="menu" style={{textAlign:"right"}}>
          <div style={{display:"flex",alignItems:"center"}}>
              <img className="closeImg" style={{width:"24px",height:"24px",cursor:"pointer"}} src={btnLClose}></img>
              <p style={{fontWeight:700,margin:"20px 0px",marginLeft:"auto"}}>Language</p>
          </div>
            <ul >
              <li onClick={()=>handleLanguageChange("ko")}><a className={language === "ko" ? "on" : ""} style={{padding:"10px 0", justifyContent:"right"}}>Korean 한국어<img src={korean} style={{marginLeft:"10px"}}/></a></li>
              <li onClick={()=>handleLanguageChange("en")}><a className={language === "en" ? "on" : ""} style={{padding:"10px 0", justifyContent:"right"}}>English (US)<img src={usa} style={{marginLeft:"10px"}}/></a></li>
              <li onClick={()=>handleLanguageChange("zhcht")}><a className={language === "zhcht" ? "on" : ""} style={{padding:"10px 0", justifyContent:"right"}}>Chinese (Traditional) 中語<img src={ct} style={{marginLeft:"10px"}}/></a></li>
              <li onClick={()=>handleLanguageChange("zhchs")}><a className={language === "zhchs" ? "on" : ""} style={{padding:"10px 0", justifyContent:"right"}}>Chinese (Simplified) 中文<img src={cs} style={{marginLeft:"10px"}}/></a></li>
            </ul>
          </div>

          <div className="menu" style={{textAlign:"right"}}>
            <p style={{fontWeight:700,margin:"20px 0px"}}>Currency</p>
            <ul >
              <li onClick={()=>handleFxCodeChange("KRW")}><a  className={fxCodeCd === "KRW" ? "on" : ""} style={{padding:"10px 0", justifyContent:"right"}}>KRW (₩)</a></li>
              <li onClick={()=>handleFxCodeChange("USD")}><a  className={fxCodeCd === "USD" ? "on" : ""} style={{padding:"10px 0", justifyContent:"right"}}>USD ($)</a></li>
              <li onClick={()=>handleFxCodeChange("HKD")}><a  className={fxCodeCd === "HKD" ? "on" : ""} style={{padding:"10px 0", justifyContent:"right"}}>HKD ($)</a></li>
              <li onClick={()=>handleFxCodeChange("RMB")}><a  className={fxCodeCd === "RMB" ? "on" : ""} style={{padding:"10px 0", justifyContent:"right"}}>RMB (¥)</a></li>
            </ul>
          </div>
        </div>

        <div className="close" ></div>
      </div>

      <div className="new_all_menu">
        <div className="row">
          <div className="" style={{margin:"0px -40px",background:"#42A9EC"}}>
            <img className="closeImg" style={{marginLeft:"10px",marginTop:"10px",cursor:"pointer",width:"30px",height:"30px"}} src={btnClose}></img>
          </div>
          
          {!isLogined ? (
            <div>
              <div className="user">
              </div>
            </div>
          ) : (
            <div className="user">
              <p className="t1">내 계정</p>
              <p className="t2">
                <font>{email}</font>
                {companyName ? (<span>플랜 크리에이터</span>) : null}
              </p>
            </div>
          )}

          {!isLogined ? (
            <div className="head">
              <a href=""  onClick={() => {
                navigate("/login");
              }}> {t("MYPAGE.mypage01")}</a>
              <a href="" onClick={() => {
                navigate("/register");
              }}> {t("MYPAGE.mypage02")}</a>
            </div>
          ) : (
            <div className="head">
              {!companyName ? ( <a href="" style={{display:"none"}} onClick={() => {
                 navigate("/mypage/myoption");
              }}>내 상품 관리</a>):( <a href="" style={{display:"flex"}} onClick={() => {
                navigate("/mypage/myoption");
             }}>내 상품 관리</a>)}
              <a href=""  onClick={() => {
                alert(t("MYPAGE.mypagePOP2"));
                window.sessionStorage.removeItem("email");
                window.sessionStorage.removeItem("token");
                window.sessionStorage.removeItem("companyName");
                setIsLogined(!isLogined);
              }}>로그아웃</a>
            </div>
          )}
          <div className="menu">
            <ul>
              <li onClick={() => {
                if (!isLogined) {
                  alert(t("MYPAGE.mypagePOP1"));
                } else {
                  navigate("/mypage/myplan");
                }
              }}><a>{t("MYPAGE.mypage03")}</a></li>
              <li onClick={() => {
                if (!isLogined) {
                  alert(t("MYPAGE.mypagePOP1"));
                } else {
                  navigate("/mypage/makingPlan");
                }
              }}><a>{t("MYPAGE.mypage04")}</a></li>
              <li ><a>{t("MYPAGE.mypage05")}</a></li>
              <li onClick={() => {
                if (!isLogined) {
                  alert(t("MYPAGE.mypagePOP1"));
                } else {
                  navigate("/mypage/wishlist");
                }
              }}><a>{t("MYPAGE.mypage06")}</a></li>
              <li onClick={() => {
                navigate("/mypage/cs");
              }}><a>{t("MYPAGE.mypage07")}</a></li>
            </ul>
          </div>
          {isLogined && (
            <div className="menu">
              <ul>
                <li onClick={() => navigate("/mypage/changeInfo")}><a>회원정보 변경</a></li>
                <li onClick={() => navigate("/mypage/changePassword")}><a>비밀번호 변경</a></li>
              </ul>
            </div>
          )}
        </div>

        <div className="close" ></div>
      </div>
      <div className="new_header">
        <div className="type_row">
          <a className="btn_back" onClick={()=>navigate("-1")}>
            <i className="xi-angle-left"></i>
          </a>
          <a className="logo" onClick={()=>navigate("/")}>
            <img src={newbailogo}></img>
            <img src={newlogo}></img>
          </a>
          <div className="fr">
            <div className="money"  onClick={handleLanguageMenuClick}>
              <p id="div_lang" className="t1">{(localStorage.getItem("language") !== 'undefined' || localStorage.getItem("language") !== null) ? localStorage.getItem("language") : "KO"}</p>
              <p className="t1">|</p>
              <p className="t3">{(fxCode != undefined || fxCode != null) ? fxCode : "₩"}</p>
           
            </div>
            <div className="new_all_menu_btn" onClick={handleAllMenuClick}><i className="xi-bars"></i></div>
          </div>
          <p className="title"></p>
        </div>
      </div>
    </div>

  );
}

function setLanguageText(language) {
  let langText;
  switch (language) {
    case "ko":
      langText = "KO | ₩"
      break;
    case "en":
      langText = "EN | US$"
      break;
    case "zhchs":
      langText = "简 / HK$"
      break;
    case "zhcht":
      langText = "繁 / HK$"
      break;
    default:
      langText = "KO | ₩"
  }
  return langText;
}

function setNewLanguageText(language){
  let langText;
  switch (language) {
    case "ko":
      langText = "KO | ₩"
      break;
    case "en":
      langText = "EN | US$"
      break;
    case "zhchs":
      langText = "简 / HK$"
      break;
    case "zhcht":
      langText = "繁 / HK$"
      break;
    default:
      langText = "KO | ₩"
  }
  return langText.split("|");
}

export default Header;
