import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { server_url } from "../../server";

import backArrow from "../../images/icons/backArrow.png";
import img_search from "../../images/icons/search.svg";
import img_add from "../../images/icons/file_add.svg";



function PlanSearchOption() {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [activityYn, setActivityYn] = useState("");
    const [planSq, setPlanSq] = useState("");
    const [dayNo, setDayno] = useState(0);
    const [activity, setActivity] = useState([]);
    const [spot, setSpot] = useState([]);
    const [email] = useState(window.sessionStorage.getItem("email"));
    const [searchWord, setSearchWord] = useState("");
    const [title,setTitle] = useState("");


    useEffect(() => {
        if (location.state != null) {
            setData(location.state.data);
            setActivityYn(location.state.activityYn);
        }
    }, []);

    useEffect(() => {
        if (data != null && data.data != undefined) {
            console.log(data);
            setDayno(data.dayNo);
            setPlanSq(data.data.planSq);
            setTitle("액티비티");
        }
    }, [data]);

    useEffect(()=>{
        handleSearch();
    },[searchWord]);


    /* 젤 앞 대문자 설정 */
    const capitalizeFirstLetter = (str) => {
        if (str.length === 0) {
            return str;
        }

        const firstLetter = str.charAt(0).toUpperCase();
        const remainingLetters = str.slice(1);

        return firstLetter + remainingLetters;
    };






    const handleEnterKey = async (e) => {
        if (e.key == 'Enter') {
            let body = {
                searchWord: searchWord,
                activityYn : 'Y'
            }
            const res = await axios.post(`${server_url}/plan/option/search/toppickOption`, body).then((response) => {
                console.log(response);
                setActivity(response.data.list);
            });
        }
    }

    const handleSearch = async (e) => {
        console.log(searchWord);
        console.log(activityYn);
        let body = {
            searchWord: searchWord,
            activityYn : 'Y'
        }
        const res = await axios.post(`${server_url}/plan/option/search/toppickOption`, body).then((response) => {
            console.log(response);
            setActivity(response.data.list);
        });

    }

    const handleChange = (e) => {
        let value = e.target.value;
        setSearchWord(value);
    }

    const handleCategoryButton = (e) => {
        let value = e.target.value;
        setSearchWord(value);
    }




    const SaveComment = async () => {
        // isChecked가 true인 항목만 필터링
        const selectedList = [...activity, ...spot];
        const selectedActivity = selectedList.filter((el) => el.isChecked === true).map((el, index) => ({
            planSq: planSq,
            dayNo: dayNo,
            ordNo: index,
            optionSq: el.optionSq,
            startTimeNm: "",
            startEndNm: ""
        }));
        // selectedActivity 배열에는 isChecked가 true인 항목만 포함됩니다.
        console.log(selectedActivity);

        const res = await axios.post(`${server_url}/plan/slot/save`, selectedActivity).then((response) => {
            console.log(response);
            if(activityYn == "Y" && response.data.result == "success"){
                navigate(`/mypage/makingPlanSlot`, { state: data.data});
            }
           

        });


    };
    return (
        <div>
            <div className={"w"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", top: "0", backgroundColor: "#ffffff", marginTop: "20px" }}>
                <img src={backArrow} alt="" width="10px" height="15px" style={{ transform: "scaleX(-1)", marginLeft: "1%" }} className="c" onClick={() => { window.history.back(); }} />
                <div style={{ color: "5c80ff", fontWeight: "800", fontSize: "20px", margin: "auto", marginLeft: "30px", display: "flex", width: "100%" }}>
                    <input type="text" style={{ width: "100%" }} onKeyDown={handleEnterKey} value={searchWord} onChange={handleChange} />
                    <img src={img_search} style={{ width: "30px", marginLeft: "auto", transform: "translate(-40px)" }} onClick={handleSearch} />
                </div>
            </div>
            <div style={{ overflow: "auto", paddingTop: "30px" }}>
                <span style={{ fontWeight: "bold" }}>Day {dayNo} 추천 {title}</span>
                <div style={{ marginTop: "20px" }}>
                    <div>
                        <input type="button" value="홍콩" onClick={handleCategoryButton} />
                    </div>
                    <div style={{maxHeight:"600px", marginTop:"20px",overflow:"auto"}}>
                    {activity != undefined && activity.length > 0 && activity.map((el, index) => {
                        let title = "title" + capitalizeFirstLetter(language) + "Nm";
                        return (
                            <div key={el.optionSq} className="w-9" style={{ display: "flex", border: "1px solid #000" }}>
                                <img src={el.savenameNm} style={{ width: "100px", height: "100px" }} />
                                <div style={{ margin: "10px", display: "flex", flexDirection: "column", flex: 1, justifyContent: "center" }}>
                                    <span style={{ margin: "5px 0" }}>{el[title]}</span>
                                    <span style={{ margin: "5px 0", color: "#56CBF5" }}>{el.categoryCd}</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
                                    <input
                                        type="checkbox"
                                        style={{ height: "30px", width: "30px" }}
                                        checked={el.isChecked} // isChecked 상태에 따라 체크박스 상태 설정
                                        onChange={() => {
                                            const updatedActivity = [...activity];
                                            const updatedItem = { ...el, isChecked: !el.isChecked }; // isChecked 값을 반전
                                            const itemIndex = updatedActivity.findIndex((item) => item.optionSq === el.optionSq); // 고유 식별자로 요소 찾기
                                            if (itemIndex !== -1) {
                                                updatedActivity[itemIndex] = updatedItem;
                                                setActivity(updatedActivity);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>


            <div style={{ position: "fixed", bottom: 0,width:"100%", maxWidth: "600px" }}>
                <input type="button" value={"수정 완료"} onClick={() => SaveComment()} style={{ background: "#4f95d5", border: "none", "borderRadius": "10px", color: "#fff", height: "30px", width: "100%" }} />
            </div>
        </div>
    )

}

export default PlanSearchOption;