import React from "react";
import { useNavigate } from "react-router-dom";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import useDrag from "./useDrag";

import axios from "axios";
import { getTodayDate, parseDatewithDash } from "../../js/getDate";
import { server_url } from "../../server";

import { ImgTitle, Title } from "../../styles/GlobalStyle";
import { img_url } from "../../server";

import "../../i18n/i18n";
import { useTranslation } from "react-i18next";

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import v1 from "../../images/new/v01.jpg"
import v2 from "../../images/new/v02.jpg"
import v3 from "../../images/new/v03.jpg"

function NarrowScrolling(props) {
    // 다국어 관련 추가 (2023-03-22)
    const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const { dragStart, dragStop, dragMove, dragging } = useDrag();

  const handleDrag =
    ({ scrollContainer }) =>
    (ev) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div className="">

      {/* 도시일 때 */}
      {props.type === "city" && (
        <div>
          {/* <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide><a><div className="bg"></div><img src={v1} /></a></SwiperSlide>
              <SwiperSlide><a><div className="bg"></div><img src={v2} /></a></SwiperSlide>
              <SwiperSlide><a><div className="bg"></div><img src={v3} /></a></SwiperSlide>
            </Swiper> */}
          <ScrollMenu
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
          >
            {props.list.map((item) => {
                let imageUrl = "";
                if(item.savenameNm.includes("https://")){
                    imageUrl = item.savenameNm;
                } else{
                  imageUrl = img_url +"/"+item.typeCd+"/"+item.savenameNm;
                }
              return (
                <div
                  key={item}
                  style={{ margin: "0 10px" }}
                  onClick={() => {
                    if (!dragging) {
                      navigate(`/spots/${item.citySq}/${item.cityCd}`, {
                        state: {
                          departure: props.departure,
                          arrival: props.arrival,
                          item: item,
                        },
                      });
                    }
                  }}
                >
                  <div
                    style={{
                      width: "150px",
                      height: "170px",
                      // backgroundImage: `url(${promotion})`,
                      backgroundImage: `url(${imageUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",

                      borderRadius: "10px",
                    }}
                  ></div>
                  {/* <img
                    src={`${img_url}/${item.typeCd}/${item.savenameNm}`}
                    // src={item.img}
                    // src="https://source.unsplash.com/random"
                    alt=""
                    width="150px"
                    height="170px"
                    style={{
                      margin: "0 auto",
                      borderRadius: "10px",
                    }}
                  /> */}
                  <div style={{ fontWeight: "600" }}>{item.cityNm}</div>
                  <div style={{ fontSize: "12px", color: "#6b6b6b" }}>
                    {item.countPlan}{t('COMMON.01')}
                  </div>
                </div>
              );
            })}
          </ScrollMenu>
        </div>
      )}

      {/* 스팟일 때 */}
      {props.type === "spot" && (
        <div>
          <ScrollMenu
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
          >
            {props.list.map((item) => {
                let imageUrl = "";
                if(item.savenameNm.includes("https://")){
                    imageUrl = item.savenameNm;
                } else{
                  imageUrl = img_url +"/"+item.typeCd+"/"+item.savenameNm;
                }
              return (
                <div
                  key={item}
                  style={{ margin: "0 10px" }}
                  onClick={() => {
                    if (!dragging) {
                      console.log(item);
                      navigate(`/spot/${item.spotSq}/${item.spotCd}`, {
                        state: { item: item },
                      });
                    }
                  }}
                >
                  <div
                    style={{
                      width: "150px",
                      height: "170px",
                      // backgroundImage: `url(${promotion})`,
                      backgroundImage: `url(${imageUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",

                      borderRadius: "10px",
                    }}
                  ></div>
                  {/* <img
                    src={`${img_url}/${item.typeCd}/${item.savenameNm}`}
                    // src="https://source.unsplash.com/random"
                    alt=""
                    width="150px"
                    height="170px"
                    style={{ margin: "0 auto", borderRadius: "10px" }}
                  /> */}
                  <div style={{ fontWeight: "600" }}>{item.titleNm}</div>
                </div>
              );
            })}
          </ScrollMenu>
        </div>
      )}

      {/* 플랜일 때 */}
      {props.type === "plan" && (
        <div>
          <ScrollMenu
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
          >
            {props.list.map((item) => {
                let imageUrl = "";
                if(item.savenameNm.includes("https://")){
                    imageUrl = item.savenameNm;
                } else{
                  imageUrl = img_url +"/"+item.typeCd+"/"+item.savenameNm;
                }
              return (
                <div
                  key={item}
                  style={{ margin: "0 10px" }}
                  // onClick={() => {
                  //   if (!dragging) {
                  //     console.log(item);
                  //     console.log(props);
                  //     navigate(`/plans/${item.planSq}/${item.planCd}`, {
                  //       state: {
                  //         departure: props.departure,
                  //         arrival: props.arrival,
                  //         item: item,
                  //       },
                  //     });
                  //   }
                  // }}
                  onClick={async () => {
                    let body = {
                        userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
                        fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
                      today: getTodayDate(),
                      startDate: parseDatewithDash(props.departure),
                    };

                    console.log(body);

                    const getPlanDetail = async () => {
                      try {
                        const res = await axios.post(
                          `${server_url}/plans/${item.planSq}/${item.planCd}`,
                          body
                        );

                        return res;
                      } catch (e) {
                        console.log(e);
                      }
                    };

                    getPlanDetail().then((res) => {
                      navigate(`/plans/${item.planSq}/${item.planCd}`, {
                        state: { data: res.data, body: body },
                      });
                    });
                  }}
                >
                  <div
                    style={{
                      width: "150px",
                      height: "170px",
                      // backgroundImage: `url(${promotion})`,
                      backgroundImage: `url(${imageUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",

                      borderRadius: "10px",
                    }}
                  ></div>
                  {/* <img
                    src={`${img_url}/${item.typeCd}/${item.savenameNm}`}
                    // src="https://source.unsplash.com/random"
                    alt=""
                    width="150px"
                    height="170px"
                    style={{ margin: "0 auto", borderRadius: "10px" }}
                  /> */}
                  <div style={{ fontWeight: "600" }}>{item.titleNm}</div>
                </div>
              );
            })}
          </ScrollMenu>
        </div>
      )}

    {/* 플랜크리에이터일 때 */}
    {props.type === "PLNCreator" && (
        <div>
            <ScrollMenu
                onMouseDown={() => dragStart}
                onMouseUp={() => dragStop}
                onMouseMove={handleDrag}
            >
                {props.list.map((item) => {
                  console.log(item);
                  let imageUrl = "";
                    if(item.savenameNm.includes("https://")){
                        imageUrl = item.savenameNm;
                    } else{
                      imageUrl = img_url +"/"+item.typeCd+"/"+item.savenameNm;
                    }
                    console.log(imageUrl);
                    return (
                        <div
                            key={item}
                            style={{ margin: "0 10px" }}
                            onClick={async () => {
                                let body = {
                                    userLang: localStorage.getItem("language") !== 'undefined' ? localStorage.getItem("language") : "ko",
                                    fxCodeCd: localStorage.getItem("fxCodeCd") !== 'undefined' ? localStorage.getItem("fxCodeCd") : "KRW",
                                    today: getTodayDate(),
                                    startDate: parseDatewithDash(props.departure),
                                };

                                console.log(body);

                                const getPlanDetail = async () => {
                                    try {
                                        const res = await axios.post(
                                            `${server_url}/mgr/${item.pcSq}`,
                                            body
                                        );

                                        return res;
                                    } catch (e) {
                                        console.log(e);
                                    }
                                };

                                getPlanDetail().then((res) => {
                                    navigate(`/mgr/${item.pcSq}`, {
                                        state: { data: res.data, body: body },
                                    });
                                });
                            }}
                        >
                            <div
                                style={{
                                    width: "150px",
                                    height: "150px",
                                    backgroundImage: `url(${imageUrl})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundRepeat: "no-repeat",

                                    borderRadius: "100px",
                                }}
                            ></div>
                            {/* <img
                                src={`${img_url}/${item.typeCd}/${item.savenameNm}`}
                                // src="https://source.unsplash.com/random"
                                alt=""
                                width="150px"
                                height="170px"
                                style={{ margin: "0 auto", borderRadius: "10px" }}
                              /> */
                            }
                            <div style={{ fontWeight: "600" }}>{localStorage.getItem("language") == "ko" ? item.localNm : item.enNm}</div>
                        </div>
                    );
                })}
            </ScrollMenu>
        </div>
    )}
    </div>
  );
}

export default NarrowScrolling;
